import React from "react";
import { Link } from "react-router-dom";

function bMentalHealthBk() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">
              Your Mental Health is what matters to your kids!{" "}
            </h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Manual Therapy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">
                  {" "}
                  Value based health
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Physiotherapy</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/iStock-505229769.jpg"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">
                Parenting a child with a special need can pose stressors to
                parents, especially mothers beyond those experienced raising
                typically developing children.
              </h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                There are many opportunities to improve the mental health of
                parents of children with special needs given they are often in
                regular contact with early intervention and health services for
                their child. It is of crucial importance that mental health
                professionals who work with children are trained to posit the
                confidence, skills, resources or time to talk to parents about
                their own mental health. Also, it is essential to recognize that
                their child’s characteristics may demand special skills in
                addition to the general knowledge, attitudes, and practices
                needed by parents.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/mental_health1.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                {/* <!-- <h1 className="fs-54 fw-700" style="line-height: 26px;">Section title</h1> --> */}
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2">
                  There are several such professionals equipped to support the
                  mental health of parents of children with special needs as it
                  can be particularly challenging for these parents to
                  prioritise their own health and wellbeing, and so interactions
                  with adult practitioners may be essential. To effectively
                  support parents, it is important that child mental health
                  practitioners feel confident talking about parental mental
                  health, know the boundaries of their role and are
                  knowledgeable about referral pathways. Some of the
                  intervention strategies for parental mental health are family
                  systems programs, parent support/focused groups, therapy
                  sessions with clinical psychologists/child
                  psychologists/counselors etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock-1215198746.jpg"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Mental Health</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        > */}
                          <p className="mb-0 fs-34">
                            Your Mental Health is what matters to your kids!
                          </p>
                          {/* </Link> */}
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_6025.jpeg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">Move More</p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Immunity Tips
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost your immunity with these simple tips and
                                tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/P1013575.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Mental Health
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise the ultimate pill to kill stress and
                                anxiety Forever.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/8A0555.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Wearable Device
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How this wearable device can help you manage
                                your stress effortlessly?
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A0280.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                The science of running is an art.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/mental_health2.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">Child Health</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bChildNeed"
                                className="d-inline-block clr-2 h-100"
                              >
                                {/* <Link
                                href="b_child_need.html"
                                className="d-inline-block clr-2 h-100"
                              > */}
                                <p className="mb-0 fs-34">
                                  My child's needs are important - virtually or
                                  otherwise!
                                </p>
                                {/* </Link> */}
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        {/* <Link href="b_adhd.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/adhd.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                ADHD and Autism
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How is ADHD and Autism different or are they the
                                same as each other?{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bMentalHealthBk;
