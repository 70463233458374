import React from "react";
import { Link } from "react-router-dom";
import FaqService from "../../../services/FaqService/FaqService";

function advancedTherapy({ slideClickData }) {
  console.log("slideClickData", slideClickData);

  return (
    <>
      <section
        className="w-100 clearfix MotsTherapy py-5 mb-5"
        id="AdvanceTherapry"
      >
        <div className="container">
          <div className="TherapyLabs w-100 clearfix position-relative">
            <div
              className="owl-carousel owl-theme OwlSliderOne Therapyowlslider pt-5"
              id="TheHolisticOwl"
            >
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-lg-100">
                      <div className="therphyimg h-lg-100">
                        <img src="/images/8.png" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            The Most Advanced{" "}
                            <br className="d-lg-block d-none" />
                            analytics{" "}
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Pro Physio uses the most advanced testing systems
                            which includes muscle testing, movement analysis
                            using 3D technology or metabolic testing using CGM
                            devices. This enables us to deliver precision care
                            to our clients and enables the ultimate hyper
                            personalisation for better results.
                          </p>
                        </div>
                        <div className="learbtn pt-4 fw-600 HoverArrow">
                          <Link
                            to="prophysioWork"
                            className="rightarrow fs-24 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                          {/* <Link to="learn_prophysio_work.html" className="rightarrow fs-24 HoverImgChnge">
                                    Explore advance therapy
                                    <img src="/images/icons/icon5.png" className="d-inline-block"/>
                                  </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-lg-100">
                      <div className="therphyimg h-lg-100">
                        <img src="/images/9.jpg" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Creating an ecosystem of health professionals
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Whether you are at home or in the clinic. Whether
                            you are in pune, bangalore or hyderabad ProPhysio
                            has a wide range of solutions which includes home
                            health, remote health, tele health and in hospital
                            health to ensure the care provided is of the highest
                            quality and convenient at all times.
                          </p>
                        </div>
                        <div className="learbtn pt-4 fw-600 HoverArrow">
                          <Link
                            to="prophysioWork"
                            className="rightarrow fs-24 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-lg-100">
                      <div className="therphyimg h-lg-100">
                        <img src="/images/10.jpg" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Recommended by the best doctors
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Pro Physios range of allied healthcare solutions is
                            the most trusted brand amongst healthcare
                            professionals including orthopaedic surgeons,
                            neurologists, paediatricians, gynecologists and
                            various other specialties. ProPhysio ensures the
                            best post discharge care for patients at their
                            location of convenience.
                          </p>
                        </div>
                        <div className="learbtn pt-4 fw-600 HoverArrow">
                          <Link
                            to="prophysioWork"
                            className="rightarrow fs-24 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                          {/* <Link to="learn_prophysio_work.html" className="rightarrow fs-24 HoverImgChnge">
                                    Explore advance therapy
                                    <img src="/images/icons/icon5.png" className="d-inline-block"/>
                                  </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-lg-100">
                      <div className="therphyimg h-lg-100">
                        <img src="/images/11.jpg" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Integrated teams working together
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            When it comes to healthcare its always teamwork.
                            Teamwork between the surgeons, physicians,
                            therapists and nurses to ensure the patient recovers
                            completely and effectively. ProPhysio ensure that
                            this communication and teamwork exists at all times
                            during the healing process of the patients.
                          </p>
                        </div>
                        <div className="learbtn pt-4 fw-600 HoverArrow">
                          <Link
                            to="prophysioWork"
                            className="rightarrow fs-24 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-lg-100">
                      <div className="therphyimg h-lg-100">
                        <img src="/images/8.png" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Most advanced therapy lab
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Healing involves carefully analysing the health of
                            the individual through objective diagnostic tools
                            enabled with bio feedback tools that help us
                            understand what are the aspects that can be worked
                            on to improve health. We use modern diagnostic and
                            therapeutic tools to effective heal our clients.
                          </p>
                        </div>
                        <div className="learbtn pt-4 fw-600 HoverArrow">
                          <Link
                            to="prophysioWork"
                            className="rightarrow fs-24 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="counter"
              className="fs-12 MuliRegular clr-4 position-absolute counters SlideCount"
            ></div>
          </div>
        </div>
      </section>
      {/* <!-- Modal --> */}
      {/* <div id="myModal" className="modal fade" role="dialog">
        {console.log('slideClickData', slideClickData)}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title fs-24 fw-500">When should I see a
                physiotherapist?
              </h4>
            </div>
            <div className="modal-body fs-14">
              <p>Physiotherapists are experts in understanding movement and the       mechanics of the body. If you have any pains
                and weakness at your joints and muscles or have any questions related to the way your body moves, stands or
                sits then visit the physiotherapists. But this is not all! Physiotherapists specialise in various fields and
                specialities like orthopaedics where they work specifically on bones, joints and muscles. Cardiovascular
                therapy where they treat and manage the way the heart works and help in improving endurance and fitness.
                Paediatric therapists who work on how children can develop and achieve physical milestones like standing,
                sitting and running. There are also neuro therapists who work on rehabilitation in stroke and management of
                people with neuromotor conditions which may occur in the cases of parkinsonism and Alzheimer’s. Sports
                physiotherapists work specifically in endurance, balance and injury management in sports. Physiotherapists
                work closely with physicians, surgeons, coaches, speech and occupational therapists and nurses to
                effectively manage clients.
              </p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div id="myModal1" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Is therapy painful and a tough experience?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>Physiotherapy is the gentle application of certain motor (muscle and bone) initiation techniques that comes
                        from an in-depth understanding of body mechanics, musculature, joint structure, neuromotor behaviour and so
                        on. This rich knowledge enables physiotherapists to deliver a very targeted application of therapy that can
                        truly enable that function of the body to better perform. Physiotherapists are empathetic to pain and
                        understand pain very closely. A good therapist always ensures that the therapy process reduces pain and
                        positively impacts the body and the mind to lengthen the state of ease and reduce tension.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal2" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">How do I know what is the best program for me?
                      </h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>Only after a detailed assessment, a therapist can recommend a specific program. Every individual is unique
                        and so are their needs. Tailoring a therapy program involves careful evaluation of the individual’s present
                        physical condition, their past medical history and their expectations of the outcomes and from there we
                        derive a carefully curated plan that is beneficial for the client both from a short term and long term
                        perspective.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal3" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">How expensive is therapy? </h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>Therapy is usually pretty affordable as it has been constructed understanding the burden healthcare bills
                        bring to a family’s life. A ProPhysio we are conscious of that and strictly follow medical ethics guidelines
                        to ensure cost-effective affordable care solutions to our clients,
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal4" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Do I need a doctor's prescription to get physiotherapy?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No, you don't need a physicians or surgeons prescription to undergo therapy though in many cases we
                        recommend that you connect with your primary physician who has been connecting with yours and your families
                        health as well as connect us to them as we believe that by sharing our knowledge we can collaborate together
                        to ensure the highest quality of delivery of care.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal5" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">I have undergone surgery on my knees. Does physiotherapy help me walk?
                      </h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>Yes, definitely so. Once you undergo surgery. Maybe at joints or muscles, its is important that you consult
                        a physiotherapist who will design a simple and effective post-surgical care plan that can further help you
                        on your road to recovery and help you heal better.
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal6" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">My son has been diagnosed with autism. Is there any treatment?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal7" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">When should I see a speech therapist for my child?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal8" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">How does speech therapy help my child?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal9" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">My family member has had a stroke. Is there any treatment for that?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal10" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Do I visit a physiotherapist for back pain?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal11" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Does physiotherapy prevent me from getting surgery?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal12" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Are physiotherapists trained, health professionals?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal13" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Is it absolutely necessary to go for therapy or can it be avoided?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal14" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Are we safe in COVID-19 times to undergo therapy?</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal15" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Ques</h4>
                    </div>
                    <div className="modal-body fs-14 ">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="myModal16" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title fs-24 fw-500">Ques</h4>
                    </div>
                    <div className="modal-body fs-14">
                      <p>No Answer Yet.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-warning" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
          */}
    </>
  );
}

export default advancedTherapy;
