import React from "react";
import { Link } from "react-router-dom";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { useContext } from "react";
import MeetOtherTherapists from "../MeetOtherTherapists/meetOtherTherapists";
import TakeYourFirstStep from "../TakeYourFirstStep/takeYourFirstStep";
import BackToTop from "../BackToTop/backToTop";
import ReadyToTransfer from "../ReadyToTransfer/readyToTransfer";

function th_RishinTk() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;

  const bookNowHandle = () => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  return (
    <>
      <section
        className="w-100 clearfix py-5 therapists "
        id="Detailedtherapists"
      >
        <div className="container">
          <div className="therapists pb-2">
            <div className="row">
              <div className="col-12">
                <div className="backbtn">
                  <Link
                    to="therapistsExploare"
                    className="text-decoration-none"
                  >
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24">Back to all therapists</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="shruthidigde my-5">
              <div className="row">
                <div className="col-lg-4  col-md-8 mx-auto">
                  <div className="girlimg my-5 my-lg-0">
                    <img
                      src="/images/therapist/default_image.png"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="doctordata pl-sm-2 position-relative">
                    <div className="doctorname">
                      <h1 className="fw-600 mt-2">
                        <span className="w-100 d-inline-block float-left fs-60">
                          Rishin T K
                        </span>
                      </h1>
                    </div>
                    <div className="doctorsubject">
                      <p
                        className="fs-40 text-darkgray fw-500 mb-0"
                        style={{ opacity: "0.67" }}
                      >
                        Physiotherapist
                      </p>
                      <p className="fs-38 fw-600">
                        <span className="">3.5</span>{" "}
                        <span className="">yrs Exp.</span>
                      </p>
                    </div>
                    <div className="doctorthought">
                      <p className="fs-30  my-md-3 my-2 lh-30 clr_graylight MuliItalic">
                        “Difficult doesn't mean impossible. It simply means that
                        you have to work hard.”
                      </p>
                    </div>
                    <div className="doctorpara">
                      <p className="fs-22 text-black my-3 MuliLight">
                        Passionate physiotherapist, expertised in orthopedic
                        department. I have worked inside icu,
                        neurorehablitation, ortho, cardiac rehabilitation.
                        Currently I'm working in Pro Physio as a
                        Physiotherapist.
                      </p>
                    </div>
                    <div className="doctorlocation pt-md-4">
                      <div className="location pt-3">
                        <div className="row">
                          <div className="col-md-8">
                            <ul className="nav">
                              <li className="nav-item">
                                <Link className="locationicon d-inline-block">
                                  <img src="/images/icons/location.svg" />
                                </Link>
                              </li>
                              <li className="nav-item pl-3">
                                <span className="fs-22 text-darkgray MuliLight">
                                  Lorem ipsum dolor sit amet, consetetur
                                  <br />
                                  sadipscing elitr, sed diam nonumy.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <div className="booksession text-md-right text-center">
                              <div className="more_show ">
                                <Link
                                  className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1"
                                  onClick={bookNowHandle}
                                >
                                  Book a session
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
      <section
        className="clearfix w-100 prophysio pb-5 aboutdoctors therpy_services TherpyDewtails1"
        id="Aboutdoctors"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-60 fw-600">About Rishin T K</h1>
              </div>
            </div>
          </div>
          <div className="assurance p-md-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Expertise</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">Neuro rehabilitation</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Credentials</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Email: rishin@prophysio.co
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Achievements</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      During second wave covid period, I work as physiotherapist
                      in covid icu, I helped so many patients recover
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        Previous Experience
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      1)Sagar Apollo, Jaynagar benglr(2017-19) 2) Fortis
                      hospital(2016-17) 3)Physio care clinic (2020)
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        What I love about my job
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      The smile the patients gives after getting recovered.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        In my free time I like to
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">Traveling</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="more_show text-center pt-4">
                  <Link
                    className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1"
                    data-toggle="modal"
                    data-target="#claimFreeModal"
                  >
                    Claim your first free consultation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="w-100 clearfix my-lg-5 py-5 position-relative bg-lightgray mertherphy explore_sec_bg DetailedTherepistLists"
        id="Meetrherpysection">
        <div className="container py-sm-5 mt-sm-2">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="exploreheading">
                <h1 className="fs-72 fw-700 pb-sm-0 pb-4">Meet other therapists</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="explorebtn ">
                <ul className="nav w-100 text-center text-md-right d-inline-block therapists_menu_list">
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3" data-filter>
                      All
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter="physiotherapists">
                      Physiotherapists
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500 fs-20 text-black btn-outline-dark px-3"
                      data-filter="locations">
                      All Locations
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="doctorslist">
            <div className="row">
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/sanjana.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Sanjana Nair</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Consultant Physiotherapist</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">I am a Consultant Physiotherapist at Pro Physio. I am working at
                      Rainbow...</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/Preeti_01.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Dr.Preethi G</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Clinical Physiotherapist</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">I'm a person who is passionate about my career and would like to
                      make people's...</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/Shruthi__Digde.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Shruti Digde</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Operation Manager</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">I am a very hard working and sincere at my work. Currently leading
                      a team of...</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/Rajalakshmi.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">S. Rajalakshmi</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">I have done my Masters in Physiotherapy- Neurology from...</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/puja_singh.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Puja Singh</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">Hard working, sincere and Passionate about my work.</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/therapist/Basnett.jpg" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Sweata Basnett</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">Key accounts manager- musculoskeletal and orthopaedics</p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">I have completed my masters of...</p>
                  </div>
                  <div className="learbtn">
                    <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="showmore pt-5">
                  <div className="more_show text-center ">
                    <Link to="therapistsExploare" className="show_btn btn bg-blue text-white fs-22 br-24 px-4 py-2 ">Show
                      More </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="w-100 clearfix  py-5 mb-lg-3 stepbetter" id="Stepbettersection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="stephealth">
                <div className="findheading text-center ">
                  <h1 className="fs-34 fw-700 clr-2">Take your first step to a better health</h1>
                </div>
                <div className="more_show text-center  py-4">
                  <Link className="show_btn btn bg-blue fs-22 px-4 py-2 br-24 text-white">Get assessed now! </Link>
                </div>
                <div className="findparagrph text-center ">
                  <p className="fs-22 fw-500 clr-2 mb-0">Quick online assessment with our expert <br />physios free for the first
                    time!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting" id="ConnectReady">
        <div className="container-fluid py-md-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">Ready to transform your health?</h2>
            <p className="fs-22 MuliLight pt-2 mb-4">Getting ProPhysio services are as easy as a click away</p>
          </div>
          <div className="client pb-xl-5 ReadyToConnect">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/16.png)" }}>
                    <div className="trulyCare1 d_table_cell_none align-middle w-75">
                      <p className="fs-57 MuliBold lh-62 mb-0">Virtual <br />Session</p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book
                          now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div className="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/17.png)" }}>
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">Home <br />Visit</p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/18.png)" }}>
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">Visit <br />Clinic</p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="NotSureTo w-100 clearfix text-center pt-4">
            <h3 className="fs-45 MuliExtra pl-5">Not sure where to begin?</h3>
            <h4 className="fs-54 MuliLight pt-5"><img src="/images/icons/icon14.png" className="img-fluid" alt="No Images" /><span
              className="d-inline-block pl-5">Call us now at <a href="tel:+91 8197378444" className="clr-1">+91
                8197378444</a></span>
            </h4>
          </div>
        </div>
      </section> */}
      <MeetOtherTherapists />
      <TakeYourFirstStep />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}

export default th_RishinTk;
