import React from "react";
import { Link } from "react-router-dom";

function leadLifeSection() {
  return (
    <>
      <section
        className="w-100 clearfix BodyBetter BodyBetter_part_main py-5 LeadLifes mb-md-5"
        id="BestLifes"
        style={{ backgroundImage: "url(images/7.png)" }}
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6">
                <div className="BeterImgs w-100 clearfix d-lg-none">
                  <img
                    src="/images/7.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="BeterImgsTexts w-100 clearfix d-table">
                  <div className="d-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4">
                      Looking to lead a life that enriches you with the best
                      health?
                    </h2>
                    <p className="fs-22 mb-3 MuliLight col-lg-10 p-0">
                      Pro Physio tailors hyper personalised health programs that
                      put you on the path to a life that filled with good health
                      and happiness. Enroll now to start a journey with us.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4">
                      <Link className="btn BtnBook fs-20 fw-500 px-4">
                        Download App
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Lead Life Section ||---------------> */}
    </>
  );
}

export default leadLifeSection;
