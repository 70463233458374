import React from "react";

function ourPrincipleA() {
  return (
    <>
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="clearfix w-100 prophysio py-5 my-md-5 ourprincipal ourprincipal_sec WhatIDoNow"
        id="Ourprincipalsection"
      >
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="prohrading text-center">
                  <h1 className="fs-72 fw-700">Our Principles</h1>
                </div>
                <div className="OtherText1 w-100 py-lg-3 text-center">
                  <p className="MuliLight fs-34 ls-1">
                    These are the 4 principles that lay our foundation.
                  </p>
                </div>
              </div>
            </div>
            <div className="assurance OurPrinciples">
              <div className="row">
                <div className="col-md-6 p-lg-5 py-2">
                  <div className="trainedexpert">
                    <div className="InfoDetails d-flex py-4">
                      <div className="InfoImg1 d-inline-block">
                        <img
                          src="/images/icons/icon19.png"
                          className="img-fluid"
                          alt="No images"
                        />
                      </div>
                      <div className="InfoText1 d-inline-block">
                        <p className="fw-600 fs-42 ls-1 mb-0">
                          Positive transformation
                        </p>
                      </div>
                    </div>
                    <div className="phpoyherpara MuliLight">
                      <p className="fs-22 fw-500">
                        Most people see a Physiotherapist because they have pain
                        or are having difficulty doing something. Perhaps you
                        have sprained your ankle in a sports injury or been
                        involved in motor vehicle accident or just woke up a few
                        weeks ago with pain in a joint or muscle that has not
                        gone away.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-lg-5 py-2">
                  <div className="trainedexpert">
                    <div className="InfoDetails d-flex py-4">
                      <div className="InfoImg1 d-inline-block">
                        <img
                          src="/images/icons/icon20.png"
                          className="img-fluid"
                          alt="No images"
                        />
                      </div>
                      <div className="InfoText1 d-inline-block">
                        <p className="fw-600 fs-42 ls-1 mb-0">
                          Continuous learning
                        </p>
                      </div>
                    </div>
                    <div className="phpoyherpara MuliLight">
                      <p className="fs-22 fw-500">
                        We continually update our knowledge and skill by
                        collaborating with experts in the field of health,
                        medicine and rehabilitation science. This helps us
                        deliver gold standards in practice.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-lg-5 py-2">
                  <div className="trainedexpert">
                    <div className="InfoDetails d-flex py-4">
                      <div className="InfoImg1 d-inline-block">
                        <img
                          src="/images/icons/icon21.png"
                          className="img-fluid"
                          alt="No images"
                        />
                      </div>
                      <div className="InfoText1 d-inline-block">
                        <p className="fw-600 fs-42 ls-1 mb-0">
                          Value based health
                        </p>
                      </div>
                    </div>
                    <div className="phpoyherpara MuliLight">
                      <p className="fs-22 fw-500">
                        We deliver health through a value driven approach which
                        involves us as practitioners to look at every client
                        with empathy, love and compassion. We have chosen to
                        offer only the most personalised care.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-lg-5 py-2">
                  <div className="trainedexpert">
                    <div className="InfoDetails d-flex py-4">
                      <div className="InfoImg1 d-inline-block">
                        <img
                          src="/images/icons/icon22.png"
                          className="img-fluid"
                          alt="No images"
                        />
                      </div>
                      <div className="InfoText1 d-inline-block">
                        <p className="fw-600 fs-42 ls-1 mb-0">
                          Evidence based practice
                        </p>
                      </div>
                    </div>
                    <div className="phpoyherpara MuliLight">
                      <p className="fs-22 fw-500">
                        Our Therapists come from an advanced academic background
                        with expansive clinical practice and it is their
                        commitment and compassionate attitude that helps deliver
                        care that is truly uplifting and powerful.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
    </>
  );
}

export default ourPrincipleA;
