import React from "react";
import { Link } from "react-router-dom";

function expertsAbout() {
  return (
    <>
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix expertslider py-5 bg-clr-3"
        id="Expartslidersection"
      >
        <div className="container py-lg-5">
          <div className="row">
            <div className="col-md-5">
              <div className="expertcontent">
                <div className="BeliveTexts w-100 clearfix py-lg-5 pt-0">
                  <div className="MainTest1 w-100 pt-md-4">
                    <h2 className="fs-72 fw-700 ">
                      Experts who make it happen
                    </h2>
                  </div>
                  <div className="OtherText1 w-100 py-4">
                    <p className="MuliLight fs-22 ls-1 m-0">
                      We come with 50 years of collective experience from the
                      field of health care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutimageslider py-md-5 aboutimageslider_menu">
                <div
                  className="owl-carousel owl-theme OwlSliderOne AllOwlSliders Aboutusslider"
                  id="MeetExpertsOwl"
                >
                  <div
                    className="physiotherapist"
                    data-toggle="tooltip"
                    title="Pramod Ravindra, an entrepreneur, leads the Pro Physio group of companies including Plexus Healthcare focussed on health literacy. WIth masters in applying Physiotherapy from the United Kingdom with Presently 10 years of experience in Physiotherapy and healthcare management as well as clinical work. He has a deep passion in health and rehab technology and skill development in Physiotherapy. With special focus on rehab entrepreneurship"
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/20.png" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Promod Ravindra
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            Founder & CEO
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          Pramod Ravindra, an entrepreneur, leads the Pro Physio
                          group of companies including Plexus Healthcare
                          focussed on health literacy. WIth masters in applying
                          Physiotherapy from the United Kingdom with Presently
                          10 years of experience in Physiotherapy and healthcare
                          management as well as clinical work. He has a deep
                          passion in health and rehab technology and skill
                          development in Physiotherapy. With special focus on
                          rehab entrepreneurship
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist"
                    data-toggle="tooltip"
                    title="Manas is a qualified member of Institute of Chartered Accountants of India, Bachelors of Law, MBA (Entrepreneurship) from Babson College (USA). He has earlier worked with Ernst & Young as Global Tax Advisor . Quite early in his life,  Manas leadership qualities were there for all to see, which found their true manifestation in his emergence as an entrepreneur par excellence, with a vision for the betterment of the society."
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/21.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Manas Mehrotra{" "}
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            Chief Mentor
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          Manas is a qualified member of Institute of Chartered
                          Accountants of India, Bachelors of Law, MBA
                          (Entrepreneurship) from Babson College (USA). He has
                          earlier worked with Ernst & Young as Global Tax
                          Advisor . Quite early in his life, Manas leadership
                          qualities were there for all to see, which found their
                          true manifestation in his emergence as an entrepreneur
                          par excellence, with a vision for the betterment of
                          the society.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist"
                    data-toggle="tooltip"
                    title="Bhagwathi Yadav, is the director of services and operations at Pro Physio. WIth masters in Cardio Physiotherapy and presently 10 years of experience in Physiotherapy care. Her implementation of the vast knowledge base  in health and rehab builds a strong backbone for the company to surge forward. "
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/22.png" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Bhagwathi Yadav
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            COO{" "}
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          Bhagwathi Yadav, is the director of services and
                          operations at Pro Physio. WIth masters in Cardio
                          Physiotherapy and presently 10 years of experience in
                          Physiotherapy care. Her implementation of the vast
                          knowledge base in health and rehab builds a strong
                          backbone for the company to surge forward.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist "
                    data-toggle="tooltip"
                    title="Pratima Nagpal, someone with a strong roots in health and rehabilitation medicine with a masters in physiotherapy from UK and a PGDip from New Zealand has focussed her career on creating models for the highest quality of care and enhanced patient experience based on  value driven health. "
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/23.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Pratima Nagapal
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            CXO
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          Pratima Nagpal, someone with a strong roots in health
                          and rehabilitation medicine with a masters in
                          physiotherapy from UK and a PGDip from New Zealand has
                          focussed her career on creating models for the highest
                          quality of care and enhanced patient experience based
                          on value driven health.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist"
                    data-toggle="tooltip"
                    title="A master in cardio-pulmonary sciences with an experience in the field of renal rehabilitation for over 3.5 years. Her keen interest lies in promoting renal wellness programs for all ages and different lifestyle modifications in order to enhance quality of life and help adapt a healthy lifestyle to live happier and longer."
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/24.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Shruthi Digde
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            General Manager{" "}
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          A master in cardio-pulmonary sciences with an
                          experience in the field of renal rehabilitation for
                          over 3.5 years. Her keen interest lies in promoting
                          renal wellness programs for all ages and different
                          lifestyle modifications in order to enhance quality of
                          life and help adapt a healthy lifestyle to live
                          happier and longer.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist"
                    data-toggle="tooltip"
                    title="A master in cardio-pulmonary sciences with an experience in the field of renal rehabilitation for ove
                    "
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/25.png" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Varsha
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            Cluster Manager (Corporate Sales){" "}
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          {" "}
                          A master in cardio-pulmonary sciences with an
                          experience in the field of renal rehabilitation for
                          ove
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist "
                    data-toggle="tooltip"
                    title="A master in cardio-pulmonary sciences with an experience in the field of renal rehabilitation for ove"
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img
                          src="/images/shruti_g_small.jpg"
                          className="br-24"
                        />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Shruti Goradia
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            Manager (Marketing){" "}
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          {" "}
                          A master in cardio-pulmonary sciences with an
                          experience in the field of renal rehabilitation for
                          ove
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="physiotherapist "
                    data-toggle="tooltip"
                    title="A master in cardio-pulmonary sciences with an experience in the field of renal rehabilitation for ove"
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/27.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Shahul Hashir{" "}
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            Manager (Business Development)
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          A master in cardio-pulmonary sciences with an
                          experience in the field of renal rehabilitation for
                          ove
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="physiotherapist "
                    data-toggle="tooltip"
                    title="A master in cardio-pulmonary sciences with an experience in
                    the field of renal rehabilitation for ove"
                  >
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/29.png" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Sabrina Merchant Mody{" "}
                          </span>
                          <br />
                          <span className="text-white fs-16 MuliLight">
                            {" "}
                            Regional Manager (Medicover Hospitals){" "}
                          </span>
                        </p>
                        <p className="text-white fs-16 MuliLight">
                          A master in cardio-pulmonary sciences with an
                          experience in the field of renal rehabilitation for
                          ove
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
    </>
  );
}

export default expertsAbout;
