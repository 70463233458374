import React from "react";
import { Link } from "react-router-dom";
import { Route } from "react-router-dom";

function professional() {
  // Menu button selection
  if (window.screen.width < 768) window.$(".cross_btn").trigger("click");

  function initiateCarousel() {
    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section
        className="w-100 clearfix PhysioServicesTopBanner bg-grad-5 professionalBanner main_professional"
        id="professionalBanner"
      >
        <div className="PhysioGradientBackground position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/38.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 custom-pr-5 order-1">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 pt-3">
                  <div className="d-table-cell align-middle">
                    <div className="backbtn ">
                      <Link to="/" className="text-decoration-none">
                        <img src="/images/icons/icon23.png" />
                        <span className="fs-24 clr-1 fw-600">Back to Home</span>
                      </Link>
                      {/* <Link to="index.html" className="text-decoration-none">
                                    <img src="/images/icons/icon23.png"/>
                                    <span className="fs-24  clr-1 fw-600">Back to Home</span>
                                </Link> */}
                    </div>
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">
                        The right platform for your growth and practise
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4 pr-5">
                      <p className="MuliLight fs-28 ls-1 m-0 custom-pr-5">
                        ProPhysio is a platform designed keeping the therapists
                        and patients in mind in order to optimise and improve
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||--------------->
                <!---------------|| Section-2 ||---------------> */}
      <section
        className="clearfix w-100 prophysio py-5 my-lg-5 moblie_what WhatIDoNow"
        id="Physioprosection"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-72 fw-700">
                  Technology, more clients, better reach and just anything a
                  clinician needs
                </h1>
              </div>
              <div className="OtherText1 w-100 py-lg-3">
                <p className="MuliLight fs-22 ls-1">
                  Packed with advantages for therapists as part of the community
                </p>
              </div>
            </div>
          </div>
          <div className="assurance ">
            <div className="row">
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/1.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Access tools that bring value
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Access the most powerful app for healthcare professionals
                      that can alter the paradigm of practice. Access the most
                      critical information thats needed to ensure the patient is
                      being delivered the highest level of care. Also access.
                      Career tools that enhance practice for you as as a
                      clinician.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/2.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Be part of the community
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We’re young, we’re vibrant and we’re passionate about what
                      we do! If you think the same then join us. Let's talk
                      about all the things that make practice rewarding and
                      fulfilling. Also lets have some fun, socialse and mingle.
                      Join the conversation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/3.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Share and gain knowledge
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      When it comes to delivering care, continued learning plays
                      a critical role in the overall outcomes to the patients.
                      We are active learners and encourage everyone to keep
                      learning. Cause when the learning starts you keep growing.
                      So join us in discovering everything that makes health the
                      most rewarding experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/4.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Get realtime patient vitals
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Whether its measuring cardiac stress, continuous blood
                      glucose monitoring, sleep, breathing, stress or more. We
                      have a range of medical devices that can help measure this
                      information and ensure your patient is connected to you
                      when it means the most.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/5.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Stay organised and grow
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      ProPhysios tools such as calendar, virtual assistant,
                      telehealth, patient chat and other such features are
                      accessible with a single click of a button and this
                      ensures you as a clinician can manage your practice
                      efficiently and effectively.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/6.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Upgrade skills via workshops
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      When it comes to delivering care, continued learning plays
                      a critical role in the overall outcomes to the patients.
                      We are active learners and encourage everyone to keep
                      learning. Cause when the learning starts you keep growing.
                      So join us in discovering everything that makes health the
                      most rewarding experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||--------------->
                <!---------------|| Section-3 ||---------------> */}
      <section
        className="w-100 clearfix mobile_body BodyBetter approach py-md-5 LeadLifes mb-lg-5 HereAppIsNow pb-5"
        id="Changeapproach"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6">
                <div className="BeterImgs w-100 clearfix">
                  <img
                    src="/images/img79.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="BeterImgsTexts12 w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4">
                      Here is an App that changes everything in allied health
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Health to us means the perfect balance of the body which
                      helps you move, mind that makes you think and spirit that
                      enables you to feel. Our programs impact you and enable
                      you to live a perfect balanced life built on these
                      foundation stones.
                    </p>
                    <div className="w-100 clearfix BodyNutrition pt-3">
                      <ul className="w-100 list-unstyled MindBodyList mb-3 pb-sm-0 pb-3">
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon11.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Body</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon12.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Mind</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon13.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Nutrition</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4">
                      <Link
                        to="prophysioWork"
                        className="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Learn more
                      </Link>
                      {/* <Link to="learn_prophysio_work.html" className="btn BtnBook fs-20 fw-500 px-4">Learn more</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      {/* <!---------------|| Section-3 ||--------------->
                <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix bg-gray-2 doctor_serach_sec doctorchangeing SeeForDtrYou TakesOurBests"
        id="TakesOurBests"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6 order-2">
                <div className="BeterImgs w-100 clearfix singaldoctor text-lg-left text-center">
                  <img
                    src="/images/img68.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100 ">
                  <div className="d-table-cell align-middle TextMiddle pt-lg-0 pt-4">
                    <h2 className="fs-72 fw-700 mb-4">
                      Do you have what it takes to be the best?
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Explore a range of tools that you can use as a healthcare
                      practitioner to elevate and optimise your practice.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pb-4">
                      <Link
                        className="btn BtnBook fs-20 fw-500 px-4 "
                        data-toggle="modal"
                        data-target="#JoinTheCommunity"
                      >
                        Join the community
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||--------------->
                <!---------------|| Section-6 ||---------------> */}
      <section
        className="w-100 clearfix withcontact py-5 hospital_sec ReadyToWithUs"
        id="ReadyToWithUs"
      >
        <div className="container-fluid my-xl-5">
          <div className="HeadingDiv w-100 clearfix text-center my-md-0 my-md-3 mb-3">
            <h2 className="fs-72 lh-80 fw-700">
              Ready to transform your career?
            </h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Join the Prophysio community and accelerate your professional
              growth
            </p>
          </div>
          <div className="client py-xl-5">
            <div className="row">
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img88.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Access more clients
                        <br className="d-md-block d-none" /> and truly care{" "}
                        <br className="d-md-block d-none" /> for them
                      </p>
                      <div className="pt-3">
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#AccessMoreClients"
                        >
                          Register now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-gray-1 w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img89.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Upgrade to a <br />
                        multidisciplinary
                        <br /> practice
                      </p>
                      <div className="pt-3">
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#UpgradePractice"
                        >
                          Join now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img90.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Start a progressive
                        <br className="d-md-block d-none" /> health clinic
                      </p>
                      <div className="pt-3" style={{ marginTop: "77px" }}>
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#ProgressiveHealth"
                        >
                          Start now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6 ||--------------->
                <!---------------|| Section-7 ||---------------> */}
      <section
        className="w-100 clearfix bg-grad-3 doctorchangeing moblie_hospital"
        id="Doctorchangeingsection"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-5 order-2">
                <div className="BeterImgs w-100 clearfix align-bottom singaldoctor text-lg-left text-center pt-5">
                  <img
                    src="/images/img69.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-7 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <p className="fs-54 mb-sm-4 fw-500 lh-52">
                      Truly amazing and life changing, many of our post surgery
                      patients have shown speedy recovery thanks to the flawless
                      service from ProPhysio
                    </p>
                    <div className="w-100 clearfix mt-5">
                      <p className="fs-30 fw-700 m-0">Dr. Ramesh</p>
                      <p className="fs-30 fw-700 m-0">Head surgeon, Kamineni</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-7 ||---------------></div> */}

      {/* <!---------------|| Back To Top Start ||-----------------> */}
      <div
        id="BackToTop"
        className="bg-blue position-fixed text-white fs-30 lh-30 text-center br-50"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </div>
      {/* <!--whatsapp --> */}
      <a
        className="fixedButton"
        href="https://wa.me/918197378444"
        target="_blank"
      >
        <div className="roundedFixedBtn">
          <i
            className="fa fa-whatsapp"
            style={{ position: "relative", bottom: "3px" }}
          ></i>
        </div>
      </a>
      {/* <!---------------|| Back To Top End ||-----------------> */}
    </>
  );
}

export default professional;
