import React from "react";

function detailBackToTop() {
  function initiateCarousel() {
    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
    /**********|| Back To Top ||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      <div
        id="BackToTop"
        className="bg-blue position-fixed text-white fs-30 lh-30 text-center br-50"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </div>
    </>
  );
}

export default detailBackToTop;
