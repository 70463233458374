import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IntegratedHealth from "../../shared/IntegratedHealth/integratedHealth";
import { useContext } from "react";
import { BookNowContext } from "../BookNowContext/BookNowContext";
import HowWeHelped from "../../shared/HomeSections/HowWeHelpedSection/HowWeHelped";
import { Helmet } from "react-helmet";

function service() {
  //   const TestimonialServe = new HowWeHelpedService();
  //   const [testimonialList, setTestimonialList] = useState([]);
  //   const [search, setSearch] = useState({
  //     start: 0,
  //     perPage: 100,
  //     searchTxt: "",
  //     searchField: "",
  //   });

  //   useEffect(async () => {
  //     await testimonialApi();
  //   }, []);

  //   const testimonialApi = async () => {
  //     const activity = {};
  //     try {
  //       let response = await TestimonialServe.testimonialList(
  //         activity,
  //         search.start,
  //         search.perPage
  //       );
  //       if (response) {
  //         console.log("response", response.rows);
  //         setTestimonialList(response.rows);
  //       }
  //     } catch (err) {
  //       throw err;
  //     }
  //   };
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  const bookNowHandle = (i) => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  // console.log("tsetsets sdfsere r");
  // Menu button selection
  if (window.screen.width < 768) window.window.$(".cross_btn").trigger("click");

  function initiateCarousel() {
    /**********|| Custom Owl Js sliding in services ||**********/
    window.$("#DetailedS_tab_data").owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      dots: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        370: {
          items: 2.5,
          nav: true,
        },
        576: {
          items: 2,
          nav: true,
        },
        768: {
          items: 3,
          nav: true,
        },
        992: {
          items: 4,
          nav: true,
        },
        1200: {
          items: 5,
          nav: true,
        },
        1550: {
          items: 5,
          nav: true,
        },
        1900: {
          items: 6,
          nav: true,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Therapyowlslider Js||**********/
    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    /**********|| Custom Owl HelpedOwlSlider Js||**********/
    window.$("#HelpedOwlSlider").owlCarousel({
      center: true,
      items: 2,
      loop: true,
      autoplay: true,
      autoplayTimeout: 15000,
      margin: 10,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        768: {
          items: 1,
          nav: true,
          dots: false,
          margin: 0,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
        1200: {
          items: 2,
          nav: true,
          loop: true,
          dots: false,
        },
        1700: {
          items: 1.8,
          nav: true,
          loop: true,
          dots: false,
        },
      },
    });

    window
      .$(".HelpPeopleOwl .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:javascript:void(0);007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".HelpPeopleOwl .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:javascript:void(0);007ce2;}.b,.c{fill:none;}.c{stroke:javascript:void(0);fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Custom Owl HelpedOwlSlider Js||**********/

    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
    /**********|| Back To Top ||**********/

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>

      {/* Meta Tags Start */}
      <Helmet>
        <title>Physiotherapy Centers in Bangalore | Physiotherapy at Home | Pro Physio</title>
        <meta name="description" content="Pro Physio for physiotherapy services that help to treat a wide range of health and wellness issues including: Back and neck pain, muscle and joint pain." />
      </Helmet>
      {/* Meta Tags Ends */}

      {/* <!---------------|| Section One ||---------------> */}
      <section
        className="w-100 clearfix service_transformation PhysioServicesTopBanner bg-grad-green services_main_banner"
        id="PhysioTopBanner"
      >
        <div
          className="PhysioGradientBackground position-relative"
          style={{ overflow: "hidden" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <picture>
                    <source
                      media="(min-width:575px)"
                      srcset="/images/services/1.png"
                    />
                    <img
                      src="/images/services/img29.png"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              <div className="col-md-7 order-1">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div className="d-table-cell align-middle">
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">
                        Step-in and experience the transformation
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4">
                      <p className="MuliLight fs-28 ls-1 m-0">
                        Scientific and personalised health care with <br />
                        experts on your side.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||--------------->*/}
      {/* <!---------------|| Section-tab ||---------------> */}
      <section className="Detailed_Servies_tab" id="Detailed_Servies_tab_data">
        <div className="container3">
          <div className="w-100 clearfix  py-3 DetailedS_tab_menu bg-lightgray">
            <ul
              className="owl-carousel owl-theme OwlSliderOne DetailedS_tab_list nav nav-tabs px-5 border-0"
              id="DetailedS_tab_data"
            >
              <li className="nav-item">
                <Link to="detailedElderlyService" className="fs-20">
                  Elderly Care
                </Link>
              </li>
              <li className="nav-item">
                <Link to="physioTherapyService" className="fs-20">
                  Physio Therapy
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedLifestyleService" className="fs-20">
                  Lifestyle Medicine
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedMentalService" className="fs-20">
                  Mental Health
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedCardiacService" className="fs-20">
                  Cardiac Therapy
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedWomenHealth" className="fs-20">
                  Women’s Health
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedSpeechService" className="fs-20">
                  Speech Therapy
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedOccupationalService" className="fs-20">
                  Occupational Therapy
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedMovementService" className="fs-20">
                  Movement Science
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedUrbanService" className="fs-20">
                  Urban Health
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedPostNatalService" className="fs-20">
                  Post Natal Care
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedNeuroService" className="fs-20">
                  Neuro-Rehabilitation
                </Link>
              </li>
              <li className="nav-item">
                <Link to="detailedEducationService" className="fs-20">
                  Special Education
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-tab ||---------------> */}

      <IntegratedHealth />

      {/* <!---------------|| Section thired ||---------------> */}
      <section
        className="w-100 clearfix bg-lightpink happiness my-md-5 py-5 position-relative yoga_sec"
        id="physicalsection"
      >
        <div className="container yoga_sec_container">
          <div className="row">
            <div className="col-md-6 position-static order-md-2 order-2 ">
              <div className="BannerImg clearfix position-absolute mb-md-4 happyimg py-md-5 happyimg_bg">
                <picture className="yoga_image_main">
                  <source
                    media="(min-width:991px)"
                    srcset="/images/services/img13.png"
                  />
                  <img
                    src="/images/services/5.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </picture>
              </div>
            </div>
            <div className="col-lg-6 col-md-7 order-md-1 order-1">
              <div className="happinesscontent d-flex">
                <div className="quataion">
                  <img src="/images/icons/icon24.png" className="img-fluid" />
                </div>
                <div className="quataiontext py-5">
                  <p className="fs-65 font-italic lh-60 pt-4">
                    Happiness is a state of mental, physical and spiritual
                    well-being.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section thired ||--------------->
            <!---------------|| Section fourth ||---------------> */}
      <section
        className="w-100 clearfix healthwelness animy_slide animy_slide1 wellness_sec py-5 kideshealth  KidsHealths"
        id="Kidshealth"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lifeheading mt-xl-5 pt-md-5">
                <h1 className="fs-64 fw-700 clr-2">Kids health & wellness</h1>
              </div>
            </div>
          </div>
          <div className="procommunity w-100 pt-md-4 d-table pt-3">
            <div className="bg-clr-5  sportbulls position-relative manualtherphy animation_hover1 active  br-first">
              <div className="row m-0">
                <div className="lifedirectors pt-5 text-left healthpracties">
                  <img src="/images/services/6.png" className="img-fluid" />
                </div>
                <div className="pl-sm-5 pt-5 pr-0 col-md-9 order-1 sportsparagraph_main_menu pl-0">
                  <div className="lifeheading PhysioPsychologyHeading ">
                    <h5 className="fs-28 fw-700 clr-2 mb-3">Speech Therapy</h5>
                  </div>
                  <div className="animy_hover1">
                    <div className="sportsparagraph MuliLight pr-4">
                      <p className="fs-22 clr-2 fw-500 pt-3">
                        A communication impairment can be caused due to various
                        reasons such as a delay in the child’s speech and
                        language development, child in the spectrum of autism,
                        stuttering or unintelligible speech.
                      </p>
                    </div>
                    <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                      <li className="nav-item d-inline-block mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Articulation
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block px-2 mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                          Fluency
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block py-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Vocal Exercises
                        </Link>
                      </li>
                    </ul>
                    <div className="knowmore my-5">
                      <Link
                        to="detailedElderlyService"
                        className="rightarrow fs-24 fw-600 HoverImgChnge"
                      >
                        Know more
                        <img src="/images/icons/icon5.png" />
                      </Link>
                      {/* <Link
                        to="detailed_elderly_servies.html"
                        className="rightarrow fs-24 fw-600 HoverImgChnge"
                      >
                        Know more
                        <img src="/images/icons/icon5.png" />
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PhysioPsychology  bg-lightorange position-relative Psychologydoctor animation_hover1">
              <div className="pl-5 pt-5 pr-0 col-md-8 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">Special Education</h5>
                </div>
                <div className="animy_hover1">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      This is a service which addresses children's individual
                      differences and styles of learning. Special Education
                      addresses an array of needs in relation to learning
                      (Dyslexia,Down syndrome and Autism), communication,
                      emotional and behavioural difficulties.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Austism Care
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        Enrichment
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Life Skills
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link
                      to="detailed_elderly_servies.html"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className=" chologyImage">
                <div className="position-absolute urbanhealthimg ">
                  <img
                    src="/images/services/7.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="selfchild bg-clr-6 position-relative urbanhealth animation_hover1 br-last last_img">
              <div className="pl-5 pt-5 pr-0 col-md-9 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">
                    Occupational Therpahy
                  </h5>
                </div>
                <div className="animy_hover1">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      A Child’s life is made up of Occupation or Daily
                      activities.These Occupations include Playing, Learning and
                      Socializing.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Fine Motor
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        Gross Motor
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Functional Rehab
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link
                      to="detailed_elderly_servies.html"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="urbanimg ">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/8.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fourth ||--------------->
            <!---------------|| Section fourth ||---------------> */}
      <section
        className="w-100 clearfix controlhealth position-relative yogo_grand"
        id="Controlsection"
      >
        <div className=" bg-clr-3 position-relative BgImg12 clearfix py-5 my-xl-5">
          <div className="container position-relative pt-xl-5">
            <div className="HeadingDiv w-100 clearfix text-center">
              <h2 className="fs-72 lh-80 fw-700">
                Take control of your health
              </h2>
              <p className="fs-22 MuliLight pt-4">
                You health is what matters most, Reach out to us and get the
                best experts to
                <br /> care for you on your road to better health.
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="exercise text-center position-static mt-xl-4">
                  <div className="controlImg clearfix mb-xl-4 w-100">
                    <img
                      src="/images/services/9.png"
                      className="img-fluid"
                      alt="No Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="controlbtn text-center py-xl-5 mt-4 clearfix">
          <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pb-4 mt-xl-5">
            <Link
              className="btn BtnBook fs-22 fw-500 px-4 BtnImg1"
              data-toggle="modal"
              data-target="#claimFreeModal"
            >
              Claim your first free consultation
            </Link>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fivth ||--------------->
            <!---------------|| Section six ||---------------> */}
      <section
        className="w-100 clearfix healthwelness animy_slide animy_slide2 wellness_sec py-md-5 py-4 kideshealth LifeWorkshop"
        id="LifeWorkshops"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lifeheading mt-5 pb-0">
                <h1 className="fs-64 fw-700 clr-2">Performance and wellness</h1>
              </div>
            </div>
          </div>
          <div className="procommunity w-100 pt-md-4 d-table">
            <div className="bg-clr-7  sportbulls position-relative manualtherphy animation_hover2 br-first">
              <div className="row m-0">
                <div className="lifedirectors pt-5 text-left healthpracties">
                  <img src="/images/services/10.png" className="img-fluid" />
                </div>
                <div className="pl-sm-5 pt-5 pr-0 col-md-8 order-1 sportsparagraph_main_menu">
                  <div className="lifeheading PhysioPsychologyHeading ">
                    <h5 className="fs-28 fw-700 clr-2 mb-3">
                      Lifestyle Medicine
                    </h5>
                  </div>
                  <div className="animy_hover2">
                    <div className="sportsparagraph MuliLight pr-4">
                      <p className="fs-22 clr-2 fw-500 pt-3">
                        A fulfilled life comes from positive health. Our
                        lifestyle health solution integrated with nutrition,
                        exercise and a healthy mind positively influence the
                        health of individuals.
                      </p>
                    </div>
                    <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                      <li className="nav-item d-inline-block mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Nutrition
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block px-2 mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                          Gut Health
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block py-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Food Science
                        </Link>
                      </li>
                    </ul>
                    <div className="knowmore my-5">
                      <Link
                        to="detailedElderlyService"
                        className="rightarrow fs-24 fw-600 HoverImgChnge"
                      >
                        Know more
                        <img src="/images/icons/icon5.png" />
                      </Link>
                      {/* <Link
                        to="detailed_elderly_servies.html"
                        className="rightarrow fs-24 fw-600 HoverImgChnge"
                      >
                        Know more
                        <img src="/images/icons/icon5.png" />
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PhysioPsychology  bg-clr-8 position-relative Psychologydoctor animation_hover2 active">
              <div className="pl-5 pt-5 pr-0 col-md-8 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">Movement Science</h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      Biohacking is the new way of living. Feeling tired and
                      tired? Putting on too much weight? Frequent bad moods?
                      Sleep is disturbed? Bad digestion? These are symptoms of
                      related poor lifestyle and living.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Gait
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        Prehab
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Analytics
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" chologyImage">
                <div className="position-absolute urbanhealthimg ">
                  <img
                    src="/images/services/11.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="selfchild bg-clr-9 position-relative urbanhealth animation_hover2 br-last">
              <div className="pl-5 pt-5 pr-0 col-md-8 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">
                    Sports Rehabilitation
                  </h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      Physiotherapists are the movement specialists who
                      determine the core root of your problem and treat the
                      cause, not just the symptoms.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        ACL Rehab
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        kinesiology
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        FMS
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="urbanimg ">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/12.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||--------------->
            <!---------------|| Section sevanth ||---------------> */}
      <section
        className="w-100 clearfix lifechanging py-md-5 pt-3 pb-5 babycare animy_slide animy_slide3"
        id="Babycaresection"
      >
        <div className="container">
          <div className="row mb-md-4 mb-md-3">
            <div className="col-12">
              <div className="lifeheading mt-xl-5 mt-md-4">
                <h1 className="fs-64 fw-700 clr-2">
                  Rehabilitation & Specialised care
                </h1>
              </div>
            </div>
          </div>
          <div className="PhysioPsychology mt-md-0 mt-5 bg-clr-10 position-relative Psychologydoctor animation_hover3 br-first">
            <div className="pl-3 pt-5 pr-0 col-md-10 order-md-1 sportsparagraph_main_menu">
              <div className="lifeheading PhysioPsychologyHeading ">
                <h5 className="fs-28 fw-700 clr-2 mb-3">
                  Post <br /> Natal Care
                </h5>
              </div>
              <div className="animy_hover3">
                <div className="sportsparagraph MuliLight pr-4">
                  <p className="fs-22 clr-2 fw-500 pt-3">
                    Physiotherapists are the movement specialists who determine
                    the core root of your problem and treat the cause, not just
                    the symptoms.
                  </p>
                </div>
                <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                  <li className="nav-item d-inline-block mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Manual Therapy
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2 mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                      Value based health
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block py-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Physiotherapy
                    </Link>
                  </li>
                </ul>
                <div className="knowmore my-5">
                  <Link
                    to="detailedElderlyService"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link>
                  {/* <Link
                    to="detailed_elderly_servies.html"
                    className="rightarrow fs-24 fw-600"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className=" chologyImage">
              <div className="position-absolute urbanhealthimg ">
                <img
                  src="/images/services/13.png"
                  alt="Psychology"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="PhysioPsychology  bg-clr-11 position-relative Psychologydoctor animation_hover3">
            <div className="pl-3 pt-5 pr-0 col-md-10 order-md-1 sportsparagraph_main_menu">
              <div className="lifeheading PhysioPsychologyHeading ">
                <h5 className="fs-28 fw-700 clr-2 mb-3">
                  Women’s <br /> Health
                </h5>
              </div>
              <div className="animy_hover3">
                <div className="sportsparagraph MuliLight pr-4">
                  <p className="fs-22 clr-2 fw-500 pt-3">
                    After the baby is born, many women experience weakness in
                    either the abdominal or pelvic floor muscles. Some suffer
                    from pain over the lower back , pelvis or neck regions.
                  </p>
                </div>
                <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                  <li className="nav-item d-inline-block mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Manual Therapy
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2 mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                      Value based health
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block py-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Physiotherapy
                    </Link>
                  </li>
                </ul>
                <div className="knowmore my-5">
                  <Link
                    to="detailedElderlyService"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link>
                  {/* <Link
                    to="detailed_elderly_servies.html"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className=" chologyImage">
              <div className="position-absolute urbanhealthimg ">
                <img
                  src="/images/services/14.png"
                  alt="Psychology"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="selfchild bg-blue-gray position-relative urbanhealth animation_hover3  active">
            <div className="pl-3 pt-5 pr-0 col-md-10 order-md-1 sportsparagraph_main_menu">
              <div className="lifeheading PhysioPsychologyHeading ">
                <h5 className="fs-28 fw-700 clr-2 mb-3">
                  Neuro Rehabilitation
                </h5>
              </div>
              <div className="animy_hover3">
                <div className="sportsparagraph MuliLight pr-4">
                  <p className="fs-22 clr-2 fw-500 pt-3">
                    Speech pathologists are the specialists who deal with
                    communication impairments and swallowing disorders.
                  </p>
                </div>
                <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                  <li className="nav-item d-inline-block mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Manual Therapy
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2 mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                      Value based health
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block py-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Physiotherapy
                    </Link>
                  </li>
                </ul>
                <div className="knowmore my-5">
                  <Link
                    to="detailedElderlyService"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link>
                  {/* <Link
                    to="detailed_elderly_servies.html"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="urbanimg ">
              <div className="position-absolute urbanhealthimg">
                <img
                  src="/images/services/15.png"
                  alt="Psychology"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="selfchild bg-clr-12 position-relative urbanhealth animation_hover3">
            <div className="pl-3 pt-5 pr-0 col-md-10 order-md-1 sportsparagraph_main_menu">
              <div className="lifeheading PhysioPsychologyHeading ">
                <h5 className="fs-28 fw-700 clr-2 mb-3">
                  Geriatric <br /> Care
                </h5>
              </div>
              <div className="animy_hover3">
                <div className="sportsparagraph MuliLight pr-4">
                  <p className="fs-22 clr-2 fw-500 pt-3">
                    Aging, a normal process without any major medical conditions
                    is associated with a variety of biological changes in the
                    body.
                  </p>
                </div>
                <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                  <li className="nav-item d-inline-block mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Fall Prevention
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2 mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                      Arthiritis Care
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block py-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Wellness
                    </Link>
                  </li>
                </ul>
                <div className="knowmore my-5">
                  <Link
                    to="detailedElderlyService"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link>
                  {/* <Link
                    to="detailed_elderly_servies.html"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="urbanimg ">
              <div className="position-absolute urbanhealthimg">
                <img
                  src="/images/services/16.png"
                  alt="Psychology"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="selfchild bg-clr-13 br-last position-relative urbanhealth animation_hover3">
            <div className="pl-3 pt-5 pr-0 col-md-10 order-md-1 sportsparagraph_main_menu">
              <div className="lifeheading PhysioPsychologyHeading ">
                <h5 className="fs-28 fw-700 clr-2 mb-3">
                  Cardiac <br /> Therapy
                </h5>
              </div>
              <div className="animy_hover3">
                <div className="sportsparagraph MuliLight pr-4">
                  <p className="fs-22 clr-2 fw-500 pt-3">
                    Cardiac Rehabilitation (CR) is a medically supervised
                    program designed to improve your cardiovascular health if
                    you have experienced heart attack, heart failure,
                  </p>
                </div>
                <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                  <li className="nav-item d-inline-block mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Hypertention
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2 mt-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                      Preventive
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block py-2">
                    <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                      Post Surgical
                    </Link>
                  </li>
                </ul>
                <div className="knowmore my-5">
                  <Link
                    to="detailedElderlyService"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link>
                  {/* <Link
                    to="detailed_elderly_servies.html"
                    className="rightarrow fs-24 fw-600 HoverImgChnge"
                  >
                    Know more
                    <img src="/images/icons/icon5.png" />
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="urbanimg ">
              <div className="position-absolute urbanhealthimg">
                <img
                  src="/images/services/17.png"
                  alt="Psychology"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      {/* <!---------------|| Section sevanth ||--------------->
            <!---------------|| Section eight ||---------------> */}
      <section
        className="w-100 clearfix callus servicecallus py-5  my-xl-5 my-md-4 bg-brown"
        id="Servivecallussection"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="findheading text-center py-3">
                <h1 className="fs-72 fw-700 text-white">
                  Cant find what you are
                  <br /> looking for?
                </h1>
              </div>
              <div className="findparagrph text-center py-1">
                <p className="fs-22 MuliLight text-white">
                  We are more than happy to help. Just give us a call or give us
                  your <br />
                  phone number so that we can call you.
                </p>
              </div>
              <div className="findcallnow text-center py-sm-3">
                <a href="tel:+91 8197378444" className="text-decoration-none">
                  <img
                    src="/images/icons/icon18.png"
                    className="pr-3 img-fluid"
                  />
                  <span className="fs-54 MuliLight text-white">
                    Call us now at <span className="clr-1">+91 8197378444</span>
                  </span>
                </a>
              </div>
              <div className="findhelp text-center py-4">
                <p className="fs-32 MuliLight text-white lh-38">
                  Need help? Get a call back from our <br />
                  support team
                </p>
              </div>
              <div className="more_show text-center pt-2 pb-4">
                <Link
                  data-toggle="modal"
                  data-target="#PartneringWithUS"
                  className="show_btn btn bg-blue fs-22 px-4 py-2 br-24 text-white"
                >
                  Get a call back{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section eight ||--------------->
            <!---------------|| Section nine ||---------------> */}

      <HowWeHelped />
      {/* <!---------------|| Section nine ||--------------->
            <!---------------|| Connect us Section ||---------------> */}
      <section
        className="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting"
        id="ConnectReady"
      >
        <div className="container-fluid py-md-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">
              Ready to transform your health?
            </h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Getting ProPhysio services are as easy as a click away
            </p>
          </div>
          <div className="client pb-xl-5 ReadyToConnect">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/16.png)" }}
                  >
                    <div className="trulyCare1 d_table_cell_none align-middle w-75">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Virtual <br />
                        Session
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 Virtual
            Session BtnImg1"
                          onClick={() => bookNowHandle(0)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/17.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Home <br />
                        Visit
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(1)}
                        >
                          Book now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/18.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Visit <br />
                        Clinic
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(2)}
                        >
                          Book now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="NotSureTo w-100 clearfix text-center pt-4">
            <h3 className="fs-45 MuliExtra pl-5">Not sure where to begin?</h3>
            <h4 className="fs-54 MuliLight pt-5">
              <img
                src="/images/icons/icon14.png"
                className="img-fluid"
                alt="No Images"
              />
              <span className="d-inline-block pl-5">
                Call us now at{" "}
                <a href="tel:+91 8197378444" className="clr-1">
                  +91 8197378444
                </a>
              </span>
            </h4>
          </div>
        </div>
      </section>
      {/* <!---------------|| Connect Us Section ||---------------></div> */}

      {/* <!---------------|| Back To Top Start ||-----------------> */}
      <div
        id="BackToTop"
        className="bg-blue position-fixed text-white fs-30 lh-30 text-center br-50"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </div>
      {/* <!--whatsapp --> */}
      <a
        className="fixedButton"
        href="https://wa.me/918197378444"
        target="_blank"
      >
        <div className="roundedFixedBtn">
          <i
            className="fa fa-whatsapp"
            style={{ position: "relative", bottom: "3px" }}
          ></i>
        </div>
      </a>
      {/* <!---------------|| Back To Top End ||-----------------> */}
    </>
  );
}

export default service;
