import React from "react";

function awardSection() {
  return (
    <>
      {/* <!---------------|| Section-6 ||---------------> */}
      <section
        className="w-100 clearfix awordsexellence py-md-5"
        id="Awardaffiliations"
      >
        <div className="container-fluid">
          <div className="bg-grad-8 br-24 py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="awordstext h-100 d-table">
                    <div className="BeliveTexts w-100 clearfix py-md-5 d-table-cell align-middle">
                      <div className="MainTest1 w-100 pt-md-4">
                        <h2 className="fs-72 fw-700 ">
                          Awards &<br /> Affiliations
                        </h2>
                      </div>
                      <div className="OtherText1 w-100 py-4">
                        <p className="MuliLight fs-22 ls-1 m-0">
                          2019 Times Health Awards - Service Excellence in
                          Innovation in Physiotherapy Practice{" "}
                        </p>
                        <p className="MuliLight fs-22 ls-1 m-0">
                          {" "}
                          2019 Times Health Awards - Service Excellence in Renal
                          Rehabilitation{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="timeshealth text-center">
                    <div className="exellenceimg py-4">
                      <img src="/images/img45.png" className="img-fluid" />
                    </div>
                    <div className="timestext ">
                      <p className="fw-600 fs-24 ls-1 ">
                        Dr. Pramod Ravindra and Dr. Bhagwathi Yadav
                      </p>
                      <p className="fs-22 ls-1 m-0">Pro Physio Healthcare</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6 ||---------------> */}
    </>
  );
}

export default awardSection;
