import React from "react";
import { Link } from "react-router-dom";

function ourPartners() {
  return (
    <>
      {/* <!---------------|| Our Partners Section ||---------------> */}
      <section
        className="w-100 clearfix ourpartner py-5 bg-clr-1"
        id="Ourpartners"
      >
        <div className="container py-lg-5">
          <div className="OurPartnersImg w-100 clearfix">
            <div className="HeadingDiv w-100 clearfix text-center">
              <h2 className="fs-72 lh-80 fw-700">Our partners in Healthcare</h2>
              <p className="fs-22 MuliLight pt-2 mb-4">
                We are proud of our associations with esteemed institutions of
                great repute. Our endeavor to care for patients and bring out
                the best outcomes.
              </p>
            </div>
            <div className="healthcarepart">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-6">
                  <div
                    className="healthimg py-lg-5 py-4"
                    data-toggle="tooltip"
                    title="Apollo Health and Lifestyle Limited (AHLL), a subsidiary of Apollo Hospitals Enterprise Limited (AHEL), is one of the largest players in the retail healthcare segment in India. The Company today runs the largest chain of standardized primary health care Models – Multispeciality Clinics, under the brand name “Apollo Clinic” in the India & the Middle East, Diabetes Management Clinics under Apollo Sugar and Diagnostic Centers under Apollo Diagnostics. The Company also Operates Specialty formats under Apollo Cradle (a Centre for Women &  Children) and Apollo Spectra Hospitals (a hospital for minimally invasive surgeries)."
                  >
                    <Link>
                      <img src="/images/logos/logo1.png" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div
                    className="healthimg py-lg-5 py-4"
                    data-toggle="tooltip"
                    title="Pro Physio in association with 315WA  creates healthy living spaces for people at work. Be it exercise programs in break hours of work or health and wellness programs that can help people continue to stay productive at work and balanced in life. "
                  >
                    <Link>
                      <img src="/images/logos/logo2.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="Invictus  believes looking at the human body through the lens of integrated sciences gives us an understanding of what makes your body tick! and how best we design a solution that addresses your body and its workings. "
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo3.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="At Kara we understand every child is unique and has the ability to express themselves in beautiful and vibrant ways. We at Kara explore the space of inclusive learning caring for children with special needs and helping them blossom in healthy productive fulfilled individuals."
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo4.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="Pro Physio in association with NU hospitals bas setup a centre for advanced renal rehabilitation where individuals suffering from Kidney disease can be provided the highest level of care to help them cope and recover from renal conditions. "
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo5.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="Sakra combines synergies of these large entities to provide cutting-edge medical technologies and herald a change in the current healthcare systems and processes. Sakra is dedicated to ensuring good health of the community."
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo9.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="Pro Physio in association with Prakriya hospitals has set up a centre for advanced integrated physiotherapy rehabilitation which offers a wide range of services for individuals suffering from various conditions and help them adapt a positive healthy lifestyle."
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo7.png" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-3 col-md-3 col-6"
                  data-toggle="tooltip"
                  title="At Rainbow children's hospital we care for children with an integrated approach. Our flagship program Pragathi creates a solution for integrated care and management of children with special needs. "
                >
                  <div className="healthimg py-lg-5 py-4">
                    <Link>
                      <img src="/images/logos/logo8.png" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Our Partners Section ||---------------> */}
    </>
  );
}

export default ourPartners;
