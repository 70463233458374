import React from "react";
import { Link } from "react-router-dom";

function lifeChangingHub() {
  return (
    <>
      {/* <!---------------|| Section fivth ||---------------> */}
      <section
        className="w-100 clearfix lifechanging bg-clr-1 py-5 winnergirl lifechanging_part lifechanging_part1 animy_slide animy_slide4 animy_slide2"
        id="Workshopes"
      >
        <div className="container-fluid py-md-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 fw-700 lh-92">Life changing Workshops</h2>
            <p className="fs-22 MuliLight pt-xl-4">
              Become a member of the Pro Community to attend free workshops and
              much more.
            </p>
          </div>
          <div className="procommunity w-100 pt-5 d-table">
            <div className="bg-clr-2 sportbulls position-relative br-first manualtherphy animation_hover2">
              <div className="row m-0">
                <div className="lifedirectors pt-5 text-left healthpracties">
                  <img src="/images/services/10.png" className="img-fluid" />
                </div>
                <div className="pl-3 pt-5 pr-0  col-md-8 order-1 sportsparagraph_main_menu">
                  <div className="lifeheading PhysioPsychologyHeading ">
                    <h5 className="fs-34 fw-700 lh-35 mb-3">
                      Empathy & Reflection in{" "}
                      <br className="d-sm-none d-block" />
                      Practice
                    </h5>
                  </div>
                  <div className="animy_hover2">
                    <div className="sportsparagraph pt-3">
                      <p className="fs-22 clr-2 MuliLight pt-3">
                        Strength conditioning is a primary science in being a
                        good sports physiotherapist
                      </p>
                    </div>
                    <div className="likes">
                      <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                        <i
                          className="fa fa-heart clr-6 pr-1"
                          aria-hidden="true"
                        ></i>{" "}
                        82 Likes
                      </Link>
                    </div>
                    <div className="director py-5">
                      <ul className="nav comunity_menu_list">
                        <li className="nav-item varunshetty pr-2">
                          <p className="fs-24 fw-600 ls-1 mb-0">
                            Course Director
                          </p>
                          <p className="fs-34 MuliLight fw-500 mb-0">
                            Varun Shetty
                          </p>
                          <p className="fs-22 MuliLight">Advisor ProPhysio</p>
                        </li>
                        <li className="nav-item pl-4">
                          <p className="fs-24 fw-600 ls-1 mb-0">
                            Course Length
                          </p>
                          <p className="fs-34 MuliLight fw-500 mb-0">20</p>
                          <p className="fs-22 MuliLight">Hours</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PhysioPsychology bg-lightgreen position-relative Psychologydoctor animation_hover2 active">
              <div className="pl-3 pt-5 pr-0  col-md-8 order-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-34 fw-700 lh-35 mb-3">
                    Health <br className="d-md-none d-block" /> Entrepreneurship
                  </h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph pt-3">
                    <p className="fs-22 clr-2 MuliLight pt-3">
                      A healthepreneur you got to get the game together. Learn
                      more how to care better.
                    </p>
                  </div>
                  <div className="likes">
                    <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                      <i
                        className="fa fa-heart clr-6 pr-1"
                        aria-hidden="true"
                      ></i>{" "}
                      82 Likes
                    </Link>
                  </div>
                  <div className="director py-5">
                    <ul className="nav comunity_menu_list">
                      <li className="nav-item varunshetty pr-2">
                        <p className="fs-24 fw-600 ls-1 mb-0">
                          Course Director
                        </p>
                        <p className="fs-34 MuliLight fw-500 mb-0">Pramod</p>
                        <p className="fs-22 MuliLight">CEO ProPhysio</p>
                      </li>
                      <li className="nav-item pl-4">
                        <p className="fs-24 fw-600 ls-1 mb-0">Course Length</p>
                        <p className="fs-34 MuliLight fw-500 mb-0">20</p>
                        <p className="fs-22 MuliLight">Hours</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className=" chologyImage">
                <div className="position-absolute urbanhealthimg ">
                  <img
                    src="/images/services/18.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="selfchild bg-whitepurpal br-last position-relative urbanhealth animation_hover2">
              <div className="pl-3 pt-5 pr-0  col-md-8 order-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-34 fw-700 lh-35 mb-3">Gait Lab</h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph pt-3">
                    <p className="fs-22 clr-2 MuliLight pt-3">
                      Strength conditioning is a primary science in being a good
                      sports physiotherapist
                    </p>
                  </div>
                  <div className="likes">
                    <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                      <i
                        className="fa fa-heart clr-6 pr-1"
                        aria-hidden="true"
                      ></i>{" "}
                      82 Likes
                    </Link>
                  </div>
                  <div className="director py-5">
                    <ul className="nav comunity_menu_list">
                      <li className="nav-item varunshetty pr-2">
                        <p className="fs-24 fw-600 ls-1 mb-0">
                          Course Director
                        </p>
                        <p className="fs-34 MuliLight fw-500 mb-0">
                          Varun Shetty
                        </p>
                        <p className="fs-22 MuliLight">Advisor ProPhysio</p>
                      </li>
                      <li className="nav-item pl-4">
                        <p className="fs-24 fw-600 ls-1 mb-0">Course Length</p>
                        <p className="fs-34 MuliLight fw-500 mb-0">20</p>
                        <p className="fs-22 MuliLight">Hours</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="urbanimg ">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/19.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Community text-center pt-4">
            <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pt-3">
              <Link
                to="/joinTheCommunity"
                className="btn BtnBook fs-20 fw-500 px-4"
              >
                Join the ProPhysio Community
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fivth ||---------------> */}
    </>
  );
}

export default lifeChangingHub;
