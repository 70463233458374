export default {
  async sendApiRequest(url, method, setauth, body) {
    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    };
    if (method === "DELETE") {
      delete requestOptions.body;
    }
    if (method === "GET") {
      delete requestOptions.body;
    }

    if (setauth === true) {
      let token = window.user ? window.user.token : "no-token";
      requestOptions.headers["Authorization"] = token;
    }
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASEURL + url,
        requestOptions
      );
      let body = await response.text();
      if (response.status != 200) {
        throw body;
      }
      const data = body.includes("{") ? JSON.parse(body) : body;
      return data;
    } catch (e) {
      throw JSON.parse(e);
    }
  },
  changeTimeFormat(time) {
    const addZero = (number) => {
      return number < 10 ? "0" + number : number;
    };
    const num = addZero(parseInt(time / 100)) + ":" + addZero(time % 100);
    return num;
  },
};
