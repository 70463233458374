import React from "react";
import { Link } from "react-router-dom";

function bPhysiotherapyInRecoveryFromCovid() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">
              Physiotherapy in recovery from COVID-19
            </h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Manual Therapy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">
                  {" "}
                  Value based health
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Physiotherapy</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/iStock-1213919480.png"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">
                Therapy plays a role on how you recover
              </h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                The second wave of the pandemic has had a crippling effect on
                people worldwide, particularly in India. We are now witness to
                astronomically high mortality and morbidity across the country.
                While the first wave impacted the older generation, the second
                wave has claimed lives of people in the middle and younger age
                groups. There is urgent need to understand how the virus works
                in its myriad ways to counter its devastating effects.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/iStock-1217603648.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">Inflammatory syndrome </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  COVID-19 infection is associated with a multisystem
                  inflammatory syndrome with predominant effects on the
                  respiratory system.The most dominant feature resulting out of
                  impaired pulmonary mechanics and insufficient arterial oxygen
                  supply is stress on the oxygen transport pathway.Depending on
                  the clinical presentation, acute COVID-19 patients are
                  classified into mild, moderate and severe categories, in which
                  respiratory symptoms range from mild cough to pneumonia with
                  drop in oxygen saturation. Based on clinical findings,
                  patients might require conservative medical management or
                  oxygen support or even a ventilator. Majority of patients with
                  COVID-19 have benefited from physiotherapy care which has been
                  shown to assist in faster and complete recovery of patients
                  infected by COVID-19.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Three ||---------------> */}
      <section className="PhysioDescripVideo" id="DescripVideo">
        <div className="container">
          <div className="PhysioBlogVideo">
            <img
              src="/images/blog/iStock-1227611791.png"
              alt="blog banner Two"
              className="img-fluid w-100"
            />
            <button className="play_btn">
              <i className="fa fa-play-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="DescriptionVideo text-center MuliBold my-3">
            <p className="fs-25 clr-2">
              Continuity of care in the form of individually-tailored therapy
              programmes
            </p>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!---------------|| Section Four ||---------------> */}
      <section
        className="PhysioDiagnosticTools py-md-5 pt-5"
        id="DiagnosticTools"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioDiagnosticBuddha ">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <div className="PhysioDiagBuddhaContant text-center">
                  <div className="PhysioDiagBuddhaPara fs-30">
                    <p className="clr-darkgray fs-30">
                      “Mental health problems don’t define who you are. They are
                      something you experience..”
                    </p>
                  </div>
                  <div className="PhysioDiagName text-center MuliBold fs-20">
                    <span className="fs-20 clr-2 fw-600">- Matt Haig</span>
                  </div>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4">
                  <p className="fs-25 fw-400 clr-2 my-4">
                    {" "}
                    During the pandemic, physiotherapists have been working
                    round the clock along with intensivists and pulmonologists
                    giving supportive care to patients and ensuring faster
                    weaning and their early discharge.Observing trends since the
                    first wave, and more prominently during the second wave, we
                    have noticed that once patients are discharged from
                    hospital, especially patients who had a long stay or needed
                    oxygen or ventilator support during their stay,they are at
                    risk of long-term impairment and disability. More than 50%
                    of such individuals experience ongoing symptoms such as
                    movement related fatigue, weight loss.
                  </p>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
                <p className="fs-25 clr-2">
                  {" "}
                  Physiotherapists play a key role in improving the
                  ventilation/perfusion mismatch, reducing its impact on the
                  oxygen transport pathway in respiratory care. Not all
                  breathing exercises or positioning techniques work for all
                  patients. It is a misnomer that every patient requires chest
                  clearance or deep breathing techniques to stabilize their
                  condition. Based on the severity of the disease, and clinical
                  presentation of each patient, treatment goals and the manner
                  in which they are managed, differs. For example, in acute care
                  ICU setting,the aim of physiotherapy treatment would be to
                  improve oxygenation and maintain bronchial hygiene, thereby
                  preventing secondary respiratory infections.Physiotherapy also
                  helps in reducing ventilator dependency, facilitating weaning
                  and minimizing the impact of immobilization on the oxygen
                  transport pathway. Patients experiencing moderate symptoms
                  with oxygen support will require assistance in breathlessness
                  management.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Five ||---------------> */}
      <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">
                  Post-COVID recovery addressed holistically
                </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="fs-25 fw-400 clr-2 my-4">
                  Most times such individuals recovering from an acute infection
                  report lack of energy.It is important for them to get back to
                  independent participation in their daily lives and maintain a
                  good balance between energy saving and expenditure. Therefore
                  continuity of care in the form of individually-tailored
                  therapy programmes delivered in the comfort of their homes
                  seems more feasible as patients need not exhaust themselves
                  travelling to a centre to receive care and instead utilise
                  their energy judiciously in recovering and becoming
                  functionally independent.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/iStock-1253762928.png"
                  alt="image6"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Five ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock_000064794819_Large.png"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Healthy living</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        > */}
                          <p className="mb-0 fs-34">
                            Get healed while your at work
                          </p>
                          {/* </Link> */}
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/Believe-you-can-2sm.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Your life choices
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">Exercise for mind health</p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-11477859.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy Technology
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-1180916548 copy.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Elderly Health
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Recover better at the comfort of home
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_7788.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Health Science
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Analyze your movement in multiple dimensions
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_5227.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Health centers
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A comprehensive centre for cerebral palsy
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/iStock_000022998473_Large.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">
                                Tips & Healthy Tricks
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bMentalHealth"
                                className="d-inline-block clr-2 h-100"
                              >
                                {/* <Link
                                href="b_mental_health.html"
                                className="d-inline-block clr-2 h-100"
                              > */}
                                <p className="mb-0 fs-34">
                                  Best exercises in pregnancy
                                </p>
                                {/* </Link> */}
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy life article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bPhysiotherapyInRecoveryFromCovid;
