import Util from "../../utils/Util";
export default class HowWeHelpedService{
    testimonialList(data, start, length) {
        const activity = Object.keys(data).reduce((object, key) => {
            if (data[key] !== "") {
                object[key] = data[key];
            }
            return object;
        }, {});

        return Util
            .sendApiRequest(
                "/testimonial/list/" + start + "/" + length,
                "POST",
                true,
                activity
            )
            .then(
                (response) => {
                    return response;
                },
                (error) => {
                    throw error;
                }
            );
    }
}