import React, { useState } from 'react'
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import CallBackService from '../../services/CallBackService/CallBackService'

function CallBackForm() {

    const [value, setValue] = useState({
        name: "", phone: ""
    })
    const callBackServe = new CallBackService()

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        phone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .min(10, "Phone number should be 10 digit")
            .max(10, "Phone number should be 10 digit").required("Required"),
    });

    const onSubmit = async (values, { resetForm }) => {
        console.log("values", values)
        let obj = {
            name: values.name,
            phone: values.phone,
            type:"CALLBACK"
        }
        try {
            let callBackResponse = await callBackServe.callbackFormApi(obj)
            if (callBackResponse) {
                toast.success(callBackResponse.message)
                resetForm()
                setValue({
                    name: "", phone: ""
                })
            }
        } catch (err) {
            throw err
        }
    }
    return (
        <Formik
            initialValues={value}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={ValidateSchema}
        >
            {(props) => (
                <form onSubmit={props.handleSubmit}>
                    <div className="PhysioWantCall col-lg-8 mt-5 p-0">
                        {/* <form action="/action_page.php" onSubmit={submitHandle}> */}
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control border-0 clr-8 fs-30 br-10"
                                placeholder="Your Name"
                                name="name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.name}
                                autoComplete='off'
                            />
                            {props.touched.name && props.errors.name ? (
                                <div className="formik-errors bg-error">
                                    {props.errors.name}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name="phone"
                                className="form-control border-0 clr-8 fs-30 br-10"
                                placeholder="Phone number"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.phone}
                                autoComplete='off'
                            />
                            {props.touched.phone && props.errors.phone ? (
                                <div className="formik-errors bg-error">
                                    {props.errors.phone}
                                </div>
                            ) : null}
                        </div>
                        <button
                            type="submit"
                            className="btn BtnBook fs-22 fw-500 px-4 mt-4"
                        //   data-toggle="modal"
                        // data-target="#PartneringWithUS"
                        >
                            Get a call back
                        </button>
                        {/* </form> */}
                        <ToastContainer />
                    </div>
                </form>
            )}
            
        </Formik>
    )
}

export default CallBackForm