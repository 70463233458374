import React from "react";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { useContext, useState } from "react";
import ReadyToTransfer from "../../shared/ReadyToTransfer/readyToTransfer";

import BackToTop from "../../shared/BackToTop/backToTop";
import { Link } from "react-router-dom";

function therapistsExploare() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;

  const formOpen = () => {
    setFirstFormOpen(!firstFormOpen);
  };
  function initiateCarousel() {
    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section
        class="w-100 clearfix PhysioServicesTopBanner our_services bg-grad-pink ThrerpyBanner"
        id="Ourtherapists"
      >
        <div class="PhysioGradientBackground position-relative">
          <div class="container">
            <div class="row">
              <div class="col-md-5 position-static order-2">
                <div class="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/36.png" class="img-fluid" />
                </div>
              </div>
              <div class="col-md-7 order-1 col-9">
                <div class="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div class="d-table-cell align-middle">
                    <div class="backbtn ">
                      <Link to="/" class="text-decoration-none">
                        <img src="/images/icons/icon23.png" />
                        <span class="fs-24 clr-1 fw-600">Back to Home</span>
                      </Link>
                      {/* <Link href="index.html" class="text-decoration-none">
                    <img src="/images/icons/icon23.png"/>
                    <span class="fs-24 clr-1 fw-600">Back to Home</span>
                  </Link> */}
                    </div>
                    <div class="MainTest1 w-100 pt-4">
                      <h2 class="fs-72 fw-700 ">
                        Our therapists are <br class="d-md-block d-none" />{" "}
                        committed to your health
                      </h2>
                    </div>
                    <div class="OtherText1 w-100 py-4 pr-5">
                      <p class="MuliLight fs-28 ls-1 m-0">
                        We are more than happy to help. Just give us a call or
                        given us your phone number so that we can call you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        class="clearfix w-100 PerksMembers ProPhysioassurance_sec  ProPhysioassurance py-5 my-xl-5 WhatIDoNow"
        id="BeingMembers"
      >
        <div class="container pt-xl-5">
          <div class="HeadingDiv w-100 clearfix">
            <h2 class="fs-72 fw-700 lh-92">Best in class experts</h2>
            <p class="fs-22 MuliLight pt-4" style={{ "max-width": "100%" }}>
              Explore the most common symptoms and what can cause them
            </p>
          </div>
          <div class="assurance pt-xl-5">
            <div class="row">
              <div class="col-xl-4 col-md-6 px-xl-5">
                <div class="trainedexpert">
                  <div class="InfoDetails d-flex py-4">
                    <div class="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/25.png"
                        class="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div class="InfoText1 d-inline-block">
                      <p class="fw-600 fs-42 ls-1 mb-0">Licensed</p>
                    </div>
                  </div>
                  <div class="phpoyherpara">
                    <p class="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-6 px-xl-5">
                <div class="trainedexpert">
                  <div class="InfoDetails d-flex py-4">
                    <div class="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/26.png"
                        class="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div class="InfoText1 d-inline-block">
                      <p class="fw-600 fs-42 ls-1 mb-0">Trained</p>
                    </div>
                  </div>
                  <div class="phpoyherpara">
                    <p class="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-6 px-xl-5">
                <div class="trainedexpert">
                  <div class="InfoDetails d-flex py-4">
                    <div class="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/27.png"
                        class="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div class="InfoText1 d-inline-block">
                      <p class="fw-600 fs-42 ls-1 mb-0">Experienced</p>
                    </div>
                  </div>
                  <div class="phpoyherpara">
                    <p class="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="ProBtns pt-5 w-100 text-center position-static">
              <Link class="btn BtnBook fs-20 fw-500 BtnImg1" onClick={formOpen}>
                Book a session
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section thired ||---------------> */}
      <section
        class="w-100 clearfix mt-lg-5 py-5 position-relative bg-lightgray mertherphy explore_sec_bg"
        id="Meetrherpysection"
      >
        <div class="container py-sm-5 mt-sm-2">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="exploreheading">
                <h1 class="fs-72 fw-700 pb-sm-0 pb-4">Explore therapists</h1>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="explorebtn ">
                <ul class="nav w-100 text-center text-md-right  d-inline-block therapists_menu_list">
                  <li class="nav-item d-inline-block px-2">
                    <Link
                      class="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter
                    >
                      All
                    </Link>
                  </li>
                  <li class="nav-item d-inline-block px-2">
                    <Link
                      class="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter="physiotherapists"
                    >
                      Physiotherapists
                    </Link>
                  </li>
                  <li class="nav-item d-inline-block px-2">
                    <Link
                      class="btn brownbtn bluebutton br-24 fw-500 fs-20 text-black btn-outline-dark px-3"
                      data-filter="locations"
                    >
                      All Locations
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="doctorslist pb-lg-5">
            <div class="row">
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Anshul Jagga</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Consultant physiotherapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="AnshulJagga"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_AnshulJagga.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Anushree PN</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Consultant Nutritionist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="anushree_P"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_anushree_P.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img src="/images/therapist/sm2/Aruna.jpg" class="w-100" />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Aruna R K</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Speech language pathologist and Audiologist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="aruna"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_aruna.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Ashwini Madihalli</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="AshwiniMadihalli"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_AshwiniMadihalli.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img src="/images/therapist/sm2/BUSHRA.jpg" class="w-100" />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">BUSHRA RASHEED</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Dietitian and Nutritionist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="BUSHRA_R"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_BUSHRA_R.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Preeti_01.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Dr.Preethi G</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Clinical physiotherapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="DrPreethiG"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_DrPreethiG.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img src="/images/therapist/sm2/meenu.jpg" class="w-100" />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Meenu Das P B</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="meenu_das"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_meenu_das.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Prateeksha.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Pratikshya Kar</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Clinical Psychologist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="Pratikshya_Kar"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_Pratikshya_Kar.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/puja_singh.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Puja Singh</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="PujaSingh"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_PujaSingh.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/RaniyaSulthana_Physiotherapist.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Raniya Sulthana</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="RaniyaSulthana"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_RaniyaSulthana.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Richwin J</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="RichwinJ"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_RichwinJ.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Rishin T K</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="RishinTK"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_RishinTK.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sabrina Mody</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Operations Head - Hyderabad
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="sabrinamody"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_sabrinamody.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sandhiya Harini K G</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Consultant physiotherapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="SandhiyaHariniKG"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_SandhiyaHariniKG.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/sanjana.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sanjana Nair</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Consultant Physiotherapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="SanjanaNair"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_SanjanaNair.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img src="/images/therapist/sm2/Shahul.jpg" class="w-100" />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">SHAHUL HASHIR S</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Business Development Manager
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="SHAHUL"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_SHAHUL.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Shaurya_456x456.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Shaurya Satish</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Clinical Physiotherapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="ShauryaSatish"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_ShauryaSatish.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/ShonalliP.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Shonalli Ponnappa</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Special Educator
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="shonalli_ponnappa"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_shonalli_ponnappa.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/shruti_goradia.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Shruti Goradia</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Marketing Manager
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="shruti_goradia"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_shruti_goradia.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Shruthi_Digde.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Shruthi Digde</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Operation Manager
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="ShrutiD"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_ShrutiD.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Sminu (Speech Therapist).jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sminu C P</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Speech Language Pathologist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="sminu"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_sminu.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img src="/images/therapist/sm2/Smita.jpg" class="w-100" />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Smitha S</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Telecommunication Manager
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="smitha_s"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_smitha_s.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sowmya V</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Physical Educator
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="sowmya"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_sowmya.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Rajalakshmi_LeadPhysiotherapist.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">S Raja Lakshmi</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">Physiotherapist</p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="SRajaLakshmi"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_SRajaLakshmi.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Sruthi G S.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sruthi G S</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Speech Language Pathologist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="sruthi_g_s"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_sruthi_g_s.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Sweata_Basnett.jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Sweata Basnett</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Key accounts manager- Musculoskeletal and Orthopaedics
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="SweataBasnett"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_SweataBasnett.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm/default_image.png"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Varsha Arun Kumar</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Sr.Cluster Manager
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="Varsha_kumar"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_Varsha_kumar.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div class="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div class="docdetail py-md-5 pt-4 therpy_services_box">
                  <div class="docimg">
                    <img
                      src="/images/therapist/sm2/Zainab Juzeer (Occupational Therapist) .jpg"
                      class="w-100"
                    />
                  </div>
                  <div class="docname mt-sm-4 mt-3">
                    <h1 class="fs-34 fw-700">Zainab Juzer</h1>
                  </div>
                  <div class="docsubject ">
                    <p class="text-darkgray fs-22 MuliLight">
                      Occupational Therapist
                    </p>
                  </div>
                  <div class="doctext ">
                    <p class="text-black fs-22 MuliLight">-</p>
                  </div>
                  <div class="learbtn">
                    <Link
                      to="zainab"
                      class="rightarrow fs-24 fw-600  HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="https://prophysio.in/th_zainab.html"
                  class="rightarrow fs-24 fw-600  HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>

              {/* <div class="col-md-12">
                <div class="showmore pt-5">
                  <div class="more_show text-center ">
                    <Link
                      to="therapists_details"
                      class="show_btn btn bg-blue text-white fs-22 br-24 px-4 py-2 "
                    >
                      Show More
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section thired ||---------------> */}
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        class="w-100 clearfix bg-clr-1 doctorchangeing SeeForDtrYou SeeForDtrYou_sec"
        id="SeeForYou"
      >
        <div class="container">
          <div class="BodyByData w-100 clearfix">
            <div class="row">
              <div class="col-lg-7 order-2">
                <div class="BeterImgs w-100 clearfix align-bottom singaldoctor text-lg-left text-center">
                  <img src="/images/37.png" class="img-fluid" alt="No Images" />
                </div>
              </div>
              <div class="col-lg-5 order-1">
                <div class="sugerdoc w-100 clearfix d-table h-100">
                  <div class="d-table-cell align-middle TextMiddle pt-lg-0 pt-5">
                    <h2 class="fs-72 fw-700 mb-4">
                      See if ProPhysio is right for you
                    </h2>
                    <p class="fs-22 mb-4 MuliLight">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna
                    </p>
                    <div class="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pb-4">
                      <Link
                        data-toggle="modal"
                        data-target="#claimFreeModal"
                        class="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Claim your first free consultation
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
      {/* <!---------------|| Connect us Section ||---------------> */}
      <ReadyToTransfer />
      {/* <section
        class="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting"
        id="ConnectReady"
      >
        <div class="container-fluid py-md-5">
          <div class="HeadingDiv w-100 clearfix text-center">
            <h2 class="fs-72 lh-80 fw-700">Ready to transform your health?</h2>
            <p class="fs-22 MuliLight pt-2 mb-4">
              Getting ProPhysio services are as easy as a click away
            </p>
          </div>
          <div class="client py-xl-5 ReadyToConnect">
            <div class="row">
              <div class="col-lg-4 col-sm-7 m-auto p-3">
                <div class="prectical">
                  <div
                    class="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ "background-image": "url(images/16.png)" }}
                  >
                    <div class="trulyCare1 d_table_cell_none align-middle w-75">
                      <p class="fs-57 MuliBold lh-62 mb-0">
                        Virtual <br />
                        Session
                      </p>
                      <div class="pt-3">
                        <Link

                          class=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-7 m-auto p-3">
                <div class="prectical">
                  <div
                    class="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/17.png)" }}
                  >
                    <div class="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p class="fs-57 MuliBold lh-62 mb-0">
                        Home <br />
                        Visit
                      </p>
                      <div class="pt-3">
                        <Link

                          class=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-7 m-auto p-3">
                <div class="prectical">
                  <div
                    class="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ "background-image": "url(images/18.png)" }}
                  >
                    <div class="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p class="fs-57 MuliBold lh-62 mb-0">
                        Visit <br />
                        Clinic
                      </p>
                      <div class="pt-3">
                        <Link

                          class=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="NotSureTo w-100 clearfix text-center pt-5">
            <h3 class="fs-45 MuliExtra pl-5">Not sure where to begin?</h3>
            <h4 class="fs-54 MuliLight pt-5">
              <img
                src="/images/icons/icon14.png"
                class="img-fluid"
                alt="No Images"
              />
              <span class="d-inline-block pl-5">
                Call us now at{" "}
                <Link href="tel:+91 8197378444" class="clr-1">
                  +91 8197378444
                </Link>
              </span>
            </h4>
          </div>
        </div>
      </section> */}
      {/* <!---------------|| Connect Us Section ||---------------> */}
      <BackToTop />
    </>
  );
}

{
  /* <script>
  
  
  (function ($){" "}
  {
    ($.autofilter = function (options) {
      alert("autofilter");
      var settings = $.extend(
        {
          showClass: "show",
          htmlAsFilter: false,
          subString: false,
          caseSensitive: false,
          animation: true,
          duration: 0,
        },
        options
      );

      $("[data-filter]:not(input)").click(function () {
        if (settings.htmlAsFilter) {
          var filterValue = $(this).html().trim();
        } else {
          var filterValue = $(this).attr("data-filter").trim();
        }

        if (filterValue != "") af_filter(filterValue);
        else
          $("[data-tags],[data-to-filter]")
            .fadeIn(settings.duration)
            .addClass(settings.showClass);
      });

      $("input[data-filter]").keyup(function () {
        var value = $(this).val();

        if (value != "" && value.length > 2) {
          af_filter(value);
        } else {
          $("[data-tags],[data-to-filter]")
            .fadeIn(settings.duration)
            .addClass(settings.showClass);
        }
      });

      function af_filter(filterValue) {
        $("[data-tags],[data-to-filter]").each(function () {
          var tags = $(this).attr("data-tags");
          var tofilter = Array();
          var valid = false;

          if (!settings.caseSensitive) filterValue = filterValue.toLowerCase();

          if (tags) {
            tofilter = tags.split(",");
          } else {
            tofilter.push($(this).html());
            settings.subString = true;
          }

          if (!settings.caseSensitive)
            tofilter = tofilter.map((v) => v.toLowerCase());

          if (settings.subString) {
            tofilter.forEach(function (toFilterOne) {
              if (toFilterOne.indexOf(filterValue) > -1) {
                valid = true;
              }
            });
          } else {
            valid = tofilter.includes(filterValue);
          }

          if (valid) {
            if (settings.animation) $(this).fadeIn(0);

            $(this).addClass(settings.showClass);
          } else {
            if (settings.animation) $(this).fadeOut(settings.duration);

            $(this).removeClass(settings.showClass);
          }
        });
      }
    })
  }
  (jQuery));
</script>;  */
}
export default therapistsExploare;
