import React from "react";
import { Link } from "react-router-dom";

function meetOtherTherapists() {
  function initiateCarousel() {
    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      <section
        className="w-100 clearfix my-lg-5 py-5 position-relative bg-lightgray mertherphy explore_sec_bg DetailedTherepistLists"
        id="Meetrherpysection"
      >
        <div className="container py-sm-5 mt-sm-2">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="exploreheading">
                <h1 className="fs-72 fw-700 pb-sm-0 pb-4">
                  Meet other therapists
                </h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="explorebtn ">
                <ul className="nav w-100 text-center text-md-right d-inline-block therapists_menu_list">
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter
                    >
                      All
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter="physiotherapists"
                    >
                      Physiotherapists
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500 fs-20 text-black btn-outline-dark px-3"
                      data-filter="locations"
                    >
                      All Locations
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="doctorslist">
            <div className="row">
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/sanjana.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Sanjana Nair</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Consultant Physiotherapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      I am a Consultant Physiotherapist at Pro Physio. I am
                      working at Rainbow...
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/Preeti_01.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Dr.Preethi G</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Clinical Physiotherapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      I'm a person who is passionate about my career and would
                      like to make people's...
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/Shruthi__Digde.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Shruti Digde</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Operation Manager
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      I am a very hard working and sincere at my work. Currently
                      leading a team of...
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/Rajalakshmi.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">S. Rajalakshmi</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Physiotherapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      I have done my Masters in Physiotherapy- Neurology from...
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/puja_singh.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Puja Singh</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Physiotherapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      Hard working, sincere and Passionate about my work.
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img
                      src="/images/therapist/Basnett.jpg"
                      className="w-100"
                    />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Sweata Basnett</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Key accounts manager- musculoskeletal and orthopaedics
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      I have completed my masters of...
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Learn more
                        <img src="/images/icons/icon5.png"/>
                        </Link> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
                    <div className="showmore pt-5">
                    <div className="more_show text-center ">
                        <Link to='therapists_details' className="show_btn btn bg-blue text-white fs-22 br-24 px-4 py-2 ">Show
                        More </Link>
                    </div>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default meetOtherTherapists;
