import Util from "../../utils/Util";
export default class ArticleHubService {
  blogList(data, start, length) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return Util.sendApiRequest(
      "/blog/list/" + start + "/" + length,
      "POST",
      true,
      activity
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  similarList(data, start, length) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});
    return Util.sendApiRequest(
      "/similarPost/list/" + start + "/" + length,
      "POST",
      true,
      activity
    ).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getBlogById(id) {
    return Util.sendApiRequest("/blog/" + id, "GET", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
