import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { useContext } from "react";
import ArticleHubService from "../../services/ArticalHubService/ArticalHubService";

function articleHub() {
  const blogListServe = new ArticleHubService();
  const [articalHubList, setArticalHubList] = useState([]);
  const [search, setSearch] = useState({
    start: 0,
    perPage: 100,
    searchTxt: "",
    searchField: "",
  });

  useEffect(async () => {
    await blogListApi();
  }, []);

  const blogListApi = async () => {
    const activity = {
      pages: "knowledgehub",
    };
    try {
      let response = await blogListServe.blogList(
        activity,
        search.start,
        search.perPage
      );
      if (response) {
        setArticalHubList(response.rows);
      }
    } catch (err) {
      throw err;
    }
  };

  const blogDataHandle = (data, index) => {
    console.log("knowledge hub blog id and data ", index, data);
    sessionStorage.setItem("blogData", JSON.stringify(data));
  };

  return (
    <>
      {/* <!---------------|| ArticleHub ||---------------> */}
      <section
        className="w-100 clearfix physioknowledgeTabs physioknowledgeTabs_sec"
        id="knowledgesTabs"
      >
        <div className="bg-brown py-md-4 pb-3">
          <div className="container-fluid">
            <div className="PhysioknowledgeNavTabs py-md-2">
              <div className="">
                <div className="row">
                  <div className="col-lg-8 order-lg-1 order-2">
                    <div className="physioknowledgeTabList text-lg-left text-center mt-lg-0 mt-3">
                      <ul
                        className="nav nav-tabs border-0  w-100"
                        role="tablist"
                      >
                        <li className="nav-item mr-md-4">
                          <Link
                            className="nav-link fs-20 fw-500 btn brownbtn bluebutton br-24 text-white"
                            role="tab"
                            data-toggle="tab"
                          >
                            Podcasts
                          </Link>
                        </li>
                        <li className="nav-item mr-md-4">
                          <Link
                            className="nav-link fs-20 fw-500 btn brownbtn bluebutton br-24 text-white"
                            role="tab"
                            data-toggle="tab"
                          >
                            Videos
                          </Link>
                        </li>
                        <li className="nav-item mx-md-4">
                          <Link
                            className="nav-link fs-20 fw-500 active btn brownbtn bluebutton br-24 text-white"
                            role="tab"
                            data-toggle="tab"
                          >
                            Article
                          </Link>
                        </li>
                        <li className="nav-item mx-md-4">
                          <Link
                            className="nav-link fs-20 fw-500 btn brownbtn bluebutton br-24 text-white"
                            role="tab"
                            data-toggle="tab"
                          >
                            Tutorials
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-7 m-auto order-lg-2 order-1">
                    {/* <div className="faqkeywords h-100 mt-md-0 mt-3"> */}
                    <div className="faqkeywords mt-md-0 mt-3">
                      <form className="form-group mb-0">
                        {/* <form className="form-group mb-0 h-100"> */}
                        {/* <div className="input-group h-100 br-10" style={{border: "0.5px solid #585858",height:"45px !important"}}> */}
                        <div
                          // className="input-group h-100 br-10"
                          className="input-group br-10"
                          style={{
                            border: "0.5px solid #585858",
                            height: "45px !important",
                          }}
                        >
                          {/* <div className="input-group-prepend h-100"> */}
                          <div className="input-group-prepend">
                            <span className="input-group-text bg-white border-0">
                              <img
                                src="/images/icons/search_icon.png"
                                alt="search"
                                className="searchimg"
                              />
                            </span>
                          </div>
                          <input
                            type="text"
                            // className="form-control inputkey input-lg h-100 border-0"
                            className="form-control inputkey input-lg border-0"
                            placeholder="Enter keywords"
                            name="username"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lightpink py-5">
          <div className="container">
            <div className="tab-content">
              <div
                role="tabpanel"
                className="tab-pane fade"
                id="KnowledgePodcasts"
              >
                <div className="TabSection">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img49.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img50.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img51.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn  text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img52.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img53.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img54.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                role="tabpanel"
                className="tab-pane fade"
                id="KnowledgeVideos"
              >
                <div className="TabSection">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img49.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img50.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img51.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn  text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img52.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img53.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img54.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                role="tabpanel"
                className="tab-pane fade in active show"
                id="KnowledgeArticle"
              >
                <div className="TabSection">
                  <div className="row">
                    {articalHubList.map((v, i) => {
                      return (
                        <div className="col-lg-4 col-md-6 p-xl-4" key={i}>
                          <div className="card border-0">
                            <div className="card-header p-0">
                              <div className="KnowledgeCardHead position-relative">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    "/" +
                                    v.thumbnail
                                  }
                                  // alt="grapes"
                                  className="img-fluid"
                                />
                                <div className="KnowledgePlayButton position-absolute">
                                  <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                    <i
                                      className="fa fa-heart clr-6 pr-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {v.likes_number} Likes
                                  </Link>
                                </div>
                                <div className="videowacthbtn position-absolute onlyvideoicon">
                                  <Link className="btn  text-white fs-14  text-center br-24">
                                    <i
                                      className="fa fa-play  "
                                      aria-hidden="true"
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="foodpart py-3 px-4">
                              <Link
                                to={"/blog/" + v?.id}
                                className="d-inline-block"
                              >
                                <div className="phyheading position-relative pb-2 ">
                                  <p
                                    className="fs-24 fw-600 clr-2 mb-1"
                                    onClick={() => blogDataHandle(v, i)}
                                  >
                                    {v.title_short_2}
                                  </p>
                                </div>
                                <div className="mt-3 friutfood">
                                  <p className="fs-34 clr-2 MuliLight">
                                    {v.title_short}
                                  </p>
                                </div>
                                <div className="mt-3 friutfood">
                                  <p className="fs-18 clr-2 MuliLight">
                                    {v.summary}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* <!--Tab Tutorials--> */}
              <div
                role="tabpanel"
                className="tab-pane fade"
                id="KnowledgeTutorials"
              >
                <div className="TabSection">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img49.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img50.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img51.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                            <div className="videowacthbtn position-absolute onlyvideoicon">
                              <Link className="btn  text-white fs-14  text-center br-24">
                                <i
                                  className="fa fa-play  "
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img52.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img53.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-4">
                      <div className="card border-0">
                        <div className="card-header p-0">
                          <div className="KnowledgeCardHead position-relative">
                            <img
                              src="/images/img54.png"
                              alt="grapes"
                              className="img-fluid"
                            />
                            <div className="KnowledgePlayButton position-absolute">
                              <Link className="clr-2 btn bg-white br-24 fs-18 mulilight fw-500 text-decoration-none">
                                <i
                                  className="fa fa-heart clr-6 pr-1"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="foodpart py-3 px-4">
                          <Link to="bExercise" className="d-inline-block">
                            <div className="phyheading position-relative pb-2 ">
                              <p className="fs-24 fw-600 clr-2 mb-1">
                                Food & recipes podcast
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-34 clr-2 MuliLight">
                                Fruits that fight flu
                              </p>
                            </div>
                            <div className="mt-3 friutfood">
                              <p className="fs-18 clr-2 MuliLight">
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and...
                              </p>
                            </div>
                          </Link>
                        </div>
                        {/* <!-- <div className="card-footer">Footer</div> --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ExploBtn DwnldApp w-100 clearfix text-center pt-5 pb-4">
            <Link
              data-toggle="modal"
              data-target="#claimFreeModal"
              className="btn BtnBook fs-20 fw-500 px-4 BtnImg1"
            >
              Claim your free first session
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default articleHub;
