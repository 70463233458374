import React from "react";
import "../../App.css";
import HomeBennerSection from "../../shared/HomeSections/HomeBannerSection/homeBannerSection";
import TransFormSection from "../../shared/HomeSections/TransFormSection/transformSection";
import FindCareSection from "../../shared/HomeSections/FindCareSection/findCareSection";
import BodyBetterSection from "../../shared/HomeSections/BodyBetterSection/bodyBetterSection";
import LeadLifeSection from "../../shared/HomeSections/LeadLifeSection/leadLifeSection";
import ProDifferenceSection from "../../shared/HomeSections/ProDifferenceSection/proDifferenceSection";
import AdvancedTherapySection from "../../shared/HomeSections/AdvancedTherapySection/advancedTherapy";
import GotQuestionsSection from "../../shared/HomeSections/GotQuestionsSection/gotQuestions";
import HowWeHelpedSection from "../../shared/HomeSections/HowWeHelpedSection/HowWeHelped";
import OurPartnersSection from "../../shared/HomeSections/OurPartnersSection/OurPartners";
import LiveHealthySection from "../../shared/HomeSections/LiveHealthySection/liveHealthy";
import ConnectUsSection from "../../shared/HomeSections/ConnectUsSection/connectUs";
import BackToTop from "../../shared/BackToTop/backToTop";
import { Helmet } from "react-helmet";

function Home() {
  setTimeout(() => {
    /**********|| Sticky Header ||**********/
    window.$(window).scroll(function () {
      var sticky = window.$(".ProMainHeader"),
        scroll = window.$(window).scrollTop();
      if (scroll > 90) {
        sticky.addClass("sticky");
      } else if (scroll < 90) {
        sticky.removeClass("sticky");
      }
    });
    /**********|| Sticky Header ||**********/
    /**********|| Custom Owl Js||**********/
    window.$("#WhatWeHaveOwl,#GotQuestionOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Js||**********/
    window.$("#MeetExpertsOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
        1300: {
          items: 4,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Js||**********/
    window.$("#DetailedS_tab_data").owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      dots: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        370: {
          items: 2.5,
          nav: true,
        },
        576: {
          items: 2,
          nav: true,
        },
        768: {
          items: 3,
          nav: true,
        },
        992: {
          items: 4,
          nav: true,
        },
        1200: {
          items: 5,
          nav: true,
        },
        1550: {
          items: 5,
          nav: true,
        },
        1900: {
          items: 6,
          nav: true,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Js||**********/
    window.$("#TopCareNeed").owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      dots: true,
      nav: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: false,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Therapyowlslider Js||**********/
    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    window.$("#TheHolisticOwl").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        501: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });
    window.$("#TheHolisticOwl2").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
        },
        768: {
          items: 1,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".Therapyowlslider .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Custom Owl Therapyowlslider Js||**********/
    /**********|| Custom Owl HelpedOwlSlider Js||**********/
    window.$("#HelpedOwlSlider").owlCarousel({
      center: true,
      items: 2,
      loop: true,
      autoplay: true,
      autoplayTimeout: 15000,
      margin: 10,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        768: {
          items: 1,
          nav: true,
          dots: false,
          margin: 0,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
        1200: {
          items: 2,
          nav: true,
          loop: true,
          dots: false,
        },
        1700: {
          items: 1.8,
          nav: true,
          loop: true,
          dots: false,
        },
      },
    });

    window
      .$(".HelpPeopleOwl .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".HelpPeopleOwl .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Custom Owl HelpedOwlSlider Js||**********/

    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
    /**********|| Back To Top ||**********/
    // window.$('.counter').counterup({
    //             delay: 10,
    //             time: 2000
    //         });
    //         window.$('.counter').addClass('animated fadeInDownBig');
    //         window.$('h3').addClass('animated fadeIn');
    window.$(".Montserrat_menu_tab a").on("click", function () {
      var x = window.$(this).attr("data-tab");
      window.$(".tab-pane").addClass("d-none").removeClass("d-block");
      window
        .$('div[data-contant="' + x + '"]')
        .addClass("d-block")
        .removeClass("d-none");
    });
    window.$(".Montserrat_menu_tab a").on("click", function () {
      window.$(".Montserrat_menu_tab a").removeClass("active");
      window.$(this).addClass("active");
    });
    /**********|| Sticky Header ||**********/
    window.$(document).ready(function () {
      window.$(".animy_slide .animation_hover").hover(function () {
        window.$(".animy_slide .animation_hover").removeClass("active");
        window.$(".animy_slide .animation_hover").removeClass("web_move");
        window.$(this).addClass("active");
        window.$(this).prevAll(".animation_hover").addClass("web_move");
      });
      window.$(".animy_slide .animation_hover1").hover(function () {
        window.$(".animation_hover1").removeClass("active");
        window.$(".animation_hover1").removeClass("web_move");
        window.$(this).addClass("active");
        window.$(this).prevAll(".animation_hover1").addClass("web_move1");
      });
      window.$(".animy_slide .animation_hover2").hover(function () {
        window.$(".animation_hover2").removeClass("active");
        window.$(".animation_hover2").removeClass("web_move");
        window.$(this).addClass("active");
        window.$(this).prevAll(".animation_hover2").addClass("web_move");
      });

      window.$(".animy_slide .animation_hover3").hover(function () {
        window.$(".animation_hover3").removeClass("active");
        window.$(".animation_hover3").removeClass("web_move");
        window.$(this).addClass("active");
        window.$(this).prevAll(".animation_hover3").addClass("web_move");
      });
    });

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }, 300);

  // window.$(document).on("mouseenter",".HoverImgChnge",function() {
  //   console.log("sdfdsfdg home");
  //   window.$(this).find("img").attr("src", "/images/icons/icon5.png");
  //   },
  //   function () {
  //     window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
  //   }
  // );
  return (
    <div className="App">

      {/* Meta Tags Start */}
      <Helmet>
        <title>Best Physiotherapy Hospital in Bangalore | Pro Physio</title>
        <meta name="description" content="Pro Physio is the best physiotherapy hospital in Bangalore which provide ultimate solution with a complete array of health and wellness services." />
      </Helmet>
      {/* Meta Tags Ends */}

      <HomeBennerSection />
      <TransFormSection />
      <FindCareSection />
      <BodyBetterSection />
      <LeadLifeSection />
      <ProDifferenceSection />
      <AdvancedTherapySection />
      <GotQuestionsSection />
      <HowWeHelpedSection />
      <OurPartnersSection />
      <LiveHealthySection />
      <ConnectUsSection />
      <BackToTop />
    </div>
  );
}
export default Home;
