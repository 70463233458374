import React from "react";
import { Link } from "react-router-dom";

function transformSection() {
  return (
    <>
      <section
        className="w-100 clearfix TransformHealth py-5"
        id="TransFormLive"
      >
        <div className="container_two">
          <div className="TransFormTexts w-100 clearfix">
            <div className="HeadingDiv w-100 clearfix text-center">
              <h2 className="fs-72 lh-80 fw-700">
                Transform your health to live an <br /> active and abundant
                life.
              </h2>
              <p className="fs-22 MuliLight pt-4">
                Life is great! At times though there may be some tough
                challenges that we as humans encounter that can lead us to
                restrict our function and activity. Pro Physio is the ultimate
                solution with a complete array of health and wellness services
                that have been curated and tailored to suit you and your
                specific healthcare needs. Our integrated expert and
                transformational health solutions help you break away from all
                those health restrictions and take a step into living life to
                the fullest.
              </p>
            </div>
            <div className="UnlimitedLiving w-100 clearfix pt-5 pb-5 mb-xl-5">
              <div className="row">
                <div className="col-lg-3 col-6 p-3">
                  <div className="LivingImpro w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon1.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Ultimate <br /> Balance
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6 p-3">
                  <div className="LivingImpro w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon2.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Enhanced <br /> Strength
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6 p-3">
                  <div className="LivingImpro w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon3.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Painfree <br /> Living
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6 p-3">
                  <div className="LivingImpro w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon4.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Improved <br /> Mobility
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="LearnHow w-100 clearfix fw-600 text-center pb-md-5 mb-xl-5 HoverArrow">
              {/* <Link href="learn_prophysio_work.html" className="d-inline-block clr-1 fs-24 HoverImgChnge">Learn how ProPhysio works
                        <img src="/images/icons/icon5.png" className="img-fluid"/></Link> */}
              <Link
                to="prophysioWork"
                className="d-inline-block clr-1 fs-24 HoverImgChnge"
              >
                Learn how ProPhysio works
                <img src="/images/icons/icon5.png" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default transformSection;
