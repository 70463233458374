import React from "react";
import { Link } from "react-router-dom";

function integratedHealth() {
  return (
    <>
      {/*<!---------------|| Integrated health ||---------------> */}
      <section
        className="w-100 clearfix healthwelness animy_slide animy_slide1 wellness_sec py-5 kideshealth "
        id="Welnesssection"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="lifeheading mt-md-4">
                <h1 className="fs-64 fw-700 clr-2">
                  Integrated health & wellness
                </h1>
              </div>
            </div>
          </div>
          <div className="procommunity w-100 pt-md-4 pt-3 d-table">
            <div className="bg-clr-2 sportbulls position-relative manualtherphy animation_hover active br-first">
              <div className="row m-0">
                <div className="lifedirectors pt-5 text-left healthpracties">
                  <img src="/images/services/2.png" className="img-fluid" />
                </div>
                <div className="pl-sm-5 pt-5 pr-0 col-md-7 order-1 sportsparagraph_main_menu pl-0">
                  <div className="lifeheading PhysioPsychologyHeading ">
                    <h5 className="fs-28 fw-700 clr-2 mb-3">Physiotherapy</h5>
                  </div>
                  <div className="animy_hover">
                    <div className="sportsparagraph MuliLight pr-4">
                      <p className="fs-22 clr-2 fw-500 pt-3">
                        Physiotherapists are the movement specialists who
                        determine the core root of your problem and treat the
                        cause, not just the symptoms.
                      </p>
                    </div>
                    <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                      <li className="nav-item d-inline-block mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Manual Therapy
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block px-2 mt-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                          Mobilisation
                        </Link>
                      </li>
                      <li className="nav-item d-inline-block py-2">
                        <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                          Rehabilitation
                        </Link>
                      </li>
                    </ul>
                    <div className="knowmore my-5">
                      <Link
                        to="detailedElderlyService"
                        className="rightarrow fs-24 fw-600 HoverImgChnge"
                      >
                        Know more
                        <img src="/images/icons/icon5.png" />
                      </Link>
                      {/* <Link href="detailed_elderly_servies.html" className="rightarrow fs-24 fw-600 HoverImgChnge ">
                                Know more
                                <img src="/images/icons/icon5.png"/>
                            </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="PhysioPsychology bg-gray-1 position-relative Psychologydoctor animation_hover">
              <div className="pl-5 pt-5 pr-0 col-md-7 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">Psychology</h5>
                </div>
                <div className="animy_hover">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      Our philosophy is based on positive psychology. Using
                      positive psychology one can flow with a balanced state of
                      mind.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Positive psychology
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        Neuro Linguistic programs
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Cognitive Behavioral Therapy
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="detailed_elderly_servies.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                            Know more
                            <img src="/images/icons/icon5.png"/>
                            </Link> */}
                  </div>
                </div>
              </div>
              <div className=" chologyImage">
                <div className="position-absolute urbanhealthimg ">
                  <img
                    src="/images/services/3.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="selfchild bg-clr-4 position-relative urbanhealth animation_hover br-last">
              <div className="pl-5 pt-5 pr-0 col-md-7 order-md-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading ">
                  <h5 className="fs-28 fw-700 clr-2 mb-3">Urban Health</h5>
                </div>
                <div className="animy_hover">
                  <div className="sportsparagraph MuliLight pr-4">
                    <p className="fs-22 clr-2 fw-500 pt-3">
                      Feeling tired? Putting on too much weight? Frequent bad
                      moods? Sleep is disturbed? Bad digestion? These are
                      symptoms of related poor lifestyle and living.
                    </p>
                  </div>
                  <ul className="nav w-100 d-inline-block sportsparagraph_menu_list">
                    <li className="nav-item d-inline-block mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Microbiome
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block px-2 mt-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark">
                        Metabolite
                      </Link>
                    </li>
                    <li className="nav-item d-inline-block py-2">
                      <Link className="btn brownbtn br-8 fw-600 fs-16 clr-2 btn-outline-dark ">
                        Wearable Health
                      </Link>
                    </li>
                  </ul>
                  <div className="knowmore my-5">
                    <Link
                      to="detailedElderlyService"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Know more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link href="detailed_elderly_servies.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                            Know more
                            <img src="/images/icons/icon5.png"/>
                            </Link> */}
                  </div>
                </div>
              </div>
              <div className="urbanimg ">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/4.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||--------------->*/}
    </>
  );
}

export default integratedHealth;
