import React from "react";
import { Link } from "react-router-dom";

function readyToConnect() {
  return (
    <>
      {/* <!---------------|| Connect us Section ||---------------> */}
      <section
        className="w-100 clearfix withcontact HowCanHelpU py-5 bg-lightgray contact_client"
        id="Readycontact"
      >
        <div className="container-fluid pt-lg-5">
          <div className="row">
            <div className="col-12">
              <div className="ourtext text-center">
                <div className="ourheading">
                  <h1 className="fs-72 fw-700 clr-2">
                    Ready to connect with us?
                  </h1>
                </div>
                <div className="ourparagrph">
                  <p className="fs-22 clr-2 MuliLight">
                    Getting ProPhysio services are as easy as a click away
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="client pb-md-5">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-lightgreen w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img85.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you an{" "}
                        <strong className="MuliExtra"> Individual </strong>{" "}
                        seeking for guidance to lead a healthy life?{" "}
                      </p>
                      <div className="pt-3">
                        <a
                          
                          data-toggle="modal"
                          data-target="#getStarted"
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                        >
                          Get started{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient contact-bg-clr1 w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img86.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you a{" "}
                        <strong className="MuliExtra">
                          {" "}
                          Health professional{" "}
                        </strong>{" "}
                        looking to work with us?{" "}
                      </p>
                      <div className="pt-3">
                        <Link
                          
                          class=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#connectWithUs"
                        >
                          Connect with us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient contact-bg-clr2 w-100 clearfix p-4 br-8"
                    style={{ "background-image": "url(images/img87.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you a{" "}
                        <strong className="MuliExtra"> Hospital </strong>{" "}
                        looking to setup a <br className="d-md-block d-none" />{" "}
                        Clinic?{" "}
                      </p>
                      <div className="pt-3">
                        <Link
                          
                          data-toggle="modal" data-target="#HospitalSetup"
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                        >
                          Enquire now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default readyToConnect;
