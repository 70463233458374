import React from "react";
import WhatWeHaveHub from "../../shared/WhatWeHaveHub/whatWeHaveHub";
import BackToTop from "../../shared/BackToTop/backToTop";
import { Link } from "react-router-dom";

function joinTheCommunity() {
  function initiateCarousel() {
    /**********|| Custom Owl Therapyowlslider Js||**********/
    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    window.$("#TheHolisticOwl").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        501: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });
    window.$("#TheHolisticOwl2").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
        },
        768: {
          items: 1,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".Therapyowlslider .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Custom Owl Therapyowlslider Js||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section-1 ||---------------> */}
      <section
        className="
      w-100
      clearfix
      PhysioServicesTopBanner
      comunity_sec
      bg-grad-4
      JoinCummunityBanner
      "
        id="JoinCummunityBanner"
      >
        <div className="PhysioGradientBackground position-relative ">
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div
                  className="
                servicebannerImg
                clearfix
                text-md-right text-center
                position-absolute
                "
                >
                  <img src="/images/img56.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 order-1">
                <div
                  className="
                BeliveTexts
                w-100
                clearfix
                d-table
                h-100
                stepexperience
                py-xl-5 py-md-0 py-4 
                "
                >
                  <div className="d-table-cell align-middle">
                    <div className="backbtn">
                      <Link to="/" className="text-decoration-none">
                        <img src="/images/icons/icon23.png" />
                        <span className="fs-24 clr-1 fw-600">Back to Home</span>
                      </Link>
                      {/* <Link to="index.html" className="text-decoration-none">
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24 clr-1 fw-600">Back to Home</span>
                  </Link> */}
                    </div>
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700">
                        Be part of the community that really cares for you
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4 pr-5">
                      <p className="MuliLight fs-28 ls-1 m-0">
                        Enjoy free perks and workshops by joining the Prophysio
                        community
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-1 ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="
      w-100
      clearfix
      MotsTherapy
      py-md-5
      pt-5
      mb-lg-5
      TheWellNess
      mt-md-5
      "
        id="AdvanceTherapry"
      >
        <div className="container">
          <div className="TherapyLabs w-100 clearfix position-relative">
            <div className="HeadingDiv w-100 clearfix pb-sm-0 pb-5">
              <h2 className="fs-72 fw-700 lh-92">About the community</h2>
              <p className="fs-22 MuliLight pt-4" style={{ maxWidth: "100%" }}>
                Packed with freebies that will change the way you see your
                health
              </p>
            </div>
            <div
              className="owl-carousel owl-theme OwlSliderOne Therapyowlslider pt-5"
              id="TheHolisticOwl"
            >
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-100">
                      <div className="therphyimg h-100">
                        <img
                          src="/images/img57.jpg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            The holistic wellness collective
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Healing involves carefully analysing the health of
                            the individual through objective diagnostic tools
                            enabled with bio feedback tools that help us
                            understand what are the aspects that can be worked
                            on to improve health. We use modern diagnostic and
                            therapeutic tools to effective heal our clients.
                          </p>
                        </div>
                        <div className="learbtn pt-4">
                          <Link
                            to="therapistsExploare"
                            className="rightarrow fs-24 fw-600 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                          {/* <Link to="therapistsExploare" className="rightarrow fs-24 fw-600 HoverImgChnge">
                        Explore advance therapy
                        <img src="/images/icons/icon5.png" className="d-inline-block" />
                      </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-100">
                      <div className="therphyimg h-100">
                        <img
                          src="/images/iStock-1293927641.jpeg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Meet other Healthcare professionals
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            The Pro Physio network provides you as a medical
                            practitioner to collaborate, engage and connect with
                            other healthcare practitioners worldwide and build
                            your skill, grow your knowledge and expand
                            opportunities. Connect with us to know more.
                          </p>
                        </div>
                        <div className="learbtn pt-4">
                          <Link
                            to="therapistsExploare"
                            className="rightarrow fs-24 fw-600 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-100">
                      <div className="therphyimg h-100">
                        <img
                          src="/images/iStock-1244803401.jpeg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Start your private clinic
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Want to start your own clinic. Connect with us, with
                            a one stop shop for finance, procurement, active
                            partnerships with hospitals, equipment. We will
                            support you in every way to build practice and grow
                            it.
                          </p>
                        </div>
                        <div className="learbtn pt-4">
                          <Link
                            to="therapistsExploare"
                            className="rightarrow fs-24 fw-600 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy bg-whitegray">
                <div className="row">
                  <div className="col-lg-6 order-lg-2">
                    <div className="h-100">
                      <div className="therphyimg h-100">
                        <img src="/images/7.png" className="w-100 img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-1">
                    <div className="therphycontent p-4 h-100 d-table">
                      <div className="d-table-cell align-middle">
                        <div className="therphyheading">
                          <h1 className="fs-54 fw-700">
                            Get technology to support your practice
                          </h1>
                        </div>
                        <div className="therphyparagraph my-4 MuliLight">
                          <p className="fs-20 text-black fw-500">
                            Looking to expand horizons and be a future forward
                            healthcare provider? Connect with us. Our extensive
                            catalogue of technology solutions will help you stay
                            relevant in the fast changing technology driven
                            times.
                          </p>
                        </div>
                        <div className="learbtn pt-4">
                          <Link
                            to="therapistsExploare"
                            className="rightarrow fs-24 fw-600 HoverImgChnge"
                          >
                            Explore advance therapy
                            <img
                              src="/images/icons/icon5.png"
                              className="d-inline-block"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="counter"
              className="fs-12 MuliRegular clr-4 position-absolute counters SlideCount"
            ></div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="clearfix w-100 prophysio py-5 my-lg-5 WhatIDoNow JoinCummunityDos"
        id="JoinCummunityDos"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-72 fw-700">Perks of being a member</h1>
              </div>
              <div className="OtherText1 w-100 py-3">
                <p className="MuliLight fs-22 ls-1">
                  Packed with freebies that will change the way you see your
                  health
                </p>
              </div>
            </div>
          </div>
          <div className="assurance" id="Physioprosection">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/2.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Community <br />
                        meet-ups
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We’re young, we’re vibrant and we’re passionate about what
                      we do! If you think the same then join us. Let's talk
                      about all the things that make practice rewarding and
                      fulfilling. Also lets have some fun, socialse and mingle.
                      Join the conversation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/6.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Access to <br />
                        workshops
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      When it comes to delivering care, continued learning plays
                      a critical role in the overall outcomes to the patients.
                      We are active learners and encourage everyone to keep
                      learning. Cause when the learning starts you keep growing.
                      So join us in discovering everything that makes health the
                      most rewarding experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/7.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Free pass to
                        <br />
                        Pro events
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Pro Events are fun, they’re about everything that makes
                      you a part of this network. Basically your zeal, passion
                      and that mind of an explorer. Pro Events are a hosted at
                      across the city and it’ll be brilliant to have you there
                      to connect and interact.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/8.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Stay informed on
                        <br />
                        the latest health tech
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We think tech is taking big leaps in the way we can
                      deliver health. From diagnostics to wearable tech they are
                      all actively and positively impacting they way we live our
                      lives. If you stay connected to everything that's
                      happening in health and wanna stay updated on the tech
                      that's making waves. Stay connected.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/9.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Full access to <br />
                        Knowledge Hub
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Knowledge hub gives clinicians access to interesting
                      information that can make a difference in terms of their
                      understanding of the evolving trends in healthcare. Our
                      team of healthcare journalists deliver interesting and
                      relevant content that makes it even more engaging and
                      actionable.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/10.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Be in the network
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Are you a medical professional looking to network with
                      others in the domain? Join the Pro Network, The Pro
                      Network ensures you're building your network and access to
                      other healthcare professionals who can truly contribute to
                      you growing your practice and delivering the highest level
                      of care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section fivth ||---------------> */}
      <section
        className="
      w-100
      clearfix
      lifechanging
      bg-clr-1
      py-5
      winnergirl
      lifechanging_part lifechanging_part1
      animy_slide animy_slide4 animy_slide2
      "
        id="Workshopes"
      >
        <div className="container-fluid py-lg-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 fw-700 lh-92">Performance and wellness </h2>
            <p className="fs-22 MuliLight pt-xl-4">
              Become a member of the Pro Community to attend free workshops and
              much more.
            </p>
          </div>
          <div className="procommunity w-100 pt-5 d-table">
            <div
              className="
            bg-clr-2
            sportbulls
            position-relative
            br-first
            manualtherphy
            animation_hover2
            "
              data-toggle="tooltip"
              title="Empathy, the ability to understand and share the feelings of another. In healthcare this becomes very important and incidentally the ultimate tool in earning your patients trust. Today's medical system in India has to find its focus back into how to ensure that we are listening to our patients and guide us to build back the trust in the community towards medical care. Come interact with us through this series of interactive sessions with a panel of medical experts from various specialties to build and sharpen this tool."
            >
              <div className="row m-0">
                <div className="lifedirectors pt-5 text-left healthpracties">
                  <img src="/images/services/10.png" className="img-fluid" />
                </div>
                <div
                  className="
                pl-3
                pt-5
                pr-0
                col-md-8
                order-1
                sportsparagraph_main_menu
                "
                >
                  <div className="lifeheading PhysioPsychologyHeading">
                    <h5 className="fs-34 fw-700 lh-35 mb-3">
                      Empathy & Reflection in Practice
                    </h5>
                  </div>
                  <div className="animy_hover2">
                    <div className="sportsparagraph pt-3">
                      <p className="fs-22 clr-2 MuliLight pt-3">
                        Our strongest tool on delivering ultimate healthcare
                      </p>
                    </div>
                    <div className="likes">
                      <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                        <i
                          className="fa fa-heart clr-6 pr-1"
                          aria-hidden="true"
                        ></i>
                        82 Likes
                      </Link>
                    </div>
                    <div className="director py-5">
                      <ul className="nav comunity_menu_list">
                        <li className="nav-item varunshetty pr-2">
                          <p className="fs-24 fw-600 ls-1 mb-0">
                            Course Director
                          </p>
                          <p className="fs-34 MuliLight fw-500 mb-0">
                            Pratima Nagpal
                          </p>
                          <p className="fs-14 MuliLight">
                            Free course for members of Pro Community.
                          </p>
                        </li>
                        <li className="nav-item pl-4">
                          <p className="fs-24 fw-600 ls-1 mb-0">
                            Course Length
                          </p>
                          <p className="fs-34 MuliLight fw-500 mb-0">20</p>
                          <p className="fs-22 MuliLight">Hours</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="
            PhysioPsychology
            bg-lightgreen
            position-relative
            Psychologydoctor
            animation_hover2
            active
            "
              data-toggle="tooltip"
              title="The sports community demands high performance not only on the field but off it. Not only to the athletes themselves but also the trainer, coaches, medical staff, physiotherapists and all the other support team. Learn from this power packed speed up seminar series how you can ramp up your practice either as a physiotherapist or a sports trainer and engage with your players to bring out the best performance. "
            >
              <div className="pl-3 pt-5 pr-0 col-md-8 order-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading">
                  <h5 className="fs-34 fw-700 lh-35 mb-3">
                    Health Entrepreneurship
                  </h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph pt-3">
                    <p className="fs-22 clr-2 MuliLight pt-3">
                      A healthepreneur you got to get the game together. Learn
                      more how to care better.
                    </p>
                  </div>
                  <div className="likes">
                    <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                      <i
                        className="fa fa-heart clr-6 pr-1"
                        aria-hidden="true"
                      ></i>{" "}
                      82 Likes
                    </Link>
                  </div>
                  <div className="director py-5">
                    <ul className="nav comunity_menu_list">
                      <li className="nav-item varunshetty pr-2">
                        <p className="fs-24 fw-600 ls-1 mb-0">
                          Course Director
                        </p>
                        <p className="fs-34 MuliLight fw-500 mb-0">Pramod</p>
                        <p className="fs-14 MuliLight">CEO ProPhysio</p>
                      </li>
                      <li className="nav-item pl-4">
                        <p className="fs-24 fw-600 ls-1 mb-0">Course Length</p>
                        <p className="fs-34 MuliLight fw-500 mb-0">20</p>
                        <p className="fs-22 MuliLight">Hours</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="chologyImage">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/18.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div
              className="
            selfchild
            bg-whitepurpal
            br-last
            position-relative
            urbanhealth
            animation_hover2
            "
              data-toggle="tooltip"
              title="Did you know that every minute in the world a child below the age of 6 is in some kind of accident. Did you know that 90% of these accidents were avoidable by simple measures? Become a safe child ambassador. Learn how to keep children safe and protected so they can grow up to make the world a better place. "
            >
              <div className="pl-3 pt-5 pr-0 col-md-8 order-1 sportsparagraph_main_menu">
                <div className="lifeheading PhysioPsychologyHeading">
                  <h5 className="fs-34 fw-700 lh-35 mb-3">Safe Child</h5>
                </div>
                <div className="animy_hover2">
                  <div className="sportsparagraph pt-3">
                    <p className="fs-22 clr-2 MuliLight pt-3">
                      How to keep your children safe at all times from avoidable
                      accidents
                    </p>
                  </div>
                  <div className="likes">
                    <Link className="clr-2 fs-18 mulilight fw-500 text-decoration-none">
                      <i
                        className="fa fa-heart clr-6 pr-1"
                        aria-hidden="true"
                      ></i>{" "}
                      82 Likes
                    </Link>
                  </div>
                  <div className="director py-5">
                    <ul className="nav comunity_menu_list">
                      <li className="nav-item varunshetty pr-2">
                        <p className="fs-24 fw-600 ls-1 mb-0">
                          Course Director
                        </p>
                        <p className="fs-34 MuliLight fw-500 mb-0">
                          Varun Shetty
                        </p>
                        <p className="fs-14 MuliLight">
                          Free course for members of Pro Community.
                        </p>
                      </li>
                      <li className="nav-item pl-4">
                        <p className="fs-24 fw-600 ls-1 mb-0">Course Length</p>
                        <p className="fs-34 MuliLight fw-500 mb-0">5</p>
                        <p className="fs-22 MuliLight">Hours</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="urbanimg">
                <div className="position-absolute urbanhealthimg">
                  <img
                    src="/images/services/19.png"
                    alt="Psychology"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Community text-center pt-4">
            <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pt-3">
              <Link
                data-toggle="modal"
                data-target="#JoinCommunity"
                class="btn BtnBook fs-20 fw-500 px-4"
              >
                Join the ProPhysio Community
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fivth ||---------------> */}
      <WhatWeHaveHub />
      {/* <!---------------|| Section-8 ||---------------> */}

      {/* <!---------------|| Section-8 ||---------------> */}
      {/* <!---------------|| Section sevanth ||---------------> */}
      <section
        className="
      w-100
      clearfix
      bg-lightgray
      comunity_main
      HealthBootcamp
      py-md-0
      position-relative
      "
        id="HealthBoot"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix BodyHealthBoot">
            <div className="row">
              <div className="col-lg-7 order-2 position-static">
                <div className="d-table h-100 w-100 pt-5">
                  <div
                    className="
                  BeterImgs
                  w-100
                  clearfix
                  d-table-cell
                  align-bottom
                  text-center
                  healthgirlimg
                  "
                  >
                    <img
                      src="/images/img42.png"
                      className="img-fluid"
                      alt="No Images"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 order-1">
                <div className="BeterImgsTexts w-100 clearfix d-table pt-5">
                  <div className="d-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4 mt-sm-0 mt-3">
                      The Health <br />
                      Bootcamp
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Have a startup idea in the health and wellness space?
                      Looking for support in terms of mentorship, investment and
                      marketing? Reach out to us with your idea and we’ll help
                      you take it to the next level.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-4 pb-4">
                      <Link
                        to="joinTheCommunity"
                        className="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Know more about Health Bootcamp
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section sevanth ||---------------> */}
      <BackToTop />
    </>
  );
}

export default joinTheCommunity;
