import React from "react";
import { Link } from "react-router-dom";

function whatWeHave() {

    return(
         <>
          {/* <!---------------|| Section-8 ||---------------> */}
     <section className="w-100 clearfix WhatWeHave py-md-5 AboutusHowHelped" id=" AboutushelpedPeople">
        <div className="container-fluid">
        <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">What we have been upto</h2>
            <p className="fs-22 MuliLight pt-2">Recent events that helped people bring back balance and control in their
            lifestyle</p>
        </div>
        <div className="workshop py-5 WhatWeBeen w-100 clearfix TherapyLabs position-relative" id="AllOwlSliders_part">
            <div className="owl-carousel owl-theme OwlSliderOne AllOwlSliders Therapyowlslider" id="WhatWeHaveOwl">
            <div className="physiotherapist pb-1 pt-4 pl-4 mx-1 pr-3 bg-property br-24"
                style={{"background-image": "url(images/DSCF9542.jpeg)"}}>
                <div className="likes text-right">
                <Link className="clr-2 btn bg-white br-24 fs-18 mulilight" >
                    <i className="fa fa-heart clr-6 pr-1" aria-hidden="true"></i> 82 Likes</Link>
                </div>
                <div className="phyheading position-relative pb-2 ">
                <p className="fs-24 fw-600 text-white">21st July</p>
                </div>
                <div className="peoplebtn pt-3">
                <Link  className="d-inline-block MuliItalic">
                    <p className="fs-34 fw-500 text-white ">Ben Greenfield
                    </p>
                </Link>
                </div>
            </div>
            <div className="physiotherapist pb-1 pt-4 pl-4 mx-1 pr-3 bg-property br-24"
                style={{"background-image":"url(images/83853851_3992566354102415_645458783438372864_n.jpeg)"}}>
                <div className="likes text-right">
                <Link className="clr-2 btn bg-white br-24 fs-18 mulilight" >
                    <i className="fa fa-heart clr-6 pr-1" aria-hidden="true"></i> 74 Likes</Link>
                </div>
                <div className="phyheading position-relative pb-2 ">
                <p className="fs-24 fw-600 text-white">21st July</p>
                </div>
                <div className="peoplebtn pt-3">
                <Link  className="d-inline-block MuliItalic">
                    <p className="fs-34 fw-500 text-white ">Pragati Launch
                    </p>
                </Link>
                </div>
            </div>
            <div className="physiotherapist pb-1 pt-4 pl-4 mx-1 pr-3 bg-property br-24"
                style={{"background-image": "url(images/DF2025-Workshop-12.jpeg)"}}>
                <div className="likes text-right">
                <Link className="clr-2 btn bg-white br-24 fs-18 mulilight" >
                    <i className="fa fa-heart clr-6 pr-1" aria-hidden="true"></i> 112 Likes</Link>
                </div>
                <div className="phyheading position-relative pb-2 ">
                <p className="fs-24 fw-600 text-white">21st July</p>
                </div>
                <div className="peoplebtn pt-3">
                <Link  className="d-inline-block MuliItalic">
                    <p className="fs-34 fw-500 text-white ">Design thinking workshop
                    </p>
                </Link>
                </div>
            </div>
            <div className="physiotherapist pb-1 pt-4 pl-4 mx-1 pr-3 bg-property br-24"
                style={{"background-image":"url(images/12046611_1206089076084653_2306286458405341089_n.jpeg)"}}>
                <div className="likes text-right">
                <Link className="clr-2 btn bg-white br-24 fs-18 mulilight" >
                    <i className="fa fa-heart clr-6 pr-1" aria-hidden="true"></i> 82 Likes</Link>
                </div>
                <div className="phyheading position-relative pb-2 ">
                <p className="fs-24 fw-600 text-white">21st July</p>
                </div>
                <div className="peoplebtn pt-3">
                <Link  className="d-inline-block MuliItalic">
                    <p className="fs-34 fw-500 text-white ">Make Bangalore differently enabled
                    </p>
                </Link>
                </div>
            </div>
            <div className="physiotherapist pb-1 pt-4 pl-4 mx-1 pr-3 bg-property br-24"
                style={{"background-image": "url(images/renalprgm.jpg)"}}>
                <div className="likes text-right">
                <Link className="clr-2 btn bg-white br-24 fs-18 mulilight" >
                    <i className="fa fa-heart clr-6 pr-1" aria-hidden="true"></i> 74 Likes</Link>
                </div>
                <div className="phyheading position-relative pb-2 ">
                <p className="fs-24 fw-600 text-white">21st July</p>
                </div>
                <div className="peoplebtn pt-3">
                <Link  className="d-inline-block MuliItalic">
                    <p className="fs-34 fw-500 text-white ">Renal Wellness Program
                    </p>
                </Link>
                </div>
            </div>
            </div>
            <div id="counter" className="fs-12 MuliRegular clr-4 position-absolute counters SlideCount"></div>
        </div>
        </div>
    </section> 
    {/* <!---------------|| Section-8 ||---------------> */}
         
         </>
    );

    
}

export default whatWeHave;