import React from "react";
import { Link } from "react-router-dom";

function liveHealthy() {
  return (
    <>
      {/* <!---------------|| Live healthy Section ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveHealthyTabs py-5 my-lg-5"
        id="LiveHealthyTabs"
      >
        <div className="container_two ">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">Live healthy like a Pro</h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Get insights into your health and tips to keep you going fit
            </p>
          </div>
          {/* <!-- Nav tabs --> */}
          <div className="PhysioHealthyNavTabs pt-4 Montserrat">
            <ul
              className="nav nav-tabs justify-content-center border-0  Montserrat_menu_tab"
              role="tablist"
            >
              <li className="nav-item mx-5 ">
                <Link
                  className="nav-link fs-20 fw-500 clr-7 br-50 active"
                  data-tab="Podcasts"
                >
                  Podcasts
                </Link>
              </li>
              <li className="nav-item mx-5 ">
                <Link
                  className="nav-link fs-20 fw-500 clr-7 br-50"
                  data-tab="Videos"
                >
                  Videos
                </Link>
              </li>
              <li className="nav-item mx-5 ">
                <Link
                  className="nav-link fs-20 fw-500 clr-7 br-50"
                  data-tab="Article"
                >
                  Article
                </Link>
              </li>
              <li className="nav-item mx-5 ">
                <Link
                  className="nav-link fs-20 fw-500 clr-7 br-50"
                  data-tab="Tutorials"
                >
                  Tutorials
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- Tab panes --> */}
          <div className="tab-content">
            {/* <!--Tab Podcasts--> */}
            <div
              role="tabpanel"
              className="tab-pane active d-block"
              id="Podcasts"
              data-contant="Podcasts"
            >
              <div className="PhysioHealthyTabPanes mt-5">
                <div className="PhysioHealthyExploreknowledge">
                  <div className="row">
                    <div className="col-lg-6 mb-lg-4">
                      <div
                        className="PhysioHealthTipsPro br-10 p-5 position-relative h-lg-100"
                        style={{ backgroundImage: "url(images/blog/1.jpg)" }}
                      >
                        <div className="PhysioHealthMovement text-white">
                          <h6 className="fs-24 fw-600">
                            Movement sciences & Kinesiology
                          </h6>
                          <img
                            src="/images/icons/Line.png"
                            alt="line"
                            className="img-fluid"
                          />
                        </div>
                        <div className="PhysioHealthMovementPara MuliRegular fs-48 mt-2">
                          <Link
                            to="bRunning"
                            className="d-inline-block text-white"
                          >
                            {/* <Link
                            href="b_running.html"
                            className="d-inline-block text-white"
                          > */}
                            <h2
                              className="fs-48 ls-1"
                              style={{ lineHeight: "1" }}
                            >
                              The science of running.
                            </h2>
                            {/* </Link> */}
                          </Link>
                        </div>
                        <div className="PhysioHealthMovementButton MuliRegular">
                          <ul className="nav">
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </li>
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                5min read
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="PhysioHealthFourBoxContant">
                        <div className="row">
                          <div className="col-6 mb-4">
                            <Link
                              to="bWearableDevice"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link href="b_wearable_device.html" className="d-inline-block h-100 w-100"> */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/2.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      A wearable device to manage stress
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bImmunityTips"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_immunity_tips.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/3.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Community
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Women power The best there is
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bExercise"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_exercise.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/4.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Healthy exercise
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Exercise is the best medicine
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bMoveMore"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_move_more.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/5.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      How is ADHD and Autism different
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4 text-center">
                  <Link
                    to="knowledgehub"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link>
                  {/* <Link
                    href="knowledge_hub.html"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <!--Tab Videos--> */}
            <div
              role="tabpanel"
              className="tab-pane"
              id="Videos"
              data-contant="Videos"
            >
              <div className="PhysioHealthyTabPanes mt-5">
                <div className="PhysioHealthyExploreknowledge">
                  <div className="row">
                    <div className="col-lg-6 mb-lg-4">
                      <div
                        className="PhysioHealthTipsPro br-10 p-5 position-relative h-lg-100"
                        style={{ backgroundImage: "url(images/blog/1.jpg)" }}
                      >
                        <div className="PhysioHealthMovement text-white">
                          <h6 className="fs-24 fw-600">
                            Movement sciences & Kinesiology
                          </h6>
                          <img
                            src="/images/icons/Line.png"
                            alt="line"
                            className="img-fluid"
                          />
                        </div>
                        <div className="PhysioHealthMovementPara MuliRegular fs-48 mt-2">
                          <Link
                            to="bRunning"
                            className="d-inline-block text-white"
                          >
                            {/* <Link
                            href="b_running.html"
                            className="d-inline-block text-white"
                          > */}
                            <h2
                              className="fs-48 ls-1"
                              style={{ lineHeight: "1" }}
                            >
                              The science of running.
                            </h2>
                            {/* </Link> */}
                          </Link>
                        </div>
                        <div className="PhysioHealthMovementButton MuliRegular">
                          <ul className="nav">
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </li>
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                5min read
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="PhysioHealthFourBoxContant">
                        <div className="row">
                          <div className="col-6 mb-4">
                            <Link
                              to="bWearableDevice"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link href="b_wearable_device.html" className="d-inline-block h-100 w-100"> */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/2.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      A wearable device to manage stress
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bImmunityTips"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_immunity_tips.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/3.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Community
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Women power The best there is
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bExercise"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_exercise.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/4.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Healthy exercise
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Exercise is the best medicine
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bMoveMore"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_move_more.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/5.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      How is ADHD and Autism different
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4 text-center">
                  <Link
                    to="knowledgehub"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link>
                  {/* <Link
                    href="knowledge_hub.html"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <!--Tab Article--> */}
            <div
              role="tabpanel"
              className="tab-pane"
              id="Article"
              data-contant="Article"
            >
              <div className="PhysioHealthyTabPanes mt-5">
                <div className="PhysioHealthyExploreknowledge">
                  <div className="row">
                    <div className="col-lg-6 mb-lg-4">
                      <div
                        className="PhysioHealthTipsPro br-10 p-5 position-relative h-lg-100"
                        style={{ backgroundImage: "url(images/blog/1.jpg)" }}
                      >
                        <div className="PhysioHealthMovement text-white">
                          <h6 className="fs-24 fw-600">
                            Movement sciences & Kinesiology
                          </h6>
                          <img
                            src="/images/icons/Line.png"
                            alt="line"
                            className="img-fluid"
                          />
                        </div>
                        <div className="PhysioHealthMovementPara MuliRegular fs-48 mt-2">
                          <Link
                            to="bRunning"
                            className="d-inline-block text-white"
                          >
                            {/* <Link
                            href="b_running.html"
                            className="d-inline-block text-white"
                          > */}
                            <h2
                              className="fs-48 ls-1"
                              style={{ lineHeight: "1" }}
                            >
                              The science of running.
                            </h2>
                            {/* </Link> */}
                          </Link>
                        </div>
                        <div className="PhysioHealthMovementButton MuliRegular">
                          <ul className="nav">
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </li>
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                5min read
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="PhysioHealthFourBoxContant">
                        <div className="row">
                          <div className="col-6 mb-4">
                            <Link
                              to="bWearableDevice"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link href="b_wearable_device.html" className="d-inline-block h-100 w-100"> */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/2.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      A wearable device to manage stress
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bImmunityTips"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_immunity_tips.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/3.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Community
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Women power The best there is
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bExercise"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_exercise.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/4.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Healthy exercise
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Exercise is the best medicine
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bMoveMore"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_move_more.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/5.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      How is ADHD and Autism different
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4 text-center">
                  <Link
                    to="knowledgehub"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link>
                  {/* <Link
                    href="knowledge_hub.html"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link> */}
                </div>
              </div>
            </div>
            {/* <!--Tab Tutorials--> */}
            <div
              role="tabpanel"
              className="tab-pane"
              id="Tutorials"
              data-contant="Tutorials"
            >
              <div className="PhysioHealthyTabPanes mt-5">
                <div className="PhysioHealthyExploreknowledge">
                  <div className="row">
                    <div className="col-lg-6 mb-lg-4">
                      <div
                        className="PhysioHealthTipsPro br-10 p-5 position-relative h-lg-100"
                        style={{ backgroundImage: "url(images/blog/1.jpg)" }}
                      >
                        <div className="PhysioHealthMovement text-white">
                          <h6 className="fs-24 fw-600">
                            Movement sciences & Kinesiology
                          </h6>
                          <img
                            src="/images/icons/Line.png"
                            alt="line"
                            className="img-fluid"
                          />
                        </div>
                        <div className="PhysioHealthMovementPara MuliRegular fs-48 mt-2">
                          <Link
                            to="bRunning"
                            className="d-inline-block text-white"
                          >
                            {/* <Link
                            href="b_running.html"
                            className="d-inline-block text-white"
                          > */}
                            <h2
                              className="fs-48 ls-1"
                              style={{ lineHeight: "1" }}
                            >
                              The science of running.
                            </h2>
                            {/* </Link> */}
                          </Link>
                        </div>
                        <div className="PhysioHealthMovementButton MuliRegular">
                          <ul className="nav">
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                ></i>{" "}
                                82 Likes
                              </Link>
                            </li>
                            <li className="nav-item mr-3">
                              <Link className="nav-link br-50 fs-18 clr-2">
                                <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                                5min read
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="PhysioHealthFourBoxContant">
                        <div className="row">
                          <div className="col-6 mb-4">
                            <Link
                              to="bWearableDevice"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link href="b_wearable_device.html" className="d-inline-block h-100 w-100"> */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/2.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      A wearable device to manage stress
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bImmunityTips"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_immunity_tips.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/3.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Community
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Women power The best there is
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bExercise"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_exercise.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/4.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Healthy exercise
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      Exercise is the best medicine
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                          <div className="col-6 mb-4">
                            <Link
                              to="bMoveMore"
                              className="d-inline-block h-100 w-100"
                            >
                              {/* <Link
                              href="b_move_more.html"
                              className="d-inline-block h-100 w-100"
                            > */}
                              <div className="PhysioHealthTips bg-lightgray br-10 h-100">
                                <div className="PhysioHealthTipsImage">
                                  <img
                                    src="/images/blog/5.jpg"
                                    alt="Common"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsContant p-3">
                                  <div className="PhysioHealthTipsHeading Montserrat">
                                    <h6 className="fs-16 fw-600 m-0 clr-2">
                                      Your Mind
                                    </h6>
                                    <img
                                      src="/images/icons/Line.png"
                                      alt="line"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="PhysioHealthTipsPara fs-20 MuliRegular clr-2">
                                    <p className="mb-0">
                                      How is ADHD and Autism different
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </Link> */}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4 text-center">
                  <Link
                    to="knowledgehub"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link>
                  {/* <Link
                    href="knowledge_hub.html"
                    className="btn BtnBook fs-20 fw-500 px-4"
                  >
                    Explore knowledge hub
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Live healthy Section ||---------------> */}
    </>
  );
}

export default liveHealthy;
