import React from "react";
import OurPrinciples from "../../shared/OurPrinciples/ourPrincipleA";
import Experts from "../../shared/Experts/expertsAbout";
import OurPartnersSection from '../../shared/HomeSections/OurPartnersSection/OurPartners';
import AwardSection from "../../shared/AwardSection/awardSection";
import WhatIndustry from "../../shared/WhatIndustry/whatIndustry";
import WhatWeHave from "../../shared/WhatWeHave/whatWeHave";
import ReadyToConnect from "../../shared/ReadyToConnect/readyToConnect";
import BackToTop from '../../shared/BackToTop/backToTop';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function aboutus() {

  // Menu button selection
  if (window.screen.width < 768)
    window.$(".cross_btn").trigger('click');

  function initiateCarousel() {

    window.$('#MeetExpertsOwl').owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        576: {
          items: 2,
          nav: false
        },
        768: {
          items: 2,
          nav: true
        },
        992: {
          items: 2,
          nav: false,
          loop: false
        },
        1200: {
          items: 3,
          nav: false,
          loop: false
        },
        1300: {
          items: 4,
          nav: false,
          loop: false
        }
      }
    });

    window.$(".Therapyowlslider").on(
      "initialized.owl.carousel changed.owl.carousel",
      function (e) {
        if (!e.namespace) {
          return;
        }
        window.$(".SlideCount").text(
          e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
        );
      }
    );



    window.$("#HelpedOwlSlider").owlCarousel({
      center: true,
      items: 2,
      loop: true,
      autoplay: true,
      autoplayTimeout: 15000,
      margin: 10,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0
        },
        576: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0
        },
        768: {
          items: 1,
          nav: true,
          dots: false,
          margin: 0
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
        1200: {
          items: 2,
          nav: true,
          loop: true,
          dots: false
        },
        1700: {
          items: 1.8,
          nav: true,
          loop: true,
          dots: false
        },
      },
    });

    window.$(".HelpPeopleOwl .owl-nav .owl-prev").html(
      '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g class="a" transform="translate(1747 10985)"><path class="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path class="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect class="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path class="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path class="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
    );

    window.$(".HelpPeopleOwl .owl-nav .owl-next").html(
      '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path class="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect class="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path class="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path class="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
    );


    window.$("#WhatWeHaveOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });

    window.$(".Therapyowlslider .owl-nav .owl-prev").html(
      '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g class="a" transform="translate(1747 10985)"><path class="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path class="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect class="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path class="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path class="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
    );

    window.$(".Therapyowlslider .owl-nav .owl-next").html(
      '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path class="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect class="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path class="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path class="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
    );


    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );


  }

  setTimeout(() => { initiateCarousel() }, 300)

  return (
    <>

      {/* Meta Tags Start */}
      <Helmet>
        <title>Best Physiotherapy Clinic in Bangalore | Pro Physio</title>
        <meta name="description" content="Pro Physio is the best physiotherapy clinic in Bangalore that offers the best physiotherapy treatment. If you are suffering from pain. Visit our clinic today!" />
      </Helmet>
      {/* Meta Tags Ends */}

      <section className="w-100 clearfix about_team_banner PhysioServicesTopBanner bg-grad-7 AboutUsBanner"
        id="AboutBennerSection">
        <div className="PhysioGradientBackground position-relative" style={{ overflow: "hidden" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/19.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 order-1">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div className="d-table-cell align-middle">
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">We believe in a <br /> collaborative approach</h2>
                    </div>
                    <div className="OtherText1 w-100 py-4 pr-5">
                      <p className="MuliLight fs-28 ls-1 m-0">We understand that ones health is most precious to them and we
                        also recognise the multi diversity of the work the caring for people involves. Through our mode of
                        reflective practice we communicate systematically with other healthcare domain specialists in order
                        to provide a solution that is effective and solution driven.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      <OurPrinciples />
      {/* <!---------------|| Section-3 ||---------------> */}
      <section className="w-100 clearfix Yearfounded bg-brown py-5 CounterSection" id="Foundedsection">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="counterfounded position-relative">
                  <div className="counternumbers phyheading position-relative pb-1">
                    <h1 className="text-white fs-120"><span className="counter spanCounter" data-n="2016">2016</span></h1>
                  </div>
                  <div className="Yearparagraph pt-2">
                    <p className="fs-42 text-white lh-45">
                      Year<br /> Founded
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="counterfounded position-relative">
                  <div className="counternumbers phyheading position-relative pb-1">
                    <h1 className="text-white fs-120"><span className="counter spanCounter" data-n="10000">10,000</span>+</h1>
                  </div>
                  <div className="Yearparagraph pt-2">
                    <p className="fs-42 text-white lh-45">
                      Happy <br />Customers
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="counterfounded position-relative">
                  <div className="counternumbers phyheading position-relative pb-1">
                    <h1 className="text-white fs-120"><span className="counter spanCounter" data-n="250">250</span><span>+</span>
                    </h1>
                  </div>
                  <div className="Yearparagraph pt-2">
                    <p className="fs-42 text-white lh-45">
                      Doctors <br />Associated
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-3 ||---------------> */}
      <Experts />
      <OurPartnersSection />
      <AwardSection />
      <WhatIndustry />
      <WhatWeHave />
      <ReadyToConnect />
      <BackToTop />

    </>
  );
}

export default aboutus;
