import React from "react";
import { Link } from "react-router-dom";

function bodyBetterSection() {
  return (
    <>
      {/* <!---------------|| Body Better Section ||---------------> */}
      <section
        className="w-100 clearfix BodyBetter py-5 my-xl-5"
        id="BetterDots"
        style={{ backgroundImage: "url(images/6.png)" }}
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6 order-lg-2 d-lg-none">
                <div className="BeterImgs w-100 clearfix">
                  <img
                    src="/images/6.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="BeterImgsTexts w-100 clearfix d-lg-table">
                  <div className="d-lg-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4">
                      We understand your body better by connecting the dots.
                    </h2>
                    <p className="fs-22 mb-4 MuliLight col-lg-9 p-0">
                      When you move better your mind also starts to work more
                      efficiently and this further enables you to make healthier
                      choices be it at work or the quality of life at home with
                      friends and family. We look at health not merely with the
                      absence of disease but elevating your health status to a
                      state of complete physical, mental and social well-being.
                    </p>
                    <div className="w-100 clearfix BodyNutrition my-md-5">
                      <ul className="w-100 list-unstyled MindBodyList mb-3 pb-sm-0 pb-3">
                        <li className="d-inline-block">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block">
                              <img
                                src="/images/icons/icon11.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Body</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block">
                              <img
                                src="/images/icons/icon12.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Mind</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block">
                              <img
                                src="/images/icons/icon13.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Nutrition</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="LearnHow w-100 clearfix fw-600 pt-xl-5 HoverArrow">
                      {/* <Link href="join_the_community.html" className="d-inline-block clr-1 fs-24 HoverImgChnge">Explore how we make
                                a difference <img src="/images/icons/icon5.png" className="img-fluid"/></Link> */}
                      <Link
                        to="joinTheCommunity"
                        className="d-inline-block clr-1 fs-24 HoverImgChnge"
                      >
                        Explore how we make a difference
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Body Better Section ||---------------> */}
    </>
  );
}

export default bodyBetterSection;
