import React from "react";
import ReadyToTransfer from "../../shared/ReadyToTransfer/readyToTransfer";
import BackToTop from "../../shared/BackToTop/backToTop";
import HowWeHelpedSection from "../../shared/HomeSections/HowWeHelpedSection/HowWeHelped";
import GotQuestionsSection from "../../shared/HomeSections/GotQuestionsSection/gotQuestions";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BookNowContext } from "../BookNowContext/BookNowContext";
import CallBackForm from "./CallBackForm";
import { Helmet } from "react-helmet";

function contactus() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  // Menu button selection
  if (window.screen.width < 768) window.$(".cross_btn").trigger("click");

  function initiateCarousel() {
    window.$("#GotQuestionOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    window.$("#HelpedOwlSlider").owlCarousel({
      center: true,
      items: 2,
      loop: true,
      autoplay: true,
      autoplayTimeout: 15000,
      margin: 10,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        768: {
          items: 1,
          nav: true,
          dots: false,
          margin: 0,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
        1200: {
          items: 2,
          nav: true,
          loop: true,
          dots: false,
        },
        1700: {
          items: 1.8,
          nav: true,
          loop: true,
          dots: false,
        },
      },
    });

    window
      .$(".HelpPeopleOwl .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".HelpPeopleOwl .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>

      {/* Meta Tags Start */}
      <Helmet>
        <title>Contact Us for Physiotherapy service in Bangalore | Pro Physio</title>
        <meta name="description" content="Contact us for any information regarding the physiotherapy service. We are here to help you at all times." />
      </Helmet>
      {/* Meta Tags Ends */}


      {/* <!---------------|| Section One ||---------------> */}
      <section
        className="w-100 clearfix service_transformation contact_us_banner  contact_us_banner_main PhysioServicesTopBanner bg-grad-5 ContactUsBanner"
        id="PhysioTopBanner"
      >
        <div className="PhysioGradientBackground position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/35.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 order-1 col-sm-8">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div className="d-table-cell align-middle">
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">
                        Connect with us to <br className="d-md-block d-none" />{" "}
                        boost your health
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4">
                      <p className="MuliLight fs-28 ls-1 m-0">
                        We are more than happy to help. Just give us a call or
                        give us your phone number so that we can call you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="w-100 clearfix withcontact HowCanHelpU pt-5 mt-lg-5 contact_client"
        id="Readycontact"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="ourtext text-center">
                <div className="ourheading">
                  <h1 className="fs-72 fw-700 clr-2">How can we help you?</h1>
                </div>
                <div className="ourparagrph">
                  <p className="fs-22 clr-2 MuliLight">
                    Getting ProPhysio services are as easy as a click away
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="client py-md-5">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-lightgreen w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img85.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you an{" "}
                        <strong className="MuliExtra"> Individual </strong>{" "}
                        seeking for guidance to lead a healthy life?
                      </p>
                      <div className="pt-3">
                        <Link
                          data-toggle="modal"
                          data-target="#getStarted"
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                        >
                          Get started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient contact-bg-clr1 w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img86.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you a{" "}
                        <strong className="MuliExtra">
                          {" "}
                          Health professional{" "}
                        </strong>{" "}
                        looking to work with us?
                      </p>
                      <div className="pt-3">
                        <Link
                          data-toggle="modal"
                          data-target="#connectWithUs"
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                        >
                          Connect with us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3 contact_img">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient contact-bg-clr2 w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img87.png)" }}
                  >
                    <div className="contanttrulyCare1 position-absolute w-50 MuliLight">
                      <p className="fs-35">
                        Are you a{" "}
                        <strong className="MuliExtra"> Hospital </strong>{" "}
                        looking to setup a <br className="d-md-block d-none" />{" "}
                        Clinic?{" "}
                      </p>
                      <div className="pt-3">
                        <Link
                          data-toggle="modal"
                          data-target="#HospitalSetup"
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                        >
                          Enquire now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section-3 ||---------------> */}
      <section
        className="w-100 clearfix Physioformcontact py-5 mb-lg-5"
        id="contactform"
      >
        <div className="ContactForm d-lg-flex w-100 h-100">
          <div className="PhysioReachOut bg-lightpink w-50 float-lg-left">
            <div className="ContentWidth m-auto pl-xl-5">
              <div className="ourtext">
                <div className="ourheading">
                  <h1 className="fs-72 fw-700 clr-2">Reach out to us</h1>
                </div>
                <div className="ourparagrph">
                  <p className="fs-22 clr-2 MuliLight">
                    Have any doubts ? feel free to contact us
                  </p>
                </div>
              </div>
              <div className="ProInfosLines">
                <div className="InfoDetails d-flex py-4">
                  <div className="InfoImg1 d-inline-block">
                    <img
                      src="/images/icons/16.png"
                      className="img-fluid"
                      alt="No images"
                    />
                  </div>
                  <div className="InfoText1 d-inline-block">
                    <p
                      className="fw-400 fs-42 ls-1 mb-0"
                      style={{ fontSize: 22 }}
                    >
                      info@prophysio.co
                    </p>
                  </div>
                </div>
                <div className="InfoDetails d-flex py-4">
                  <div className="InfoImg1 d-inline-block">
                    <img
                      src="/images/icons/17.png"
                      className="img-fluid"
                      alt="No images"
                    />
                  </div>
                  <div className="InfoText1 d-inline-block">
                    <p
                      className="fw-400 fs-42 ls-1 mb-0"
                      style={{ fontSize: 22 }}
                    >
                      8197378444
                    </p>
                  </div>
                </div>
                <div className="InfoDetails d-flex py-4">
                  <div className="InfoImg1 d-inline-block">
                    <img
                      src="/images/icons/18.png"
                      className="img-fluid"
                      alt="No images"
                    />
                  </div>
                  <div className="InfoText1 d-inline-block">
                    <p
                      className="fw-400 fs-42 ls-1 mb-0"
                      style={{ fontSize: 22 }}
                    >
                      GK complex, No 257, 4th floor, 8th Main Rd, Amarjyoti
                      Layout, Domlur, Bengaluru, Karnataka 560071
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wantuscall bg-brown w-50 float-lg-right">
            <div className="ContentWidth m-auto">
              <div className="ourtext ">
                <div className="ourheading">
                  <h1 className="fs-72 fw-700 clr-2 text-white">
                    Want us to call you?
                  </h1>
                </div>
                <div className="ourparagrph">
                  <p className="fs-32 clr-2 text-white MuliLight">
                    Need help? Get a call back from our support team
                  </p>
                </div>
              </div>
              <CallBackForm />
              {/* <div className="PhysioWantCall col-lg-8 mt-5 p-0">
                <form action="/action_page.php" onSubmit={submitHandle}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border-0 clr-8 fs-30 br-10"
                      placeholder="Your Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control border-0 clr-8 fs-30 br-10"
                      placeholder="Phone number"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn BtnBook fs-22 fw-500 px-4 mt-4"
                  //   data-toggle="modal"
                  // data-target="#PartneringWithUS"
                  >
                    Get a call back
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-3 ||---------------> */}
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix PhysioProPhysio py-md-5 pb-5 my-xl-5"
        id="ProPhysioCommunity"
      >
        <div className="container">
          <div className="PhysioProContactHeading w-100 clearfix text-center">
            <h2 className="fs-34 lh-80 fw-700">
              Get timely health tips curated by doctors
            </h2>
            <Link
              to="joinTheCommunity"
              className="btn BtnBook fs-22 fw-500 px-4 mt-lg-4"
            >
              Join the ProPhysio community
            </Link>
            {/* <Link  className="btn BtnBook fs-22 fw-500 px-4 mt-lg-4 activateContactModal">Join the ProPhysio community</Link>  */}
            <p className="fs-22 MuliLight pt-2 mb-md-4 mt-2 mb-0">
              Quick online assessment with our expert{" "}
              <br className="d-md-block d-none" /> physios free for the first
              time!
            </p>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
      <GotQuestionsSection />
      <HowWeHelpedSection />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}

export default contactus;
