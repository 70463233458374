import React, { useEffect, useState } from "react";
import ArticleHub from "../../shared/ArticleHub/articleHub";
import UnderstandHub from "../../shared/UnderstandHub/understandHub";
import CareDuringHub from "../../shared/CareDuringHub/careDuringHub";
import MovementHub from "../../shared/MovementHub/movementHub";
import LifeChangingHub from "../../shared/LifeChangingHub/lifeChangingHub";
import WhatWeHaveHub from "../../shared/WhatWeHaveHub/whatWeHaveHub";
import TakeYourPractice from "../../shared/TakeYourPractice/takeYourPractice";
import BackToTop from "../../shared/BackToTop/backToTop";
import "../../App.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Knowledgehub() {
  // Menu button selection
  if (window.screen.width < 768) window.$(".cross_btn").trigger("click");

  function initiateCarousel() {
    window.jQuery(".AllOwlSliders").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
        576: {
          items: 2,
          nav: true,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
        1800: {
          items: 4,
          nav: false,
          loop: false,
        },
      },
    });

    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);
  return (
    <>

      {/* Meta Tags Start */}
      <Helmet>
        <title>Physiotherapy Article |</title>
        <meta name="description" content="Pro Physio for physiotherapy services that help to treat a wide range of health and wellness issues including: Back and neck pain, muscle and joint pain." />
      </Helmet>
      {/* Meta Tags Ends */}
      {/* <!---------------|| Section One ||---------------> */}
      <div id="physioknowledgebanner" style={{ paddingTop: "100px" }}>
        <section
          className="w-100 clearfix knowledgebanner bg-property py-xl-5"
          style={{ backgroundImage: "url(images/blog/9L1A7169.jpg)" }}
        >
          <div className="container h-100 py-5 my-xl-5 position-relative">
            <div className="h-100 d-table">
              <div className="d-table-cell align-middle text-left">
                <div className="knowledgebannerContant text-white ">
                  <div className="knowledgebannertitle fs-24 fw-600 mb-2">
                    <span>Movement Science</span>
                  </div>
                  <div className="knowledgebannerheading">
                    <h1 className="fs-72 fw-700 pt-4">
                      The science of <br /> running.
                    </h1>
                  </div>
                  <div className="knowledgebannerviews py-4">
                    <ul className="nav knowledgebanneIcons MuliRegular">
                      <li className="nav-item ">
                        <Link className=" fs-18 text-white" >
                          <i
                            className="fa fa-heart clr-6"
                            aria-hidden="true"
                          ></i>{" "}
                          82 Likes
                        </Link>
                      </li>
                      <li className="nav-item px-4">
                        <Link className=" fs-18 text-white" >
                          <i className="fa fa-eye" aria-hidden="true"></i> 5min
                          read
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="knowledgebannerpara MuliRegular fs-22 col-lg-6 p-0">
                    <p>
                      Running is one of the world’s most popular forms of
                      exercise, with millions of regular participants. Although
                      running is an effective way to achieve many health
                      benefits, it is associated with a high risk of injury.
                      Some injuries are traumatic, but most are due to overuse.
                    </p>
                  </div>
                  <div className="knowledgebannerlink fs-20">
                    <Link to="bRunning" className="clr-1 fw-500">
                      Read more
                    </Link>
                    {/* <Link href="b_running.html" className="clr-1 fw-500">Read more</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <ArticleHub />
      <UnderstandHub />
      <CareDuringHub />
      <MovementHub />
      <LifeChangingHub />
      <WhatWeHaveHub />
      <TakeYourPractice />
      <BackToTop />
    </>
  );
}

export default Knowledgehub;
