import React from "react";
import { Link } from "react-router-dom";

function backToTop() {
  function initiateCarousel() {
    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
    /**********|| Back To Top ||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Back To Top Start ||-----------------> */}
      <div
        id="BackToTop"
        className="bg-blue position-fixed text-white fs-30 lh-30 text-center br-50"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </div>
      {/* <!--whatsapp --> */}
      <a
        className="fixedButton"
        href="https://wa.me/918197378444"
        target="_blank"
      >
        <div className="roundedFixedBtn">
          <i
            className="fa fa-whatsapp"
            style={{ position: "relative", bottom: "3px" }}
          ></i>
        </div>
      </a>
      {/* <!---------------|| Back To Top End ||-----------------> */}
    </>
  );
}

export default backToTop;
