import React from "react";
import MeetOtherTherapists from "../MeetOtherTherapists/meetOtherTherapists";
import TakeYourFirstStep from "../TakeYourFirstStep/takeYourFirstStep";
import ReadyToTransfer from "../ReadyToTransfer/readyToTransfer";
import BackToTop from "../BackToTop/backToTop";
import { Link } from "react-router-dom";

function th_MeenuDasPB() {
  return (
    <>
      {/* <!---------------|| Section one ||---------------> */}
      <section
        className="w-100 clearfix py-5 therapists "
        id="Detailedtherapists"
      >
        <div className="container">
          <div className="therapists pb-2">
            <div className="row">
              <div className="col-12">
                <div className="backbtn">
                  <Link
                    to="therapistsExploare"
                    className="text-decoration-none"
                  >
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24">Back to all therapists</span>
                  </Link>
                  {/* <Link href="therapists_exploare.html" className="text-decoration-none">
                <img src="/images/icons/icon23.png"/>
                <span className="fs-24">Back to all therapists</span>
              </Link> */}
                </div>
              </div>
            </div>
            <div className="shruthidigde my-5">
              <div className="row">
                <div className="col-lg-4  col-md-8 mx-auto">
                  <div className="girlimg my-5 my-lg-0">
                    <img
                      src="/images/therapist/MeenuDasPhysiotherapist.jpg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="doctordata pl-sm-2 position-relative">
                    <div className="doctorname">
                      <h1 className="fw-600 mt-2">
                        <span className="w-100 d-inline-block float-left fs-60">
                          Meenu Das P B
                        </span>
                      </h1>
                    </div>
                    <div className="doctorsubject">
                      <p
                        className="fs-40 text-darkgray fw-500 mb-0"
                        style={{ opacity: "0.67" }}
                      >
                        Physiotherapist
                      </p>
                      <p className="fs-38 fw-600">
                        <span className="">6</span>{" "}
                        <span className="">yrs Exp.</span>
                      </p>
                    </div>
                    <div className="doctorthought">
                      <p className="fs-30  my-md-3 my-2 lh-30 clr_graylight MuliItalic">
                        “I discovered that, Mind body connection is a perfect
                        balance between exercise sleep and appetite as we age.”
                      </p>
                    </div>
                    <div className="doctorpara">
                      <p className="fs-22 text-black my-3 MuliLight">
                        I am a very passionate and supportive physiotherapist. I
                        strive to bring the best possible outcome for the
                        patient with positive motivation and making the patient
                        understand the necessity of doing each protocol and
                        their results.
                      </p>
                    </div>
                    <div className="doctorlocation pt-md-4">
                      <div className="location pt-3">
                        <div className="row">
                          <div className="col-md-8">
                            <ul className="nav">
                              <li className="nav-item">
                                <Link className="locationicon d-inline-block">
                                  <img src="/images/icons/location.svg" />
                                </Link>
                              </li>
                              <li className="nav-item pl-3">
                                <span className="fs-22 text-darkgray MuliLight">
                                  Lorem ipsum dolor sit amet, consetetur
                                  <br />
                                  sadipscing elitr, sed diam nonumy.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <div className="booksession text-md-right text-center">
                              <div className="more_show ">
                                <Link className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1">
                                  Book a session
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section two ||---------------> */}
      <section
        className="clearfix w-100 prophysio pb-5 aboutdoctors therpy_services TherpyDewtails1"
        id="Aboutdoctors"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-60 fw-600">About Meenu Das PB</h1>
              </div>
            </div>
          </div>
          <div className="assurance p-md-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Expertise</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Inclination is always towards Women’s Health but have
                      great interest and exposure towards orthopedics and
                      pediatrics equally.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Credentials</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">Email: meenu@prophysio.co</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Achievements</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      1)Head of Wellwomen in Wellness one, multi specialty rehab
                      center, Calicut 2)Head of Physiotherapy Department, Dar Al
                      Shifa, Bahrain 3)Employee of the year award from the same
                      institution.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        Previous Experience
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      1)Licensed Physiotherapist Wellness one Multi- speciality
                      Rehabilitation center, Calicut, Kerala. 2)Licensed
                      Physiotherapist, Dar Al Shifa Medical Center, Kingdom of
                      Bahrain.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        What I love about my job
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Evolution of self developmentEvolution of self
                      developmentEvolution of self development.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        In my free time I like to
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">Listen music</p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="more_show text-center pt-4">
                  <Link className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1">
                    Claim your first free consultation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||---------------> */}

      <MeetOtherTherapists />
      <TakeYourFirstStep />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}
export default th_MeenuDasPB;
