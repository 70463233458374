import React, { useState, useEffect } from "react";
import ReadyToTransfer from "../../shared/ReadyToTransfer/readyToTransfer";
import BackToTop from "../../shared/BackToTop/backToTop";
import { Link } from "react-router-dom";
import FaqService from "../../services/FaqService/FaqService";

function prophysioWork() {
  const FaqServe = new FaqService();
  const [showForm, setShowForm] = useState(false);
  const [slideClickData, setSlideClickData] = useState({});
  const [faqList, setFaqList] = useState([]);
  const [showMore, setShowMore] = useState({});
  const [search, setSearch] = useState({
    start: 0,
    perPage: 100,
    searchTxt: "",
    searchField: "",
  });

  useEffect(async () => {
    await faqListApi();
  }, []);

  const faqListApi = async () => {
    const activity = {
      screens: "home",
    };
    try {
      let response = await FaqServe.faqList(
        activity,
        search.start,
        search.perPage
      );
      if (response) {
        console.log("response", response.rows);
        setFaqList(response.rows);
      }
    } catch (err) {
      throw err;
    }
  };

  const clickHandle = (index, data) => {
    console.log("index", index, data);
    setSlideClickData(data);
    setShowForm(!showForm);
  };
  const closeHandleForm = (i) => {
    setShowForm(false);
  };

  function initiateCarousel() {
    window.$("#WhatWeHaveOwl,#GotQuestionOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section
        className="w-100 clearfix learn_pro_main service_transformation PhysioServicesTopBanner bg-grad-6 LearnProBanner Ourtherapists work_transformation"
        id="PhysioTopBanner"
      >
        <div className="PhysioGradientBackground position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/30.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 order-1 col-sm-8">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div className="d-table-cell align-middle">
                    <div className="backbtn">
                      <Link to="/" className="text-decoration-none">
                        <img src="/images/icons/icon23.png" />
                        <span className="fs-24 clr-1 fw-600">Back to Home</span>
                      </Link>
                      {/* <Link href="index.html" className="text-decoration-none">
                    <img src="/images/icons/icon23.png"/>
                    <span className="fs-24  clr-1 fw-600">Back to Home</span>
                  </Link> */}
                    </div>
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">
                        How we rebuild your <br className="d-md-block d-none" />{" "}
                        health from root up
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4">
                      <p className="MuliLight fs-28 ls-1 m-0">
                        Physiotherapists are the movement specialists who
                        determine the core root of your problem and treat the
                        cause, not just the symptoms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="clearfix w-100 PerksMembers ProPhysioassurance py-5 my-lg-5 HolisticNess1 WhatIDoNow PerksMembers_work"
        id="BeingMembers"
      >
        <div className="container">
          <div className="HeadingDiv w-100 clearfix text-center pb-lg-5">
            <h2 className="fs-72 fw-700 lh-92">
              A 360 degree approach <br />
              to health and wellness
            </h2>
            <p className="fs-22 MuliLight pt-4">
              The mind, body and the gut work closely with each other. Only an
              integrated analytic and scientific approach catalyse complete
              recovery.
            </p>
          </div>
          <div className="assurance">
            <div className="row">
              <div className="col-lg-4 px-xl-5 py-md-2">
                <div className="trainedexpert LearnProPhysioWork">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/icon12.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">Mind</p>
                    </div>
                  </div>
                  <div className="phpoyherpara ">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-xl-5 py-md-2">
                <div className="trainedexpert LearnProPhysioWork">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/icon11.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">Body</p>
                    </div>
                  </div>
                  <div className="phpoyherpara ">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-xl-5 py-md-2">
                <div className="trainedexpert LearnProPhysioWork">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/icon13.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">Nutrition</p>
                    </div>
                  </div>
                  <div className="phpoyherpara ">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation science. This helps us deliver
                      gold standards in practice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section-3 ||---------------> */}
      <section
        className="w-100 clearfix LearnProPhysioWork bg-lightgray"
        id="ChangeYOurWay"
      >
        <div className=" BodyBetter py-5 LeadLifes LearnMore1 DataDrvieCare">
          <div className="container">
            <div className="BodyByData w-100 clearfix">
              <div className="row">
                <div className="col-lg-6">
                  <div className="BeterImgs w-100 clearfix">
                    <img
                      src="/images/img80.png"
                      className="img-fluid"
                      alt="No Images"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="BeterImgsTexts1 w-100 clearfix d-table h-100">
                    <div className="d-table-cell align-middle TextMiddle">
                      <h2 className="fs-72 fw-700 mb-4">
                        Data Driven Healthcare
                      </h2>
                      <p className="fs-22 mb-lg-4 MuliLight m-0">
                        Pro Physio tailors hyper personalised health programs
                        that put you on the path to a life that is filled with
                        good health and happiness. Enroll now to start a journey
                        with us. When its comes to health we look at the
                        outcomes as the critical part of delivering care. Has
                        there been any changes to the mechanics, chemistry,
                        physiology of the individual is measured using glucose
                        monitoring, Heart rate variability and various other
                        parameters to ensure the health delivered evidence based
                        and impactful with positive outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" BodyBetter body_img py-md-5 pb-5 my-xl-5 LeadLifes LearnMore1 DataDrvieCare1">
          <div className="container">
            <div className="BodyByData w-100 clearfix">
              <div className="row">
                <div className="col-lg-6 order-lg-2">
                  <div className="BeterImgs w-100 clearfix">
                    <img
                      src="/images/Group74680.png"
                      className="img-fluid"
                      alt="No Images"
                    />
                  </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                  <div className="BeterImgsTexts1 w-100 clearfix d-table h-100">
                    <div className="d-table-cell align-middle TextMiddle">
                      <h2 className="fs-72 fw-700 mb-4">
                        Hyper-personalised experience through a dedicated App
                      </h2>
                      <p className="fs-22 mb-lg-4 MuliLight m-0">
                        The Proactive app picks up, measures, reminds and
                        connects you to the best solutions in healthcare. From
                        lifestyle monitoring to cardiac monitoring, from
                        consulting your clinician with ease as well the medical
                        professionals being able to provide continuous care. Its
                        all possible on the Proactive app. Get ready to download
                        health into you!
                      </p>
                      <div className="ExploBtn DwnldApp w-100 clearfix pb-4 pt-4 mt-md-0 mt-3">
                        <Link
                          className="btn BtnBook fs-20 fw-500 px-4"
                          data-toggle="modal"
                          data-target="#GetAssessedModal"
                        >
                          Get assessed now!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-3 ||---------------> */}
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix LearnPhysioJourney mb-md-5 pb-md-5"
        id="ProPhysioJourney"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading mt-sm-5 pt-5">
                <h1 className="fs-72 fw-700">The ProPhysio journey</h1>
              </div>
            </div>
          </div>
          <div className="HealthStatusStepFirst my-md-4 py-lg-5 my-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="HealthStatusImages">
                  <img
                    src="/images/31.jpg"
                    alt="ProPhysio1"
                    className="rounded img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="HealthStatusContant">
                  <div className="HealthStatusSubHeading">
                    <h6 className="fs-40  mb-4 fw-700">Step 1</h6>
                  </div>
                  <div className="HealthStatusHeading">
                    <h1 className="fs-72 mb-4 fw-600">
                      Understanding your body & health status
                    </h1>
                  </div>
                  <div className="HealthStatusPara">
                    <p className="fs-22 mb-4 MuliLight">
                      Your body, mind and gut is all unique to you. So what you
                      need is different from what another individual needs. We
                      know that, we respect that and we work with that.
                      Considering your baseline at the moment and where you
                      would like to be in terms of health, we take you through a
                      journey that ensures not only that you reach your optimal
                      health but also have the right habits to maintain it. Get
                      onto the ProPhysio platform and discover your road to the
                      healthiest you’ve ever been!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="HealthStatusStepSecond my-md-4 py-lg-5 my-3">
            <div className="row">
              <div className="col-lg-6 order-lg-2">
                <div className="HealthStatusImages">
                  <img
                    src="/images/32.jpg"
                    alt="ProPhysio1"
                    className="rounded img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="HealthStatusContant">
                  <div className="HealthStatusSubHeading">
                    <h6 className="fs-40  mb-4 fw-700">Step 2</h6>
                  </div>
                  <div className="HealthStatusHeading">
                    <h1 className="fs-72 mb-4 fw-600">
                      Building the human in 360
                    </h1>
                  </div>
                  <div className="HealthStatusPara">
                    <p className="fs-22 mb-4 MuliLight">
                      Building you from root up means understanding your
                      physiology, which is the enzymes, impulses and molecules
                      that interact within you, further to analyse your movement
                      and understand what works for you at a level of function
                      and what doesn't. Further on measuring your fatigability,
                      endurance and energy. Then on exploring your mind and how
                      it copes or responses to stress, how mindful it is and
                      more. Based on this we provide you with exercise
                      prescription, diet and lifestyle modification. This helps
                      us build what it takes for you to be the best you've ever
                      been!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="HealthStatusStepThird my-md-4 py-lg-5 my-3">
            <div className="row">
              <div className="col-lg-6">
                <div className="HealthStatusImages">
                  <img
                    src="/images/33.jpg"
                    alt="ProPhysio1"
                    className="rounded img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="HealthStatusContant">
                  <div className="HealthStatusSubHeading">
                    <h6 className="fs-40  mb-4 fw-700">Step 3</h6>
                  </div>
                  <div className="HealthStatusHeading">
                    <h1 className="fs-72 mb-4 fw-600">
                      Activating the way of life
                    </h1>
                  </div>
                  <div className="HealthStatusPara">
                    <p className="fs-22 mb-4 MuliLight">
                      Now that you know what works for you, you've tried it and
                      it really works! So it's time to take on the challenge to
                      move to the next level! To ensure you remain hail and
                      hearty, calm and easy, active and agile all at the same
                      time and balanced. This prompts us to build the journey
                      further and make it a way of life. Track quality of life,
                      sleep, hydration and many more parameters and stay
                      connected on our annual health programs to ensure you stay
                      fit, active and lead a fulfilled life!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix work-bg-clr mt-5  doctorchangeing_sec doctorchangeing position-relative TakesYourFirst"
        id="TakesYourFirst"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row h-100">
              <div className="col-lg-5 order-2 position-static">
                <div className="BeterImgs w-100 clearfix align-bottom singaldoctor position-absolute">
                  <img
                    src="/images/34.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-7 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle pt-lg-0 pt-5">
                    <h2 className="fs-72 fw-700 mb-4">
                      Take your first step towards a healthier life
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      You health is what matters most, Reach out to us and
                      <br /> get the best experts to care for you on your road
                      to better health.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pb-md-4">
                      <Link
                        data-toggle="modal"
                        data-target="#claimFreeModal"
                        className="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Claim your first free consultation
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
      {/* <!---------------|| Section-6||---------------> */}
      <section
        className="w-100 clearfix Learnhowfaqconditions py-sm-5 pt-5 bg-lightpink"
        id="FAQSConditions"
      >
        <div className="container py-lg-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700 pb-4">
              Conditions & symptoms we treat
            </h2>
          </div>
        </div>
        <div className="container_two">
          <div className="BrowseSymptoms">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Physical Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Manual Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physical Education
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Mental Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Neuro Linguistic Programming
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Cognitive behavioral Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Mindfullness
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Urban Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Metabolite Testing
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Microbiome Testing
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Vo2 Max Testing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Special Care</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Geriatric Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Occupational Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Mental Health
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Child Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Early Learning
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physical Health
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6||---------------> */}
      {/* <!---------------|| Section eight ||---------------> */}
      <section
        className="w-100 clearfix callus servicecallus py-5 bg-brown"
        id="Servivecallussection"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="findheading text-center py-3">
                <h1 className="fs-72 fw-700 text-white">
                  Cant find what you are
                  <br /> looking for?
                </h1>
              </div>
              <div className="findparagrph text-center py-1">
                <p className="fs-22 MuliLight text-white">
                  We are more than happy to help. Just give us a call or give us
                  your
                  <br />
                  phone number so that we can call you.
                </p>
              </div>
              <div className="findcallnow text-center py-sm-3">
                <a href="tel:+91 8197378444" className="text-decoration-none">
                  <img
                    src="/images/icons/icon18.png"
                    style={{ verticalAlign: "text-bottom" }}
                    className="pr-3"
                  />
                  <span className="fs-54 MuliLight text-white">
                    Call us now at <span className="clr-1">+91 8197378444</span>
                  </span>
                </a>
              </div>
              <div className="findhelp text-center py-4">
                <p className="fs-32 MuliLight text-white lh-38">
                  Need help? Get a call back from our <br />
                  support team
                </p>
              </div>
              <div className="more_show text-center pt-2 pb-4">
                <Link
                  className="show_btn btn bg-blue fs-22 px-4 py-2 br-24 text-white"
                  data-toggle="modal"
                  data-target="#PartneringWithUS"
                >
                  Get a call back{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section eight ||---------------> */}
      {/* <!---------------|| Section-8||---------------> */}
      <section
        className="w-100 clearfix CustomerReviews pt-sm-0 pt-5"
        id="CustomerReviews"
      >
        <div className="container">
          <div className="HeadingDiv w-100 clearfix my-md-5 mb-5">
            <h2 className="fs-72 lh-80 fw-700">Customer stories</h2>
            <p className="fs-22 MuliLight pt-2" style={{ maxWidth: "100%" }}>
              ProPhysio has transform peoples lifestyle to help lead lives on
              their own terms.
            </p>
          </div>
        </div>

        {/* <!---------------|| Section-4 ||---------------> */}
        <div
          className="w-100 clearfix bg-lightorange doctorchangeing CustomerStories CustomerStories_sec"
          id="CustomerStories"
        >
          <div className="container">
            <div className="BodyByData w-100 clearfix">
              <div className="row">
                <div className="col-lg-7 order-lg-1 order-2">
                  <div className="BeterImgs w-100 clearfix align-bottom singaldoctor pt-5">
                    <img
                      src="/images/img59.png"
                      className="img-fluid mx-auto"
                      alt="No Images"
                    />
                  </div>
                </div>
                <div className="col-lg-5 order-lg-2 order-1">
                  <div className="sugerdoc w-100 clearfix d-table h-100">
                    <div className="d-table-cell align-middle TextMiddle pt-lg-0 pt-5">
                      <div className="hlelptext mb-5 position-relative">
                        <h1 className="fw-700 fs-28">Vishal Kumar</h1>
                      </div>
                      <div className="lelpage">
                        <p className="fs-20 MuliLight">Age 32 years</p>
                      </div>
                      <div className="lelpage">
                        <p className="fs-22 MuliLight">
                          A year ago I was in no position to stand, walking was
                          just impossible. Lorem ipsum dolor sit amet,
                          consetetur sadipscing elitr, sed diam nonumy eirmod
                          tempor invidunt ut labore et dolore magna
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <!---------------|| Section-4 ||---------------> */}
      </section>
      {/* <!---------------|| Section-8||---------------> */}
      {/* <!---------------|| Section-9||---------------> */}

      <section
        className="w-100 clearfix GotQuestions py-5 mt-sm-5 faq_section_slider"
        id="QuestionSection"
      >
        <div className="container-fluid">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">
              Have more questions on how Prophysio works?
            </h2>
            <p className="fs-22 MuliLight pt-3">
              No worries, we’ve got you covered
            </p>
          </div>
        </div>
        <div className="container-fluid">
          <div className="GotQueDetails w-100 clearfix">
            {faqList?.length && (
              <div
                className="GotQueSlider w-100 clearfix pt-5"
                id="AllOwlSliders_part"
              >
                <div
                  className="owl-carousel owl-theme OwlSliderOne AllOwlSliders"
                  id="GotQuestionOwl"
                >
                  {faqList.map((v, i) => {
                    return (
                      <div
                        className="physiotherapist py-4 px-5 mx-1 bg-clr-3"
                        key={i}
                      >
                        <div className="phyheading position-relative pb-2">
                          <p
                            className="fs-34 MuliItalic"
                            onClick={() => clickHandle(i, v)}
                          >
                            {v.question}
                          </p>
                        </div>
                        <div className="peoplebtn pt-3">
                          <Link className="clr-2 fw-500">
                            {v.people_asked || 0} people asked
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="backbtn py-md-4 text-center pt-2">
              <Link
                to="faq"
                className="text-decoration-none d-inline-block HoverImgChnge"
              >
                <span className="fs-24 clr-1 fw-600">Explore all FAQ’s</span>
                <img src="/images/icons/icon5.png" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {showForm ? (
        <div
          id="myModal"
          className={`modal fade ${showForm ? "show" : ""}`}
          role={showForm ? "dialog" : ""}
          style={showForm ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog">
            {/* {faqList.map((v, i) => { */}
            {/* return  */}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-24 fw-500">
                  {slideClickData.question}
                </h4>
              </div>
              <div className="modal-body fs-14 ">
                <p>{slideClickData.answer}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={closeHandleForm}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
            {/* })} */}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <!---------------|| Section-9||---------------> */}
      <ReadyToTransfer />

      {/* <!---------------|| Connect us Section ||---------------> */}
      {/* <section className="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting" id="ConnectReady">
    <div className="container-fluid py-md-5">
      <div className="HeadingDiv w-100 clearfix text-center">
        <h2 className="fs-72 lh-80 fw-700">Ready to transform your health?</h2>
        <p className="fs-22 MuliLight pt-2 mb-4">Getting ProPhysio services are as easy as a click away</p>
      </div>
      <div className="client pb-xl-5 ReadyToConnect">
        <div className="row">
          <div className="col-lg-4 col-sm-7 m-auto p-3">
            <div className="prectical">
              <div className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                style={{"background-image": "url(images/16.png)"}}>
                <div className="trulyCare1 d_table_cell_none align-middle w-75">
                  <p className="fs-57 MuliBold lh-62 mb-0">Virtual <br/>Session</p>
                  <div className="pt-5">
                    <Link
                      className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book
                      now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-7 m-auto p-3">
            <div className="prectical">
              <div className="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                style={{"background-image": "url(images/17.png)"}}>
                <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                  <p className="fs-57 MuliBold lh-62 mb-0">Home <br/>Visit</p>
                  <div className="pt-5">
                    <Link
                      className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-7 m-auto p-3">
            <div className="prectical">
              <div className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                style={{"background-image": "url(images/18.png)"}}>
                <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                  <p className="fs-57 MuliBold lh-62 mb-0">Visit <br/>Clinic</p>
                  <div className="pt-5">
                    <Link
                      className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1">Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="NotSureTo w-100 clearfix text-center pt-4">
        <h3 className="fs-45 MuliExtra pl-5">Not sure where to begin?</h3>
        <h4 className="fs-54 MuliLight pt-5"><img src="/images/icons/icon14.png" className="img-fluid" alt="No Images"/><span
            className="d-inline-block pl-5">Call us now at <Link href="tel:+91 8197378444" className="clr-1">+91
              8197378444</Link></span>
        </h4>
      </div>
    </div>
  </section> */}
      {/* <!---------------|| Connect Us Section ||---------------> */}
      <BackToTop />
    </>
  );
}

export default prophysioWork;
