import Form from "../../pages/BookNowForm.js/Form";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { useContext } from "react";



function Header() {
    const { bookNowModal, contactForm } = useContext(BookNowContext)
    const [firstFormOpen, setFirstFormOpen] = bookNowModal
    const [contactModal, setContactModal] = contactForm



    // window.$(document).on("click","#test-element",function() {
    //     //alert("click");
    //     if (window.$('#user_name').val() == '') {
    //         window.$('.err_user_name').html('This is a required field!');

    //     } else {
    //         var name = window.$('#user_name').val();
    //         localStorage.setItem("name", name);
    //         window.$('.err_user_name').html('');

    //     }
    //     if (window.$('#user_email').val() == '') {
    //         window.$('.err_user_email').html('This is a required field!');
    //     } else {
    //         var email = window.$('#user_email').val();
    //         localStorage.setItem("email", email);
    //         window.$('.err_user_email').html('');

    //     }

    //     if (window.$('#user_phone').val() == '') {
    //         window.$('.err_user_phone').html('This is a required field!');
    //     } else {
    //         var phone = window.$('#user_phone').val();
    //         localStorage.setItem("phone", phone);
    //         window.$('.err_user_phone').html('');

    //     }
    //     if (window.$('#user_location').val() == '') {
    //         window.$('.err_user_location').html('This is a required field!');

    //     } else {
    //         var location = window.$('#user_location').val();
    //         localStorage.setItem("location", location);
    //         window.$('.err_user_location').html('');


    //     }

    //     if (window.$('#user_location').val() != '' && window.$('#user_phone').val() != '' && window.$('#user_name').val() != '' && window.$('#user_email').val() != '') {
    //         window.$('.contact-btn').addClass('cont_btn');
    //         window.$('#popup_contact').addClass('none');
    //         window.$('#popup_abt ').removeClass('none');
    //         window.$('#user_nickname').val(localStorage.getItem("name"));
    //         localStorage.setItem("session_location", "Online");

    //     }    

    // });

    const formOpen = () => {
        setFirstFormOpen(!firstFormOpen)
    }



    return (
        <div>


            <header className="w-100 clearfix ProMainHeader py-3" id="ProMainHeaderSection">
                <div className="container-fluid">
                    <div className="TopHeaderLogo w-100 clearfix position-relative">
                        <div className="ProLogo float-left">
                            {/* <Link className="navbar-brand" to="index.html"><img src="/images/logo.png" className="img-fluid" alt="No Image"/></Link> */}
                            <Link to='/' className="navbar-brand">
                                <img alt="No Image" src="/images/logo.png" className="img-fluid" />
                            </Link>
                        </div>
                        <div className="ProMenus float-left text-right">
                            <ul className="list-unstyled HospitalLists fs-16 mb-2">
                                <li className="d-inline-block px-3">
                                    {/* <Link to="hospital" className="clr-1 d-block fw-500 fs-16">Are you a Hospital?</Link> */}
                                    <Link to='hospital' className="clr-1 d-block fw-500 fs-16">Are you a Hospital?</Link>

                                </li>
                                <li className="d-inline-block px-3">
                                    {/* <Link to="professional.html" className="clr-1 d-block fw-500 fs-16">Are you a Doctor?</Link> */}
                                    <Link to='professional' className="clr-1 d-block fw-500 fs-16">Are you a Doctor?</Link>
                                </li>
                                <li className="d-inline-block px-3">
                                    <a href="tel:+91 8197378444" className="clr-1 d-block fw-500 fs-16">Call us: +91 8197378444</a>
                                </li>
                            </ul>
                            <nav className="navbar navbar-expand-lg p-0 HOmeNavBar">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                    <i className="fa fa-bars" aria-hidden="true"></i>
                                    <span className="cross_btn"><img src="/images/icons/close_1.png" /></span>
                                </button>
                                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                    <ul className="navbar-nav w-100 d-block collapse_menu">
                                        <li className="nav-item d-inline-block d-lg-none active">
                                            {/* <Link className="nav-link fs-20 fw-500 clr-2 px-3" to="index.html">Home</Link> */}
                                            <Link to='/' className="nav-link fs-20 fw-500 clr-2 px-3">Home
                                            </Link>

                                        </li>
                                        <li className="nav-item d-inline-block">
                                            <Link to='/service' className="nav-link fs-20 fw-500 clr-2 px-3">Services</Link>
                                            {/* <Link className="nav-link fs-20 fw-500 clr-2 px-3" to="services.html">Services</Link> */}
                                        </li>
                                        <li className="nav-item d-inline-block">
                                            <Link to='/knowledgehub' className="nav-link fs-20 fw-500 clr-2 px-3">Knowledge Hub</Link>
                                            {/* <Link className="nav-link fs-20 fw-500 clr-2 px-3" to="knowledgehub">Knowledge Hub</Link> */}
                                        </li>
                                        <li className="nav-item d-inline-block">
                                            <Link className="nav-link fs-20 fw-500 clr-2 px-3" >ProStore</Link>
                                        </li>
                                        <li className="nav-item d-inline-block">
                                            <Link to='/aboutus' className="nav-link fs-20 fw-500 clr-2 px-3">About Us</Link>
                                            {/* <Link className="nav-link fs-20 fw-500 clr-2 px-3" to="aboutus">About Us</Link> */}
                                        </li>
                                        <li className="nav-item d-inline-block">
                                            <Link to='/contactus' className="nav-link fs-20 fw-500 clr-2 px-3">Contact Us</Link>
                                            {/* <Link className="nav-link fs-20 fw-500 clr-2 px-3" to="contactus">Contact Us</Link> */}
                                        </li>
                                        <li className="nav-item d-block moblie_menu d-lg-none">
                                            {/* <Link to="hospitals.html" className="clr-1 d-block fw-500 fs-20 px-3"><span>Are you a Hospital?
                                                        </span><span><img src="/images/icons/hospital.png" className="img-fluid"/></span></Link> */
                                            }
                                            <Link to='hospital' className="clr-1 d-block fw-500 fs-20 px-3"><span>Are you a Hospital?
                                            </span><span><img src="/images/icons/hospital.png" className="img-fluid" /></span>

                                            </Link>
                                        </li>
                                        <li className="nav-item d-block moblie_menu d-lg-none">
                                            {/* <Link to="professional.html" className="clr-1 d-block fw-500 fs-20 px-3"><span>Are you a
                                                        Doctor?</span><span><img src="/images/icons/doctor.png" className="img-fluid"/></span></Link> */}
                                            <Link to='professional' className="clr-1 d-block fw-500 fs-20 px-3"><span>Are you a
                                                Doctor?</span><span><img src="/images/icons/doctor.png" className="img-fluid" /></span>

                                            </Link>

                                        </li>
                                        <li className="nav-item d-block moblie_menu d-lg-none">
                                            <Link to="tel:+91 8197378444" className="clr-1 d-block fw-500 fs-20 px-3"><span>Call us:
                                                +91 8197378444 </span><span><img src="/images/icons/call.png" className="img-fluid" /></span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="ProBtns float-left text-right pt-2">

                            <Link className="BtnImg1"><img src="/images/btns/btn1.png" className="img-fluid" onClick={formOpen} /></Link>


                        </div>

                    </div>
                </div>
            </header>

        </div>

    );
}
export default Header;