import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PopUpService from "../../services/PopUpService/PopUpService";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function GetAssesNowPopUp() {
  const getAssessNowServe = new PopUpService();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [value, setValue] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const onSubmit = async (values, { resetForm }) => {
    let obj = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      type: "getassessment",
      role: 1,
    };
    try {
      let response = await getAssessNowServe.getAssessNow(obj);
      if (typeof response.messages === "string") {
        toast.error(response.messages);
        resetForm();
      } else {
        setIsSubmitted(true);
        resetForm();
      }
    } catch (err) {
      throw err;
    }
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").required("Required"),
    phone: Yup.string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number should be 10 digit")
      .max(10, "Phone number should be 10 digit")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  const crossHandleBtn = (resetForm) => {
    resetForm();
    setValue({
      name: "",
      phone: "",
      email: "",
    });
    resetForm();
  };

  return (
    <div className="proPhysioModal w-100">
      <div
        className="modal modalProPhysio"
        id="GetAssessedModal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="d-table-cell align-middle">
            {!isSubmitted ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="modal-content">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={() => crossHandleBtn(formik.resetForm)}
                  >
                    <img
                      src="/images/modal_images/cross.svg"
                      className="img-fluid"
                    />
                  </button>
                  <div className="modal-body modalBody modalBG3 formModal">
                    <div className="modalBodyData">
                      <div className="row">
                        <div className="col-md-8">
                          <h2>Get assessed now</h2>
                          <div className="formInside mt-5 pr-lg-5">
                            <div className="form-group">
                              <label htmlFor="input1">Name</label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter full name"
                                id="input1"
                                onBlur={formik.handleBlur}
                                value={formik?.values?.name}
                                onChange={formik.handleChange}
                                autoComplete="off"
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="formik-errors bg-error">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label htmlFor="input2">Phone Number</label>
                              <input
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="Enter contact number"
                                id="input2"
                                onBlur={formik.handleBlur}
                                value={formik?.values?.phone}
                                onChange={formik.handleChange}
                                autoComplete="off"
                              />
                              {formik.touched.phone && formik.errors.phone ? (
                                <div className="formik-errors bg-error">
                                  {formik.errors.phone}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group">
                              <label htmlFor="input3">Email ID</label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email id"
                                id="input3"
                                onBlur={formik.handleBlur}
                                value={formik?.values?.email}
                                onChange={formik.handleChange}
                                autoComplete="off"
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="formik-errors bg-error">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group submitBtn mb-0 pt-3">
                              <button type="submit" className="btn FormSuccess">
                                <img
                                  src="/images/modal_images/btn3.svg"
                                  className="img-fluid"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={() => crossHandleBtn(formik.resetForm)}
                >
                  <img
                    src="/images/modal_images/cross.svg"
                    className="img-fluid"
                  />
                </button>
                <div
                  className="modal-body modalBody thankuModalPage"
                  style={!isSubmitted ? { display: "none" } : {}}
                >
                  <div className="modalBodyData text-center">
                    <img
                      src="/images/modal_images/success.gif"
                      className="img-fluid"
                    />
                    <div className="contentOne py-4">
                      <h2>
                        Booking
                        <br />
                        Successfully
                      </h2>
                      <h6>Thank you for booking our team will reach out</h6>
                    </div>
                    <div className="submitBtn">
                      <Link to="/detailedElderlyService" className="btn">
                        <img
                          src="/images/modal_images/btn2.svg"
                          className="img-fluid"
                          data-dismiss="modal"
                          onClick={() => setIsSubmitted(false)}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
