import React, { useState } from "react";
import { Link } from "react-router-dom";

function understandHub() {
  const [open, setOpen] = useState(false);
  const [treatment, setTreatment] = useState(false);
  const [heal, setHeal] = useState(false);
  const [assessment, setAssessment] = useState(false);

  const painClickHandle = () => {
    setOpen(!open);
    setTreatment(false);
    setAssessment(false);
    setHeal(false);
  };
  const treatmentHandle = () => {
    setTreatment(!treatment);
    setOpen(false);
    setAssessment(false);
    setHeal(false);
  };
  const healHandle = () => {
    setHeal(!heal);
    setTreatment(false);
    setOpen(false);
    setAssessment(false);
  };

  const assessmentHandle = () => {
    setAssessment(!assessment);
    setTreatment(false);
    setOpen(false);
    setHeal(false);
  };

  return (
    <>
      {/* <!---------------|| Understand Hub ||---------------> */}
      <section className="w-100 clearfix my-md-5 pt-5 pb-5" id="Bodysection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bodypart text-center">
                <div className="firstheading ">
                  <h1 className="fs-72 fw-700">Understand your body</h1>
                </div>
                <div className="phpoyherpara pt-2">
                  <p className="fs-22 ">
                    Explore the most common symptoms and what can cause them
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bodyknee my-sm-5">
            <div className="row">
              <div className="col-lg-5">
                <div className="Understand text-lg-left text-center">
                  <div className="bodyimg">
                    <img src="/images/img77.png" />
                  </div>
                  <div className="bodytext pt-3  text-center w-75">
                    <p className="fs-18 MuliLight">Drag to view 360 Degree</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="kneecap py-lg-5 mt-4">
                  <div className="kneeclider position-relative mb-4">
                    <div className="kneeheading mb-4">
                      <h1 className="fs-42 fw-700">Knee Cap</h1>
                    </div>
                    <hr />
                    <div className=" osteoarthritis pt-3">
                      <div
                        id="body"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        <a
                          className="carousel-control-prev bodylefticon"
                          href="#body"
                          data-slide="prev"
                        >
                          <span className="carousel-control-prev-icon">
                            <i
                              className="fa fa-angle-left clr-1"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </a>
                        <a
                          className="carousel-control-next bodyrighticon"
                          href="#body"
                          data-slide="next"
                        >
                          <span className="carousel-control-next-icon">
                            <i
                              className="fa fa-angle-right clr-1"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </a>
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge1 of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item ">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge2 of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div id="accordion">
                    <div className="card border-0">
                      <div className="card-header border-0 bg-white py-1 px-0">
                        <Link
                          className={`card-link ${!open ? "collapsed" : ""}`}
                          data-toggle="collapse"
                          aria-expanded={open ? "true" : "false"}
                        >
                          <h1
                            className="fs-34 fw-700 clr-2 position-relative  m-0"
                            onClick={painClickHandle}
                          >
                            Pain
                            <span className="position-absolute downicon">
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h1>
                        </Link>
                      </div>

                      {open ? (
                        <div
                          id="pain"
                          className={`"collapse "${!open ? "show" : ""}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="my-2" />
                    <div className="card border-0">
                      <div className="card-header border-0 bg-white py-1 px-0">
                        <Link
                          className={`card-link ${
                            !treatment ? "collapsed" : ""
                          }`}
                          data-toggle="collapse"
                          aria-expanded={treatment ? "true" : "false"}
                        >
                          <h1
                            className="fs-34 fw-700 clr-2 position-relative  m-0"
                            onClick={treatmentHandle}
                          >
                            Treatments
                            <span className="position-absolute downicon">
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h1>
                        </Link>
                      </div>
                      {treatment ? (
                        <div
                          id="treatments"
                          className={`"collapse "${!treatment ? "show" : ""}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="my-2" />
                    <div className="card border-0">
                      <div className="card-header border-0 bg-white py-1 px-0">
                        <Link
                          className={`card-link ${!heal ? "collapsed" : ""}`}
                          data-toggle="collapse"
                          aria-expanded={heal ? "true" : "false"}
                        >
                          <h1
                            className="fs-34 fw-700 clr-2 position-relative  m-0"
                            onClick={healHandle}
                          >
                            Healing Time
                            <span className="position-absolute downicon">
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h1>
                        </Link>
                      </div>
                      {heal ? (
                        <div
                          id="healingtime"
                          className={`"collapse "${!heal ? "show" : ""}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="my-2" />
                    <div className="card border-0">
                      <div className="card-header border-0 bg-white py-1 px-0">
                        <Link
                          className={`card-link ${
                            !assessment ? "collapsed" : ""
                          }`}
                          data-toggle="collapse"
                          aria-expanded={assessment ? "true" : "false"}
                        >
                          <h1
                            className="fs-34 fw-700 clr-2 position-relative  m-0"
                            onClick={assessmentHandle}
                          >
                            Assessment
                            <span className="position-absolute downicon">
                              <i
                                className="fa fa-angle-down"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </h1>
                        </Link>
                      </div>
                      {assessment ? (
                        <div
                          id="assessment"
                          className={`"collapse "${!assessment ? "show" : ""}`}
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-3">
                                <div className="kneeimg">
                                  <img src="/images/40.png" className="w-100" />
                                </div>
                              </div>
                              <div className="col-9 ">
                                <div className="kneetext">
                                  <p className="fs-22 clr-2 MuliLight">
                                    Knowledge of the body systems and how they
                                    interact with one another is important
                                    because it builds and promotes health and
                                    well-being. Each body system has roles and
                                    functions that affect all other body
                                    systems.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <hr className="my-2" />
                  </div>
                  <div className="getnow mt-md-4 pt-md-2">
                    <div className="more_show text-md-left text-center pt-5">
                      <Link
                        to="knowledgehub"
                        data-toggle="modal"
                        data-target="#GetAssessedModal"
                        className="show_btn btn bg-blue text-white fs-22 bluebutton br-24"
                      >
                        Get assessed now!
                      </Link>
                      {/* <Link  className="show_btn btn bg-blue text-white fs-22 bluebutton br-24">Get assessed now!
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
    </>
  );
}

export default understandHub;
