import util from "../utils/Util";
export default class BookNowService{
    doctorlist(data, start, length) {
        const activity = Object.keys(data).reduce((object, key) => {
          if (data[key] !== "") {
            object[key] = data[key];
          }
          return object;
        }, {});
    
        return util
          .sendApiRequest(
            "/slot/filter/" + start + "/" + length,
            "POST",
            true,
            activity
          )
          .then(
            (response) => {
              return response;
            },
            (error) => {
              throw error;
            }
          );
      }

      userData(payload){
        return util
        .sendApiRequest("/user", "POST", true, payload)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
      }

      sessionData(payload){
        return util
        .sendApiRequest("/session", "POST", true, payload)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
      }

      sendBookEmail(payload){
        return util
        .sendApiRequest("/user/sendBookEmail", "POST", true, payload)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
      }
}