import Util from "../../utils/Util";

export default class PopUpService {
  claimFree(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  joinProPhysio(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  getAssessNow(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  hospitalClinic(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  individualGuidance(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  healthProfessional(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  interestedPartnering(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  joinTheCommunity(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  accessMoreClient(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  UpgradeMultidisciplinary(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  progressiveHealth(payload) {
    return Util.sendApiRequest("/enquiry", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
