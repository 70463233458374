import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ToastContainer, toast } from "react-toastify";
import BookNowService from "../../services/BookNowService";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";

function bookNowPopUp() {
  let mailLoading = false;
  const listInnerRef = useRef();
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [date, setDate] = useState(new Date());
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  const [aboutModal, setAboutModal] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [sessionForm, setSessionForm] = useState(false);
  const [bookingDone, setBookingDone] = useState(false);
  const [finalForm, setFinalForm] = useState(false);
  const [secondForm, setSecondForm] = useState({});
  const [timeSection, setTimeSection] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [userDetail, setuserDetail] = useState({});
  const [active, setActive] = useState(null);
  const [active1, setActive1] = useState(null);
  const [activeTime, setActiveTime] = useState(null);
  const [slotData, setSlotData] = useState({});
  const [basicForm, setBasicForm] = useState({});
  const [backgroundColor, setBackgroundColor] = useState("#F7F0EA");
  const [color, setColor] = useState("");
  const [useDetailId, setUserDetailId] = useState("");
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const liValue = ["Online", "At Center", "Homecare"];
  const [locationName, setLocationName] = useState("");
  const [doctorLocation, setDoctorLocation] = useState("");
  const [doctorCount, setDoctorCount] = useState(null);
  const [locationModal, setLocationModal] = useState(false);
  const [submitCall, setSubmitCall] = useState(false);
  // const [location, setLocation] = useState([
  //   { city: "Bangalore" },
  //   { city: "Hyderabad" },
  //   { city: "Pune" },
  // ]);
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
  });
  const [form2values, setForm2Values] = useState({
    gender: "",
    age: "",
    name: "",
    health_condition: "",
    session_location: "",
  });
  const [search, setSearch] = useState({
    start: 0,
    perPage: 10000,
    searchTxt: "",
    searchField: "",
  });

  const bookNowServe = new BookNowService();

  const abtFormValidateSchema = Yup.object().shape({
    gender: Yup.string().required("Required"),
    age: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    health_condition: Yup.string().required("Required"),
    session_location: Yup.string().required("Required"),
  });

  const onSubmit1 = (values) => {
    // console.log("values", values)
    setSecondForm(values);
    setAboutModal(false);
    setSessionForm(true);
    setForm2Values({
      gender: "",
      age: "",
      name: "",
      health_condition: "",
      session_location: "",
    });
  };
  const handleScroll = (e) => {
    e.preventDefault();
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 9) {
        doctorListApi();
      }
    }
  };

  useEffect(() => {
    if (doctorList.length < 7 && submitCall) {
      doctorListApi();
      setSubmitCall(false);
    }
  }, [doctorList, submitCall]);

  useEffect(() => {
    doctorListApi();
  }, [date]);

  const locationClick = (e) => {
    setLocationName(e.target.value);
    setLocationModal(false);
  };

  useEffect(() => {
    if (firstFormOpen) {
      setContactModal(true);
      // for testing
      setSessionForm(false);
    }
  }, [firstFormOpen]);

  useEffect(() => {
    setForm2Values({ ...form2values, name: basicForm.name });
  }, [basicForm.name]);

  const doctorListApi = async (doctorLocation) => {
    let counterDate = moment(date);
    const activity = {
      day: {
        from_date: counterDate.format("YYYY-MM-DD 00:00:00"),
        to_date: counterDate.format("YYYY-MM-DD 23:59:59"),
      },
      location: doctorLocation,
    };
    if (mailLoading === false) {
      try {
        mailLoading = true;
        let response = await bookNowServe.doctorlist(
          activity,
          search.start,
          search.perPage
        );
        if (response) {
          let tempList = response.data.rows;
          let filteredArr = tempList.map((d) => {
            d.slots = d.slots.sort((a, b) =>
              a.start_time > b.start_time
                ? 1
                : b.start_time > a.start_time
                ? -1
                : 0
            );
            const timeNow = new Date().getHours() * 100;
            const dateNow = new Date().getDate();
            if (dateNow == date.getDate()) {
              d.slots = d.slots.filter((s) =>
                s.start_time > timeNow ? true : false
              );
            }
            return d;
          });
          setDoctorList(filteredArr);
          setDoctorCount(response.data.count);
          mailLoading = false;
        }
      } catch (err) {
        throw err;
      }
    }
  };

  const dateChangeHandler = (date) => {
    setDate(date);
  };

  const formCloseHandle = (resetForm) => {
    setFirstFormOpen(false);
    resetForm();
    setValue({
      name: "",
      email: "",
      phone: "",
      location: "",
    });
  };

  const onSubmit = async (values, { resetForm }) => {
    console.log("location", values.location);
    setDoctorLocation(values.location);

    console.log(location);
    setContactModal(false);
    setBasicForm(values);
    setAboutModal(true);
    // setFirstFormOpen(false)
    setValue({
      name: "",
      email: "",
      phone: "",
      location: "",
    });
    // setDoctorList([])
    await doctorListApi(values.location);
    setSubmitCall(true);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),

    phone: Yup.string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number should be 10 digit")
      .max(10, "Phone number should be 10 digit")
      .required("Required"),
    location: Yup.string().required("Required"),
  });

  const timeActiveHandler = (index, value) => {
    console.log("value in book now", value?.start_time);
    setSlotData(value);

    const start_time = slotTime(value.start_time);
    const end_time = slotTime(value.end_time);
    console.log("time", start_time, end_time);
    setSelectedTime({ start_time, end_time });
    console.log("selectedTime");
    setActiveTime(index);
  };

  function slotTime(time) {
    if (time == 800) {
      time = "8:00 am";
    }
    if (time == 900) {
      time = "9:00 am";
    }
    if (time == 1000) {
      time = "10:00 am";
    }
    if (time == 1100) {
      time = "11:00 am";
    }
    if (time == 1200) {
      time = "12:00 pm";
    }
    if (time == 1300) {
      time = "1:00 pm";
    }
    if (time == 1400) {
      time = "2:00 pm";
    }
    if (time == 1500) {
      time = "3:00 pm";
    }
    if (time == 1600) {
      time = "4:00 pm";
    }
    if (time == 1700) {
      time = "5:00 pm";
    }
    if (time == 1800) {
      time = "6:00 pm";
    }
    if (time == 1900) {
      time = "7:00 pm";
    }
    if (time == 2000) {
      time = "8:00 pm";
    } else {
      return time;
    }
  }

  let selectedDate;

  const submitHandler = async (event) => {
    if (selectedTime?.end_time && selectedTime?.start_time) {
      event.preventDefault();
      setBookingDone(!bookingDone);
      setSessionForm(false);
    } else {
      alert("Please select time to schedule appointment");
    }
  };

  const dataSubmitHandler = async () => {
    let userDetailId;

    setBookingDone(false);
    setFinalForm(!finalForm);
    let obj = {
      age: secondForm.age,
      email: basicForm.email,
      gender: secondForm.gender,
      location: basicForm.location,
      name: basicForm.name,
      role: 1,
    };
    try {
      let response = await bookNowServe.userData(obj);
      if (response) {
        console.log("user data", response.result.id);
        userDetailId = response.result.id;
        setuserDetail(response.result);
      }
    } catch (err) {
      throw err;
    }

    selectedDate = date.toDateString();
    let formObj = { ...selectedDate };
    formObj["start_time"] =
      moment(selectedDate).format("YYYY-MM-DD") + " " + selectedTime.start_time;

    formObj["end_time"] =
      moment(selectedDate).format("YYYY-MM-DD") + " " + selectedTime.end_time;

    console.log("secondForm.doctor_id", userDetail);
    let sessionObj = {
      doctor_id: slotData.doctor_id,
      end_time: formObj.end_time,

      start_time: formObj.start_time,
      user_id: userDetailId,
      session_location: secondForm.session_location,
      prefer_location: basicForm.location,
      health_condition: secondForm.health_condition,
    };
    try {
      let sessionResponse = await bookNowServe.sessionData(sessionObj);
      // if (sessionResponse.message === "This slot is not available. Please try for another slot.") {
      //   toast.error(sessionResponse.message);
      // }
      if (sessionResponse.message === "Booked successfully") {
        toast.success(sessionResponse.message);
      }
    } catch (err) {
      throw err;
    }

    let sendEmail = {
      age: secondForm.age,
      doctor_name: selectedDoctor.name,
      doctor_rate: selectedDoctor.rate,
      email: basicForm.email,
      end_time: selectedTime.end_time,
      gender: secondForm.gender,
      health: secondForm.health_condition,
      location: basicForm.location,
      name: basicForm.name,
      phone: basicForm.phone,
      selected_date: date.toDateString(),
      session_location: secondForm.session_location,
      start_time: selectedTime.start_time,
    };

    try {
      let emailResponse = bookNowServe.sendBookEmail(sendEmail);
      if (emailResponse) {
        alert("Email sent successfully!");
      }
    } catch (err) {
      throw err;
    }
  };

  // const getsessionlocation = (id, value) => {
  //   console.log("li value", id, value)
  // }
  // const changeColorHandle=(id)=>{
  //   setBackgroundColor("#707070")
  // }

  const backBtnHandler = () => {
    setForm2Values({
      gender: secondForm.gender,
      age: secondForm.age,
      name: secondForm.name,
      health_condition: secondForm.health_condition,
      session_location: secondForm.session_location,
    });
    console.log("reintiazling data", {
      gender: secondForm.gender,
      age: secondForm.age,
      name: secondForm.name,
      health_condition: secondForm.health_condition,
      session_location: secondForm.session_location,
    });
    // setSecondForm(values)
    setuserDetail({});
    setDoctorList([]);
    setAboutModal(true);
    setSessionForm(false);
    setSubmitCall(false);
  };

  const aboutFormCloseHandler = (resetForm) => {
    setFirstFormOpen(false);
    setAboutModal(false);
    // console.log("resetForm", resetForm);
    resetForm();
    setForm2Values({
      gender: "",
      age: "",
      name: "",
      health_condition: "",
      session_location: "",
    });
  };

  const bookingDoneCloseBtnHandle = () => {
    setFirstFormOpen(false);
    setBookingDone(false);
    setSessionForm(false);
  };
  const finalFormCloseBtnHandle = () => {
    console.log("................");
    setFirstFormOpen(false);
    setFinalForm(false);
    setBookingDone(false);
    setSessionForm(false);
  };

  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() + 3));

  const sessionLocationHandle = (v, i) => {
    props.setFieldValue("session_location", v);
  };

  const aboutBackBtnHandler = () => {
    setContactModal(true);
    setAboutModal(false);
  };

  const bookingdoneBackBtnHandler = () => {
    setSessionForm(true);
    setBookingDone(false);
  };

  const getInitials = (string) => {
    var names = string.split(" "),
      initials =
        names[1]?.substring(0, 1).toUpperCase() ||
        names[0]?.substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const filteredDoctoctorList = doctorList.filter((doctor) => {
    return doctor.location?.toLowerCase() === doctorLocation;
  });

  const locationHandler = (e, props) => {
    if (e.target.value !== props.values.location) {
      setSearch({ ...search, start: 0 });
    }
    props.handleChange(e);
  };

  return firstFormOpen ? (
    <>
      <div>
        {/* -------Book now------ */}
        <div
          className={`"fade modal bookNowModal" ${
            firstFormOpen ? "active" : "none"
          }`}
        >
          <div className="modal-dialog modal-lg ">
            <div className="modal-content">
              <Formik
                initialValues={value}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={ValidateSchema}
              >
                {(props) => (
                  <form className="" onSubmit={props.handleSubmit}>
                    {/* {console.log('inside jsx', firstFormOpen)} */}
                    {contactModal ? (
                      <div
                        id="popup_contact"
                        className={!contactModal ? "none" : ""}
                      >
                        <div className="modal-header">
                          <div className="container-fluid">
                            <div className="TopHeaderLogo w-100 clearfix position-relative">
                              <div className="ProLogo float-left">
                                <Link className="navbar-brand" to="/">
                                  <img
                                    alt=""
                                    src="/images/logo.png"
                                    className="img-fluid"
                                  />
                                </Link>
                              </div>
                              <div className="float-right">
                                <div className="dil_txt">
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  ></i>
                                  1500 happy customers and counting
                                </div>
                                <div className="call_txt">
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Call us at 1800 345 678
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="address addresslist">
                            <div className="user_form row">
                              <div className="col-md-4 col-sm-12">
                                <div className="pop_txt_sec">
                                  <h1>Book your session</h1>
                                  <p>
                                    Welcome to ProPhysio tele therapy! With just
                                    few clicks and information about your
                                    health_condition condition we will be able
                                    to connect you with the right expert.
                                  </p>
                                  <div className="txt_icon_list_sec">
                                    <p>Work with best in class therapists</p>
                                    <ul>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img
                                              alt=""
                                              src="/images/icons/icon12.png"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">
                                              Licenced
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img
                                              alt=""
                                              src="/images/icons/icon11.png"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">
                                              Trained
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img
                                              alt=""
                                              src="/images/icons/icon13.png"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">
                                              Experienced
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="form_div col-md-6 col-sm-12">
                                <div className="row">
                                  <div className="form_sec">
                                    <ul>
                                      <li className="active">
                                        <div className="NutriImg ">
                                          <i
                                            className="fa fa-user-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i
                                            className="fa fa-file-text"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i
                                            className="fa fa-calendar-check-o"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i
                                            className="fa fa-check-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </li>
                                    </ul>
                                    <h1>Your Contacts</h1>
                                  </div>
                                  <div className="form_detail">
                                    <div className="form-group">
                                      <label className="form-label">Name</label>
                                      <input
                                        name="name"
                                        type="text"
                                        autoComplete="off"
                                        placeholder="Full Name"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.name}
                                        // id="user_name"
                                      />
                                      {props.touched.name &&
                                      props.errors.name ? (
                                        <div className="formik-errors bg-error-booknow-error">
                                          {props.errors.name}
                                        </div>
                                      ) : null}
                                      {/* <span className="err_user_name"></span> */}
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">
                                        Email
                                      </label>
                                      <input
                                        name="email"
                                        type="email"
                                        autoComplete="off"
                                        placeholder="Your active email address"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        id="user_email"
                                      />
                                      {props.touched.email &&
                                      props.errors.email ? (
                                        <div className="formik-errors bg-error-booknow-error">
                                          {props.errors.email}
                                        </div>
                                      ) : null}
                                      {/* <span className="err_user_email"></span> */}
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">
                                        Mobile Number
                                      </label>
                                      <input
                                        name="phone"
                                        type="text"
                                        autoComplete="off"
                                        placeholder=" 10 digit number"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.phone}
                                        // id="user_phone"
                                        // pattern="[0-9]{10}"
                                      />
                                      {props.touched.phone &&
                                      props.errors.phone ? (
                                        <div className="formik-errors bg-error-booknow-error">
                                          {props.errors.phone}
                                        </div>
                                      ) : null}
                                      {/* <span className="err_user_phone"></span> */}
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">
                                        Location
                                      </label>
                                      <input
                                        name="location"
                                        type="text"
                                        role="combobox"
                                        list=""
                                        id="input"
                                        autoComplete="off"
                                        aria-controls=""
                                        placeholder=" e.g. Koramangla 8th block"
                                        onChange={(e) =>
                                          locationHandler(e, props)
                                        }
                                        onBlur={props.handleBlur}
                                        value={props.values.location}
                                        // onClick={locationOpenHandler}
                                        // style={
                                        //   locationModal
                                        //     ? {
                                        //         borderRadius: "5px 5px 0px 0px",
                                        //       }
                                        //     : { borderRadius: "5px" }
                                        // }
                                        // id="user_location"
                                      />
                                      {/* <datalist
                                        id="location"
                                        name="location"
                                        className="timeDateList"
                                        style={
                                          locationModal
                                            ? { display: "block" }
                                            : { display: "none" }
                                        }
                                      >
                                        {location.map((v, i) => {
                                          return (
                                            <option
                                              value={v.city}
                                              onClick={(e) => {
                                                console.log("days in slots", e);

                                                locationClick(e);
                                                props.setFieldValue(
                                                  "location",
                                                  e.target.value
                                                );
                                              }}
                                              key={i}
                                            >
                                              {v.city}
                                            </option>
                                          );
                                        })}
                                      </datalist> */}
                                      {props.touched.location &&
                                      props.errors.location ? (
                                        <div className="formik-errors bg-error-booknow-error">
                                          {props.errors.location}
                                        </div>
                                      ) : null}
                                      {/* <span className="err_user_location"></span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row">
                            <div className="col-md-4 col-sm-12"></div>
                            <div className="pl_10_per col-md-6 col-sm-12">
                              <button
                                type="button"
                                className="close_btn btn btn-secondary"
                                onClick={() => formCloseHandle(props.resetForm)}
                              >
                                Close
                              </button>
                              <button
                                id="test-element"
                                type="submit"
                                className="btn btn-primary contact-btn"
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                )}
              </Formik>
              {/* <!-------------------> */}
              {aboutModal ? (
                <div className={!aboutModal ? "none" : ""} id="popup_abt">
                  <Formik
                    initialValues={form2values}
                    onSubmit={onSubmit1}
                    enableReinitialize={true}
                    validationSchema={abtFormValidateSchema}
                    setFieldValue
                  >
                    {(props) => (
                      <form className="" onSubmit={props.handleSubmit}>
                        <div className="modal-header">
                          <div className="container-fluid">
                            <div className="TopHeaderLogo w-100 clearfix position-relative">
                              <div className="ProLogo float-left">
                                <Link className="navbar-brand" to="/">
                                  <img
                                    alt=""
                                    src="/images/logo.png"
                                    className="img-fluid"
                                  />
                                </Link>
                              </div>
                              <div className="float-right">
                                <div className="dil_txt">
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  ></i>
                                  1500 happy customers and counting
                                </div>
                                <div className="call_txt">
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Call us at 1800 345 678
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="address addresslist">
                            <div className="container">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="pop_txt_sec">
                                    <h1>Book your session</h1>
                                    <p>
                                      Welcome to ProPhysio tele therapy! With
                                      just few clicks and information about your
                                      health_condition condition we will be able
                                      to connect you with the right
                                    </p>
                                  </div>
                                </div>
                                <div className="form_div col-md-6 col-sm-12">
                                  <div className="row">
                                    <div className="form_sec">
                                      <ul>
                                        <li>
                                          <div className="NutriImg ">
                                            <i
                                              className="fa fa-user-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </li>
                                        <li className="active">
                                          <div className="NutriImg ">
                                            <i
                                              className="fa fa-file-text"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i
                                              className="fa fa-calendar-check-o"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i
                                              className="fa fa-check-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </li>
                                      </ul>
                                      <h1>About you and your condition</h1>
                                    </div>
                                    <div className="form_detail">
                                      <div className="row">
                                        <div className="col-md-7 col-sm-12">
                                          <div className="form-group">
                                            <label className="form-label">
                                              Gender
                                            </label>
                                            <select
                                              name="gender"
                                              id="user_gender"
                                              style={{
                                                height: "36px",
                                                border: "1px solid #007bff",
                                                borderRadius: "6px",
                                                paddingLeft: "5px",
                                              }}
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              value={props.values.gender}
                                            >
                                              <option value="gender">
                                                gender
                                              </option>
                                              <option value="Male">Male</option>
                                              <option value="Female">
                                                Female
                                              </option>
                                            </select>
                                            {props.touched.gender &&
                                            props.errors.gender ? (
                                              <div className="formik-errors bg-error-booknow-error">
                                                {props.errors.gender}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12">
                                          <div className="form-group">
                                            <label className="form-label">
                                              Age
                                            </label>
                                            <input
                                              name="age"
                                              type="text"
                                              placeholder="Years"
                                              autoComplete="off"
                                              id="user_age"
                                              onChange={props.handleChange}
                                              onBlur={props.handleBlur}
                                              value={props.values.age}
                                            />
                                            {props.touched.age &&
                                            props.errors.age ? (
                                              <div className="formik-errors bg-error-booknow-error">
                                                {props.errors.age}
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">
                                          Name
                                        </label>
                                        <input
                                          name="name"
                                          type="text"
                                          autoComplete="off"
                                          placeholder="Full Name"
                                          id="user_nickname"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                          value={props.values.name}
                                        />
                                        {props.touched.name &&
                                        props.errors.name ? (
                                          <div className="formik-errors bg-error-booknow-error">
                                            {props.errors.name}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">
                                          Your Current health Condition
                                        </label>
                                        <input
                                          name="health_condition"
                                          autoComplete="off"
                                          type="text"
                                          placeholder="Mention symptoms, pain, or discomfort you have"
                                          id="user_health_condition"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}
                                          value={props.values.health_condition}
                                        />
                                        {props.touched.health_condition &&
                                        props.errors.health_condition ? (
                                          <div className="formik-errors bg-error-booknow-error">
                                            {props.errors.health_condition}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">
                                          Preffered Session location
                                        </label>
                                        <input
                                          type="hidden"
                                          id="lname"
                                          name="session_location"
                                        />
                                        <ul
                                          className="btn_list"
                                          id="user_session_location"
                                        >
                                          {liValue.map((v, i) => {
                                            return (
                                              <li>
                                                <button
                                                  type="button"
                                                  style={
                                                    active === i
                                                      ? {
                                                          backgroundColor:
                                                            "#1565c0",
                                                          color: "#fff",
                                                        }
                                                      : {}
                                                  }
                                                  className=" btn btn-primaryy getsessionlocation"
                                                  onClick={() => {
                                                    props.setFieldValue(
                                                      "session_location",
                                                      v
                                                    );
                                                    setActive(i);
                                                  }}
                                                >
                                                  {v}
                                                </button>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                        {props.touched.session_location &&
                                        props.errors.session_location ? (
                                          <div className="formik-errors bg-error-booknow-error">
                                            {props.errors.session_location}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <div className="row">
                            <div className="col-md-4 col-sm-12"></div>
                            <div className="pl_8_per col-lg-8 col-md-6 col-sm-12">
                              <button
                                type="button"
                                className="close_btn btn btn-secondary"
                                onClick={() =>
                                  aboutFormCloseHandler(props.resetForm)
                                }
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="close_btn btn btn-secondary"
                                onClick={aboutBackBtnHandler}
                              >
                                Back
                              </button>
                              <button
                                id="aboutYourCondition"
                                type="submit"
                                className="btn btn-primary about-btn"
                                // onClick={() => {setFieldValue("hiddenField", "yourValueHere OR dynamicVariable"); }}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              ) : (
                ""
              )}

              {/* <!--------------------> */}
              {/* <!-------------------> */}
              {sessionForm ? (
                <div
                  className={!sessionForm ? "none" : ""}
                  id="popup_select_section1"
                >
                  <div className="modal-header">
                    <div className="container-fluid">
                      <div className="TopHeaderLogo w-100 clearfix position-relative">
                        <div className="ProLogo float-left">
                          <Link className="navbar-brand" to="/">
                            <img
                              alt=""
                              src="/images/logo.png"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="float-right">
                          <div className="dil_txt">
                            <i className="fa fa-heart" aria-hidden="true"></i>
                            1500 happy customers and counting
                          </div>
                          <div className="call_txt">
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            Call us at 1800 345 678
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div>
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <div className="pop_txt_sec">
                            <h1>Book your session</h1>
                            <p>
                              Welcome to ProPhysio tele therapy! With just few
                              clicks and information about your health condition
                              we will be able to connect you with the right
                            </p>
                            <div>
                              <div className="calendar-container">
                                <Calendar
                                  onChange={dateChangeHandler}
                                  value={date}
                                  minDate={new Date()}
                                  maxDate={last3months}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-12">
                          <div className="row">
                            <div className="col-md-8 col-sm-12">
                              <div className="form_sec">
                                <ul>
                                  <li>
                                    <div className="NutriImg ">
                                      <i
                                        className="fa fa-user-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="NutriImg ">
                                      <i
                                        className="fa fa-file-text"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </li>
                                  <li className="active">
                                    <div className="NutriImg ">
                                      <i
                                        className="fa fa-calendar-check-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="NutriImg ">
                                      <i
                                        className="fa fa-check-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-12"></div>
                            <div className="col-md-12 col-sm-12">
                              <div className="form_sec">
                                <h2>
                                  Select Therapist ({" "}
                                  <span className="tommdate">
                                    {date.toDateString()}
                                  </span>
                                  )
                                </h2>
                              </div>
                              <ul
                                className="book_slot_time"
                                ref={listInnerRef}
                                onScroll={handleScroll}
                                // style={{ height: 500, overflowY: "scroll" }}
                              >
                                {doctorList.map((v, i) => {
                                  return (
                                    <li
                                      className={active1 == i ? "active" : ""}
                                      key={i}
                                      onClick={() => {
                                        setTimeSection(!timeSection);
                                        setSelectedDoctor(v);
                                        setActive1(i);
                                      }}
                                    >
                                      <div
                                        className="slot_sec"
                                        onClick={() => {
                                          setBackgroundColor(i);
                                          setActive1(i);
                                        }}
                                        style={{
                                          backgroundColor:
                                            backgroundColor === i
                                              ? "#707070"
                                              : "#F7F0EA",
                                        }}
                                      >
                                        <div className="slot_cont_sec">
                                          <div className="slot_img_sec">
                                            {v.image ===
                                              "https://prophysio.in/images/therapist/sm/default_image.png" ||
                                            v.image === "" ? (
                                              <button
                                                style={{
                                                  height: "120px",
                                                  marginTop: "3%",
                                                  width: "100px",
                                                  fontSize: "40px",
                                                  border: "none",
                                                  color: "bisque",
                                                  background: "gray",
                                                }}
                                              >
                                                {getInitials(v.name)}
                                              </button>
                                            ) : (
                                              <img
                                                src={v.image}
                                                alt=""
                                                style={{
                                                  maxHeight: "120px",
                                                  width: "100px",
                                                }}
                                              />
                                            )}
                                          </div>
                                          <div
                                            className="slot_cont"
                                            onClick={() => setColor(i)}
                                            style={{
                                              color: color === i && "#fff",
                                            }}
                                          >
                                            <div
                                              className="slot_txt"
                                              onClick={() => setActive1(i)}
                                            >
                                              <h3>{v.name}</h3>
                                              <ul
                                                onClick={() => setColor(i)}
                                                style={{
                                                  color: color === i && "#fff",
                                                }}
                                              >
                                                <li>
                                                  {v.designation}, Since{" "}
                                                  {v.year_experience}Years
                                                </li>
                                                <li>{v.location}</li>
                                              </ul>
                                            </div>
                                            <ul
                                              className="slot_amt_sec"
                                              onClick={() => setColor(i)}
                                              style={{
                                                color:
                                                  color === i ? "#fff" : "",
                                              }}
                                            >
                                              <li
                                                style={{
                                                  marginBottom: "25%",
                                                }}
                                              >
                                                {v.rate}
                                              </li>
                                              <li>
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                />
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                />
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                />
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                />
                                                <i
                                                  className="fa fa-star"
                                                  aria-hidden="true"
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        {active1 == i && (
                                          <div className="slot_date_sec">
                                            <ul>
                                              <li>
                                                <ul className="time_list slot1">
                                                  {v.slots.map((slot, i) => {
                                                    return (
                                                      <li
                                                        onClick={() =>
                                                          timeActiveHandler(
                                                            i,
                                                            slot
                                                          )
                                                        }
                                                        style={
                                                          activeTime === i
                                                            ? {
                                                                backgroundColor:
                                                                  "#1565c0",
                                                              }
                                                            : {}
                                                        }
                                                        key={i}
                                                      >
                                                        {slotTime(
                                                          slot.start_time
                                                        )}{" "}
                                                        -{" "}
                                                        {slotTime(
                                                          slot.end_time
                                                        )}
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>

                              {/* <ul className="book_slot_time1"></ul> */}
                              <span className=".err_user_name"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="row">
                      <div className="col-md-4 col-sm-12"></div>
                      <div className="pl_10_per col-md-6 col-sm-12">
                        <button
                          type="button"
                          className="close_btn btn btn-secondary"
                          onClick={backBtnHandler}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          // id="bookappointmentBtn"
                          className="cont_btn btn btn-primary"
                          onClick={submitHandler}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* <!--------------------> */}
              {/* <!-------------------> */}
              {bookingDone ? (
                <div
                  className={!bookingDone ? "none" : ""}
                  id="popup_booking_done"
                >
                  <div className="modal-header">
                    <div className="container-fluid">
                      <div className="TopHeaderLogo w-100 clearfix position-relative">
                        <div className="ProLogo float-left">
                          <Link className="navbar-brand" to="/">
                            <img
                              alt=""
                              src="/images/logo.png"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="float-right">
                          <div className="dil_txt">
                            <i className="fa fa-heart" aria-hidden="true"></i>
                            1500 happy customers and counting
                          </div>
                          <div className="call_txt">
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            Call us at 1800 345 678
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="address addresslist">
                      <div className="container">
                        <form className="">
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className="pop_txt_sec">
                                <h1>You are almost there!</h1>
                                <p>
                                  Review your appointment details and make
                                  online payment to complete the booking.
                                </p>
                              </div>
                            </div>
                            <div className="form_div col-md-6 col-sm-12">
                              <div className="row">
                                <div className="form_sec">
                                  <ul>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-user-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-file-text"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-calendar-check-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li className="active">
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-check-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                  </ul>
                                  <h1>Your appointment Summary</h1>
                                  <div className="res_sec ">
                                    <div className="pro_block success-msg">
                                      <h4>Consultant Physiotherapist</h4>
                                      <div class="pro_sec">
                                        <div class="pro_img_sec">
                                          <img
                                            src={selectedDoctor.image}
                                            alt=""
                                          />
                                        </div>
                                        <div class="pro_txt_sec">
                                          <h3>
                                            {selectedDoctor.name}{" "}
                                            <span>{selectedDoctor.rate} </span>
                                          </h3>
                                          <ul>
                                            <li>
                                              {selectedDoctor.designation},
                                              Since{" "}
                                              {selectedDoctor.year_experience}{" "}
                                              Years,
                                            </li>
                                            <li>{selectedDoctor.location}</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="time_block">
                                      <h4>Session Schedule</h4>
                                      <p>
                                        {selectedTime.start_time}-
                                        {selectedTime.end_time},{" "}
                                        {date.toDateString()}
                                      </p>
                                      <div></div>
                                      <div class="sess_loc_block">
                                        <h4>Session Location</h4>
                                        <p>
                                          {secondForm.session_location ===
                                          "online"
                                            ? "Online ,Will need smart phone or laptop with internet connection"
                                            : secondForm.session_location}
                                        </p>
                                      </div>
                                      <div class="fee_block">
                                        <h4>Session Fee</h4>
                                        <p>
                                          <i
                                            class="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>
                                          {selectedDoctor.rate}, Duration 1:00
                                          Hour
                                        </p>
                                      </div>
                                      <div class="ready_block">
                                        <h4>Getting ready for session</h4>
                                        <p>
                                          You will receive a detailed email
                                          about the session schedule and on how
                                          to prepare yourself as well as your
                                          space for this session.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer book-footer">
                      <div className="row">
                        <div className="col-md-12 "></div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-12"></div>
                        <div className="pl_5_per col-lg-8 col-md-6 col-sm-12">
                          <button
                            type="button"
                            className="close_btn btn btn-secondary"
                            onClick={bookingDoneCloseBtnHandle}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="close_btn btn btn-secondary"
                            onClick={bookingdoneBackBtnHandler}
                          >
                            Back
                          </button>
                          <button
                            type="button"
                            id="submitBookNowBtn"
                            onClick={dataSubmitHandler}
                            className="cont_btn btn btn-primary pay_btn"
                          >
                            Book Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--------------------> */}
                </div>
              ) : (
                ""
              )}

              {/* last success message form */}
              {finalForm ? (
                <div
                  className={!finalForm ? "none" : ""}
                  id="popup_booking_done_successfully"
                >
                  <div className="modal-header">
                    <div className="container-fluid">
                      <div className="TopHeaderLogo w-100 clearfix position-relative">
                        <div className="ProLogo float-left">
                          <Link className="navbar-brand" to="/">
                            <img
                              alt=""
                              src="/images/logo.png"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="float-right">
                          <div className="dil_txt">
                            <i className="fa fa-heart" aria-hidden="true"></i>
                            1500 happy customers and counting
                          </div>
                          <div className="call_txt">
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            Call us at 1800 345 678
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="address addresslist">
                      <div className="container">
                        <form className="">
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className="pop_txt_sec">
                                <h1>You are almost there!</h1>
                                <p>
                                  Review your appointment details and make
                                  online payment to complete the booking.
                                </p>
                              </div>
                            </div>
                            <div className="form_div col-md-6 col-sm-12">
                              <div className="row">
                                <div className="form_sec">
                                  <ul>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-user-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-file-text"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-calendar-check-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                    <li className="active">
                                      <div className="NutriImg ">
                                        <i
                                          className="fa fa-check-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                  </ul>
                                  <h1>Your appointment Summary</h1>
                                  <div className="res_sec ">
                                    <div class="pro_sec">
                                      <div class="">
                                        <img src="/images/40066.png" />
                                      </div>
                                    </div>
                                    <div class="alert">
                                      <p
                                        style={{
                                          color: "#28a745",
                                          fontSize: "20px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Your request has been taken. Our care
                                        manager will get back to you shortly!
                                      </p>
                                      <p>
                                        For anything reach out to us on
                                        <br />
                                        <i
                                          class="fa fa-phone"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        <strong>8197378444</strong> or{" "}
                                        <strong>1800121999994</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="modal-footer book-footer">
                      <div className="row">
                        <div className="col-md-12 "></div>
                      </div>

                      <div class="row">
                        <div class="col-md-4 col-sm-4"></div>
                        <div class="col-md-8 col-sm-8">
                          <button
                            type="button"
                            class="close_btn btn btn-secondary"
                            onClick={finalFormCloseBtnHandle}
                          >
                            close
                          </button>
                          <a href="/" class="cont_btn btn btn-primary">
                            Explore Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!--------------------> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* <!---------Book Now-----------> */}
        <ToastContainer />
      </div>
    </>
  ) : null;
}

export default bookNowPopUp;
