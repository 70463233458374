import React from "react";
import { Link } from "react-router-dom";

function bWearableDevice() {
  const blogData = JSON.parse(sessionStorage.getItem("blogData"));

  console.log("blog id and data in wearable device", blogData);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <section dangerouslySetInnerHTML={{ __html: blogData?.body_content }}></section> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">
              A Device to help you manage stress{" "}
            </h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link fs-18 fw-600">Wellness</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fs-18 fw-600"> Science</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fs-18 fw-600"> Health</a>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/C8A0737.png"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </a>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">
                Natural breathing rhythm synchronises the body{" "}
              </h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                Did you know that we have a natural breathing rhythm that can
                synchronise the whole body. Relieve all the tension and stress?
                We discovered a device that synchronizes with your physiology,
                using real-time vitals obtained by the heart rate sensor, to
                guide you to breathe at your Resonance Frequency Breathing rate.
                The technology measures the activity in your sympathetic and
                parasympathetic nervous system by taking continuous readings via
                the fingertip sensor.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/8A0555.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">Breathing : To help sleep </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2">
                  We then train you to decrease the activity of the sympathetic
                  nervous system (STRESS) and increase the activity in the
                  parasympathetic nervous system. (CALM). The sensor and app
                  work as your personal coach that uses a breath pacer that
                  guide you lengthen your exhalation to calm your heart and mind
                  and increase your resilience to stress. Modules that can calm
                  down & improve sleep Calming videos with relaxing sounds and
                  breath pacers guide you to breathe at your Personal Best
                  Relaxation Breathing Rate to help you fall asleep. This
                  includes breathing exercises and guided meditations
                  specifically aimed at activating the parasympathetic nervous
                  system and improving sleep.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Three ||---------------> */}
      <section className="PhysioDescripVideo" id="DescripVideo">
        <div className="container">
          <div className="PhysioBlogVideo">
            <img
              src="/images/blog/C8A0509.jpg"
              alt="blog banner Two"
              className="img-fluid w-100"
            />
            <button className="play_btn">
              <i className="fa fa-play-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="DescriptionVideo text-center MuliBold my-3">
            <p className="fs-25 clr-2">
              Watch this video to know more on how this device works
            </p>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!---------------|| Section Four ||---------------> */}
      <section
        className="PhysioDiagnosticTools py-md-5 pt-5"
        id="DiagnosticTools"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioDiagnosticBuddha ">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <div className="PhysioDiagBuddhaContant text-center">
                  <div className="PhysioDiagBuddhaPara fs-30">
                    <p className="clr-darkgray fs-30">
                      "Breathe. Let go. And remind yourself that this very
                      moment is the only one you know you have for sure."
                    </p>
                  </div>
                  <div className="PhysioDiagName text-center MuliBold fs-20">
                    <span className="fs-20 clr-2 fw-600">- Oprah Winfrey</span>
                  </div>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4">
                  <p className="fs-25 fw-400 clr-2 my-4">
                    {" "}
                    Resonance Frequency Breathing is used by professional and
                    Olympic athletes to increase focus and improve performance
                    as well as individuals in highly stressful professions such
                    as high-performance military pilots, law enforcement
                    personnel, and actors and singers to cope with stage fright.
                    It has also shown to improve scores during test taking.
                  </p>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
                <p className="fs-25 clr-2">
                  Some to reduce that muscle tension and pain? The app comes
                  with guided meditation of various themes such as Progressive
                  Muscle Relaxation which is based on the simple practice of
                  tensing, or tightening, one muscle group at a time followed by
                  a relaxation phase with release of the tension. This relieves
                  chronic pain and muscle tension as well as related
                  psychological issues like stress, anxiety and insomnia.You’re
                  probably looking to Increase focus & improve performance Using
                  real-time vitals obtained by the heart rate sensor, the app
                  guides you to breathe at your Resonance Frequency Breathing
                  rate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Four ||---------------> */}
      {/* <!---------------|| Section Five ||---------------> */}
      <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">Its all in the device</h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="fs-25 fw-400 clr-2 my-4">
                  The device comes with a simple to use pulse Oxymeter that is
                  connected through an app. The app has various programs that
                  encouragers users to practice systematic breathing techniques
                  to help improve outcomes which are desired. The device has
                  been used by various medical and therapeutic practitioners to
                  help improve function.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/iStock-11477859.png"
                  alt="image6"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {console.log("inside jsx blogData", blogData)}
      {/* <section
        dangerouslySetInnerHTML={{ __html: blogData?.similar_post }}
      ></section> */}
      {/* <!---------------|| Section Five ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock_000022998473_Large.png"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">
                          Tips & Healthy Tricks
                        </h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          <p className="mb-0 fs-34">
                            Best exercises in pregnancy
                          </p>
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <a className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </a>
                          </li>
                          <li className="nav-item mx-2">
                            <a className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/C8A0666.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A7169.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy life article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise is the best medicine
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy diet article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/Image54.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <div to="bRunning" className="d-inline-block clr-2 h-100">
                        <Link
                          to="bRunning"
                          className="d-inline-block clr-2 h-100"
                        >
                          <div className="PhysioHealthTips bg-white br-10 h-100">
                            <div className="PhysioHealthTipsImage">
                              <img
                                src="/images/blog/Common.png"
                                alt="Common"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsContant p-3">
                              <div className="PhysioHealthTipsHeading Montserrat">
                                <h6 className="fs-16 fw-600 m-0">
                                  Healthy diet article
                                </h6>
                                <img
                                  src="/images/icons/Line.png"
                                  alt="line"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                <p className="mb-0">
                                  Exercise is the best medicine
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/Believe-you-can-2.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">
                                Living Pro Life
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bChildNeed"
                                className="d-inline-block clr-2 h-100"
                              >
                                <p className="mb-0 fs-34">
                                  Truth about companionship
                                </p>
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <a className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </a>
                                </li>
                                <li className="nav-item mx-2">
                                  <a className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/3.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Pro Community
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Women power. The best there is.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bWearableDevice;
