import React from "react";
import MeetOtherTherapists from "../MeetOtherTherapists/meetOtherTherapists";
import TakeYourFirstStep from "../TakeYourFirstStep/takeYourFirstStep";
import ReadyToTransfer from "../ReadyToTransfer/readyToTransfer";
import BackToTop from "../BackToTop/backToTop";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";

function th_Pratikshya_Kar() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;

  const bookNowHandle = () => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };
  return (
    <>
      {/* <!---------------|| Section one ||---------------> */}
      <section
        className="w-100 clearfix py-5 therapists "
        id="Detailedtherapists"
      >
        <div className="container">
          <div className="therapists pb-2">
            <div className="row">
              <div className="col-12">
                <div className="backbtn">
                  <Link
                    to="therapistsExploare"
                    className="text-decoration-none"
                  >
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24">Back to all therapists</span>
                  </Link>
                  {/* <Link href="therapists_exploare.html" className="text-decoration-none">
                <img src="/images/icons/icon23.png"/>
                <span className="fs-24">Back to all therapists</span>
              </Link> */}
                </div>
              </div>
            </div>
            <div className="shruthidigde my-5">
              <div className="row">
                <div className="col-lg-4  col-md-8 mx-auto">
                  <div className="girlimg my-5 my-lg-0">
                    <img
                      src="/images/therapist/Prateeksha.jpg"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="doctordata pl-sm-2 position-relative">
                    <div className="doctorname">
                      <h1 className="fw-600 mt-2">
                        <span className="w-100 d-inline-block float-left fs-60">
                          Pratikshya Kar
                        </span>
                      </h1>
                    </div>
                    <div className="doctorsubject">
                      <p
                        className="fs-40 text-darkgray fw-500 mb-0"
                        style={{ opacity: "0.67" }}
                      >
                        Clinical Psychologist
                      </p>
                      <p className="fs-38 fw-600">
                        <span className="">1 yr. 9</span>{" "}
                        <span className="">month Exp.</span>
                      </p>
                    </div>
                    <div className="doctorthought">
                      <p className="fs-30  my-md-3 my-2 lh-30 clr_graylight MuliItalic">
                        “Life is never made unbearable by circumstances, but
                        only by lack of meaning and purpose.”
                      </p>
                    </div>
                    <div className="doctorpara">
                      <p className="fs-22 text-black my-3 MuliLight">
                        My name is Pratikshya Kar and I Graduated my M.Phil in
                        Clinical Psychology in 2019. Since, December 2019, I am
                        practicing as a clinical psychologist primarily with
                        kids with neuro-developmental disorders.I belong to
                        Bhubaneswar, Orissa and I am currently residing in
                        Bangalore. Someday I hope to write a book about my area
                        of interest.
                      </p>
                    </div>
                    <div className="doctorlocation pt-md-4">
                      <div className="location pt-3">
                        <div className="row">
                          <div className="col-md-8">
                            <ul className="nav">
                              <li className="nav-item">
                                <Link className="locationicon d-inline-block">
                                  <img src="/images/icons/location.svg" />
                                </Link>
                              </li>
                              <li className="nav-item pl-3">
                                <span className="fs-22 text-darkgray MuliLight">
                                  Lorem ipsum dolor sit amet, consetetur
                                  <br />
                                  sadipscing elitr, sed diam nonumy.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <div className="booksession text-md-right text-center">
                              <div className="more_show ">
                                <Link
                                  onClick={bookNowHandle}
                                  className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1"
                                >
                                  Book a session
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section two ||---------------> */}
      <section
        className="clearfix w-100 prophysio pb-5 aboutdoctors therpy_services TherpyDewtails1"
        id="Aboutdoctors"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-60 fw-600">About Pratikshya Kar</h1>
              </div>
            </div>
          </div>
          <div className="assurance p-md-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Expertise</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Diagnosing, assessing and providing therapy to a range of
                      psychiatric disorders including, ASD, depresiion, anxiety
                      spectrum disorders such as GAD, Panic attacks, PTSD, OCD
                      etc.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Credentials</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">pratikshya@prophysio.co</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Achievements</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Gold medal in psychology in UG, second prize in French
                      during UG,winner in inter-college debate competition
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        Previous Experience
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      1 y 7 months at The Brain Tree, Patia, Bhubaneswar
                      primarily with neuro-developmental disorders and some
                      adult cases with various psychatric disorders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        What I love about my job
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Being able to understand the needs of unique kids who
                      experience significant gaps from their environment and to
                      help cope with it. Also helping parents to understand
                      their kids better and engage more effectively with them
                      which enhances the parent child bond that may be
                      challenging with kids who are not typically developing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        In my free time I like to
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      I like to being occupied by stories.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="more_show text-center pt-4">
                  <Link
                    data-toggle="modal"
                    data-target="#claimFreeModal"
                    className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton "
                  >
                    Claim your first free consultation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||---------------> */}
      <MeetOtherTherapists />
      <TakeYourFirstStep />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}
export default th_Pratikshya_Kar;
