import "./App.css";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home/home";
import Header from "./shared/Header/header";
import BlogSingle from "./pages/BlogSingle/BlogSingle.js";
import Footer from "./shared/Footer/footer";
import KnowledgeHub from "./pages/KnowledgeHub/knowledgehub";
import AboutUs from "./pages/AboutUs/aboutus";
import ContactUs from "./pages/ContactUs/contactus";
import ScrollTotop from "./shared/ScrollToTop/scrollTotop";
import Hospital from "./pages/Hospital/hospital";
import Professional from "./pages/Professional/professional";
import Service from "./pages/Service/service";
import ProphysioWork from "./pages/ProphysioWork/prophysioWork";
import DetailedElderlyService from "./services/DetailedElderlyService/detailedElderlyService";
import PhysioTherapyService from "./services/PhysioTherapyService/physioTherapyService";
import DetailedLifestyleService from "./services/DetailedLifestyleService/detailedLifestyleService";
import DetailedMentalService from "./services/DetailedMentalService/detailedMentalService";
import DetailedCardiacService from "./services/DetailedCardiacService/detailedCardiacService";
import DetailedWomenHealth from "./services/DetailedWomenHealth/detailedWomenHealth";
import DetailedSpeechService from "./services/DetailedSpeechService/detailedSpeechService";
import DetailedOccupationalService from "./services/DetailedOccupationalService/detailedOccupationalService";
import DetailedMovementService from "./services/DetailedMovementService/detailedMovementService";
import DetailedUrbanService from "./services/DetailedUrbanService/detailedUrbanService";
import DetailedPostNatalService from "./services/DetailedPostNatalService/detailedPostNatalService";
import DetailedNeuroService from "./services/DetailedNeuroService/detailedNeuroService";
import DetailedEducationService from "./services/DetailedEducationService/detailedEducationService";
import TherapistsExploare from "./services/TherapistsExploare/therapistsExploare";
import JoinTheCommunity from "./pages/JoinTheCommunity/joinTheCommunity";
import Faq from "./pages/Faq/faq";
import BlogWearableDevice from "./pages/Blog/BlogWearableDevice/bWearableDevice";
import BlogRunning from "./pages/Blog/BlogRunning/bRunning";
import BlogPhysiotherapyInRecoveryFromCovid from "./pages/Blog/BlogPhysiotherapyInRecoveryFromCovid/bPhysiotherapyInRecoveryFromCovid";
import BlogMoveMore from "./pages/Blog/BlogMoveMore/bMoveMore";
import BlogMentalHealth from "./pages/Blog/BlogMentalHealth/bMentalHealth";
import BlogMentalHealthBk from "./pages/Blog/BlogMentalHealthBk/bMentalHealthBk";
import BlogChildNeed from "./pages/Blog/BlogChildNeed/bChildNeed";
import BlogAdhd from "./pages/Blog/BlogAdhd/bAdhd";
import BlogExercise from "./pages/Blog/BlogExercise/bExercise";
import BlogImmunityTips from "./pages/Blog/BlogImmunityTips/bImmunityTips";
import th_AnshulJagga from "./shared/Therapists/th_AnshulJagga";
import th_anushree_P from "./shared/Therapists/th_anushree_P";
import th_aruna from "./shared/Therapists/th_aruna";
import th_AshwiniMadihalli from "./shared/Therapists/th_AshwiniMadihalli";
import th_BUSHRA_R from "./shared/Therapists/th_BUSHRA_R";
import th_DrPreethiG from "./shared/Therapists/th_DrPreethiG";
import th_meenu_das from "./shared/Therapists/th_meenu_das";
import th_MeenuDasPB from "./shared/Therapists/th_MeenuDasPB";
import th_Pratikshya_Kar from "./shared/Therapists/th_Pratikshya_Kar";
import th_PujaSingh from "./shared/Therapists/th_PujaSingh";
import th_RaniyaSulthana from "./shared/Therapists/th_RaniyaSulthana";
import th_RichwinJ from "./shared/Therapists/th_RichwinJ";
import th_sabrinamody from "./shared/Therapists/th_sabrinamody";
import th_SandhiyaHariniKG from "./shared/Therapists/th_SandhiyaHariniKG";
import th_SanjanaNair from "./shared/Therapists/th_SanjanaNair";
import th_SHAHUL from "./shared/Therapists/th_SHAHUL";
import th_ShauryaSatish from "./shared/Therapists/th_ShauryaSatish";
import th_shonalli_ponnappa from "./shared/Therapists/th_shonalli_ponnappa";
import th_shruti_goradia from "./shared/Therapists/th_shruti_goradia";
import th_ShrutiD from "./shared/Therapists/th_ShrutiD";
import th_sminu from "./shared/Therapists/th_sminu";
import th_smitha_s from "./shared/Therapists/th_smitha_s";
import th_sowmya from "./shared/Therapists/th_sowmya";
import th_SRajaLakshmi from "./shared/Therapists/th_SRajaLakshmi";
import th_sruthi_g_s from "./shared/Therapists/th_sruthi_g_s";
import th_SweataBasnett from "./shared/Therapists/th_SweataBasnett";
import th_Varsha_kumar from "./shared/Therapists/th_Varsha_kumar";
import th_zainab from "./shared/Therapists/th_zainab";
import Therapists_details from "./shared/Therapists_details/therapists_details";
import bExercise from './pages/Blog/BlogExercise/bExercise'
import th_RishinTk from "./shared/Therapists/th_RishinTk";


function App() {
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          <Header></Header>
          <ScrollTotop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/knowledgehub" component={KnowledgeHub} />
            <Route exact path="/blog/:blog_id" component={BlogSingle} />
            <Route exact path="/aboutus" component={AboutUs} />
            <Route exact path="/contactus" component={ContactUs} />
            <Route exact path="/hospital" component={Hospital} />
            <Route exact path="/professional" component={Professional} />
            <Route exact path="/service" component={Service} />
            <Route exact path="/prophysioWork" component={ProphysioWork} />
            <Route exact path="/b_exercise" component={bExercise} />
            <Route
              exact
              path="/Elderlycareservice"
              component={DetailedElderlyService}
            />
            <Route
              exact
              path="/physiotherapyservicebangalore"
              component={PhysioTherapyService}
            />
            <Route
              exact
              path="/Lifestylemedicinespecialist"
              component={DetailedLifestyleService}
            />
            <Route
              exact
              path="/Mentalhealthcareservices"
              component={DetailedMentalService}
            />
            <Route
              exact
              path="/Cardiactherapyservice"
              component={DetailedCardiacService}
            />
            <Route
              exact
              path="/Womenhealth"
              component={DetailedWomenHealth}
            />

            <Route
              exact
              path="/Speechtherapyservicebangalore"
              component={DetailedSpeechService}
            />
            <Route
              exact
              path="/Occupationaltehrapyservicebangalore"
              component={DetailedOccupationalService}
            />
            <Route
              exact
              path="/Movementscienceservice"
              component={DetailedMovementService}
            />
            <Route
              exact
              path="/Urbanhealthservice"
              component={DetailedUrbanService}
            />
            <Route
              exact
              path="/Postnatalcareservice"
              component={DetailedPostNatalService}
            />
            <Route
              exact
              path="/Neurorehabilitationservices"
              component={DetailedNeuroService}
            />
            <Route
              exact
              path="/detailedEducationService"
              component={DetailedEducationService}
            />
            <Route
              exact
              path="/therapistsExploare"
              component={TherapistsExploare}
            />
            <Route
              exact
              path="/joinTheCommunity"
              component={JoinTheCommunity}
            />
            <Route exact path="/faq" component={Faq} />
            <Route
              exact
              path="/bWearableDevice"
              component={BlogWearableDevice}
            />
            <Route exact path="/bRunning" component={BlogRunning} />
            <Route
              exact
              path="/bPhysiotherapyInRecoveryFromCovid"
              component={BlogPhysiotherapyInRecoveryFromCovid}
            />
            <Route exact path="/bMoveMore" component={BlogMoveMore} />
            <Route exact path="/bMentalHealth" component={BlogMentalHealth} />
            <Route
              exact
              path="/bMentalHealthBk"
              component={BlogMentalHealthBk}
            />
            <Route exact path="/bChildNeed" component={BlogChildNeed} />
            <Route exact path="/bAdhd" component={BlogAdhd} />
            <Route exact path="/bExercise" component={BlogExercise} />
            <Route exact path="/bImmunityTips" component={BlogImmunityTips} />

            <Route exact path="/AnshulJagga" component={th_AnshulJagga} />
            <Route exact path="/anushree_P" component={th_anushree_P} />
            <Route exact path="/aruna" component={th_aruna} />
            <Route
              exact
              path="/AshwiniMadihalli"
              component={th_AshwiniMadihalli}
            />
            <Route exact path="/BUSHRA_R" component={th_BUSHRA_R} />
            <Route exact path="/DrPreethiG" component={th_DrPreethiG} />
            <Route exact path="/meenu_das" component={th_meenu_das} />
            <Route exact path="/MeenuDasPB" component={th_MeenuDasPB} />
            <Route exact path="/Pratikshya_Kar" component={th_Pratikshya_Kar} />
            <Route exact path="/PujaSingh" component={th_PujaSingh} />
            <Route exact path="/RaniyaSulthana" component={th_RaniyaSulthana} />
            <Route exact path="/RichwinJ" component={th_RichwinJ} />
            <Route exact path="/RishinTK" component={th_RishinTk} />
            <Route exact path="/sabrinamody" component={th_sabrinamody} />
            <Route
              exact
              path="/SandhiyaHariniKG"
              component={th_SandhiyaHariniKG}
            />
            <Route exact path="/SanjanaNair" component={th_SanjanaNair} />
            <Route exact path="/SHAHUL" component={th_SHAHUL} />
            <Route exact path="/ShauryaSatish" component={th_ShauryaSatish} />
            <Route
              exact
              path="/shonalli_ponnappa"
              component={th_shonalli_ponnappa}
            />
            <Route exact path="/shruti_goradia" component={th_shruti_goradia} />
            <Route exact path="/ShrutiD" component={th_ShrutiD} />
            <Route exact path="/sminu" component={th_sminu} />
            <Route exact path="/smitha_s" component={th_smitha_s} />
            <Route exact path="/sowmya" component={th_sowmya} />
            <Route exact path="/SRajaLakshmi" component={th_SRajaLakshmi} />
            <Route exact path="/sruthi_g_s" component={th_sruthi_g_s} />
            <Route exact path="/SweataBasnett" component={th_SweataBasnett} />
            <Route exact path="/Varsha_kumar" component={th_Varsha_kumar} />
            <Route exact path="/zainab" component={th_zainab} />
            <Route
              exact
              path="/therapists_details"
              component={Therapists_details}
            />
          </Switch>
          <Footer></Footer>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
