import React from "react";
import { Link } from "react-router-dom";
import AccessMoreClient from "../AccessMoreClient/AccessMoreClient";
import BookNowPopUp from "../BookNowPopUp/bookNowPopUp";
import ClaimFreeConsultationPopUP from "../FreeConsultationPop/ClaimFreeConsultationPopUP";
import GetAssesNowPopUp from "../GetAssessNow/GetAssesNowPopUp";
import IndividualGuidance from "../IndividualGuidance/IndividualGuidance";
import InterestedPartneringPopUp from "../InterestedPartnering/InterestedPartneringPopUp";
import JoinProPhysioCommunityPopUp from "../JoinProPhysioCommunityPopup/JoinProPhysioCommunityPopUp";
import JoinTheCommunityPopUp from "../JoinTheCommunity/JoinTheCommunityPopUp";
import ProgressiveHealthPopUp from "../ProgressiveHealth/ProgressiveHealthPopUp";
import UpgradeMultidisciplinary from "../UpgradeMultidisciplinary/UpgradeMultidisciplinary";
import HospitalClinicPopUp from "../HospitalClinic/HospitalClinicPopUp";
import HealthProfessionalPopUp from "../HealthProfessionalPopUp/HealthProfessionalPopUp";

function Footer() {
  return (
    <div>
      <HealthProfessionalPopUp />
      <HospitalClinicPopUp />
      <IndividualGuidance />
      <InterestedPartneringPopUp />
      <ProgressiveHealthPopUp />
      <UpgradeMultidisciplinary />
      <AccessMoreClient />
      <JoinTheCommunityPopUp />
      <GetAssesNowPopUp />
      <ClaimFreeConsultationPopUP />
      <BookNowPopUp />
      <JoinProPhysioCommunityPopUp />

      {/* -------Book now------ */}
      {/* <div className="fade modal none">
                <div className="modal-dialog modal-lg ">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="container-fluid">
                        <div className="TopHeaderLogo w-100 clearfix position-relative">
                          <div className="ProLogo float-left">
                            <Link className="navbar-brand" href="/"><img alt="" src="/images/logo.png" className="img-fluid"/></Link>
                          </div>
                          <div className="float-right">
                            <div className="dil_txt">
                              <i className="fa fa-heart" aria-hidden="true"></i>
                              1500 happy customers and counting
                            </div>
                            <div className="call_txt">
                              <i className="fa fa-phone" aria-hidden="true"></i> Call us at 1800 345 678
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="popup_contact">
                      <div className="modal-body">
                        <div className="address addresslist">
                          <form className="">
                            <div className="user_form row">
                              <div className="col-md-4 col-sm-12">
                                <div className="pop_txt_sec">
                                  <h1>Book your session</h1>
                                  <p>Welcome to ProPhysio tele therapy! With just few clicks and information about your health
                                    condition we will be able to connect you with the right expert.
                                  </p>
                                  <div className="txt_icon_list_sec">
                                    <p>Work with best in class therapists</p>
                                    <ul>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img alt="" src="/images/icons/icon12.png" className="img-fluid"/>
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">Licenced</p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img alt="" src="/images/icons/icon11.png" className="img-fluid"/>
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">Trained</p>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriDites w-100">
                                          <div className="NutriImg d-inline-block">
                                            <img alt="" src="/images/icons/icon13.png" className="img-fluid"/>
                                          </div>
                                          <div className="NutriImgText d-inline-block align-bottom">
                                            <p className="fs-24 fw-600 m-0">Experienced</p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="form_div col-md-6 col-sm-12">
                                <div className="row">
                                  <div className="form_sec">
                                    <ul>
                                      <li className="active">
                                        <div className="NutriImg ">
                                          <i className="fa fa-user-circle" aria-hidden="true"></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i className="fa fa-file-text" aria-hidden="true"></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="NutriImg ">
                                          <i className="fa fa-check-circle" aria-hidden="true"></i>
                                        </div>
                                      </li>
                                    </ul>
                                    <h1>Your Contacts</h1>
                                  </div>
                                  <div className="form_detail">
                                    <div className="form-group">
                                      <label className="form-label">Name</label>
                                      <input name="name" type="text" placeholder="Full Name" id="user_name" required/>
                                      <span className="err_user_name"></span>
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">Email</label>
                                      <input name="email" type="email" placeholder="Your active email address" id="user_email"
                                        required="required"/>
                                      <span className="err_user_email"></span>
    
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">Mobile Number</label>
                                      <input name="phone" type="text" placeholder=" 10 digit number" id="user_phone"
                                        pattern="[0-9]{10}" required/>
                                      <span className="err_user_phone"></span>
    
                                    </div>
                                    <div className="form-group">
                                      <label className="form-label">Location</label>
                                      <input name="location" type="text" placeholder=" e.g. Koramangla 8th block" id="user_location"
                                        required/>
                                      <span className="err_user_location"></span>
    
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                          </div>
                          <div className="pl_10_per col-md-6 col-sm-12">
                            <button type="button" className="close_btn btn btn-secondary">Close</button>
                            <button type="button" className="btn btn-primary contact-btn" onclick="getcontacts()">Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="none" id="popup_abt">
                      <div className="modal-body">
                        <div className="address addresslist">
                          <div className="container">
                            <form className="">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="pop_txt_sec">
                                    <h1>Book your session</h1>
                                    <p>Welcome to ProPhysio tele therapy! With just few clicks and information about your health
                                      condition we will be able to connect you with the right
                                    </p>
                                  </div>
                                </div>
                                <div className="form_div col-md-6 col-sm-12">
                                  <div className="row">
                                    <div className="form_sec">
                                      <ul>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-user-circle" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li className="active">
                                          <div className="NutriImg ">
                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                      </ul>
                                      <h1>About you and your condition</h1>
                                    </div>
                                    <div className="form_detail">
                                      <div className="row">
                                        <div className="col-md-7 col-sm-12">
                                          <div className="form-group">
                                            <label className="form-label">Gender</label>
                                            
                                            <select name="gender" id="user_gender"
                                              style={{height: "36px",border: "1px solid #007bff","border-radius": "6px","padding-left": "5px"}}>
                                              <option value="M">Male</option>
                                              <option value="F">Female</option>
                                            </select>
                                            <span className="err_user_gender"></span>
                                          </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12">
                                          <div className="form-group">
                                            <label className="form-label">Age</label>
                                            <input name="age" type="text" placeholder="Years" id="user_age" required/>
                                            <span className="err_user_age"></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">Name</label>
                                        <input name="name" type="text" placeholder="Full Name" id="user_nickname" readonly/>
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">Your Current health Condition</label>
                                        <input name="health_condition" type="text"
                                          placeholder="Mention symptoms, pain, or discomfort you have" id="user_health"/>
                                        <span className="err_user_health"></span>
                                      </div>
                                      <div className="form-group">
                                        <label className="form-label">Preffered Session location</label>
                                        <ul className="btn_list" id="user_session_location">
                                          <li>
                                            <button type="button" className="active btn btn-primary"
                                              onclick="getsessionlocation('Online')">Online</button>
                                          </li>
                                          <li>
                                            <button type="button" className="btn btn-primary"
                                              onclick="getsessionlocation('InClinic')">In Clinic</button>
                                          </li>
                                          <li>
                                            <button type="button" className="btn btn-primary"
                                              onclick="getsessionlocation('HomeVisit')">Home
                                              Visit</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                          </div>
                          <div className="pl_10_per col-md-6 col-sm-12">
                            <button type="button" className="close_btn btn btn-secondary">Close</button>
                            <button type="button" className="btn btn-primary about-btn" onclick="gethealth()">Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    

                    <div className="none" id="popup_select_section">
                      <div className="modal-body">
                        <div>
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                              <div className="pop_txt_sec">
                                <h1>Book your session</h1>
                                <p>Welcome to ProPhysio tele therapy! With just few clicks and information about your health
                                  condition we will be able to connect you with the right
                                </p>
                                <div className="calendar-wrapper" id="calendar-wrapper"></div>
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12">
                              <div className="row">
                                <div className="col-md-8 col-sm-12">
                                  <div className="form_sec">
                                    <ul>
                                      <li>
                                        <div className="NutriImg "><i className="fa fa-user-circle" aria-hidden="true"></i></div>
                                      </li>
                                      <li>
                                        <div className="NutriImg "><i className="fa fa-file-text" aria-hidden="true"></i></div>
                                      </li>
                                      <li className="active">
                                        <div className="NutriImg "><i className="fa fa-calendar-check-o" aria-hidden="true"></i></div>
                                      </li>
                                      <li>
                                        <div className="NutriImg "><i className="fa fa-check-circle" aria-hidden="true"></i></div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-4 col-sm-12"></div>
                                <div className="col-md-12 col-sm-12">
                                  <div className="form_sec">
                                    <h2>Select Therapist (Tomorrow <span className="tommdate"></span>)</h2>
                                  </div>
                                  <ul className="book_slot_time">    
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                          </div>
                          <div className="pl_10_per col-md-6 col-sm-12">
                            <button type="button" className="close_btn btn btn-secondary">Back</button>
                            <button type="button" className="cont_btn btn btn-primary" onclick="bookappointment()">Continue</button>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div className="none" id="popup_booking_done">
                      <div className="modal-body">
                        <div className="address addresslist">
                          <div className="container">
                            <form className="">
                              <div className="row">
                                <div className="col-md-4 col-sm-12">
                                  <div className="pop_txt_sec">
                                    <h1>You are almost
                                      there!
                                    </h1>
                                    <p>Review your appointment details and
                                      make online payment to complete
                                      the booking.
                                    </p>
                                  </div>
                                </div>
                                <div className="form_div col-md-6 col-sm-12">
                                  <div className="row">
                                    <div className="form_sec">
                                      <ul>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-user-circle" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-file-text" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="NutriImg ">
                                            <i className="fa fa-calendar-check-o" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                        <li className="active">
                                          <div className="NutriImg ">
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                                          </div>
                                        </li>
                                      </ul>
                                      <h1>Your appointment Summary</h1>
                                      <div className="res_sec ">
                                        <div className="pro_block success-msg">
    
                                        </div>
                                        <div className="time_block">
    
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="modal-footer book-footer">
                          <div className="row">
                            <div className="col-md-12 ">
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-sm-12">
                            </div>
                            <div className="pl_5_per col-md-6 col-sm-12">
                              <button type="button" className="close_btn btn btn-secondary"
                                onclick="localStorage.clear();">Close</button>
                              <button type="button" onclick="adduser()" className="cont_btn btn btn-primary pay_btn">Book Now</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div> */}
      {/* <!---------Book Now-----------> */}

      <footer
        className="w-100 clearfix ProMainFooter bg-black"
        id="MainFooterPhysio"
      >
        <div className="container">
          <div className="TopFooter w-100 clearfix py-5">
            <div className="row">
              <div className="col-lg-5 order-lg-2 py-lg-0 py-3">
                <div className="w-100 FooterLogo clearfix text-lg-left text-center">
                  {/* <Link href="/" className="d-inline-block">
                        <img src="/images/footer-logo.svg" className="img-fluid" alt="No Logo"/>
                        </Link> */}
                  <Link to="/" className="d-inline-block">
                    <img
                      src="/images/footer-logo.svg"
                      className="img-fluid"
                      alt="No Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 order-lg-1 py-lg-0 py-3">
                <div className="LetsStops w-100 clearfix text-white  text-lg-left text-center">
                  <h3 className="fs-34 fw-700">
                    Don’t let the small setbacks stop you <br /> from living
                    fully.
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="MiddleFooter w-100 clearfix py-5">
            <div className="row">
              <div className="col-xl-2 col-md-4 col-6 ColRespo">
                <div className="FooterLists w-100 clearfix py-3 MuliLight">
                  <h5 className="clr-3 fs-20">About us</h5>
                  <ul className="list-unstyled FooterMenuLists w-100 pt-2">
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Vision
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        People
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Partners
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Awards & Affiliations
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Testimonials
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Locations
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="FooterLists w-100 clearfix py-3 MuliLight">
                  <h5 className="clr-3 fs-20">Contact Us</h5>
                  <ul className="list-unstyled FooterMenuLists w-100 pt-2">
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Locations
                      </Link>
                    </li>
                    <li className="py-1">
                      <Link
                        
                        className="clr-4 d-inline-block fs-14"
                      >
                        Contacts
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-md-4 col-6">
                <div className="FooterLists w-100 clearfix py-3 MuliLight">
                  <h5 className="clr-3 fs-20">Services</h5>
                  <ul className="list-unstyled FooterMenuLists w-100 pt-2">
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="physioTherapyService"
                      >
                        Physio Therapy
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_physio_therapy_servies.html">Physio Therapy</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedSpeechService"
                      >
                        Speech Therapy
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_speech_servies.html">Speech Therapy</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedMentalService"
                      >
                        Mental Health
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_mental_servies.html">Mental Health</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedEducationService"
                      >
                        Special Education
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_education_servies.html">Special Education</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedLifestyleService"
                      >
                        Lifestyle Medicine
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_lifestyle_servies.html">Lifestyle Medicine</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedUrbanService"
                      >
                        Urban Health
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_urban_servies.html">Urban Health</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedElderlyService"
                      >
                        Elderly Care
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_elderly_servies.html">Elderly Care</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedMovementService"
                      >
                        Movement Science
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_movement_servies.html">Movement Science</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedCardiacService"
                      >
                        Cardiac Therapy
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_cardiac_servies.html">Cardiac Therapy</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedPostNatalService"
                      >
                        Post Natal Care
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_post_natal_servies.html">Post Natal Care</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedOccupationalService"
                      >
                        Occupational Therapy
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_occupational_servies.html">Occupational
                            Therapy</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedNeuroService"
                      >
                        Neuro-Rehabilitation
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_neuro_servies.html">Neuro-Rehabilitation</Link> */}
                    </li>
                    <li className="py-1">
                      <Link
                        className="clr-4 d-inline-block fs-14"
                        to="detailedWomenHealth"
                      >
                        Women’s Health
                      </Link>
                      {/* <Link className="clr-4 d-inline-block fs-14" href="detailed_women_health_servies.html">Women’s Health</Link> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-md-4">
                <div className="AreYouWhat w-100 clearfix">
                  <div className="WhatIsYou w-100 clearfix py-4">
                    <Link to="hospital" className="d-block">
                      {/* <Link href="hospitals.html" className="d-block"> */}
                      <div className="IconImg1 float-left">
                        <img
                          src="/images/icons/icon15.png"
                          className="img-fluid"
                          alt="No Image"
                        />
                      </div>
                      <div className="WhatUText MuliLight float-right">
                        <p className="m-0 text-white fs-15">
                          Are you a <b className="MuliExtra">Hospital</b>
                        </p>
                        <p className="m-0 text-white fs-15">
                          looking to setup a clinic?
                        </p>
                      </div>
                      {/* </Link> */}
                    </Link>
                  </div>
                  <div className="WhatIsYou w-100 clearfix py-4">
                    <Link to="professional" className="d-block">
                      {/* <Link href="professional.html" className="d-block"> */}
                      <div className="IconImg1 float-left">
                        <img
                          src="/images/icons/icon16.png"
                          className="img-fluid"
                          alt="No Image"
                        />
                      </div>
                      <div className="WhatUText MuliLight float-right">
                        <p className="m-0 text-white fs-15">
                          <b className="MuliExtra">Health Professional</b>
                        </p>
                        <p className="m-0 text-white fs-15">
                          looking to work with us?
                        </p>
                      </div>
                      {/* </Link> */}
                    </Link>
                  </div>
                  <div className="WhatIsYou w-100 clearfix py-4">
                    <Link to="joinTheCommunity" className="d-block">
                      {/* <Link href="join_the_community.html" className="d-block"> */}
                      <div className="IconImg1 float-left">
                        <img
                          src="/images/icons/icon17.png"
                          className="img-fluid"
                          alt="No Image"
                        />
                      </div>
                      <div className="WhatUText MuliLight float-right">
                        <p className="m-0 text-white fs-15">
                          Join the <b className="MuliExtra">ProPhysio</b>
                        </p>
                        <p className="m-0 text-white fs-15">
                          <b className="MuliExtra">community</b> and benefit
                        </p>
                      </div>
                      {/* </Link> */}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 ColRespo1 text-right">
                <div className="ExploreNow w-100 clearfix py-3">
                  <div className="row m-0">
                    <div className="COlOne float-left">
                      <div className="SoldImg w-100 clearfix">
                        <img
                          src="/images/img16.png"
                          className="img-fluid"
                          alt="No Image"
                        />
                      </div>
                    </div>
                    <div className="COlTwo float-right pl-4 text-left">
                      <div className="DownLoadData w-100 clearfix h-100 d-table">
                        <div className="d-table-cell align-bottom ContntMidle w-100">
                          <div className="DownloadText w-100 clearfix">
                            <Link
                              
                              className="btn BtnBook fs-20 fw-500"
                            >
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>{" "}
                              Download
                            </Link>
                            <p className="fs-20 MuliLight text-white py-4 mb-0">
                              Download your Self Care Free{" "}
                              <br className="d-none d-xl-block" />
                              Booklet for Shoulder Pain
                            </p>
                            <Link
                              
                              className="d-block fs-22 fw-500 clr-1"
                            >
                              Explore more
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="BottomFooter w-100 clearfix pb-xl 5 pt-md-3 pb-3">
            <div className="row">
              <div className="col-xl-9">
                <div className="CopyRightText w-100 clearfix text-xl-left text-center py-3">
                  <ul className="CopyLists w-100 list-unstyled mb-0 MuliLight">
                    <li className="d-inline-block clr-4 fs-12 pr-3">
                      Copyright © 2020, ProPhysio. All rights reserved.
                    </li>
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block clr-4 fs-12"
                      >
                        Terms & conditions
                      </Link>
                    </li>
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block clr-4 fs-12"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block clr-4 fs-12"
                      >
                        Cookie Policy
                      </Link>
                    </li>
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block clr-4 fs-12"
                      >
                        Editorial Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="SocialRight w-100 clearfix text-xl-right text-center py-3">
                  <ul className="SocialLists w-100 list-unstyled mb-0">
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block text-white fs-20"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li className="d-inline-block px-3">
                      <Link
                        
                        className="d-block text-white fs-20"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li className="d-inline-block pl-3">
                      <Link
                        
                        className="d-block text-white fs-20"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
