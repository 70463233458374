import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";

function FlowServiceTab() {
  
  function initiateCarousel() {

    
    /**********|| Custom Owl Js sliding in services ||**********/
    window.$("#DetailedS_tab_data").owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      dots: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        370: {
          items: 2.5,
          nav: true,
        },
        576: {
          items: 2,
          nav: true,
        },
        768: {
          items: 3,
          nav: true,
        },
        992: {
          items: 4,
          nav: true,
        },
        1200: {
          items: 5,
          nav: true,
        },
        1550: {
          items: 5,
          nav: true,
        },
        1900: {
          items: 6,
          nav: true,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  

  return (
    <>
      {/* section tab  */}
      <div className="secondContainer">
        <section className="Detailed_Servies_tab" id="Detailed_Servies_tab_data">
          <div className="container3">
            <div className="w-100 clearfix  py-3 DetailedS_tab_menu bg-lightgray">
              <ul
                className="owl-carousel owl-theme OwlSliderOne DetailedS_tab_list nav nav-tabs px-5 border-0"
                id="DetailedS_tab_data"
              >
                <li id="detailedElderlyS" className="nav-item">
                <Link to='detailedElderlyService' className="fs-20">Elderly Care
                </Link> 
                </li>
                <li id="physioTherapyS"  className="nav-item">
                  <Link to='physioTherapyService'  className="fs-20">Physio Therapy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='detailedLifestyleService'  className="fs-20">Lifestyle Medicine
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='detailedMentalService'  className="fs-20">Mental Health
                  </Link> 
                </li>
                <li className="nav-item">
                <Link to='detailedCardiacService'  className="fs-20">Cardiac Therapy
                  </Link>
                </li>
                <li className="nav-item"> 
                  <Link to='detailedWomenHealth'  className="fs-20">Women’s Health
                  </Link>
                </li>
                <li className="nav-item">  
                <Link to='detailedSpeechService'  className="fs-20">Speech Therapy
                  </Link>
                </li>
                <li className="nav-item">
                <Link to='detailedOccupationalService'  className="fs-20">Occupational Therapy
                  </Link>
              </li>
              <li className="nav-item">
                <Link to='detailedMovementService'  className="fs-20">Movement Science
                  </Link>
              </li>
              <li className="nav-item">
                <Link to='detailedUrbanService'  className="fs-20">Urban Health
                  </Link>
              </li>
              <li className="nav-item">
                <Link to='detailedPostNatalService'  className="fs-20">Post Natal Care
                  </Link>
              </li>
              <li className="nav-item">
                <Link to='detailedNeuroService'  className="fs-20">Neuro-Rehabilitation
                  </Link>
              </li>
              <li className="nav-item">
                <Link to='detailedEducationService'  className="fs-20">Special Education
                  </Link>
              </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FlowServiceTab;
