import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import ArticleHubService from "../../services/ArticalHubService/ArticalHubService";

function BlogSingle() {
  const params = useParams();
  const [blogData, setBlogData] = useState({});
  const [blogList, setBlogList] = useState([]);
  const articleHub = new ArticleHubService();

  useEffect(async () => {
    try {
      const response = await articleHub.getBlogById(params?.blog_id);
      console.log("data", response);
      setBlogData(response);
    } catch (error) {
      throw error;
    }
  }, []);
  useEffect(async () => {
    try {
      const response = await articleHub.similarList({ blog: params?.blog_id }, 0, 10);
      console.log("data", response.rows);
      setBlogList(response.rows);
    } catch (error) {
      throw error;
    }
  }, []);
  let category = blogData?.category?.split(",");
  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <div dangerouslySetInnerHTML={{ __html: blogData?.body_content }}></div> */}
      {blogData?.typeOf == 0 ? (
        <>
          <section
            className="w-100 clearfix PhysioBlogTitle py-5"
            id="BlogTitle"
          >
            <div className="container">
              <div className="PhysioBlogTitleHeading fs-24 fw-600">
                <Link to="/knowledgehub" className="clr-1">
                  <img src="/images/icons/icon23.png" alt="left icon" /> Back to
                  knowledge hub
                </Link>
              </div>
              <div className="PhysioBlogPara mt-4 ">
                <h1 className="fw-700 fs-72">{blogData?.title}</h1>
                <img src="/images/icons/Line.png" alt="line" />
              </div>
              <div className="PhysioBlogNav  mt-4">
                <ul className="nav">
                  {category?.map((item) => {
                    return (
                      <li className="nav-item">
                        <a className="nav-link fs-18 fw-600" to="#">
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className="PhysioBlogBanner mt-5 br-24"
                style={{ overflow: "hidden" }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_BASEURL + blogData?.banner
                  }
                  alt="blog banner"
                  className="img-fluid"
                />
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                      {blogData?.likes_number} Like
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-share-alt" aria-hidden="true"></i>{" "}
                      Share
                    </a>
                  </li>
                </ul>
              </div>
              <div className="PhysioIntroHook">
                <div className="PhysioIntroHeading pt-5">
                  <h1 className="fs-54 fw-700">{blogData?.sub_title}</h1>
                </div>
                <div className="PhysioIntroPara fs-25 MuliLight">
                  <p
                    className="clr-2"
                    dangerouslySetInnerHTML={{
                      __html: blogData?.sub_content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </section>
          {/* <!---------------|| Section One ||---------------> */}
          {/* <!---------------|| Section Two ||---------------> */}
          <section
            className="PhysioSectionTitle py-xl-5 mb-4"
            id="SectionTitle"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 pr-xl-5">
                  <div className="PhysioSectionImages blog_services_img">
                    <img
                      src={
                        process.env.REACT_APP_API_BASEURL +
                        blogData?.image_1
                      }
                      alt="image6"
                      className="img-fluid br-24"
                    />
                  </div>
                </div>
                <div className="col-md-6 pl-xl-5">
                  <div className="PhysioSectionHeading">
                    <h1 className="fs-54 fw-700">{blogData?.heading_1}</h1>
                  </div>
                  <div className="PhysioSectionPara MuliLight fs-25">
                    <p
                      className="clr-2"
                      style={{ "white-space": "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: blogData?.content_1,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!---------------|| Section Two ||---------------> */}
          {/* <!---------------|| Section Three ||---------------> */}
          {blogData?.video_thumbnail !== null ||
            blogData?.video_title !== null ? (
            <section className="PhysioDescripVideo" id="DescripVideo">
              <div className="container">
                <div className="PhysioBlogVideo">
                  <img
                    src={
                      process.env.REACT_APP_API_BASEURL +
                      blogData?.video_thumbnail
                    }
                    alt="blog banner Two"
                    className="img-fluid w-100"
                  />
                  <button className="play_btn">
                    <i className="fa fa-play-circle" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="DescriptionVideo text-center MuliBold my-3">
                  <p className="fs-25 clr-2">{blogData?.video_title}</p>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
          {/* <!---------------|| Section Three ||---------------> */}
          {/* <!---------------|| Section Four ||---------------> */}
          <section
            className="PhysioDiagnosticTools py-md-5 pt-5"
            id="DiagnosticTools"
          >
            {blogData?.paragraph_1_content_1 ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="PhysioDiagnosticBuddha ">
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                      <div className="PhysioDiagBuddhaContant text-center">
                        <div className="PhysioDiagBuddhaPara fs-30">
                          <p className="clr-darkgray fs-30">
                            {blogData?.paragraph_1}
                          </p>
                        </div>
                        <div className="PhysioDiagName text-center MuliBold fs-20">
                          <span className="fs-20 clr-2 fw-600">
                            - {blogData?.paragraph_author_1}
                          </span>
                        </div>
                      </div>
                      <div className="ToolsEnabled MuliLight fs-25 mt-4">
                        <p
                          className="fs-25 fw-400 clr-2 my-4"
                          dangerouslySetInnerHTML={{
                            __html: blogData?.paragraph_1_content_2,
                          }}
                        ></p>
                      </div>
                      <div className="ToolsEnabled MuliLight fs-25 mt-4"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
                      <p
                        className="fs-25 clr-2"
                        dangerouslySetInnerHTML={{
                          __html: blogData?.paragraph_1_content_1,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="PhysioDiagnosticBuddha ">
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                      <div className="PhysioDiagBuddhaContant text-center">
                        <div className="PhysioDiagBuddhaPara fs-30">
                          <p className="clr-darkgray fs-30">
                            {blogData?.paragraph_1}
                          </p>
                        </div>
                        <div className="PhysioDiagName text-center MuliBold fs-20">
                          <span className="fs-20 clr-2 fw-600">
                            - {blogData?.paragraph_author_1}
                          </span>
                        </div>
                      </div>
                      <div className="ToolsEnabled MuliLight fs-25 mt-4">
                        <p
                          className="fs-25 fw-400 clr-2 my-4"
                          dangerouslySetInnerHTML={{
                            __html: blogData?.paragraph_1_content_2,
                          }}
                        ></p>
                      </div>
                      <div className="ToolsEnabled MuliLight fs-25 mt-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
          {/* <!---------------|| Section Four ||---------------> */}
          {/* <!---------------|| Section Five ||---------------> */}
          {blogData?.heading_2 && (
            <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="PhysioSectionHeading">
                      <h1 className="fs-54 fw-700">{blogData?.heading_2}</h1>
                    </div>
                    <div className="PhysioSectionPara MuliLight fs-25">
                      <p className="fs-25 fw-400 clr-2 my-4">
                        {blogData?.content_2}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="PhysioSectionImages blog_services_img">
                      {blogData?.image_2 ? (
                        <img
                          src={
                            process.env.REACT_APP_API_BASEURL +
                            blogData?.image_2
                          }
                          alt="image6"
                          className="img-fluid"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      ) : blogData?.typeOf == 1 ? (
        <>
          <section
            className="w-100 clearfix PhysioBlogTitle py-5"
            id="BlogTitle"
          >
            <div className="container">
              <div className="PhysioBlogTitleHeading fs-24 fw-600">
                <Link to="knowledgehub" className="clr-1">
                  <img src="/images/icons/icon23.png" alt="left icon" /> Back to
                  knowledge hub
                </Link>
              </div>
              <div className="PhysioBlogPara mt-4 ">
                <h1 className="fw-700 fs-72">{blogData?.title}</h1>
                <img src="/images/icons/Line.png" alt="line" />
              </div>
              <div className="PhysioBlogNav  mt-4">
                <ul className="nav">
                  {category?.map((item) => {
                    return (
                      <li className="nav-item">
                        <a className="nav-link fs-18 fw-600" to="#">
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className="PhysioBlogBanner mt-5 br-24"
                style={{ overflow: "hidden" }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_BASEURL + blogData?.banner
                  }
                  alt="blog banner"
                  className="img-fluid"
                />
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                      {blogData?.likes_number} Like
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-share-alt" aria-hidden="true"></i>{" "}
                      Share
                    </a>
                  </li>
                </ul>
              </div>
              <div className="PhysioIntroHook">
                {/* <div className="PhysioIntroHeading pt-5">
                    <h1 className="fs-54 fw-700">
                      {blogData?.sub_title}
                    </h1>
                  </div> */}
                <div className="PhysioIntroPara fs-25 MuliLight pt-5">
                  <p
                    className="clr-2"
                    dangerouslySetInnerHTML={{
                      __html: blogData?.sub_content,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </section>
          {/* <!---------------|| Section One ||---------------> */}
          {/* <!---------------|| Section Two ||---------------> */}
          <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="PhysioSectionHeading">
                    <h1 className="fs-54 fw-700">{blogData?.heading_1}</h1>
                  </div>
                  <div className="PhysioSectionPara MuliLight fs-25">
                    <p className="fs-25 fw-400 clr-2 my-4">
                      {blogData?.content_1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="PhysioSectionHeading">
                    <h1 className="fs-54 fw-700">{blogData?.heading_2}</h1>
                  </div>
                  <div className="PhysioSectionPara MuliLight fs-25">
                    <p className="fs-25 fw-400 clr-2 my-4">
                      {blogData?.content_2}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <section
            className="w-100 clearfix PhysioBlogTitle py-5"
            id="BlogTitle"
          >
            <div className="container">
              <div className="PhysioBlogTitleHeading fs-24 fw-600">
                <Link to="knowledgehub" className="clr-1">
                  <img src="/images/icons/icon23.png" alt="left icon" /> Back to
                  knowledge hub
                </Link>
              </div>
              <div className="PhysioBlogPara mt-4 ">
                <h1 className="fw-700 fs-72">{blogData?.title}</h1>
                <img src="/images/icons/Line.png" alt="line" />
              </div>
              <div className="PhysioBlogNav  mt-4">
                <ul className="nav">
                  {category?.map((item) => {
                    return (
                      <li className="nav-item">
                        <a className="nav-link fs-18 fw-600" to="#">
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                className="PhysioBlogBanner mt-5 br-24"
                style={{ overflow: "hidden" }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_BASEURL + blogData?.banner
                  }
                  alt="blog banner"
                  className="img-fluid"
                />
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                      {blogData?.likes_number} Like
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link fs-18 MuliLight" to="#">
                      <i className="fa fa-share-alt" aria-hidden="true"></i>{" "}
                      Share
                    </a>
                  </li>
                </ul>
              </div>
              <div className="PhysioIntroHook">
                <div className="PhysioIntroPara fs-25 MuliLight pt-5">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>{blogData?.heading_1}</th>
                        <td>{blogData?.content_1}</td>
                        <td>{blogData?.content_2}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{blogData?.heading_2}</th>
                        <td>{blogData?.content_3}</td>
                        <td>{blogData?.content_4}</td>
                      </tr>
                      <tr>
                        <th>{blogData?.heading_3}</th>
                        <td>{blogData?.content_5}</td>
                        <td>{blogData?.content_6}</td>
                      </tr>

                      <tr>
                        <th>{blogData?.heading_4}</th>
                        <td colspan="2">{blogData?.content_7}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
          {/* <!---------------|| Section One ||---------------> */}
          {/* <!---------------|| Section Two ||---------------> */}
          <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="PhysioSectionHeading">
                    <h1 className="fs-54 fw-700">{blogData?.sub_title}</h1>
                  </div>
                  <div className="PhysioSectionPara MuliLight fs-25">
                    <p
                      className="fs-25 fw-400 clr-2 my-4"
                      style={{ "white-space": "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: blogData?.sub_content,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {/* {console.log("inside jsx blogData", blogData)} */}
      {/* <div dangerouslySetInnerHTML={{ __html: blogData?.similar_post }}></div> */}
      {blogList.length > 0 ? (
        <>
          <section
            className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
            id="TruthAboutCoffees"
          >
            <div className="container">
              <div className="OtherSimilarPosts mb-5 pt-xl-5">
                <h1 className="fs-72 fw-700">Other similar posts</h1>
              </div>
              <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
                <div className="row">
                  <div className="col-xl-5 pb-4">
                    <div className="PhysioHealthTips bg-white br-10 h-100">
                      <div className="row m-0 h-100">
                        <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                          <img
                            src={
                              process.env.REACT_APP_API_BASEURL +
                              // "/" +
                              blogList[0].banner
                            }
                            alt="Common"
                            className="img-fluid h-100"
                          />
                        </div>
                        <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                          <div className="PhysioHealthTipsHeading Montserrat">
                            {/* title */}
                            <h6 className="fs-24 fw-600 m-0">
                              {blogList[0].title}
                            </h6>
                            <img
                              src="/images/icons/Line.png"
                              alt="line"
                              className="img-fluid"
                            />
                          </div>
                          {/* sub title and slug */}
                          <div className="PhysioHealthTipsPara MuliRegular">
                            <a
                              href={"/blog/" + blogList[0].slug}
                              className="d-inline-block clr-2 h-100"
                            >
                              <p className="mb-0 fs-34">
                                {blogList[0].sub_title}
                              </p>
                            </a>
                          </div>
                          <div className="PhysioTruthCoffeesIcons">
                            <ul className="nav PhysioCoffeesIcons MuliLight">
                              <li className="nav-item mx-2">
                                <a
                                  className="nav-link fs-18 "
                                  href="javascript:void(0);"
                                >
                                  <i
                                    className="fa fa-heart"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {blogList[0].likes_number} Likes
                                </a>
                              </li>
                              <li className="nav-item mx-2">
                                <a
                                  className="nav-link fs-18"
                                  href="javascript:void(0);"
                                >
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {blogList[0].read_time}min read
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 pb-md-4">
                    <div className="PhysioHealthFourBoxContant ">
                      <div className="row">
                        <div className="col-md-4 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[1].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[1].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[1].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[1].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[2].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[2].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[2].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[2].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-4 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[3].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[3].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[3].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[3].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 pb-md-4">
                    <div className="PhysioHealthFourBoxContant ">
                      <div className="row">
                        <div className="col-md-6 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[4].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[4].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[4].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[4].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-md-6 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[5].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[5].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[5].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[5].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 pb-md-4">
                    <div className="PhysioHealthFourBoxContant ">
                      <div className="row">
                        <div className="col-md-8 pb-md-0 pb-3">
                          <div className="PhysioHealthTips bg-white br-10 h-100">
                            <div className="row m-0 h-100">
                              <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[6].banner
                                  }
                                  alt="Common"
                                  className="img-fluid h-100"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-24 fw-600 m-0">
                                    {blogList[6].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara MuliRegular">
                                  <a
                                    href={"/blog/" + blogList[6].slug}
                                    className="d-inline-block clr-2 h-100"
                                  >
                                    <p className="mb-0 fs-34">
                                      {blogList[6].sub_title}
                                    </p>
                                  </a>
                                </div>
                                <div className="PhysioTruthCoffeesIcons">
                                  <ul className="nav PhysioCoffeesIcons MuliLight">
                                    <li className="nav-item mx-2">
                                      <a
                                        className="nav-link fs-18 "
                                        href="javascript:void(0);"
                                      >
                                        <i
                                          className="fa fa-heart"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {blogList[6].likes_number} Likes
                                      </a>
                                    </li>
                                    <li className="nav-item mx-2">
                                      <a
                                        className="nav-link fs-18"
                                        href="javascript:void(0);"
                                      >
                                        <i
                                          className="fa fa-eye"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {blogList[6].read_time}min read
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 pb-md-0 pb-3">
                          <a
                            href={"/blog/" + blogList[7].slug}
                            className="d-inline-block clr-2 h-100"
                          >
                            <div className="PhysioHealthTips bg-white br-10 h-100">
                              <div className="PhysioHealthTipsImage">
                                <img
                                  src={
                                    process.env.REACT_APP_API_BASEURL +
                                    // "/" +
                                    blogList[7].banner
                                  }
                                  alt="Common"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsContant p-3">
                                <div className="PhysioHealthTipsHeading Montserrat">
                                  <h6 className="fs-16 fw-600 m-0">
                                    {blogList[7].title}
                                  </h6>
                                  <img
                                    src="/images/icons/Line.png"
                                    alt="line"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                  <p className="mb-0">
                                    {blogList[7].sub_title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}

      {/* <!---------------|| Section Five ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      {/* <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock_000022998473_Large.png"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">
                          Tips & Healthy Tricks
                        </h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          <p className="mb-0 fs-34">
                            Best exercises in pregnancy
                          </p>
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <a className="nav-link fs-18 " to="#">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </a>
                          </li>
                          <li className="nav-item mx-2">
                            <a className="nav-link fs-18" to="#">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/C8A0666.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A7169.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy life article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise is the best medicine
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy diet article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/Image54.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <div to="bRunning" className="d-inline-block clr-2 h-100">
                        <Link
                          to="bRunning"
                          className="d-inline-block clr-2 h-100"
                        >
                          <div className="PhysioHealthTips bg-white br-10 h-100">
                            <div className="PhysioHealthTipsImage">
                              <img
                                src="/images/blog/Common.png"
                                alt="Common"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsContant p-3">
                              <div className="PhysioHealthTipsHeading Montserrat">
                                <h6 className="fs-16 fw-600 m-0">
                                  Healthy diet article
                                </h6>
                                <img
                                  src="/images/icons/Line.png"
                                  alt="line"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                                <p className="mb-0">
                                  Exercise is the best medicine
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/Believe-you-can-2.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">
                                Living Pro Life
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bChildNeed"
                                className="d-inline-block clr-2 h-100"
                              >
                                <p className="mb-0 fs-34">
                                  Truth about companionship
                                </p>
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <a className="nav-link fs-18 " to="#">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </a>
                                </li>
                                <li className="nav-item mx-2">
                                  <a className="nav-link fs-18" to="#">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/3.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Pro Community
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Women power. The best there is.
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default BlogSingle;
