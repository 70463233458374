import React from "react";
import { Link } from "react-router-dom";

function takeYourFirstStep() {
 
function initiateCarousel() {

}

setTimeout(() => {
    initiateCarousel();
  }, 300);

    return(
         <>
            <section className="w-100 clearfix  py-5 mb-lg-3 stepbetter" id="Stepbettersection">
                <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="stephealth">
                        <div className="findheading text-center ">
                        <h1 className="fs-34 fw-700 clr-2">Take your first step to a better health</h1>
                        </div>
                        <div className="more_show text-center  py-4">
                        <Link data-toggle="modal"
                data-target="#GetAssessedModal" className="show_btn btn bg-blue fs-22 px-4 py-2 br-24 text-white">Get assessed now! </Link>
                        </div>
                        <div className="findparagrph text-center ">
                        <p className="fs-22 fw-500 clr-2 mb-0">Quick online assessment with our expert <br/>physios free for the first
                            time!</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
         
         </>
    );

    
}

export default takeYourFirstStep