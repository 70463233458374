import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FaqService from "../../services/FaqService/FaqService";
import BackToTop from "../../shared/BackToTop/backToTop";
import OwlCarousel from "react-owl-carousel";

function faq() {
  const FaqServe = new FaqService();
  const [showForm, setShowForm] = useState(false);
  const [slideClickData, setSlideClickData] = useState({});
  const [tilesList, setTilesList] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [faqListCount, setFaqListCount] = useState(0);
  const [showMore, setShowMore] = useState({});
  const [search, setSearch] = useState({
    start: 0,
    perPage: 6,
    searchTxt: "",
    searchField: "",
  });
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  useEffect(async () => {
    await faqListApi();
  }, [search]);

  function searchUpdateList(e) {
    search.searchTxt = e.target.value;
    search.start = 0;
    setSearch({ ...search });
  }

  const faqListApi = async () => {
    const activity = {};
    activity["searchText"] = search.searchTxt;
    try {
      let response = await FaqServe.faqList(
        activity,
        search.start,
        search.perPage
      );
      if (response) {
        if (search.start === 0) {
          setFaqList(response.rows);
        } else {
          setFaqList([...faqList, ...response.rows]);
        }
        setTilesList(response.rows);
        setFaqListCount(response.count);
        setShowMore({});
      }
    } catch (err) {
      throw err;
    }
  };

  const showMoreHandle = (index) => {
    setShowMore({ ...showMore, [index]: !showMore[index] });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required"),

    phone: Yup.string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number should be 10 digit")
      .max(10, "Phone number should be 10 digit")
      .required("Required"),
    description: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let obj = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      description: values.description,
      type:"FAQ"
    };

    try {
      let response = await FaqServe.faqFormApi(obj);
      if (response) {
        resetForm();
        setValue({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
        toast.success(response.message);
      }
    } catch (err) {
      throw err;
    }
  };

  // function initiateCarousel() {
  //   /**********|| Custom Owl Js||**********/
  //   window.$("#GotQuestionOwl").owlCarousel({
  //     loop: true,
  //     margin: 10,
  //     responsiveClass: true,
  //     dots: true,
  //     responsive: {
  //       0: {
  //         items: 1,
  //         nav: false,
  //       },
  //       576: {
  //         items: 2,
  //         nav: false,
  //       },
  //       768: {
  //         items: 2,
  //         nav: true,
  //       },
  //       992: {
  //         items: 2,
  //         nav: false,
  //         loop: false,
  //       },
  //       1200: {
  //         items: 3,
  //         nav: false,
  //         loop: false,
  //       },
  //     },
  //   });
  //   /**********|| Custom Owl Js||**********/
  // }

  // setTimeout(() => {
  //   initiateCarousel();
  // }, 300);

  const clickHandle = (index, data) => {
    setSlideClickData(data);
    setShowForm(!showForm);
  };
  const closeHandleForm = (i) => {
    setShowForm(false);
  };

  const paginationHandle = () => {
    let searchTemp = { ...search };
    searchTemp.start = searchTemp.start + search.perPage;
    setSearch(searchTemp);
  };

  return (
    <>
      {/* <!---------------|| Got Questions Section ||---------------> */}
      <section
        className="w-100 clearfix GotQuestions py-5 faq_section_slider"
        id="QuestionSection"
      >
        <div className="container">
          <div className="HeadingDiv w-100 clearfix">
            <div className="backbtn pb-md-4">
              <Link
                to="service"
                className="text-decoration-none d-inline-block"
              >
                <img src="/images/icons/icon23.png" />
                <span className="fs-24 clr-1 fw-600">Back to Services</span>
              </Link>
            </div>
            <h2 className="fs-72 lh-80 fw-700">Have questions ?</h2>
            <p className="fs-22 MuliLight pt-md-3" style={{ maxWidth: "100%" }}>
              No worries, we’ve got you covered
            </p>
          </div>
        </div>
        <div className="container-fluid">
          {tilesList?.length && (
            <div
              className="GotQueSlider w-100 clearfix pt-5"
              id="AllOwlSliders_part"
            >
              <OwlCarousel
                className="owl-carousel owl-theme OwlSliderOne AllOwlSliders"
                loop
                margin={10}
                nav
                responsive={{
                  0: {
                    items: 1,
                    nav: false,
                  },
                  576: {
                    items: 2,
                    nav: false,
                  },
                  768: {
                    items: 2,
                    nav: true,
                  },
                  992: {
                    items: 2,
                    nav: false,
                    loop: false,
                  },
                  1200: {
                    items: 3,
                    nav: false,
                    loop: false,
                  },
                }}
              >
                {tilesList.map((v, i) => {
                  return (
                    <div
                      className="physiotherapist py-4 px-5 mx-2 bg-lightgray"
                      data-toggle="modal"
                      data-target="#myModal"
                      key={i}
                    >
                      <div className="phyheading position-relative pb-2 MuliItalic">
                        <p
                          className="fs-34 fw-500"
                          onClick={() => clickHandle(i, v)}
                        >
                          <span>{v.i}</span>
                          {v.question}
                        </p>
                      </div>
                      <div className="peoplebtn pt-3">
                        <Link className="clr-2 fw-500">
                          {v.people_asked || 0} people asked
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
              {/* <div
                className="owl-carousel owl-theme OwlSliderOne AllOwlSliders"
                id="GotQuestionOwl"
              >
                
              </div> */}
            </div>
          )}
        </div>
      </section>

      {/* <!-- Modal --> */}

      {showForm ? (
        <div
          id="myModal"
          className={`modal fade ${showForm ? "show" : ""}`}
          role={showForm ? "dialog" : ""}
          style={showForm ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-24 fw-500">
                  {slideClickData.question}
                </h4>
              </div>
              <div className="modal-body fs-14 ">
                <p>{slideClickData.answer}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={closeHandleForm}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <!---------------|| Got Questions Section ||---------------> */}
      {/* <!---------------|| Section two ||---------------> */}
      <section
        className="w-100 clearfix bg-lightgray mt-xl-5 py-5  OtherFaqs"
        id="OtherFaqsDetails"
      >
        <div className="container py-xl-5">
          <div className="row">
            <div className="col-md-7">
              <div className="HeadingDiv w-100 clearfix">
                <h2 className="fs-72 fw-700 mb-0">Other FAQs</h2>
              </div>
            </div>
            <div className="col-md-5">
              <div className="faqkeywords h-100 mt-md-0 mt-3">
                <form className="form-group mb-0 h-100">
                  <div
                    className="input-group h-100 br-10"
                    style={{ border: "0.5px solid #585858" }}
                  >
                    <div className="input-group-prepend h-100">
                      <span className="input-group-text bg-white border-0">
                        <img
                          src="/images/icons/search_icon.png"
                          alt="search"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control inputkey input-lg h-100 border-0"
                      placeholder="Enter keywords"
                      name="username"
                      id="SearchWord"
                      onChange={(e) => searchUpdateList(e)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="faqphysiother pt-5">
            <div className="row">
              {faqList.map((faq, i) => {
                return (
                  <div className="col-lg-4 col-md-6 px-xl-5" key={i}>
                    <div className="faqpart">
                      <div className="phpoyherheading">
                        <p className="fs-34 fw-500">{faq.question}</p>
                      </div>
                      <div className="phpoyherpara">
                        <p className="fs-22 MuliLight">
                          {showMore[i]
                            ? faq.answer
                            : `${faq.answer?.substring(0, 250)}`}
                          <span
                            className="text-decoration-none  clr-1 wrapMore "
                            onClick={() => showMoreHandle(i)}
                          >
                            {showMore[i] ? " less" : " more"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}

              {faqList.length < faqListCount && (
                <div className="col-12">
                  <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pt-3 text-center">
                    <button
                      className="btn BtnBook fs-22 fw-500 px-4 ShowMoreBtn"
                      onClick={paginationHandle}
                    >
                      Show More
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||---------------> */}
      {/* <!---------------|| Section thired ||---------------> */}
      <section
        className="w-100 clearfix yourquestion bg-brown py-5"
        id="WhatQuestions"
      >
        <div className="container">
          <div className="HeadingDiv w-100 clearfix text-center text-white ">
            <h2 className="fs-72 lh-80 fw-700">Don’t see your question? </h2>
            <p className="fs-22 MuliLight pt-4">
              Have a question? Just drop in a message and we will get back to
              you
            </p>
          </div>
          <div className="WhatsQueForm py-md-5 mt-lg-5 pt-4 MuliLight ">
            <div className="container_three m-auto">
              <Formik
                initialValues={value}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={ValidateSchema}
              >
                {(props) => (
                  <form
                    className="w-100 clearfix"
                    onSubmit={props.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-5">
                        <div className="seeform ">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control border-0 text-white fs-22 br-10 MuliLight"
                              placeholder="Name"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.name}
                            />
                            {props.touched.name && props.errors.name ? (
                              <div className="formik-errors bg-error faq-error">
                                {props.errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              name="phone"
                              className="form-control border-0 text-white fs-22 br-10 MuliLight"
                              placeholder="Phone no."
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.phone}
                            />
                            {props.touched.phone && props.errors.phone ? (
                              <div className="formik-errors bg-error faq-error">
                                {props.errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control border-0 text-white fs-22 br-10 MuliLight"
                              placeholder="Email Id"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.email}
                            />
                            {props.touched.email && props.errors.email ? (
                              <div className="formik-errors bg-error faq-error">
                                {props.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="message">
                          <div className="form-group">
                            <textarea
                              className="form-control border-0 text-white fs-22 br-10 MuliLight"
                              value={props.values.description}
                              name="description"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              placeholder="Send us your doubt or question…"
                            />
                            {props.touched.description &&
                            props.errors.description ? (
                              <div className="formik-errors bg-error faq-error">
                                {props.errors.description}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="ExploBtn DwnldApp w-100 clearfix pt-md-5 pt-3 text-center">
                          <button
                            type="submit"
                            // href="#"
                            className="btn BtnBook fs-22 fw-500 px-4"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section thired ||---------------> */}
      {/* <!---------------|| Section fourth ||---------------> */}
      <section
        className="w-100 clearfix faqconditions Browsefaq py-5 bg-lightpink faq_condition_sec"
        id="FAQSConditions"
      >
        <div className="container py-xl-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">
              Browse FAQ by conditions & symptoms
            </h2>
          </div>
        </div>
        <div className="container_two mt-sm-0 mt-3">
          <div className="BrowseSymptoms">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Physical Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Manual Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physical Education
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Mental Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Neuro Linguistic Programming
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Cognitive behavioral Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Mindfullness
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Urban Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Metabolite Testing
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Microbiome Testing
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Vo2 Max Testing
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Special Care</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Geriatric Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Occupational Therapy
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Mental Health
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="physical py-xl-5 py-sm-3">
                  <h5 className="clr-2 fs-38 fw-600 mb-3">Child Health</h5>
                  <ul className="list-unstyled  w-100 pt-2">
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Early Learning
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physical Health
                      </Link>
                    </li>
                    <li className="py-xl-3 py-2">
                      <Link className="text-decoration-none clr-2 d-inline-block fs-28 fw-400">
                        Physiotherapy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fourth ||---------------> */}
      {/* <!---------------|| Section fivth ||---------------> */}
      <section className="w-100 clearfix CantFindCall py-5" id="FindCallWithUs">
        <div className="container py-xl-4">
          <div className="w-100 clearfix LookingCall">
            <div className="HeadingDiv w-100 clearfix text-center">
              <h2 className="fs-72 fw-700 lh-92">
                Cant find what you are <br />
                looking for?
              </h2>
              <p className="fs-22 MuliLight pt-4">
                We are more than happy to help. Just give us a call or give us
                your <br className="d-sm-block d-none" /> phone number so that
                we can call you.
              </p>
            </div>
            <div className="NotSureTo w-100 clearfix text-center pt-xl-5 NeedHelps">
              <h4 className="fs-54 MuliLight pb-xl-5">
                <img
                  src="/images/icons/icon14.png"
                  className="img-fluid"
                  alt="No Images"
                />
                <span className="d-inline-block pl-5">
                  Call us now at{" "}
                  <a href="tel:+91 8197378444" className="clr-1">
                    +91 8197378444
                  </a>
                </span>
              </h4>
              <p className="fs-32 ls-1 MuliLight">
                Need help? Get a call back from our
                <br className="d-sm-block d-none" /> support team
              </p>
              <div className="ExploBtn DwnldApp w-100 clearfix pt-4 text-center">
                <Link
                  className="btn BtnBook fs-22 fw-500 px-4"
                  data-toggle="modal"
                  data-target="#PartneringWithUS"
                >
                  Get a call back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section fivth ||---------------> */}
      <BackToTop />
    </>
  );
}

export default faq;
