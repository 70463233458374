import React from "react";
import { Link } from "react-router-dom";

function bMentalHealth() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">Exercise for mind health</h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Wellness</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Health</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Exercise</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/Believe you can-2.png"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">
                Exercise plays a huge role on how you feel inside
              </h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                All these little changes in your life can help to improve your
                physical health which can then have an impact on your mental
                health and help reduce the effects of depression & anxiety. The
                symptoms of Depression & Anxiety can often be improved when
                individuals introduce exercise and physical activity into their
                lives. It is well documented that exercise can prevent and also
                improve a number of health related issues such as high blood
                pressure, weight loss, diabetes and arthritis. Much research has
                shown how the psychological and physical benefits of exercise
                can very often help to alleviate the symptoms of anxiety and can
                improve overall mood.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/Believe you can.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">Exercise if affordable </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  The connection between anxiety, depression and exercise are
                  not 100% clear, however a gym workout, a brisk walk or a jog
                  will definitely help you to unwind and relax and you will feel
                  better afterwards. Exercising & being active does not have to
                  mean going to gym and digging out the often feared lycra out
                  of the closet- there are an unlimited amount of ways by which
                  to get the heart beating. Being active does not have to mean
                  going to the gym, taking up jogging or wearing lycra. There
                  are lots of ways to be active – and they don’t need to cost
                  much money.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Three ||---------------> */}
      <section className="PhysioDescripVideo" id="DescripVideo">
        <div className="container">
          <div className="PhysioBlogVideo">
            <img
              src="/images/blog/iStock_000072692397_Large.png"
              alt="blog banner Two"
              className="img-fluid w-100"
            />
            <button className="play_btn">
              <i className="fa fa-play-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="DescriptionVideo text-center MuliBold my-3">
            <p className="fs-25 clr-2">
              10 simple tricks on how parents can manage their mental health by
              Dr Ruchi Guptha
            </p>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!---------------|| Section Four ||---------------> */}
      <section
        className="PhysioDiagnosticTools py-md-5 pt-5"
        id="DiagnosticTools"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioDiagnosticBuddha ">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <div className="PhysioDiagBuddhaContant text-center">
                  <div className="PhysioDiagBuddhaPara fs-30">
                    <p className="clr-darkgray fs-30">
                      “Mental health problems don’t define who you are. They are
                      something you experience..”
                    </p>
                  </div>
                  <div className="PhysioDiagName text-center MuliBold fs-20">
                    <span className="fs-20 clr-2 fw-600">- Matt Haig</span>
                  </div>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4">
                  <p className="fs-25 fw-400 clr-2 my-4">
                    {" "}
                    Expertise in prescribing individualized exercise programs,
                    which can improve mood, promote wellbeing and address
                    co-morbidities associated with mental health diagnoses.
                    Interventions to address physical issues of people with
                    mental health diagnoses which hinder social participation
                    and recovery, eg. minimising or counteracting the
                    side-effects of some psychotropic medications. Expertise in
                    motivating, where appropriate, patients and promoting
                    self-management in the context of mental and physical health
                    issues. Management of falls and mobility issues for older
                    people and developmental issues for children and young
                    people.
                  </p>
                </div>
                <div className="ToolsEnabled MuliLight fs-25 mt-4"></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
                <p className="fs-25 clr-2">
                  Exercise Provides Numerous Psychological Benefits{" "}
                </p>
                <p className="fs-25 clr-2">
                  {" "}
                  Sense of Achievement - Meeting exercise targets/goals, no
                  matter how small, will boost self-confidence. Exercise can
                  help people to get into shape and look good, which in turns
                  leads to positive self-esteem.Distraction - Exercising is a
                  distraction from any worries you may be encountering and will
                  take you away from any negative thoughts. Social Benefits -
                  Exercise gives you the chance to play with a team or against
                  other people so you can get out of the house/office etc and
                  have some fun with people. Even going for a walk might give
                  you the opportunity to see the neighbours, friends etc. Coping
                  in Positive Way - With Depression & Anxiety, doing something
                  positive such as exercising becomes a coping strategy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Five ||---------------> */}
      <section className="PhysioExtroSubtitle py-5" id="ExtroSubtitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">
                  Ways that exercise can help with depression
                </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="fs-25 fw-400 clr-2 my-4">
                  Feel - Good Brain Chemicals - These chemicals are released
                  during exercise, and these neurotransmitters and endorphins
                  may help to reduce depression. Reduction of immune system
                  chemicals - The chemicals can make depression worse, Sleep -
                  Regular exercise will help to improve sleep patterns which may
                  be affected by Depression, Increasing Body Temperature - Which
                  may have calming effects.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/iStock-1186671110.png"
                  alt="image6"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Five ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock_000064794819_Large.png"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Healthy living</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        > */}
                          <p className="mb-0 fs-34">
                            Get healed while your at work
                          </p>
                          {/* </Link> */}
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/Believe-you-can-2sm.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Your life choices
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">Exercise for mind health</p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-11477859.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy Technology
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-1180916548 copy.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Elderly Health
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Recover better at the comfort of home
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_7788.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Health Science
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Analyze your movement in multiple dimensions
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_5227.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Health centers
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A comprehensive centre for cerebral palsy
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/iStock_000022998473_Large.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">
                                Tips & Healthy Tricks
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bMentalHealth"
                                className="d-inline-block clr-2 h-100"
                              >
                                {/* <Link
                                href="b_mental_health.html"
                                className="d-inline-block clr-2 h-100"
                              > */}
                                <p className="mb-0 fs-34">
                                  Best exercises in pregnancy
                                </p>
                                {/* </Link> */}
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy life article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bMentalHealth;
