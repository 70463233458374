import React from "react";
import { Link } from "react-router-dom";
function proDifferenceSection() {
  return (
    <>
      <section
        className="w-100 clearfix ProDifference bg-lightpink py-5 CounterSection"
        id="ProDiffrenceSection"
      >
        <div className="container-fluid">
          <div className="container">
            <div className="DiffrenceProX w-100 clearfix py-md-5">
              <div className="row">
                <div className="col-lg-6">
                  <div className="BeterImgsTexts2 w-100 clearfix Pro4Xs">
                    <h2 className="fs-72 fw-700 mb-4">
                      The ProPhysio <br className="d-none d-lg-block" />
                      Difference
                    </h2>
                    <p className="fs-22 mb-2 MuliLight">
                      Therapy at ProPhysio is built on our fundamentals of a
                      progressive health experience. This means when a therapist
                      assesses and understands the condition they go back to a
                      robust research wing and analyse the status in all the
                      possible directions by consulting the team of peers and
                      researchers who help devise a care plan that can be highly
                      effective in treating the patient. In one way if we look
                      at it every patient has almost a team of 10 working at the
                      backend to support the care delivered which means five
                      fold faster recovery, and ten times more probability for
                      you to have an effective outcome based on your personal
                      target.
                    </p>
                    <div className="LearnHow w-100 clearfix fw-600 pt-5 HoverArrow">
                      <Link
                        to="service"
                        className="d-inline-block clr-1 fs-24 HoverImgChnge"
                      >
                        Explore all services{" "}
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 float-lg-right">
                  <div className="MoreCare4x w-100 clearifx">
                    <div className="row pl-lg-5 ml-lg-5">
                      <div className="col-6">
                        <div className="More10XCare w-100 clearfix py-3">
                          <div className="TenXText w-100 clearfix">
                            <h4 className="fs-84 ls--5 clr-6">
                              <span className="spanCounter" data-n="10">
                                10
                              </span>
                              x
                            </h4>
                          </div>
                          <div className="TenXTextTwo w-100 clearfix">
                            <p className="ls-1 fs-24 fw-600">
                              More <br />
                              Effectiveness
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="More10XCare w-100 clearfix py-3">
                          <div className="TenXText w-100 clearfix">
                            <h4 className="fs-84 ls--5 clr-6">
                              <span className="spanCounter" data-n="5">
                                5
                              </span>
                              x
                            </h4>
                          </div>
                          <div className="TenXTextTwo w-100 clearfix">
                            <p className="ls-1 fs-24 fw-600">
                              Faster
                              <br /> Recovery
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="More10XCare w-100 clearfix py-3">
                          <div className="TenXText w-100 clearfix">
                            <h4 className="fs-84 ls--5 clr-6">
                              <span className="spanCounter" data-n="4">
                                4
                              </span>
                              x
                            </h4>
                          </div>
                          <div className="TenXTextTwo w-100 clearfix">
                            <p className="ls-1 fs-24 fw-600">
                              More <br />
                              Compliance
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="More10XCare w-100 clearfix py-3">
                          <div className="TenXText w-100 clearfix">
                            <h4 className="fs-84 ls--5 clr-6">
                              <span className="spanCounter" data-n="100">
                                100
                              </span>
                              %
                            </h4>
                          </div>
                          <div className="TenXTextTwo w-100 clearfix">
                            <p className="ls-1 fs-24 fw-600">Care</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default proDifferenceSection;
