import React from "react";
import { Link } from "react-router-dom";

function bMoveMore() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">Move More</h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Manual Therapy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">
                  {" "}
                  Value based health
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Physiotherapy</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/IMG_6025.jpeg"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">
                Do you want to feel better, move better and sleep better? Find
                opportunities to be active throughout your day. Every minute
                counts.
              </h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                Walk to the supermarket, Rake some leaves, Get on a bike, Dance!
                Choose what works for you and what’s fun!
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-5 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/P1013800.jpg"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-7 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">
                  Walking is simple, inexpensive and may be a great way to
                  start. Which of these will you do in the next two weeks?
                </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  1. Roll out of bed and take a 20-minute walk before the day
                  gets going and you talk yourself out of it.
                </p>
                <p className="clr-2 my-4">
                  2. Meet a friend at your local shopping mall for an early
                  morning walk. Malls often open early to support local walkers.
                </p>
                <p className="clr-2 my-4">
                  3. Volunteer to walk your dog every day. Don’t have a dog?
                  Walk your neighbor’s pet.
                </p>
                <p className="clr-2 my-4">
                  4. Try a smart phone walking app or count your steps with an
                  activity tracker. Gradually build up to 7,000-9,000 steps/day.
                </p>
                <p className="clr-2 my-4">
                  5. Join a local fitness program to get guidance and
                  motivation.
                </p>
                <p className="clr-2 my-4">
                  6. Take a walk with your family every night after dinner.
                </p>
                <p className="clr-2 my-4">
                  7. Go for a 10- to15-minute walk at lunchtime and bring a
                  friend.
                </p>
                <p className="clr-2 my-4">
                  8. Start a neighborhood walking group – with at least two
                  other neighbors. Swap child care if needed.
                </p>
                <p className="clr-2 my-4">
                  10. Walk or ride to work or the store 1-2 times/ week.Try an
                  e-bike: they’re easier, faster and fun!
                </p>
                <p className="clr-2 my-4">
                  11. Find beautiful places in your community to walk or bike:
                  local parks, trails, streets with trees or interesting
                  buildings. Become an explorer.
                </p>
                <p className="clr-2 my-4">
                  12. When watching TV, get up during every commercial and do an
                  active chore. For example, empty the dishwasher, throw some
                  clothes in the laundry, or take out the garbage. Feel
                  productive after just one show!
                </p>
                <p className="clr-2 my-4">
                  13. When sitting at your desk or computer, get up every 30
                  minutes or so and stretch. Walk down the hallway. A smartphone
                  Stand Up Reminder app can help.
                </p>
                <p className="clr-2 my-4">
                  14. Try “walking meetings” when you don’t need a pen and
                  paper.
                </p>
                <p className="clr-2 my-4">
                  15. Instead of calling or instant messaging your co-workers,
                  get up and go to their desk for a chat
                </p>
                <p className="clr-2 my-4">
                  16. Get off the bus one stop early and walk the rest of the
                  way. Wear supportive and comfortable shoes so this is easy to
                  do
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!-- <section className="PhysioDescripVideo" id="DescripVideo">
      <div className="container">
        <div className="PhysioBlogVideo">
          <img src="/images/img94.png" alt="blog banner Two" className="img-fluid w-100">
          <button className="play_btn"><i className="fa fa-play-circle" aria-hidden="true"></i></button>
        </div>
        <div className="DescriptionVideo text-center MuliBold my-3">
          <p className="fs-25 clr-2">Title or description of the video, source, publish date and other information etc.</p>
        </div>
      </div>
      </section> --> */}
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!---------------|| Section Four ||---------------> */}
      <section
        className="PhysioDiagnosticTools py-md-5 pt-5"
        id="DiagnosticTools"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="PhysioDiagnosticBuddha ">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <div className="PhysioDiagBuddhaContant text-center">
                  <div className="PhysioDiagBuddhaPara fs-30">
                    <p className="clr-darkgray fs-30">
                      Start where you are. Use what you have. Do what you can.{" "}
                    </p>
                  </div>
                  <div className="PhysioDiagName text-center MuliBold fs-20">
                    <span className="fs-20 clr-2 fw-600"></span>
                  </div>
                </div>
                {/* <!-- <div className="ToolsEnabled MuliLight fs-25 mt-4">
                <p className="fs-25 clr-2">Through objective diagnostic tools enabled with bio feedback tools that help us
                  understand what are the aspects that can be worked .</p>
                <p className="fs-25 clr-2"> Healing involves carefully analysing the health of the individual through
                  objective diagnostic tools enabled with bio feedback</p>
                </div> --> */}
              </div>
            </div>
            {/* <!-- <div className="col-md-6">
            <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
              <p className="fs-25 fw-400 clr-2 my-4">Healing involves carefully analysing the health of the individual through
                objective <span className="clr_dark_blue fw-600">diagnostic</span> tools enabled with bio feedback tools that
                help us understand what are the aspects that can be worked on to improve health. We use modern diagnostic
                and therapeutic tools to <span className="clr_dark_blue fw-600"> effective </span>heal our clients. </p>
              <p className="fs-25 fw-400 clr-2 my-4">Healing involves carefully analysing the health of the individual through
                objective diagnostic tools enabled with bio feedback tools that help us understand what are the aspects
                that can be worked .</p>
              <p className="fs-25 fw-400 clr-2 my-4"> Healing involves carefully analysing the health of the individual
                through objective diagnostic tools enabled with bio feedback</p>
            </div> --> */}
          </div>
        </div>
        {/* </div> */}
      </section>
      {/* <!---------------|| Section Four ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock-1215198746.jpg"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Mental Health</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        > */}
                          <p className="mb-0 fs-34">
                            Your Mental Health is what matters to your kids!
                          </p>
                          {/* </Link> */}
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_6025.jpeg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">Move More</p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Immunity Tips
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost your immunity with these simple tips and
                                tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/P1013575.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Mental Health
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise the ultimate pill to kill stress and
                                anxiety Forever.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/8A0555.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Wearable Device
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How this wearable device can help you manage
                                your stress effortlessly?
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A0280.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                The science of running is an art.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/mental_health2.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">Child Health</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bChildNeed"
                                className="d-inline-block clr-2 h-100"
                              >
                                {/* <Link
                                href="b_child_need.html"
                                className="d-inline-block clr-2 h-100"
                              > */}
                                <p className="mb-0 fs-34">
                                  My child's needs are important - virtually or
                                  otherwise!
                                </p>
                                {/* </Link> */}
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        {/* <Link href="b_adhd.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/adhd.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                ADHD and Autism
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How is ADHD and Autism different or are they the
                                same as each other?{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bMoveMore;
