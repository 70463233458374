import React from "react";
import { Link } from "react-router-dom";

function hospital() {
  // Menu button selection
  if (window.screen.width < 768) window.$(".cross_btn").trigger("click");

  function initiateCarousel() {
    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section
        className="w-100 clearfix PhysioServicesTopBanner bg-grad-2 HospitalBanner HospitalBanner_sec"
        id="HospitalBanner"
      >
        <div className="PhysioGradientBackground position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-5 position-static order-2">
                <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                  <img src="/images/39.png" className="img-fluid" />
                </div>
              </div>
              <div className="col-md-7 order-1">
                <div className="BeliveTexts w-100 clearfix d-table h-100 stepexperience py-xl-5 py-md-0 py-4">
                  <div className="d-table-cell align-middle">
                    <div className="backbtn ">
                      <Link to="/" className="text-decoration-none">
                        <img src="/images/icons/icon23.png" />
                        <span className="fs-24 clr-1 fw-600">Back to Home</span>
                      </Link>
                      {/* <Link to="index.html" className="text-decoration-none">
                            <img src="/images/icons/icon23.png"/>
                            <span className="fs-24  clr-1 fw-600">Back to Home</span>
                        </Link> */}
                    </div>
                    <div className="MainTest1 w-100 pt-4">
                      <h2 className="fs-72 fw-700 ">
                        Partner with us and <br />
                        extend your offerings
                      </h2>
                    </div>
                    <div className="OtherText1 w-100 py-4 pr-5">
                      <p className="MuliLight fs-28 ls-1 m-0">
                        With ease expand your services instantly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section-2 ||---------------> */}
      <section
        className="clearfix w-100 prophysio py-5 my-lg-5 WhatIDoNow"
        id="Physioprosection"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-72 fw-700">The ProPhysio assurance</h1>
              </div>
              <div className="OtherText1 w-100 py-3">
                <p className="MuliLight fs-22 ls-1">
                  Packed with advantages for therapists as part of the community
                </p>
              </div>
            </div>
          </div>
          <div className="assurance assurance_contant">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/27.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Trained & licensed experts
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      ProPhysio’s easy plug and play model works simply and
                      effectively. Your hospital provides the space and we set
                      up interiors, Co-branding, Install equipment, integrated
                      technology & deploy medical professionals. All this at no
                      cost to the hospital/medical centre/wellness centre
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/12.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">Active community</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We only set up the highest quality of state of the art
                      equipment and facilities across all our partners. Follow
                      this link (http//www.dinofdffnd.com) on our blog page and
                      browse thru our catalogue of centres.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/13.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Leverage partner ecosystem
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Quality of care comes from various parameters that we look
                      at, from the way the clinician interacts with the patient,
                      assessment techniques, evidence based practice, clinical
                      documentation, upkeep of records and follow up. All these
                      play a role in delivering quality of care. And our
                      philosophy is to deliver care with empathy, integrity,
                      compassion and authenticity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/14.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Data driven healthcare
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      The proof is in the pudding. And for us the quality is in
                      the care delivered. Therefore we continually train and
                      develop SOPs, protocols and clinical pathways that
                      continually improve the quality of care delivered. Our
                      team is continually engaged with domain experts from India
                      and overseas to educate on the latest trends.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/11.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Stay organised & grow client base
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Running a hospital involves managing so many activities
                      and outreach programs that help deliver care to a larger
                      audience and care for more patients. We participate in
                      this pursuit by engaging with you in media, promotions,
                      organising learning sessions, seminars, conferences &
                      health awareness programs to enhance the reach of your
                      hospital.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5">
                <div className="trainedexpert pt-lg-5">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoImg1 d-inline-block">
                      <img
                        src="/images/icons/15.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="InfoText1 d-inline-block">
                      <p className="fw-600 fs-42 ls-1 mb-0">
                        Extend services beyond hospital
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Therapy can be delivered to a wide range of patients
                      across every single specialty within a hospital. From
                      cardiac to geraitric, from paediatric to sports, from
                      womens health to intensive care therapy plays a vital role
                      in care delivered. Hospitals with understanding and
                      delivering the right therapy can improve patient outcomes,
                      increase retention and customer loyalty, enhance follow up
                      care and add value to the entire healthcare ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-2 ||---------------> */}
      {/* <!---------------|| Section-3 ||---------------> */}
      <section
        className="w-100 clearfix BodyBetter mobile_body approach py-md-5 pb-5 LeadLifes mb-lg-5 HereAppIsNow ChangeAppThats"
        id="ChangeAppThats"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6">
                <div className="BeterImgs w-100 clearfix">
                  <img
                    src="/images/img79.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="BeterImgsTexts12 w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4">
                      Change the way you approach healthcare
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Health to us means the perfect balance of the body which
                      helps you move, mind that makes you think and spirit that
                      enables you to feel. Our programs impact you and enable
                      you to live a perfect balanced life built on these
                      foundation stones.
                    </p>
                    <div className="w-100 clearfix BodyNutrition pt-3">
                      <ul className="w-100 list-unstyled MindBodyList mb-3 pb-sm-0 pb-3">
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon11.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Body</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon12.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Mind</p>
                            </div>
                          </div>
                        </li>
                        <li className="d-inline-block pr-xl-5">
                          <div className="NutriDites w-100">
                            <div className="NutriImg d-inline-block pr-3">
                              <img
                                src="/images/icons/icon13.png"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </div>
                            <div className="NutriImgText d-inline-block align-bottom">
                              <p className="fs-24 fw-600 m-0">Nutrition</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-5 pb-4">
                      <Link
                        to="prophysioWork"
                        className="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Learn more
                      </Link>
                      {/* <Link to="learn_prophysio_work.html" className="btn BtnBook fs-20 fw-500 px-4">Learn more</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>

      {/* <!---------------|| Section-3 ||--------------->
        <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix hospital_dr bg-lightgray doctorchangeing SeeForDtrYou YouWithUS"
        id="YouWithUS"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-6 order-2">
                <div className="BeterImgs w-100 clearfix singaldoctor text-lg-left text-center">
                  <img
                    src="/images/img55.png"
                    className="img-fluid mt-0"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-6 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <h2 className="fs-72 fw-700 mb-4">
                      Interested in partnering with us?
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Explore a range of tools that you can use as a healthcare
                      practitioner to elevate and optimise your practice.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-xl-5 pb-4">
                      <Link
                        className="btn BtnBook fs-20 fw-500 px-4"
                        data-toggle="modal"
                        data-target="#PartneringWithUS"
                      >
                        Send an enquiry
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||--------------->
        <!---------------|| Our Partners Section ||---------------> */}
      <section className="w-100 clearfix ourpartner py-5" id="Hospitalpartners">
        <div className="container py-lg-5">
          <div className="OurPartnersImg w-100 clearfix">
            <div className="HeadingDiv w-100 clearfix text-center">
              <h2 className="fs-72 lh-80 fw-700">Our partners in Healthcare</h2>
              <p className="fs-22 MuliLight pt-2 mb-4">
                We are proud of our associations with esteemed institutions of
                great repute. Our endeavor to care for patients and bring out
                the best outcomes.
              </p>
            </div>
            <div className="healthcarepart">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo1.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo2.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo3.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo4.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo5.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo6.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo7.png" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-6">
                  <div className="healthimg py-lg-5 py-4">
                    <img src="/images/logos/logo8.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Our Partners Section ||--------------->
        <!---------------|| Section-6 ||---------------> */}
      <section
        className="w-100 clearfix bg-grad-3 doctorchangeing moblie_hospital"
        id="Doctorchangeingsection"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-5 order-2">
                <div className="BeterImgs w-100 clearfix align-bottom singaldoctor text-lg-left text-center pt-5">
                  <img
                    src="/images/img69.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-7 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <p className="fs-54 mb-sm-4 fw-500 lh-52">
                      Truly amazing and life changing, many of our post surgery
                      patients have shown speedy recovery thanks to the flawless
                      service from ProPhysio
                    </p>
                    <div className="w-100 clearfix mt-5">
                      <p className="fs-30 fw-700 m-0">Harish Puranik</p>
                      <p className="fs-30 fw-700 m-0">Sr Orthopaedic Surgeon</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6 ||--------------->
        <!---------------|| Section-6 ||---------------> */}
      <section
        className="w-100 clearfix withcontact hospital_sec py-5 ReadyToWithUs"
        id="ReadyToWithUs"
      >
        <div className="container-fluid">
          <div className="HeadingDiv w-100 clearfix text-center my-md-0 my-md-4 mb-3">
            <h2 className="fs-72 lh-80 fw-700">Ready to connect with us?</h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Join the Prophysio community and accelerate your professional
              growth
            </p>
          </div>
          <div className="client py-xl-5">
            <div className="row">
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img88.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Access more clients
                        <br className="d-md-block d-none" /> and truly care{" "}
                        <br className="d-md-block d-none" /> for them
                      </p>
                      <div className="pt-3">
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#AccessMoreClients"
                        >
                          Register now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-gray-1 w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img89.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Upgrade to a <br />
                        multidisciplinary
                        <br /> practice
                      </p>
                      <div className="pt-3">
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#UpgradePractice"
                        >
                          Join now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 m-auto bg_hospital py-lg-0 py-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/img90.png)" }}
                  >
                    <div className="trulyCare1 position-absolute w-75">
                      <p className="fs-45 mb-5 MuliSemiBold p-0">
                        Start a progressive
                        <br className="d-md-block d-none" /> health clinic
                      </p>
                      <div className="pt-3" style={{ marginTop: "77px" }}>
                        <Link
                          className=" px-3 d-inline-block fs-15 btn bg-white  br-24 fw-500 ls-1 clr-2"
                          data-toggle="modal"
                          data-target="#ProgressiveHealth"
                        >
                          Start now{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6 ||---------------></div> */}

      {/* <!---------------|| Back To Top Start ||-----------------> */}
      <div
        id="BackToTop"
        className="bg-blue position-fixed text-white fs-30 lh-30 text-center br-50"
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </div>
      {/* <!--whatsapp --> */}
      <a
        className="fixedButton"
        href="https://wa.me/918197378444"
        target="_blank"
      >
        <div className="roundedFixedBtn">
          <i
            className="fa fa-whatsapp"
            style={{ position: "relative", bottom: "3px" }}
          ></i>
        </div>
      </a>
      {/* <!---------------|| Back To Top End ||-----------------> */}
    </>
  );
}

export default hospital;
