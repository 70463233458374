import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FaqService from "../../../services/FaqService/FaqService";

function gotQuestions() {
  const FaqServe = new FaqService();
  const [showForm, setShowForm] = useState(false);
  const [slideClickData, setSlideClickData] = useState({});
  const [faqList, setFaqList] = useState([]);
  const [showMore, setShowMore] = useState({});
  const [search, setSearch] = useState({
    start: 0,
    perPage: 100,
    searchTxt: "",
    searchField: "",
  });

  useEffect(async () => {
    await faqListApi();
  }, []);

  const faqListApi = async () => {
    const activity = {
      screens: "home",
    };
    try {
      let response = await FaqServe.faqList(
        activity,
        search.start,
        search.perPage
      );
      if (response) {
        setFaqList(response.rows);
      }
    } catch (err) {
      throw err;
    }
  };

  function initiateCarousel() {
    /**********|| Custom Owl Js||**********/
    window.$("#WhatWeHaveOwl,#GotQuestionOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  const clickHandle = (index, data) => {
    console.log("index", index, data);
    setSlideClickData(data);
    setShowForm(!showForm);
  };
  const closeHandleForm = (i) => {
    setShowForm(false);
  };

  return (
    <>
      {/* <!---------------|| Got Questions Section ||---------------> */}
      <section
        className="w-100 clearfix GotQuestions py-5 bg-clr-3"
        id="QuestionSection"
      >
        <div className="container-fluid">
          <div className="GotQueDetails w-100 clearfix py-xl-5">
            <div className="HeadingDiv w-100 clearfix text-center ">
              <h2 className="fs-72 lh-80 fw-700">Got questions?</h2>
              <p className="fs-22 MuliLight pt-4">
                No worries, we’ve got you covered
              </p>
            </div>
            {faqList?.length && (
              <div
                className="GotQueSlider w-100 clearfix pt-5"
                id="AllOwlSliders_part"
              >
                <div
                  className="owl-carousel owl-theme OwlSliderOne AllOwlSliders"
                  id="GotQuestionOwl"
                >
                  {faqList.map((v, i) => {
                    return (
                      <div
                        className="physiotherapist py-4 px-5 mx-2 bg-white"
                        data-toggle="modal"
                        data-target="#myModal"
                        key={i}
                      >
                        <div className="phyheading position-relative pb-2 MuliItalic">
                          <p
                            className="fs-34 fw-500"
                            onClick={() => clickHandle(i, v)}
                          >
                            {v.question}
                          </p>
                        </div>
                        <div className="peoplebtn pt-3">
                          <Link className="clr-2 fw-500">
                            {v.people_asked || 0} people asked
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="LearnHow w-100 clearfix fw-600 text-center pt-5 pb-md-3 HoverArrow">
              {/* <Link href="FAQ.html" className="d-inline-block clr-1 fs-24 HoverImgChnge">Explore all FAQ’s <img
                          src="/images/icons/icon5.png" className="img-fluid"/></Link> */}
              <Link
                to="faq"
                className="d-inline-block clr-1 fs-24 HoverImgChnge"
              >
                Explore all FAQ’s{" "}
                <img src="/images/icons/icon5.png" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Got Questions Section ||---------------> */}
      {showForm ? (
        <div
          id="myModal"
          className={`modal fade ${showForm ? "show" : ""}`}
          role={showForm ? "dialog" : ""}
          style={showForm ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog">
            {/* {faqList.map((v, i) => { */}
            {/* return  */}
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-24 fw-500">
                  {slideClickData.question}
                </h4>
              </div>
              <div className="modal-body fs-14 ">
                <p>{slideClickData.answer}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={closeHandleForm}
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
            {/* })} */}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default gotQuestions;
