import React from "react";
import { Link } from "react-router-dom";

function bRunning() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">The science of running defined</h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Kinesiology</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Biomechanics</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Physiotherapy</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/9L1A7169.jpg"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700">Analyse your movement in 3D</h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                Running is one of the world’s most popular forms of exercise,
                with millions of regular participants. Although running is an
                effective way to achieve many health benefits, it is associated
                with a high risk of injury. Some injuries are traumatic, but
                most are due to overuse. Research suggests that up to 70% of
                recreational and competitive runners sustain overuse injuries
                during any 12 months period. 42% of all running injuries are to
                the knee, followed by 17% to the foot and ankle, 13% to the
                lower leg and 11% to the hip and pelvis.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/Website.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">When running is a science</h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  {" "}
                  Common running injuries include: Patello-femoral pain
                  syndrome, Shin splints, Achilles tendinopathy, iIlio-tibial
                  band syndrome, plantar fasciitis, Hamstring strain, Groin
                  strain, Back pain. Running Analysis involves recording
                  runner’s movement patterns through 3D motion capture cameras
                  while they walk and run on treadmill, along with detailed
                  running injury history, running characteristics, physical
                  examination, various specialized tests and 2D Video Analysis.
                  The information from the 3D Analysis is combined with runner’s
                  physical examination data and 2D video analysis provides a
                  comprehensive analysis of runner’s walking and running
                  mechanics
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioDescripVideo" id="DescripVideo">
        <div className="container">
          <div className="PhysioBlogVideo">
            <img
              src="/images/blog/IMG_7788.jpg"
              alt="blog banner Two"
              className="img-fluid w-100"
            />
            <button className="play_btn">
              <i className="fa fa-play-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div className="DescriptionVideo text-center MuliBold my-3">
            <p className="fs-25 clr-2">
              The movement analysis lab @ Rainbow Children’s Hospital,
              Marthahalli
            </p>
          </div>
        </div>
      </section>
      <section
        className="PhysioDiagnosticTools py-md-5 pt-5"
        id="DiagnosticTools"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="PhysioDiagnosticBuddha ">
                <i className="fa fa-quote-left" aria-hidden="true"></i>
                <div className="PhysioDiagBuddhaContant text-center">
                  <div className="PhysioDiagBuddhaPara fs-30">
                    <p className="clr-darkgray fs-30">
                      “To keep the body in good health is a duty… otherwise we
                      shall not be able to keep our mind strong and clear.”{" "}
                    </p>
                  </div>
                  <div className="PhysioDiagName text-center MuliBold fs-20">
                    <span className="fs-20 clr-2 fw-600">- Buddha</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
                <p className="fs-25 fw-400 clr-2 my-4">
                  The information from the 3D Analysis is combined with runner’s
                  physical examination data and 2D video analysis provides a
                  comprehensive analysis of runner’s walking and running
                  mechanics.This includes measuring your Injury Index Our unique
                  diagnostic tool precisely identifies what you chances of
                  injury are based on the way you walk or run,{" "}
                </p>
                <p className="fs-25 fw-400 clr-2 my-4">
                  Muscular & Joint Velocity which also helps identify areas of
                  muscular strength, flexibility and strain so we can identify
                  the muscle and create a more precise and effective exercise
                  program that suits you specifically
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="ToolsEnabled MuliLight fs-25 mt-4">
                <p className="fs-25 clr-2">
                  Through objective diagnostic tools enabled with bio feedback
                  tools that help us understand what are the aspects that can be
                  worked .{" "}
                </p>
                <p className="fs-25 clr-2">
                  Healing involves carefully analysing the health of the
                  individual through objective diagnostic tools enabled with bio
                  feedback
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">A multifold approach</h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  Fitness Testing performed a detailed physical and
                  cardiovascular assessment for all the individuals getting
                  tested. The cardiac, pulmonary function, the fitness testing
                  with physical testing and the movement analysis on the
                  treadmill gives the most accurate information that one needs
                  to further perform better in exercise.
                </p>
              </div>
            </div>
            <div className="col-md-6 pr-xl-5">
              <div className="PhysioSectionImages blog_services_img">
                <img
                  src="/images/blog/iStock_000083087403_Double.png"
                  alt="image6"
                  className="img-fluid br-24"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Five ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/Believe-you-can-2.png"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Living Pro Life</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          Exercise for mind health
                        </Link>
                        {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        >
                          <p className="mb-0 fs-34">Exercise for mind health</p>
                        </Link> */}
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/C8A0666.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                A wearable device to manage stress
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A7169.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy life article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Healthy diet article
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost immunity with simple tips & tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            {/* <!-- Image full width not working --> */}
                            <img
                              src="/images/blog/4.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Body</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise to beat stress and anxiety
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-505229769.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                The enrichment program
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How important is life skills for kids?
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/P1013268.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">
                                Exercise plans & Staying active
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                href="/images/blog/P1013268.png"
                                className="d-inline-block clr-2 h-100"
                              >
                                <p className="mb-0 fs-34">
                                  Boost your immunity
                                </p>
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        {/* <Link href="b_adhd.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/iStock-1215198746.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Your Mind</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Your Mental Health is what matters to kids!
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bRunning;
