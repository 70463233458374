import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BookNowContext } from "../../../pages/BookNowContext/BookNowContext";

function findCareSection() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  const bookNowHandle = (i) => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  //   useEffect(() => {
  //     document.body.style.overflow = "hidden";
  //     return () => document.body.style.overflow = 'unset';
  // }, [])

  return (
    <>
      {/* <!---------------|| Find Care Section ||---------------> */}
      {/* section changing to div below  */}
      <section
        className="w-100 clearfix FindCare py-5 bg-clr-1"
        id="FindTheCare"
      >
        <div className="container-fluid">
          <div className="NeedTextData w-100 clearfix py-xl-4">
            <div className="HeadingDiv w-100 clearfix text-center ">
              <h2 className="fs-72 lh-80 fw-700">Find the care you need</h2>
              <p className="fs-22 MuliLight pt-4">
                Consult top doctors for any health concern. Find experienced{" "}
                <br />
                doctors across all specialties
              </p>
            </div>
            <div className="CareSlider w-100 clearfix pt-5 pb-3">
              <div
                className="owl-carousel owl-theme OwlSliderOne TopNeedCares Therapyowlslider "
                id="TopCareNeed"
              >
                <div
                  className="PhysioHealth PhysioHealth1 w-100 clearfix position-relative"
                  data-toggle="tooltip"
                  title="Whether its you recovering from surgery or its managing those nagging pains. Physiotherapy is the right solution for you."
                >
                  <div className="HealthCare bg-clr-2 w-100 clearfix p-3 position-relative">
                    <div className="CareImge position-absolute">
                      <img
                        src="/images/2.png"
                        className="img-fluid"
                        alt="No Image"
                      />
                    </div>
                    <div className="HelthoCare1 position-absolute w-50">
                      {/* <!-- <p className="fs-28 fw-600 mb-0">Physical Health</p> --> */}
                      <p className="fs-57 MuliBold lh-62">
                        Physical <br /> Health
                      </p>
                      <Link
                        className="d-inline-block fs-20 fw-500 ls-1 clr-1 HoverImgChnge BtnImg1"
                        onClick={() => bookNowHandle(0)}
                      >
                        Book now
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="PhysioHealth PhysioHealth2 w-100 clearfix position-relative"
                  data-toggle="tooltip"
                  title="Your child is the most precious thing you have, and for us too. Our child focused therapies involve a combination of physiotherapy, speech and special education."
                >
                  <div className="HealthCare  bg-pink w-100 clearfix p-3">
                    <div className="CareImge position-absolute">
                      <img
                        src="/images/3.png"
                        className="img-fluid"
                        alt="No Image"
                      />
                    </div>
                    <div className="HelthoCare1 position-absolute w-50 HoverArrow">
                      {/* <!-- <p className="fs-28 fw-600 mb-0">Kids Health</p> --> */}
                      <p className="fs-57 MuliBold lh-62">
                        Kids <br />
                        Health
                      </p>
                      <Link
                        className="d-inline-block fs-20 fw-500 ls-1 clr-1 HoverImgChnge BtnImg1"
                        onClick={() => bookNowHandle(1)}
                      >
                        Book now
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="PhysioHealth PhysioHealth3 w-100 clearfix position-relative"
                  data-toggle="tooltip"
                  title="Can I sleep well? Feeling tired? Hair fall? It's all an impact of your lifestyle. Get into the Urban Health program that can take you from exhausted to super charged."
                >
                  <div className="HealthCare bg-blue_clr w-100 clearfix p-3">
                    <div className="CareImge position-absolute">
                      <img
                        src="/images/4.png"
                        className="img-fluid"
                        alt="No Image"
                      />
                    </div>
                    <div className="HelthoCare1 position-absolute w-50 HoverArrow">
                      {/* <!-- <p className="fs-28 fw-600 mb-0">Mental Health</p> --> */}
                      <p className="fs-57 MuliBold lh-62">
                        Urban <br />
                        Health
                      </p>
                      <Link
                        className="d-inline-block fs-20 fw-500 ls-1 clr-1 HoverImgChnge BtnImg1"
                        onClick={() => bookNowHandle(2)}
                      >
                        Book now
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="PhysioHealth PhysioHealth3 w-100 clearfix position-relative"
                  data-toggle="tooltip"
                  title="7 out of 10 people today have mental issues and it's up to us to manage them before they start affecting our function. Reach out to know more."
                >
                  <div className="HealthCare bg-green_clr w-100 clearfix p-3">
                    <div className="CareImge position-absolute">
                      <img
                        src="/images/5.png"
                        className="img-fluid"
                        alt="Psychology, Counselling, Developmental Learning"
                      />
                    </div>
                    <div className="HelthoCare1 position-absolute w-50 HoverArrow">
                      <p className="fs-57 MuliBold lh-62">
                        Mental <br />
                        Health
                      </p>
                      <Link
                        className="d-inline-block fs-20 fw-500 ls-1 clr-1 HoverImgChnge BtnImg1"
                        onClick={() => bookNowHandle(3)}
                      >
                        Book now
                        <img
                          src="/images/icons/icon5.png"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="ExploreMoreNow w-100 clearfix text-center py-3">
            <h4 className="fs-28 clr-5 fw-600">Explore more of our services</h4>
            <div className="SpeechCare w-100 clearfix pt-5 pb-3 EderlyCare">
              <div
                className="d-inline-block SpeedyCol br-24 bg-white"
                data-toggle="tooltip"
                title="Speech therapy plays an important role in achieving overall communication, enhancing social skills."
              >
                <Link to="detailedElderlyService" className="d-block clr-2 p-3">
                  {/* <Link href="detailed_elderly_servies.htmljavascript:void(0);SpeechTherapySec" className="d-block clr-2 p-3"> */}
                  <div className="LivingImpro LivingSpeech w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon6.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right text-left">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Speech <br />
                        Therapy
                      </p>
                    </div>
                    {/* <!-- <div className="LivoImgTextDesc">
                                <p className="fw-400 fs-12">
                                  Speech therapy plays an important role in achieving overall communication, enhancing social skills.
                                </p>
                                </div> --> */}
                  </div>
                  {/* </Link> */}
                </Link>
              </div>
              <div
                className="d-inline-block SpeedyCol br-24 bg-white"
                data-toggle="tooltip"
                title="Women's Health focuses on antenatal care, post delivery care, exercise therapy for PCOS & PCOD, Post menopausal care."
              >
                <Link to="detailedElderlyService" className="d-block clr-2 p-3">
                  {/* <Link href="detailed_elderly_servies.htmljavascript:void(0);WomenHealthSec" className="d-block clr-2 p-3"> */}
                  <div className="LivingImpro LivingSpeech w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon7.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right text-left">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Women’s
                        <br /> Health
                      </p>
                    </div>
                  </div>
                  {/* </Link> */}
                </Link>
              </div>
              <div
                className="d-inline-block SpeedyCol br-24 bg-white"
                data-toggle="tooltip"
                title="Movement science of kinesiology is the science of understanding the way we move,run, walk, jump and play."
              >
                <Link to="detailedElderlyService" className="d-block clr-2 p-3">
                  {/* <Link href="detailed_elderly_servies.htmljavascript:void(0);MovementScienceSec" className="d-block clr-2  p-3"> */}
                  <div className="LivingImpro LivingSpeech w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon8.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right text-left">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Movement <br />
                        Science
                      </p>
                    </div>
                  </div>
                  {/* </Link> */}
                </Link>
              </div>
              <div
                className="d-inline-block SpeedyCol br-24 bg-white"
                data-toggle="tooltip"
                title="Our Special education program focuses on developmental education and inclusively in children across all spectrums."
              >
                <Link to="detailedElderlyService" className="d-block clr-2 p-3">
                  {/* <Link href="detailed_elderly_servies.htmljavascript:void(0);SpecialEducationSec" className="d-block clr-2 p-3"> */}
                  <div className="LivingImpro LivingSpeech w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon9.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right text-left">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Special <br />
                        Education
                      </p>
                    </div>
                  </div>
                  {/* </Link> */}
                </Link>
              </div>
              <div
                className="d-inline-block SpeedyCol br-24 bg-white"
                data-toggle="tooltip"
                title="Care for the elderly is our speciality with a wide range of integrated therapies to enhance the lives of those who brought us to life."
              >
                <Link to="detailedElderlyService" className="d-block clr-2 p-3">
                  {/* <Link href="detailed_elderly_servies.html" className="d-block clr-2 p-3"> */}
                  <div className="LivingImpro LivingSpeech w-100 clearfix">
                    <div className="LivoImg float-left">
                      <img
                        src="/images/icons/icon10.png"
                        className="img-fluid"
                        alt="No images"
                      />
                    </div>
                    <div className="LivoImgText float-right text-left">
                      <p className="fw-600 fs-24 ls-1 mb-0">
                        Ederly <br />
                        Care
                      </p>
                    </div>
                  </div>
                  {/* </Link> */}
                </Link>
              </div>
            </div>
            <div className="ExploBtn w-100 clearfix pt-lg-5 pb-lg-4 pt-2">
              <Link to="service" className="btn BtnBook fs-20 fw-500 px-4">
                Explore all Services
              </Link>
              {/* <Link href="services.html" className="btn BtnBook fs-20 fw-500 px-4">Explore all Services</Link> */}
            </div>
          </div>
        </div>
      </section>

      {/* // <!---------------|| Find Care Section ||--------------->*/}
    </>
  );
}

export default findCareSection;
