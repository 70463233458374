import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HowWeHelpedService from "../../../services/HowWeHelpedService/HowWeHelpedService";

function HowWeHelped() {
  const TestimonialServe = new HowWeHelpedService();
  const [testimonialList, setTestimonialList] = useState([]);
  const [search, setSearch] = useState({
    start: 0,
    perPage: 100,
    searchTxt: "",
    searchField: "",
  });

  useEffect(async () => {
    await testimonialApi();
  }, []);

  const testimonialApi = async () => {
    const activity = {};
    try {
      let response = await TestimonialServe.testimonialList(
        activity,
        search.start,
        search.perPage
      );
      if (response) {
        setTestimonialList(response.rows);
      }
    } catch (err) {
      throw err;
    }
  };

  function initiateCarousel() {
    window.$("#MeetExpertsOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
        1300: {
          items: 4,
          nav: false,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    window.$("#HelpedOwlSlider").owlCarousel({
      center: true,
      items: 2,
      loop: true,
      autoplay: true,
      autoplayTimeout: 15000,
      margin: 10,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
          dots: true,
          margin: 0,
        },
        768: {
          items: 1,
          nav: true,
          dots: false,
          margin: 0,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
        1200: {
          items: 2,
          nav: true,
          loop: true,
          dots: false,
        },
        1700: {
          items: 1.8,
          nav: true,
          loop: true,
          dots: false,
        },
      },
    });

    window
      .$(".HelpPeopleOwl .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g class="a" transform="translate(1747 10985)"><path class="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path class="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect class="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path class="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path class="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".HelpPeopleOwl .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path class="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect class="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path class="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path class="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    window.$("#WhatWeHaveOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:#007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g class="a" transform="translate(1747 10985)"><path class="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path class="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect class="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path class="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path class="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".Therapyowlslider .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:#007ce2;}.b,.c{fill:none;}.c{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path class="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect class="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path class="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path class="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );

    /**********|| Back To Top ||**********/
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop()) {
        window.$("#BackToTop").fadeIn();
      } else {
        window.$("#BackToTop").fadeOut();
      }
    });
    window.$("#BackToTop").click(function () {
      window.$("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
    });

    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section nine ||---------------> */}
      <section
        className="py-5 my-lg-5 w-100 clearfix HowHelped how_help_main section_nine"
        id="helpedPeople"
      >
        <div className="container">
          <div className="HeadingDiv w-100 clearfix my-md-0 my-5">
            <h2 className="fs-72 lh-80 fw-700">How we’ve helped people</h2>
            <p
              className="fs-22 MuliLight pt-xl-2 w-100"
              style={{ maxWidth: "100%" }}
            >
              ProPhysio has transform peoples lifestyle to help lead lives on
              their own terms.
            </p>
          </div>
        </div>
        <div className="w-100 clearifx HowToHelpSlide">
          <div className="TherapyLabs w-100 clearfix position-relative">
            {testimonialList?.length && (
              <div
                className="owl-carousel owl-theme OwlSliderOne HelpPeopleOwl pt-4 Therapyowlslider"
                id="HelpedOwlSlider"
              >
                {testimonialList.map((v, i) => {
                  return (
                    <div className="therapy1" key={i}>
                      <div className="healthcoffee bg-light-blue br-10">
                        <div className="row m-0">
                          <div className="col-lg-5">
                            <div className="boyimg h-100 w-100 d-table">
                              <div className="sportshelp d-table-cell align-bottom">
                                <img src={v.image} className="w-100" />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 pt-5">
                            <div className="helpcontent h-100 w-100 d-table">
                              <div className="d-table-cell align-middle">
                                <div className="hlelptext mb-5 position-relative">
                                  <h1 className="fw-700 fs-28">
                                    {v.name}, {v.year}
                                  </h1>
                                </div>
                                <div className="lelpage MuliLight">
                                  <p className="fs-20 fw-600">
                                    {v.description}
                                  </p>
                                </div>
                                <div className="videowacthbtn my-5">
                                  <Link className="d-inline-block p-0">
                                    <img
                                      src="/images/btns/btn3.png"
                                      className="img-fluid Btn3 OnHover1"
                                    />
                                    <img
                                      src="/images/btns/btn3-1.png"
                                      className="img-fluid OnHover Btn3"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              id="counter"
              className="fs-12 MuliRegular clr-4 position-absolute counters SlideCount"
              style={{ bottom: "-33px" }}
            ></div>
            <div
              className="fs-24 pl-3 position-absolute LearnHow  fw-500 d-flex claim_btn HoverArrow"
              style={{ bottom: "-38px", left: "10%" }}
            >
              <Link
                className="d-inline-block clr-1 fs-24 HoverImgChnge BtnImg1"
                data-toggle="modal"
                data-target="#claimFreeModal"
              >
                Claim your free session now!
                <img src="/images/icons/icon5.png" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section nine ||---------------> */}
    </>
  );
}

export default HowWeHelped;
