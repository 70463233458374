import Util from "../../utils/Util";

export default class CallBackService{
    callbackFormApi(payload) {
        return Util.sendApiRequest("/enquiry/CallBack", "POST", true, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }
}