import React from "react";
import { Link } from "react-router-dom";

function homeBennerSection() {
  return (
    <>
      {/* <!---------------|| Home Banner Section ||---------------> */}
      <section
        className="w-100 clearfix HomeBanner HomeBanner_part"
        id="HomeBennerSection"
      >
        <div className="container-fluid">
          <div className="GardBgColor w-100 clearfix py-5 position-relative bg-grad-1 br-24 d-lg-table">
            <div className="containerNone PaddingSlot d-lg-table-cell align-middle pt-4">
              <div className="YouCanDetails w-100 clearfix">
                <div className="row">
                  <div className="col-md-7 position-static order-2">
                    <div className="BannerImg clearfix position-absolute">
                      <img
                        src="/images/1.png"
                        className="img-fluid DesktopImg"
                        alt="No Images"
                      />
                      <img
                        src="/images/1-1.png"
                        className="img-fluid MobileImg"
                        alt="No Images"
                      />
                    </div>
                  </div>
                  <div className="col-md-5 col-11 order-1">
                    <div className="BeliveTexts w-100 clearfix pt-3 beliveAlign">
                      <div className="MainTest1 w-100">
                        <h2 className="fs-120 fw-700 lh-98">
                          Believe,
                          <br /> you can.
                        </h2>
                      </div>
                      <div className="OtherText1 w-100 py-4">
                        <p className="MuliLight fs-28 ls--1 m-0">
                          Limitless possibilities is what life is all about!
                          Barriers are just what needs to be overcome to
                          experience life to the fullest.
                        </p>
                      </div>
                      <div className="videowacthbtn w-100 pt-2">
                        <Link className="BtnImg2 d-inline-block">
                          <img
                            src="/images/btns/btn2.png"
                            className="img-fluid"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Home Banner Section ||---------------> */}
    </>
  );
}

export default homeBennerSection;
