import React from "react";
import { Link } from "react-router-dom";

function takeYourPractice() {
  return (
    <>
      {/* <!---------------|| Section sevanth ||---------------> */}
      <section
        className="w-100 clearfix bg-lightgray comunity_main HealthBootcamp py-md-0 position-relative"
        id="HealthBoot"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix BodyHealthBoot">
            <div className="row">
              <div className="col-lg-7 order-2 position-static">
                <div className="d-table h-100 w-100 pt-5">
                  <div className="BeterImgs w-100 clearfix d-table-cell align-bottom text-center healthgirlimg">
                    <img
                      src="/images/img42.png"
                      className="img-fluid"
                      alt="No Images"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5 order-1">
                <div className="BeterImgsTexts w-100 clearfix d-table">
                  <div className="d-table-cell align-middle TextMiddle ">
                    <h2 className="fs-72 fw-700 mb-4 mt-sm-0 mt-3">
                      Take your practice to the next level
                    </h2>
                    <p className="fs-22 mb-4 MuliLight">
                      Have a startup idea in the health and wellness space?
                      Looking for support in terms of mentorship, investment and
                      marketing? Reach out to us with your idea and we’ll help
                      you take it to the next level.
                    </p>
                    <div className="ExploBtn DwnldApp w-100 clearfix pt-4 pb-4">
                      <Link
                        to="joinTheCommunity"
                        className="btn BtnBook fs-20 fw-500 px-4"
                      >
                        Join the ProPhysio Community
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default takeYourPractice;
