import React from "react";
import { Link } from "react-router-dom";
import TakeYourFirstStep from "../TakeYourFirstStep/takeYourFirstStep";
import ReadyToTransfer from "../ReadyToTransfer/readyToTransfer";
import BackToTop from "../BackToTop/backToTop";

function therapists_details() {
  function initiateCarousel() {
    window.$(".HoverImgChnge").hover(
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5-1.png");
      },
      function () {
        window.$(this).find("img").attr("src", "/images/icons/icon5.png");
      }
    );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section one ||---------------> */}
      <section
        className="w-100 clearfix py-5 therapists "
        id="Detailedtherapists"
      >
        <div className="container">
          <div className="therapists pb-2">
            <div className="row">
              <div className="col-12">
                <div className="backbtn">
                  <Link
                    to="therapistsExploare"
                    className="text-decoration-none"
                  >
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24">Back to all therapists</span>
                  </Link>
                  {/* <Link to="therapists_exploare.html" className="text-decoration-none">
                <img src="/images/icons/icon23.png"/>
                <span className="fs-24">Back to all therapists</span>
              </Link> */}
                </div>
              </div>
            </div>
            <div className="shruthidigde my-5">
              <div className="row">
                <div className="col-lg-4  col-md-8 mx-auto">
                  <div className="girlimg my-5 my-lg-0">
                    <img src="/images/img66.png" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="doctordata pl-sm-2 position-relative">
                    <div className="doctorname">
                      <h1 className="fw-600 mt-2">
                        <span className="w-100 d-inline-block float-left fs-60">
                          Shruthi Digde
                        </span>
                      </h1>
                    </div>
                    <div className="doctorsubject">
                      <p
                        className="fs-40 text-darkgray fw-500 mb-0"
                        style={{ opacity: "0.67" }}
                      >
                        Sr. Physiotherapist
                      </p>
                      <p className="fs-38 fw-600">
                        <span className="">8</span>{" "}
                        <span className="">yrs Exp.</span>
                      </p>
                    </div>
                    <div className="doctorthought">
                      <p className="fs-30  my-md-3 my-2 lh-30 clr_graylight MuliItalic">
                        “I strongly believe that miracles come from hard work
                        and persistence, a little effort is all it takes”
                      </p>
                    </div>
                    <div className="doctorpara">
                      <p className="fs-22 text-black my-3 MuliLight">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy. Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit
                        amet, consetetur sadipscing elitr, sed diam nonumy.
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy.Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit
                        amet, consetetur sadipscing elitr, sed diam nonumy.
                      </p>
                    </div>
                    <div className="doctorlocation pt-md-4">
                      <div className="location pt-3">
                        <div className="row">
                          <div className="col-md-8">
                            <ul className="nav">
                              <li className="nav-item">
                                <Link className="locationicon d-inline-block">
                                  <img src="/images/icons/location.svg" />
                                </Link>
                              </li>
                              <li className="nav-item pl-3">
                                <span className="fs-22 text-darkgray MuliLight">
                                  Lorem ipsum dolor sit amet, consetetur
                                  <br />
                                  sadipscing elitr, sed diam nonumy.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <div className="booksession text-md-right text-center">
                              <div className="more_show ">
                                <Link className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1">
                                  Book a session
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section two ||---------------> */}
      <section
        className="clearfix w-100 prophysio pb-5 aboutdoctors therpy_services TherpyDewtails1"
        id="Aboutdoctors"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-60 fw-600">About Shruthi Digde</h1>
              </div>
            </div>
          </div>
          <div className="assurance p-md-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Expertise</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Credentials</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Achievements</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        Previous Experience
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        What I love about my job
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        In my free time I like to
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      We continually update our knowledge and skill by
                      collaborating with experts in the field of health,
                      medicine and rehabilitation
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="more_show text-center pt-4">
                  <Link className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton ">
                    Claim your first free consultation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||---------------> */}
      {/* <!---------------|| Section thired ||---------------> */}
      <section
        className="w-100 clearfix my-lg-5 py-5 position-relative bg-lightgray mertherphy explore_sec_bg DetailedTherepistLists"
        id="Meetrherpysection"
      >
        <div className="container py-sm-5 mt-sm-2">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="exploreheading">
                <h1 className="fs-72 fw-700 pb-sm-0 pb-4">
                  Meet other therapists
                </h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="explorebtn ">
                <ul className="nav w-100 text-center text-md-right d-inline-block therapists_menu_list">
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter
                    >
                      All
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500  fs-20 text-black btn-outline-dark px-3"
                      data-filter="physiotherapists"
                    >
                      Physiotherapists
                    </Link>
                  </li>
                  <li className="nav-item d-inline-block px-2">
                    <Link
                      className="btn brownbtn bluebutton br-24 fw-500 fs-20 text-black btn-outline-dark px-3"
                      data-filter="locations"
                    >
                      All Locations
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="doctorslist">
            <div className="row">
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img62.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Shruthi Digde</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img63.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Raniya Sulthana</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img64.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Roma Pujari</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img65.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">SHAHUL HASHIR S</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="physiotherapists">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img62.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Shruthi Digde</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-xl-5" data-tags="locations">
                <div className="docdetail py-md-5 pt-4 therpy_services_box">
                  <div className="docimg">
                    <img src="/images/img63.png" className="w-100" />
                  </div>
                  <div className="docname mt-sm-4 mt-3">
                    <h1 className="fs-34 fw-700">Raniya Sulthana</h1>
                  </div>
                  <div className="docsubject ">
                    <p className="text-darkgray fs-22 MuliLight">
                      Sr. Therapist
                    </p>
                  </div>
                  <div className="doctext ">
                    <p className="text-black fs-22 MuliLight">
                      #Lorem ipsum, #dolor sit amet, <br /> #consetetur,
                      #sadipscing elitr, #sed
                    </p>
                  </div>
                  <div className="learbtn">
                    <Link
                      to="therapists_details"
                      className="rightarrow fs-24 fw-600 HoverImgChnge"
                    >
                      Learn more
                      <img src="/images/icons/icon5.png" />
                    </Link>
                    {/* <Link to="therapists_details.html" className="rightarrow fs-24 fw-600 HoverImgChnge">
                  Learn more
                  <img src="/images/icons/icon5.png"/>
                </Link> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
            <div className="showmore pt-5">
              <div className="more_show text-center ">
                  <Link to='therapists_details' className="show_btn btn bg-blue text-white fs-22 br-24 px-4 py-2 ">Show
                  More</Link>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section thired ||---------------> */}
      <TakeYourFirstStep />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}

export default therapists_details;
