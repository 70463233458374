import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
// import BookNowPopUp from "../BookNowPopUp/bookNowPopUp";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";

function meetTheExperts() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;

  const bookSessionHandle = () => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  function initiateCarousel() {
    /**********|| Custom Owl Js||**********/
    window.$("#MeetExpertsOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
        1300: {
          items: 4,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <BookNowPopUp/> */}
      {/* <!---------------|| Section-4 ||---------------> */}
      <section
        className="w-100 clearfix expertslider py-5 bg-clr-1"
        id="Expartslidersection"
      >
        <div className="container py-lg-5">
          <div className="row">
            <div className="col-md-5">
              <div className="expertcontent">
                <div className="BeliveTexts w-100 clearfix py-md-5">
                  <div className="MainTest1 w-100 pt-md-4">
                    <h2 className="fs-72 fw-700 ">
                      Meet the <br />
                      Physio Experts
                    </h2>
                  </div>
                  <div className="OtherText1 w-100 py-3">
                    <p className="MuliLight fs-22 ls-1 m-0">
                      Book your expert for a consultation at <br />
                      home, in the clinic or on tele
                    </p>
                    <div className="Aboutshortslider_title_menu tramneted_menu my-md-4">
                      <ul className="Aboutshortslider_title_list nav Aboutshortslider_title_list_menu">
                        <li className="nav-item">
                          {/* <Link
                            to="therapists_exploare.html"
                            className="clr-1 fs-22 nav-link"
                          >
                            Explore Therapists
                          </Link> */}
                          <Link
                            to="therapistsExploare"
                            className="clr-1 fs-22 nav-link"
                          >
                            Explore Therapists
                          </Link>
                        </li>
                        <li className="nav-item active">
                          {/* <Link to = 'bookNowPopUp' className="clr-1 fs-22 nav-link BtnImg1">Book a session
                          </Link> */}
                          <Link
                            className="clr-1 fs-22 nav-link BtnImg1"
                            onClick={bookSessionHandle}
                          >
                            Book a session
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutimageslider py-sm-5 pt-3">
                <div
                  className="owl-carousel owl-theme OwlSliderOne Aboutusslider AllOwlSliders"
                  id="MeetExpertsOwl"
                >
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider exprince_hover pb-2">
                      <Link>
                        <img src="/images/Rajalakshmi.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Rajalakshmi
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Lead Physiotherapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            8 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/Puja_Singh.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Puja Singh
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Sr Clinical Physiotherapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            5 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img
                          src="/images/Raniy_Sultana.jpg"
                          className="br-24"
                        />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Raniya Sultana
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Clinical Therapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            5 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider exprince_hover pb-2">
                      <Link>
                        <img src="/images/Rajalakshmi.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Rajalakshmi
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Lead Physiotherapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            8 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img src="/images/Puja_Singh.jpg" className="br-24" />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Puja Singh
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Sr Clinical Physiotherapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            5 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="physiotherapist mx-2 exprince_hover">
                    <div className="Aboutshortslider  pb-2">
                      <Link>
                        <img
                          src="/images/Raniy_Sultana.jpg"
                          className="br-24"
                        />
                      </Link>
                      <div className="Aboutshortslider_title">
                        <p>
                          <span className="text-white fs-25 MuliSemiBold">
                            Raniya Sultana
                          </span>
                          <br />
                          <span className="text-white fs-14 fw-300">
                            Clinical Therapist
                          </span>
                          <span className="text-white fs-16 fw-300 d-block">
                            5 Yrs Ex
                          </span>
                          <span></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-4 ||---------------> */}
    </>
  );
}

export default meetTheExperts;
