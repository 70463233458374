import React from "react";
import { Link } from "react-router-dom";

function careDuringHub() {
  return (
    <>
      {/* <!---------------|| Section Thired ||---------------> */}
      <section
        className="clearfix  w-100 careduring bg-lightgray py-xl-5"
        id="carecovid"
      >
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-5 order-md-1 order-2">
              <div className="duringpart pt-lg-5 pb-3">
                <div className="h-100 d-table">
                  <div className="d-table-cell align-middle text-left">
                    <div className="knowledgebannerContant clr-2 pt-md-4 pt-xl-0">
                      <div className="knowledgebannertitle fs-24 fw-600 mb-2">
                        <span>Food &amp; recipies</span>
                      </div>
                      <div className="knowledgebannerheading">
                        <h1 className="fs-72 fw-600 pt-4">
                          Care during <br />
                          Covid-19
                        </h1>
                      </div>
                      <div className="knowledgebannerviews py-4">
                        <ul className="nav knowledgebanneIcons MuliRegular">
                          <li className="nav-item ">
                            <Link className=" fs-18 clr-2">
                              <i
                                className="fa fa-heart clr-6"
                                aria-hidden="true"
                              ></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item px-5">
                            <Link className=" fs-18 clr-2">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="knowledgebannerpara MuliRegular fs-22">
                        <p className="fs-22">
                          Rehab technology ranging from electrotherapy which is
                          laser, ultrasound and other modalities as well as
                          diagnostic tools using retro reflective cameras as
                          well as 3D technology to study movement patterns to
                          comprehensive AI driven EMR software to help maintain
                          patient outcomes. Pro Physio is in the forefront of
                          med-tech.3D technology to study movement patterns to
                          comprehensive AI driven...
                        </p>
                      </div>
                      <div className="knowledgebannerlink fs-20">
                        <Link to="bExercise" className="clr-1 fw-500">
                          Read more
                        </Link>
                        {/* <Link href="b_exercise.html" className="clr-1 fw-500">Read more</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 order-md-2 order-1">
              <div className="covidparttwo h-100 d-table">
                <div className="covidimg d-table-cell align-bottom text-md-left text-center py-md-0">
                  <img
                    src="/images/img78.jpg"
                    className="w-100 igm-fluid br-24"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Thired ||---------------> */}
    </>
  );
}

export default careDuringHub;
