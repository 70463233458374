import React from "react";
import { Link } from "react-router-dom";

function bChildNeed() {
  function initiateCarousel() {}

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>
      {/* <!---------------|| Section One ||---------------> */}
      <section className="w-100 clearfix PhysioBlogTitle py-5" id="BlogTitle">
        <div className="container">
          <div className="PhysioBlogTitleHeading fs-24 fw-600">
            <Link to="knowledgehub" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link>
            {/* <Link href="knowledge_hub.html" className="clr-1">
              <img src="/images/icons/icon23.png" alt="left icon" /> Back to
              knowledge hub
            </Link> */}
          </div>
          <div className="PhysioBlogPara mt-4 ">
            <h1 className="fw-700 fs-72">
              My child's needs are important - virtually or otherwise!
            </h1>
            <img src="/images/icons/Line.png" alt="line" />
          </div>
          <div className="PhysioBlogNav  mt-4">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">Manual Therapy</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600">
                  {" "}
                  Value based health
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 fw-600"> Physiotherapy</Link>
              </li>
            </ul>
          </div>
          <div
            className="PhysioBlogBanner mt-5 br-24"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/images/blog/adhd.png"
              alt="blog banner"
              className="img-fluid"
            />
            <ul className="nav flex-column">
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-heart" aria-hidden="true"></i> 82 Like
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link fs-18 MuliLight">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> Share
                </Link>
              </li>
            </ul>
          </div>
          <div className="PhysioIntroHook">
            <div className="PhysioIntroHeading pt-5">
              <h1 className="fs-54 fw-700"></h1>
            </div>
            <div className="PhysioIntroPara fs-25 MuliLight">
              <p className="clr-2">
                Virtual-reality (VR) based therapies have not only become a
                vital lifeline for parents during the COVID-19 pandemic but have
                also made sure that children with special needs are tended to in
                whichever possible. Nonetheless, the challenges in delivering
                therapy online (teletherapy) cannot be swept under the carpet.
              </p>
              <p className="clr-2">
                The transition to various digital platforms can be a good news
                for those who have already been in face to face sessions prior
                to the occurrence of the pandemic but can be extremely
                challenging for those children and parents who are starting
                treatment for the first time. Various research studies have
                concluded the efficacy of teletherapy which are deemed to have
                done more good than harm in terms of symptom management and
                skill improvement in children. This format also embarks on
                having the parents more involved in their child's concerns
                through extensive parental involvement and also making available
                some forms of support for children with special needs rather
                than having no therapy at all. However, there are children who
                may not benefit from this format. Therefore, it is of crucial
                importance to make VR based sessions more child and parent
                friendly, accessible, and helpful considering how social
                distancing is of a prime importance during the stride of this
                pandemic. Because the challenges with social distancing can
                increase the risk for various mental health problems and other
                adverse effects.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section One ||---------------> */}
      {/* <!---------------|| Section Two ||---------------> */}
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            {/* <!-- <div className="col-md-6 pr-xl-5">
            <div className="PhysioSectionImages blog_services_img">
              <img src="/images/img93.png" alt="image6" className="img-fluid br-24">
            </div>
            </div> --> */}
            <div className="col-md-9 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">
                  Collaborative work for therapists and parents
                </h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  Whilst planning a VR based setup of both the therapist's and
                  child’s teletherapy space, one must consider that children
                  might prefer less formality and can be distracted by items in
                  the environment that would not be distracting for adults.
                  Younger children will need more space to engage in therapy
                  activities, such as drawing or playing. The therapists are to
                  explain the format of sessions to the child in a
                  developmentally appropriate manner (sometimes using stories,
                  puppets etc). To establish rapport with children by sharing
                  items from home or drawings on the camera and to keep the
                  child, especially a younger child engaged in the sessions it
                  could be helpful to switch activities or using interactive
                  online features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="PhysioSectionTitle py-xl-5 mb-4" id="SectionTitle">
        <div className="container">
          <div className="row">
            <div className="col-md-9 pl-xl-5">
              <div className="PhysioSectionHeading">
                <h1 className="fs-54 fw-700">Summary</h1>
              </div>
              <div className="PhysioSectionPara MuliLight fs-25">
                <p className="clr-2 my-4">
                  Regardless of how effective or ineffective virtual therapy
                  sessions are, it is important for the therapist and parents to
                  collaboratively make observations whether VR based sessions
                  are helpful to a particular child. The primordial aspect of VR
                  based sessions has to be child centered and child-driven. It
                  is of an utmost importance that therapists and doctors who
                  work closely with children make case to case distinctions and
                  make healthcare facilities more accessible to
                  differently-abled children by establishing a daily routine
                  along with empowerment of parents with home-based
                  interventions by attending to their emotional needs which is
                  central to a disability-inclusive response to pandemic.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Two ||---------------> */}
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!-- <section className="PhysioDescripVideo" id="DescripVideo">
      <div className="container">
        <div className="PhysioBlogVideo">
          <img src="/images/img94.png" alt="blog banner Two" className="img-fluid w-100">
          <button className="play_btn"><i className="fa fa-play-circle" aria-hidden="true"></i></button>
        </div>
        <div className="DescriptionVideo text-center MuliBold my-3">
          <p className="fs-25 clr-2">Title or description of the video, source, publish date and other information etc.</p>
        </div>
      </div>
      </section> --> */}
      {/* <!---------------|| Section Three ||---------------> */}
      {/* <!---------------|| Section Four ||---------------> */}
      {/* <!-- <section className="PhysioDiagnosticTools py-md-5 pt-5" id="DiagnosticTools">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="PhysioDiagnosticBuddha ">
              <i className="fa fa-quote-left" aria-hidden="true"></i>
              <div className="PhysioDiagBuddhaContant text-center">
                <div className="PhysioDiagBuddhaPara fs-30">
                  <p className="clr-darkgray fs-30">Start where you are. Use what you have. Do what you can. </p>
                </div>
                <div className="PhysioDiagName text-center MuliBold fs-20">
                  <span className="fs-20 clr-2 fw-600"></span>
                </div>
              </div>
              <div className="ToolsEnabled MuliLight fs-25 mt-4">
                <p className="fs-25 clr-2">Through objective diagnostic tools enabled with bio feedback tools that help us
                  understand what are the aspects that can be worked .</p>
                <p className="fs-25 clr-2"> Healing involves carefully analysing the health of the individual through
                  objective diagnostic tools enabled with bio feedback</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="CarefullyAnalysing MuliLight fs-25 pt-md-4">
              <p className="fs-25 fw-400 clr-2 my-4">Healing involves carefully analysing the health of the individual through
                objective <span className="clr_dark_blue fw-600">diagnostic</span> tools enabled with bio feedback tools that
                help us understand what are the aspects that can be worked on to improve health. We use modern diagnostic
                and therapeutic tools to <span className="clr_dark_blue fw-600"> effective </span>heal our clients. </p>
              <p className="fs-25 fw-400 clr-2 my-4">Healing involves carefully analysing the health of the individual through
                objective diagnostic tools enabled with bio feedback tools that help us understand what are the aspects
                that can be worked .</p>
              <p className="fs-25 fw-400 clr-2 my-4"> Healing involves carefully analysing the health of the individual
                through objective diagnostic tools enabled with bio feedback</p>
            </div>
        </div>
      </div>
      </div>
      </section> --> */}
      {/* <!---------------|| Section Four ||---------------> */}
      {/* <!---------------|| Section Six ||---------------> */}
      <section
        className="w-100 clearfix PhysioLiveTruthAboutCoffees bg-gray_dark py-5 mt-lg-5"
        id="TruthAboutCoffees"
      >
        <div className="container">
          <div className="OtherSimilarPosts mb-5 pt-xl-5">
            <h1 className="fs-72 fw-700">Other similar posts</h1>
          </div>
          <div className="PhysioHealthyExploreknowledge w-100 clearfix SimilarPosts mb-xl-5 pb-xl-5">
            <div className="row">
              <div className="col-xl-5 pb-4">
                <div className="PhysioHealthTips bg-white br-10 h-100">
                  <div className="row m-0 h-100">
                    <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                      <img
                        src="/images/blog/iStock-1215198746.jpg"
                        alt="Common"
                        className="img-fluid h-100"
                      />
                    </div>
                    <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                      <div className="PhysioHealthTipsHeading Montserrat">
                        <h6 className="fs-24 fw-600 m-0">Mental Health</h6>
                        <img
                          src="/images/icons/Line.png"
                          alt="line"
                          className="img-fluid"
                        />
                      </div>
                      <div className="PhysioHealthTipsPara MuliRegular">
                        <Link
                          to="bMentalHealth"
                          className="d-inline-block clr-2 h-100"
                        >
                          {/* <Link
                          href="b_mental_health.html"
                          className="d-inline-block clr-2 h-100"
                        > */}
                          <p className="mb-0 fs-34">
                            Your Mental Health is what matters to your kids!
                          </p>
                          {/* </Link> */}
                        </Link>
                      </div>
                      <div className="PhysioTruthCoffeesIcons">
                        <ul className="nav PhysioCoffeesIcons MuliLight">
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18 ">
                              <i className="fa fa-heart" aria-hidden="true"></i>{" "}
                              82 Likes
                            </Link>
                          </li>
                          <li className="nav-item mx-2">
                            <Link className="nav-link fs-18">
                              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                              5min read
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bMoveMore"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_move_more.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/IMG_6025.jpeg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">Move More</p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bImmunityTips "
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_immunity_tips.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A9169.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Immunity Tips
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Boost your immunity with these simple tips and
                                tricks
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link
                        to="bExercise"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_exercise.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/P1013575.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Mental Health
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                Exercise the ultimate pill to kill stress and
                                anxiety Forever.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bWearableDevice"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link href="b_wearable_device.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/8A0555.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                Wearable Device
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How this wearable device can help you manage
                                your stress effortlessly?
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                    <div className="col-md-6 pb-md-0 pb-3">
                      <Link
                        to="bRunning"
                        className="d-inline-block clr-2 h-100"
                      >
                        {/* <Link
                        href="b_running.html"
                        className="d-inline-block clr-2 h-100"
                      > */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/9L1A0280.jpg"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">Exercise</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                The science of running is an art.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 pb-md-4">
                <div className="PhysioHealthFourBoxContant ">
                  <div className="row">
                    <div className="col-md-8 pb-md-0 pb-3">
                      <div className="PhysioHealthTips bg-white br-10 h-100">
                        <div className="row m-0 h-100">
                          <div className="PhysioHealthTipsImage col-md-4 p-0 h-100">
                            <img
                              src="/images/blog/mental_health2.png"
                              alt="Common"
                              className="img-fluid h-100"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3 col-md-8 p-0 h-100">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-24 fw-600 m-0">Child Health</h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara MuliRegular">
                              <Link
                                to="bChildNeed"
                                className="d-inline-block clr-2 h-100"
                              >
                                {/* <Link
                                href="b_child_need.html"
                                className="d-inline-block clr-2 h-100"
                              > */}
                                <p className="mb-0 fs-34">
                                  My child's needs are important - virtually or
                                  otherwise!
                                </p>
                                {/* </Link> */}
                              </Link>
                            </div>
                            <div className="PhysioTruthCoffeesIcons">
                              <ul className="nav PhysioCoffeesIcons MuliLight">
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18 ">
                                    <i
                                      className="fa fa-heart"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    82 Likes
                                  </Link>
                                </li>
                                <li className="nav-item mx-2">
                                  <Link className="nav-link fs-18">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    5min read
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 pb-md-0 pb-3">
                      <Link to="bAdhd" className="d-inline-block clr-2 h-100">
                        {/* <Link href="b_adhd.html" className="d-inline-block clr-2 h-100"> */}
                        <div className="PhysioHealthTips bg-white br-10 h-100">
                          <div className="PhysioHealthTipsImage">
                            <img
                              src="/images/blog/adhd.png"
                              alt="Common"
                              className="img-fluid"
                            />
                          </div>
                          <div className="PhysioHealthTipsContant p-3">
                            <div className="PhysioHealthTipsHeading Montserrat">
                              <h6 className="fs-16 fw-600 m-0">
                                ADHD and Autism
                              </h6>
                              <img
                                src="/images/icons/Line.png"
                                alt="line"
                                className="img-fluid"
                              />
                            </div>
                            <div className="PhysioHealthTipsPara fs-20  MuliRegular">
                              <p className="mb-0">
                                How is ADHD and Autism different or are they the
                                same as each other?{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Six ||---------------> */}
    </>
  );
}

export default bChildNeed;
