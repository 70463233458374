import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BookNowContext } from "../../../pages/BookNowContext/BookNowContext";

function connectUs() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  const bookNowHandle = (i) => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  return (
    <>
      {/* <!---------------|| Connect us Section ||---------------> */}
      <section
        className="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting"
        id="ConnectReady"
      >
        <div className="container-fluid py-md-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">Ready to connect with us?</h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Whether you are an individual or an institution ProPhysio has a
              wide range of offerings for you.
            </p>
          </div>
          <div className="client pb-xl-5 ReadyToConnect">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/16.png)" }}
                  >
                    <div className="trulyCare1 d_table_cell_none align-middle w-75">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Virtual <br />
                        Session
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(0)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/17.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Home <br />
                        Visit
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(1)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/18.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Visit <br />
                        Clinic
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(2)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="NotSureTo w-100 clearfix text-center pt-4">
            <h3 className="fs-45 MuliExtra pl-5">
              Getting a physiotherapy session at you
              <br /> home is easier than you think!
            </h3>
            <h4 className="fs-54 MuliLight pt-5">
              <img
                src="/images/icons/icon14.png"
                className="img-fluid"
                alt="No Images"
              />
              <span className="d-inline-block pl-5">
                Call us now at{" "}
                <a href="tel:+91 8197378444" className="clr-1">
                  +91 8197378444
                </a>
              </span>
            </h4>
            <p className="pt-3">
              You will get an instant call from your care manager. Once we
              understand <br /> your needs will assign the best therapist who
              suits your requirements.
            </p>
          </div>
        </div>
      </section>
      {/* <!---------------|| Connect Us Section ||---------------> */}
    </>
  );
}

export default connectUs;
