import React, { createContext, useState } from "react";

export const BookNowContext = createContext()

const { Provider } = BookNowContext;

export const BookNowProvider = (props) => {

    const [firstFormOpen, setFirstFormOpen] = useState(false);
    const [contactModal, setContactModal] = useState(true)
    const [slideClickData, setSlideClickData] = useState({})

    return (

        <Provider value={{ bookNowModal: [firstFormOpen, setFirstFormOpen], contactForm: [contactModal, setContactModal], form: [slideClickData, setSlideClickData] }}>

            {props.children}

        </Provider>

    );

}


