import React from "react";
import MeetOtherTherapists from "../MeetOtherTherapists/meetOtherTherapists";
import TakeYourFirstStep from "../TakeYourFirstStep/takeYourFirstStep";
import ReadyToTransfer from "../ReadyToTransfer/readyToTransfer";
import BackToTop from "../BackToTop/backToTop";
import { Link } from "react-router-dom";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { useContext } from "react";

function th_sabrinamody() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;

  const bookNowHandle = () => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };

  return (
    <>
      {/* <!---------------|| Section one ||---------------> */}
      <section
        className="w-100 clearfix py-5 therapists "
        id="Detailedtherapists"
      >
        <div className="container">
          <div className="therapists pb-2">
            <div className="row">
              <div className="col-12">
                <div className="backbtn">
                  <Link
                    to="therapistsExploare"
                    className="text-decoration-none"
                  >
                    <img src="/images/icons/icon23.png" />
                    <span className="fs-24">Back to all therapists</span>
                  </Link>
                  {/* <Link to="therapists_exploare.html" className="text-decoration-none">
                <img src="/images/icons/icon23.png"/>
                <span className="fs-24">Back to all therapists</span>
              </Link> */}
                </div>
              </div>
            </div>
            <div className="shruthidigde my-5">
              <div className="row">
                <div className="col-lg-4  col-md-8 mx-auto">
                  <div className="girlimg my-5 my-lg-0">
                    <img
                      src="/images/therapist/default_image.png"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="doctordata pl-sm-2 position-relative">
                    <div className="doctorname">
                      <h1 className="fw-600 mt-2">
                        <span className="w-100 d-inline-block float-left fs-60">
                          Sabrina Mody
                        </span>
                      </h1>
                    </div>
                    <div className="doctorsubject">
                      <p
                        className="fs-40 text-darkgray fw-500 mb-0"
                        style={{ opacity: "0.67" }}
                      >
                        Operations head- Hyderabad
                      </p>
                      <p className="fs-38 fw-600">
                        <span className="">7</span>{" "}
                        <span className="">yrs. Exp.</span>
                      </p>
                    </div>
                    <div className="doctorthought">
                      <p className="fs-30  my-md-3 my-2 lh-30 clr_graylight MuliItalic">
                        “It takes more than dreams to get one to greatness.
                        One’s passion, dedication and work ethics will always
                        help soar them to greater heights.”
                      </p>
                    </div>
                    <div className="doctorpara">
                      <p className="fs-22 text-black my-3 MuliLight">
                        Strong personality with good interpersonal skills. I
                        love working in a team which helps me motivated. I like
                        to take charge of things and keep a positive environment
                        for me and my colleagues. Ability to build strong
                        relationship with doctors and patients from diverse
                        backgrounds
                      </p>
                    </div>
                    <div className="doctorlocation pt-md-4">
                      <div className="location pt-3">
                        <div className="row">
                          <div className="col-md-8">
                            <ul className="nav">
                              <li className="nav-item">
                                <Link className="locationicon d-inline-block">
                                  <img src="/images/icons/location.svg" />
                                </Link>
                              </li>
                              <li className="nav-item pl-3">
                                <span className="fs-22 text-darkgray MuliLight">
                                  Lorem ipsum dolor sit amet, consetetur
                                  <br />
                                  sadipscing elitr, sed diam nonumy.
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-4">
                            <div className="booksession text-md-right text-center">
                              <div className="more_show ">
                                <Link
                                  onClick={bookNowHandle}
                                  className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton BtnImg1"
                                >
                                  Book a session
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </section>
      {/* <!---------------|| Section one ||---------------> */}
      {/* <!---------------|| Section two ||---------------> */}
      <section
        className="clearfix w-100 prophysio pb-5 aboutdoctors therpy_services TherpyDewtails1"
        id="Aboutdoctors"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="prohrading">
                <h1 className="fs-60 fw-600">About Sabrina Mody</h1>
              </div>
            </div>
          </div>
          <div className="assurance p-md-3">
            <div className="row">
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Expertise</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      A strong Physiotherapist with expertise in Orthopedic and
                      Sports injuries, certified Pregnancy Fitness trainer from
                      USA (CAPPA), Pilates certified trainer (APPI). Well versed
                      with modern techniques like dry needling, manual therapy
                      and taping. Worked with Abhinav Bindra’s foundation and
                      trained lot of athletes and sports people playing national
                      and international sports events
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Credentials</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">sabrina@prophysio.co</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">Achievements</p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      One of the top performing physiotherapists at ABTP
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        Previous Experience
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Worked with Abhinav Bindra Targeting performance. Worked
                      with Healyos powered by Sancheti hospitals. Worked with
                      Healthsprings clinic.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        What I love about my job
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      This job with different challenges, helps me grow
                      professionally. The company has helped me in building
                      strong leadership qualities. I look forward to grow more
                      under the expert guidance of my seniors
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 px-xl-5 py-lg-5 py-2">
                <div className="trainedexpert">
                  <div className="InfoDetails d-flex py-4">
                    <div className="InfoText1 d-inline-block w-100">
                      <p className="fw-500 fs-34 ls-1 mb-0">
                        In my free time I like to
                      </p>
                    </div>
                  </div>
                  <div className="phpoyherpara">
                    <p className="fs-22 MuliLight">
                      Travel, listen to music and spend time with my toddler.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="more_show text-center pt-4">
                  <Link
                    data-toggle="modal"
                    data-target="#claimFreeModal"
                    className="show_btn btn bg-blue br-24 text-white fs-22 bluebutton "
                  >
                    Claim your first free consultation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section two ||---------------> */}
      <MeetOtherTherapists />
      <TakeYourFirstStep />
      <ReadyToTransfer />
      <BackToTop />
    </>
  );
}
export default th_sabrinamody;
