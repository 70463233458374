import Util from "../../utils/Util";
export default class FaqService {
    faqList(data, start, length) {
        const activity = Object.keys(data).reduce((object, key) => {
            if (data[key] !== "") {
                object[key] = data[key];
            }
            return object;
        }, {});

        return Util
            .sendApiRequest(
                "/faq/list/" + start + "/" + length,
                "POST",
                true,
                activity
            )
            .then(
                (response) => {
                    return response;
                },
                (error) => {
                    throw error;
                }
            );
    }

    faqFormApi(payload) {
        return Util.sendApiRequest("/faq/enquiry", "POST", true, payload)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            throw err;
          });
      }
}