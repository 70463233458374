import React from "react";
import MeetTheExperts from "../../shared/MeetTheExperts/meetTheExperts";
import FlowServiceTab from "../../shared/FlowServiceTab/flowServiceTab";
import DetailBackToTop from "../../shared/DetailBackToTop/detailBackToTop";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { BookNowContext } from "../../pages/BookNowContext/BookNowContext";
import { Helmet } from "react-helmet";

function detailedElderlyService() {
  const { bookNowModal, contactForm } = useContext(BookNowContext);
  const [firstFormOpen, setFirstFormOpen] = bookNowModal;
  const [contactModal, setContactModal] = contactForm;
  const bookNowHandle = (i) => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };
  const bookSessionHandle = () => {
    setFirstFormOpen(!firstFormOpen);

    setContactModal(true);
  };
  function initiateCarousel() {
    /**********|| Custom Owl Js||**********/
    window.$("#MeetExpertsOwl").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      dots: true,
      responsive: {
        0: {
          items: 2,
          nav: false,
        },
        576: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 2,
          nav: false,
          loop: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: false,
        },
        1300: {
          items: 4,
          nav: false,
          loop: false,
        },
      },
    });
    /**********|| Custom Owl Js||**********/
    /**********|| Custom Owl Js||**********/
    window.$("#DetailedS_tab_data").owlCarousel({
      loop: true,
      margin: 0,
      responsiveClass: true,
      dots: false,
      responsive: {
        0: {
          items: 2,
          nav: true,
        },
        370: {
          items: 2.5,
          nav: true,
        },
        576: {
          items: 2,
          nav: true,
        },
        768: {
          items: 3,
          nav: true,
        },
        992: {
          items: 4,
          nav: true,
        },
        1200: {
          items: 5,
          nav: true,
        },
        1550: {
          items: 5,
          nav: true,
        },
        1900: {
          items: 6,
          nav: true,
        },
      },
    });
    /**********|| Custom Owl Js||**********/

    /**********|| Custom Owl Therapyowlslider Js||**********/
    window
      .$(".Therapyowlslider")
      .on("initialized.owl.carousel changed.owl.carousel", function (e) {
        if (!e.namespace) {
          return;
        }
        window
          .$(".SlideCount")
          .text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
      });

    window.$("#TheHolisticOwl").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        501: {
          items: 2,
          nav: false,
        },
        768: {
          items: 2,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });
    window.$("#TheHolisticOwl2").owlCarousel({
      loop: false,
      margin: 10,
      responsiveClass: false,
      dots: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          margin: 0,
        },
        576: {
          items: 1,
          nav: false,
        },
        768: {
          items: 1,
          nav: true,
        },
        992: {
          items: 1,
          nav: true,
          loop: false,
        },
        1200: {
          items: 1,
          nav: true,
          loop: false,
        },
      },
    });

    window
      .$(".Therapyowlslider .owl-nav .owl-prev")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a,.b,.c{fill:none;}.a,.c{stroke:javascript:void(0);007ce2;}.c{stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.d{stroke:none;}</style></defs><g transform="translate(-1747 -10985)"><g className="a" transform="translate(1747 10985)"><path className="d" d="M24,0H54a0,0,0,0,1,0,0V48a0,0,0,0,1,0,0H24A24,24,0,0,1,0,24v0A24,24,0,0,1,24,0Z"/><path className="b" d="M24,.5H53a.5.5,0,0,1,.5.5V47a.5.5,0,0,1-.5.5H24A23.5,23.5,0,0,1,.5,24v0A23.5,23.5,0,0,1,24,.5Z"/></g><g transform="translate(1789.466 11021.466) rotate(180)"><rect className="b" width="24" height="24" transform="translate(0.466 0.466)"/><g transform="translate(3.116 4.675)"><path className="c" d="M6,24H24.7" transform="translate(-6 -16.209)"/><path className="c" d="M29,9l6.752,7.791L29,24.582" transform="translate(-17.054 -9)"/></g></g></g></svg>'
      );

    window
      .$(".Therapyowlslider .owl-nav .owl-next")
      .html(
        '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48"><defs><style>.a{fill:javascript:void(0);007ce2;}.b,.c{fill:none;}.c{stroke:javascript:void(0);fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-1801 -10985)"><path className="a" d="M0,0H30A24,24,0,0,1,54,24v0A24,24,0,0,1,30,48H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(1801 10985)"/><g transform="translate(1813 10997)"><rect className="b" width="24" height="24" transform="translate(0 0)"/><g transform="translate(3 4.5)"><path className="c" d="M6,24H23.2" transform="translate(-6 -16.5)"/><path className="c" d="M29,9l6.5,7.5L29,24" transform="translate(-17.5 -9)"/></g></g></g></svg>'
      );
  }

  setTimeout(() => {
    initiateCarousel();
  }, 300);

  return (
    <>

      {/* Meta Tags Start */}
      <Helmet>
        <title>Elderly Care Service in Bangalore | Pro Physio</title>
        <meta name="description" content="Pro Physio is one of the top most leading elderly care services provider in Bangalore which includes senior care, geriatric care." />
      </Helmet>
      {/* Meta Tags Ends */}

      <FlowServiceTab />

      {/* <!---------------|| Section-1 ||---------------> */}
      <div className="proDetailedTabServices">
        <div id="detailed_elderly_servies" className="active">
          <section
            className="w-100 clearfix hospitalBanner Detailed_Servies_banner Detailed_Servies_banner1 position-relative tab-content"
            id="AboutBennerSection1"
          >
            <div className="container">
              <div className="w-100 clearfix">
                <div className="containerNone" id="ElderlyCare">
                  <div className="YouCanDetails w-100 clearfix">
                    <div className="row">
                      <div className="col-xl-6 order-1 detail_banner">
                        <div className="BeliveTexts w-100 clearfix py-md-5">
                          <div className="MainTest1 w-100 pt-4">
                            <h2 className="fs-72 fw-700">Elderly Care</h2>
                          </div>
                          <div className="OtherText1 w-100 pr-xl-5">
                            <p className="MuliLight fs-34 clr-2">
                              Getting the right balance{" "}
                            </p>
                            <p className="MuliLight fs-22 ls-1 m-0">
                              Aging, a normal process without any major medical
                              conditions is associated with a variety of
                              biological changes in the body. Which often
                              results in reduced muscles mass, strength and
                              endurance and cognitive abilities, which may
                              result in vulnerable, catastrophic events. Normal
                              growth includes an increased prevalence of
                              aging-related mobility impairments which makes
                              elderly people physically dependent on others.
                            </p>
                          </div>
                          <div className="Detailed_Servies_year pt-3">
                            <p className="clr-6 fs-40 mb-0">
                              <span className="fs-50">10+</span> years
                            </p>
                            <p className="clr-2 fs-24 fw-600">
                              Collective experience
                            </p>
                          </div>
                          <div className="Detailed_Servies_book my-lg-5 pb-md-5 pt-5">
                            <Link
                              onClick={bookSessionHandle}
                              className="btn BtnBook fs-22 fw-400 BtnImg1"
                            >
                              Book your session now!
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 position-static order-2 detail_banner">
                        <div className="servicebannerImg clearfix text-md-right text-center position-absolute">
                          <img
                            src="/images/services/details/b1.png"
                            className="w-100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!---------------|| Section-1 ||---------------> */}
          {/* <!---------------|| Section-2 ||---------------> */}
          <section
            className="clearfix w-100 prophysio pb-5 pt-md-5 head_details ourprincipal WhatIDoNow"
            id="Ourprincipalsection"
          >
            <div className="container-fluid">
              <div className="container">
                <div className="assurance">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 p-xl-5">
                      <div className="trainedexpert py-md-5 py-2">
                        <div className="InfoDetails d-flex py-4">
                          <div className="InfoImg1 d-inline-block">
                            <img
                              src="/images/icons/icon19.png"
                              className="img-fluid"
                              alt="No images"
                            />
                          </div>
                          <div className="InfoText1 d-inline-block">
                            <p className="fw-600 fs-42 ls-1 mb-0">
                              What does this include?
                            </p>
                          </div>
                        </div>
                        <div className="phpoyherpara FFOpen">
                          <p className="fs-22 fw-500">
                            Frequent falls? Scared to go out of the house?
                            Multiple joint pain? Reduced ability for daily
                            chores? Aging doesn’t mean being dependent on
                            others..! Physiotherapists can help you become
                            independent inside as well as outside your home by
                            using simple techniques. Our Geriatric
                            rehabilitation program will help you build your
                            strength, endurance, improve your balance and
                            ability to work efficiently with minimal energy
                            expenditure.
                          </p>
                          <Link className="clr-1 fs-22">Learn More</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-5">
                      <div className="trainedexpert py-md-5 py-2">
                        <div className="InfoDetails d-flex py-4">
                          <div className="InfoImg1 d-inline-block">
                            <img
                              src="/images/icons/icon22.png"
                              className="img-fluid"
                              alt="No images"
                            />
                          </div>
                          <div className="InfoText1 d-inline-block">
                            <p className="fw-600 fs-42 ls-1 mb-0">
                              How does it work?
                            </p>
                          </div>
                        </div>
                        <div className="phpoyherpara FFOpen">
                          <p className="fs-22 fw-500">
                            Assessing the scientific problem behind your
                            condition plays an important role. Our geriatric
                            assessment focuses on problems that u face every day
                            and we try to eliminate any physical barriers that
                            are causing the same. Over a period of time of the
                            rehabilitation program we work in a holistic
                            approach to gain one’s confidence to move in and
                            around the community without any fear of getting
                            hurt or facing any vulnerable situations.
                          </p>
                          <Link className="clr-1 fs-22">
                            Get a remote assessment
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-xl-5">
                      <div className="trainedexpert py-md-5 py-2">
                        <div className="InfoDetails d-flex py-4">
                          <div className="InfoImg1 d-inline-block">
                            <img
                              src="/images/icons/icon21.png"
                              className="img-fluid"
                              alt="No images"
                            />
                          </div>
                          <div className="InfoText1 d-inline-block">
                            <p className="fw-600 fs-42 ls-1 mb-0">
                              What are the <br /> solutions??
                            </p>
                          </div>
                        </div>
                        <div className="phpoyherpara FFOpen">
                          <p className="fs-22 fw-500">
                            We offer individual as well as group therapy which
                            helps elderly people. The rehabilitation program
                            includes various components of strength training,
                            conditioning, balance and proprioceptive training
                            and cognitive therapy.
                          </p>
                          <ul
                            className="tramneted_menu nav"
                            id="tramneted_menu_data"
                          >
                            <li className="nav-item">
                              <Link className="clr-1 fs-18 nav-link">
                                Fall Prevention
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="clr-1 fs-18 nav-link">
                                Better Aging
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="clr-1 fs-18 nav-link">
                                Longevity
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ExploBtn w-100 clearfix pt-lg-5 pb-lg-4 pt-2 text-center">
                  <Link
                    className="btn BtnBook fs-22 fw-500 px-4"
                    data-toggle="modal"
                    data-target="#claimFreeModal"
                  >
                    Get your first free consultation
                  </Link>
                  {/* <Link to="services.html" className="btn BtnBook fs-22 fw-500 px-4">
                    Get your first free consultation
                  </Link> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <!---------------|| Section-2 ||---------------> */}

      {/* <!---------------|| Section-4 ||---------------> */}

      {/* <!---------------|| Section-4 ||---------------> */}
      <MeetTheExperts />

      {/* <!---------------|| Advanced Therapy Section ||---------------> */}
      <section
        className="w-100 clearfix MotsTherapy py-5 Detailed_Servies_slider mb-5"
        id="AdvanceTherapry"
      >
        <div className="container pb-5">
          <div className="TherapyLabs w-100 clearfix position-relative">
            <div
              className="owl-carousel owl-theme OwlSliderOne Therapyowlslider pt-5"
              id="TheHolisticOwl2"
            >
              <div className="therapy">
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="Detailed_Servies_Labs mt-lg-0 mt-sm-4">
                      <div className="therphyimg position-relative">
                        <img
                          src="/images/iStock-645687012.png"
                          className="w-100 img-fluid"
                        />
                        <div className="HospitalText w-100 position-absolute">
                          {/* <!-- <h3 className="fw-700 fs-42 p-3 text-white">Rainbow Hospital, <br>Bangalore</h3> --> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="therphycontent px-3">
                      <div className="therphyheading">
                        <h1 className="fs-72 fw-700">
                          The best in class solutions
                        </h1>
                      </div>
                      <div className="therphyparagraph my-4 MuliLight">
                        <p className="fs-22 text-black">
                          We are EBP evidence-based practice enabled We are a
                          practice that relies on scientific evidence for
                          guidance and decision-making. The goal of our practice
                          is to eliminate unsound or outdated practices in
                          favour of more effective ones by shifting the basis
                          for decision making from tradition, intuition, and
                          unsystematic experience to firmly grounded scientific
                          research.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy">
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="Detailed_Servies_Labs mt-lg-0 mt-sm-4">
                      <div className="therphyimg ">
                        <img
                          src="/images/blog/IMG_7833.jpg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="therphycontent px-3">
                      <div className="therphyheading">
                        <h1 className="fs-72 fw-700">Movement Analysis Lab</h1>
                      </div>
                      <div className="therphyparagraph my-4">
                        <p className="fs-20 text-black fw-500">
                          3D running analysis is an advanced and scientific tool
                          used for diagnosis. To identify biomechanical
                          abnormalities, To assess the way an individual runs
                          Recording the runner's movement pattern through 3D
                          motion capture cameras. Detailed Running injury
                          history is taken Physical Examination and Various
                          specialised tests, 2D video analysis, Training
                          variables and running characteristic recommendations
                          for prevention and management of injuries. Footwear
                          recommendations based on foot mechanics of runner's.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy">
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="Detailed_Servies_Labs mt-lg-0 mt-sm-4">
                      <div className="therphyimg ">
                        <img
                          src="/images/IMG_9643-Enhanced.jpeg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="therphycontent px-3">
                      <div className="therphyheading">
                        <h1 className="fs-72 fw-700">
                          Advanced centre for renal health
                        </h1>
                      </div>
                      <div className="therphyparagraph my-4">
                        <p className="fs-20 text-black fw-500">
                          In renal disease exercise can help in more energy,
                          hake you feel strong, help reduce fatigue levels,
                          improve your mood and quality of life, reduce stress
                          and help you sleep, Help Your Digestion And prevent
                          excessive weight gain help control blood pressure and
                          makes your heart strong, reduce cholesterol levels and
                          your chance of developing heart disease, strengthen
                          your muscles and bones, help Reduce your blood Sugar,
                          improve the efficiency Of dialysis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="therapy">
                <div className="row">
                  <div className="col-lg-6 order-lg-1 order-2">
                    <div className="Detailed_Servies_Labs mt-lg-0 mt-sm-4">
                      <div className="therphyimg ">
                        <img
                          src="/images/blog/8 _C8A0666.jpg"
                          className="w-100 img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-lg-2 order-1">
                    <div className="therphycontent px-3">
                      <div className="therphyheading">
                        <h1 className="fs-72 fw-700">
                          Using Technology for health{" "}
                        </h1>
                      </div>
                      <div className="therphyparagraph my-4">
                        <p className="fs-20 text-black fw-500">
                          Using Technology for health{" "}
                        </p>
                        <p className="fs-20 text-black fw-500">
                          ProPhysio at its core uses state of the art technology
                          including the best wearable technology. Pro Physio
                          collaborations with various wearable tech companies
                          enables the team to actively measure vitals and
                          thereby recommend the right exercise, nutritional or
                          lifestyle prescription that can have long term
                          benefits and positive effects on the the human body
                          functions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="counter"
              className="fs-12 MuliRegular clr-4 position-absolute counters SlideCount"
            ></div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Advanced Therapy Section ||---------------> */}
      {/* <!---------------|| Section-6 ||---------------> */}
      <section
        className="w-100 clearfix bg_1 doctorchangeing moblie_hospital mt-5"
        id="Doctorchangeingsection"
      >
        <div className="container">
          <div className="BodyByData w-100 clearfix">
            <div className="row">
              <div className="col-lg-5 order-2">
                <div className="BeterImgs w-100 clearfix align-bottom singaldoctor text-lg-left text-center pt-5">
                  <img
                    src="/images/img69.png"
                    className="img-fluid"
                    alt="No Images"
                  />
                </div>
              </div>
              <div className="col-lg-7 order-1">
                <div className="sugerdoc w-100 clearfix d-table h-100">
                  <div className="d-table-cell align-middle TextMiddle">
                    <p className="fs-54 mb-sm-4 fw-500 lh-52">
                      Truly amazing and life changing, many of our post surgery
                      patients have shown speedy recovery thanks to the flawless
                      service from ProPhysio
                    </p>
                    <div className="w-100 clearfix mt-5">
                      <p className="fs-30 fw-700 m-0">Dr Harish Puranik</p>
                      <p className="fs-30 fw-700 m-0">
                        Consultant Pulmonologist
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section-6 ||---------------> */}
      {/* <!---------------|| Section Fourth ||---------------> */}
      <section
        className="w-100 clearfix  py-5 my-sm-4 stepbetter"
        id="Stepbettersection"
      >
        <div className="container">
          <div className="stephealth">
            <div className="findheading text-center mb-md-4">
              <h1 className="fs-34 fw-700 clr-2">
                Take a step towards positive health transformation
              </h1>
            </div>
            <div className="more_show text-center py-4">
              <Link
                class="show_btn btn bg-blue fs-22 px-4 py-2 br-24 text-white"
                data-toggle="modal"
                data-target="#GetAssessedModal"
              >
                Get assessed now!{" "}
              </Link>
            </div>
            <div className="findparagrph text-center MuliLight">
              <p className="fs-22 clr-2 mb-0">
                Get a quick assessment and access simple guidelines on your
                health. A 5 minute simple questionnaire will give you great
                insights on how your health can be positively transformed.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!---------------|| Section Fourth ||---------------> */}
      {/* <!---------------|| Connect us Section ||---------------> */}
      <section
        className="w-100 clearfix withcontact py-5 CnnectWithUs bg-lightgray Deteled_services_getting"
        id="ConnectReady"
      >
        <div className="container-fluid py-md-5">
          <div className="HeadingDiv w-100 clearfix text-center">
            <h2 className="fs-72 lh-80 fw-700">Ready to connect with us? </h2>
            <p className="fs-22 MuliLight pt-2 mb-4">
              Whether you are an individual or an institution ProPhysio has a
              wide range of offerings for you. Are you an individual seeking
              guidance to live a healthy life?
            </p>
          </div>
          <div className="client pb-xl-5 ReadyToConnect">
            <div className="row">
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-pink-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/16.png)" }}
                  >
                    <div className="trulyCare1 d_table_cell_none align-middle w-75">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Virtual <br />
                        Session
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(0)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-gray-1 d-table w-100 clearfix p-4 br-8"
                    style={{ backgroundImage: "url(images/17.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Home <br />
                        Visit
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(1)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-7 m-auto p-3">
                <div className="prectical">
                  <div
                    className="HealthCare trulycareclient bg-purpal-1 w-100 clearfix p-4 br-8 d-table"
                    style={{ backgroundImage: "url(images/18.png)" }}
                  >
                    <div className="trulyCare1 w-75 d_table_cell_none align-middle">
                      <p className="fs-57 MuliBold lh-62 mb-0">
                        Visit <br />
                        Clinic
                      </p>
                      <div className="pt-5">
                        <Link
                          className=" px-3 d-inline-block fs-22 btn bg-white br-24 fw-500 ls-1 clr-2 br-blur1 BtnImg1"
                          onClick={() => bookNowHandle(1)}
                        >
                          Book now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="NotSureTo w-100 clearfix text-center pt-4">
            <h3 className="fs-45 MuliExtra pl-5">Not sure where to begin?</h3>
            <h4 className="fs-54 MuliLight pt-5">
              <img
                src="/images/icons/icon14.png"
                className="img-fluid"
                alt="No Images"
              />
              <span className="d-inline-block pl-5">
                Call us now at{" "}
                <a href="tel:+91 8197378444" className="clr-1">
                  +91 8197378444
                </a>
              </span>
            </h4>
          </div>
        </div>
      </section>
      {/* <!---------------|| Connect Us Section ||---------------> */}
      <DetailBackToTop />
    </>
  );
}

export default detailedElderlyService;
